import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import {
    MetricCalculation,
    MetricConfiguration,
    PluginConnection,
} from '../../../../domain/metrics';
import { PluginEntity, ViewEntity } from '../../../../domain';
import { PeerGroup } from '../../../../domain/peers';
import { MetricSummaryQuery } from '../../../../app/metrics';
import {
    AnyResolvedType,
    isReferenceProperty,
    isReferenceType,
    Property,
    resolveType,
} from '../../../../domain/attributes';
import { assert } from '../../../../util/assert';
import { Asset } from '../../../../domain/assets';
import { ReportDetailConfig } from './reportDetailConfig';
import { ReportDetailAggregate } from './reportDetailModel';
import { ReportDetailLoader } from './reportDetailnterface';
import { buildQueries } from './reportDetailFactory';
import { chain, groupBy } from 'lodash';
import { ReportDetailMetricAggregate } from './metric';
import { MetricDefinitionKind } from '../../../../api';

export function createReportDetailLoader(
    config: Pick<ReportDetailConfig, 'repository'>
): ReportDetailLoader {
    const { repository } = config;

    const METRIC_ORDER: MetricDefinitionKind[] = [
        'performance',
        'outcome',
        'diagnostic',
        'input',
    ];

    return {
        useLoad(context, props) {
            const report = repository.report.useLookup(context, props);

            const plugins = repository.plugin.useFind(context, {}, {});

            const assets = repository.asset.useFind(context, {}, {});

            const connections = repository.connection.useFind(
                context,
                {
                    workspaces: [
                        {
                            id: report.data?.asset.id!,
                        },
                    ],
                },
                { enabled: plugins.status === 'success' && report.status === 'success' }
            );

            const connectionsByPlugin = useMemo(
                () =>
                    connections.data?.reduce(
                        (acc, item) => ({ ...acc, [item.plugin.id]: item }),
                        {} as Record<string, PluginConnection | undefined>
                    ) ?? {},
                [connections.data]
            );

            // const insightDefinition = repository.insight.definition.useFind(
            //     context,
            //     {
            //         assets: [report.data?.asset.id!],
            //         category: 'opportunity',
            //         // NOTE temporary hack to avoid long load times
            //         limit: 10,
            //     },
            //     { enabled: report.status === 'success' }
            // );

            const opportunities = repository.insight.opportunity.useFind(
                { ...context, workspace: { id: report.data?.asset.id! } },
                {
                    limit: 10,
                    since: report.data?.period.from,
                    before: report.data?.period.to,
                    aggregate: {
                        keys: ['definition', 'kind'],
                        take: 1,
                    },
                    orderBy: [
                        {
                            key: 'priority',
                            direction: 'desc',
                        },
                    ],
                },
                { enabled: plugins.status === 'success' && report.status === 'success' }
            );

            // const selectedDefinitions = useMemo(() => {
            //     return chain(insightDefinition.data?.items ?? [])
            //         .flatMap((item) => {
            //             const connection = connectionsByPlugin[item.plugin];
            //             if (!connection) {
            //                 return [];
            //             }
            //             if (connection.status !== 'ready') {
            //                 return [];
            //             }
            //             return item;
            //         })
            //         .reject((item) =>
            //             [
            //                 'googleads_spend_campaign_type_distribution_anomaly_detection',
            //                 'googleads_branded_vs_unbranded_oportunity_detection',
            //                 'googleads_keyword_match_type_distribution_anomaly_detection',
            //                 'googleads_spend_bidding_strategy_type_distribution_anomaly_detection',
            //             ].includes(item.id)
            //         )
            //         .sampleSize(5)
            //         .slice(0, 5)
            //         .map((item) => item.id)
            //         .value();
            // }, [insightDefinition.data?.items, connectionsByPlugin]);

            // const opportunities = repository.insight.opportunity.useCreate(
            //     { ...context, workspace: { id: report.data?.asset.id! } },
            //     {
            //         date: report.data?.period.to!,
            //         assets: [report.data?.asset.id!],
            //         definitions: selectedDefinitions,
            //         limit: 10,
            //     },
            //     {
            //         enabled:
            //             plugins.status === 'success' &&
            //             report.status === 'success' &&
            //             insightDefinition.status === 'loaded' &&
            //             connections.status === 'success',
            //     }
            // );

            const traitTypes = useMemo(
                () =>
                    chain(plugins.data ?? [])
                        .flatMap((plugin) =>
                            plugin.traits.map((trait) => ({ plugin, type: trait.type }))
                        )
                        .flatMap((item) =>
                            isReferenceType(item.type)
                                ? [{ ...item, type: item.type }]
                                : []
                        )
                        .uniqBy((item) => item.type.id)
                        .value(),
                [plugins.data]
            );

            const responseMembers = repository.member.useFind(
                context,
                {
                    workspace: { id: report.data?.asset.id! },
                    types: traitTypes.map((item) => item.type),
                },
                { enabled: plugins.status === 'success' && report.status === 'success' }
            );

            const traitsByPlugin = useMemo(
                () =>
                    plugins.data?.reduce(
                        (acc, plugin) => ({
                            ...acc,
                            [plugin.id]: plugin.traits.map(
                                (trait): Property<AnyResolvedType> => {
                                    if (!isReferenceType(trait.type)) {
                                        return {
                                            ...trait,
                                            type: trait.type,
                                        };
                                    }
                                    return {
                                        ...trait,
                                        type: resolveType(
                                            responseMembers.data ?? [],
                                            trait.type
                                        ),
                                    };
                                }
                            ),
                        }),
                        {} as Record<string, Property<AnyResolvedType>[] | undefined>
                    ) ?? {},
                [plugins.data, responseMembers.data]
            );

            const views = repository.view.useFind(context, {
                assets: report.data?.asset ? [report.data.asset] : undefined
            }, {});
            const peergroups = repository.peergroup.useFind(
                context,
                {
                    workspace: {
                        id: report.data?.asset.id!,
                    },
                    plugins: plugins.data?.map((item) => item.id) ?? [],
                },
                { enabled: plugins.status === 'success' && report.status === 'success' }
            );

            const definitions = repository.definition.useFind(
                context,
                {
                    plugins: plugins.data ?? [],
                    assets: report.data?.asset ? [report.data.asset]: undefined
                 },
                { enabled: plugins.status === 'success' && report.status === 'success' }
            );

            const configurations = repository.configuration.useFind(
                context,
                {
                    workspace: {
                        id: report.data?.asset?.id!,
                    },
                    metrics: definitions.data ?? [],
                },
                {
                    enabled:
                        plugins.status === 'success' &&
                        report.status === 'success' &&
                        definitions.status === 'success',
                }
            );

            const configurationByMetric = useMemo(
                () =>
                    configurations.data?.reduce(
                        (acc, item) => ({ ...acc, [item.definition.id]: item }),
                        {} as Record<
                            string,
                            | Pick<MetricConfiguration, 'definition' | 'ranking'>
                            | undefined
                        >
                    ) ?? {},
                [configurations.data]
            );

            const pluginsById = useMemo(
                () =>
                    plugins.data?.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, PluginEntity | undefined>
                    ) ?? {},
                [plugins.data]
            );

            const assetsById = useMemo(
                () =>
                    assets.data?.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, Asset | undefined>
                    ) ?? {},
                [assets.data]
            );

            const viewsByPlugin = useMemo(
                () => groupBy(views.data, (item) => item.plugin),
                [views.data]
            );

            const peergroupsByPlugin = useMemo(
                () =>
                    peergroups.data?.reduce(
                        (acc, item) => ({ ...acc, [item.plugin.id]: item }),
                        {} as Record<string, PeerGroup | undefined>
                    ) ?? {},
                [peergroups.data]
            );

            const viewByMetric = useMemo(() => {
                return (
                    definitions.data?.reduce(
                        (acc, item) => {
                            const candidateViews = viewsByPlugin[item.plugin] ?? [];
                            const found = candidateViews.find((view) =>
                                view.columns.some((column) => column.key === item.key)
                            );
                            if (!found) {
                                console.warn(`no view matched metric ${item.id}`);
                                return acc;
                            }
                            return { ...acc, [item.id]: found };
                        },
                        {} as Record<string, ViewEntity | undefined>
                    ) ?? {}
                );
            }, [definitions.data]);

            const queries: MetricSummaryQuery[] = useMemo(() => {
                return buildQueries({
                    definitions: definitions.data ?? [],
                    peersByPlugin: peergroupsByPlugin,
                    viewsByMetric: viewByMetric,
                    report: report.data!,
                    configurationByMetric,
                });
            }, [
                definitions.data,
                report.data,
                peergroupsByPlugin,
                viewByMetric,
                configurationByMetric,
            ]);

            const summaries = repository.summary.useBulk(context, queries, {
                enabled:
                    definitions.status === 'success' &&
                    report.status === 'success' &&
                    peergroups.status === 'success' &&
                    views.status === 'success' &&
                    configurations.status === 'success',
            });

            const summaryByDefinition = useMemo(
                () =>
                    summaries.data?.reduce(
                        (acc, item) => ({ ...acc, [item.definition.id]: item }),
                        {} as Record<string, MetricCalculation | undefined>
                    ) ?? {},
                [summaries.data]
            );

            const status = [
                report.status,
                plugins.status,
                definitions.status,
                connections.status,
                summaries.status,
                responseMembers.status,
                opportunities.status,
                assets.status,
                configurations.status,
            ].every(
                (item) =>
                    // TODO unify response models
                    item === 'success' || item === 'loaded' || item === 'empty'
            )
                ? 'success'
                : 'loading';

            // console.log('status', status, [
            //     report.status,
            //     plugins.status,
            //     definitions.status,
            //     connections.status,
            //     summaries.status,
            //     responseMembers.status,
            //     opportunities.status,
            //     assets.status,
            //     configurations.status,
            // ]);

            return {
                status,
                item: useQuery({
                    enabled: status === 'success',
                    queryKey: ['report', 'detail', props.id, status],
                    queryFn() {
                        assert(report.data, 'report data not available');
                        const asset = assetsById[report.data.asset.id];
                        assert(asset, `asset ${report.data.asset.id} not found`);
                        const aggregate: ReportDetailAggregate = {
                            report: report.data,
                            asset,
                            connections: connections.data ?? [],
                            views: views.data ?? [],
                            opportunities:
                                opportunities.data?.items.flatMap((insight) => {
                                    const plugin = pluginsById[insight.definition.plugin];
                                    if (!plugin) {
                                        console.warn(
                                            `plugin ${insight.definition.plugin} not found`
                                        );
                                        return [];
                                    }
                                    return [
                                        {
                                            insight,
                                            plugin,
                                        },
                                    ];
                                }) ?? [],
                            metrics: chain(definitions.data ?? [])
                                .filter((item) => item.kind !== 'input')
                                .flatMap((definition): ReportDetailMetricAggregate[] => {
                                    const plugin = pluginsById[definition.plugin];
                                    if (!plugin) {
                                        console.warn(
                                            `plugin ${definition.plugin} not found`
                                        );
                                        return [];
                                    }
                                    const traits = traitsByPlugin[plugin.id];
                                    if (!traits) {
                                        console.warn(
                                            `traits for plugin ${definition.plugin} not found`
                                        );
                                        return [];
                                    }
                                    const view = viewByMetric[definition.id];
                                    const connection =
                                        connectionsByPlugin[definition.plugin];
                                    const peergroup =
                                        peergroupsByPlugin[definition.plugin];
                                    const summary = summaryByDefinition[definition.id];
                                    const configuration =
                                        configurationByMetric[definition.id];

                                    if (!connection) {
                                        console.warn(
                                            `connection for plugin ${definition.plugin} not found`
                                        );
                                        return [];
                                    }
                                    if (!view) {
                                        console.warn(
                                            `view for metric ${definition.id} not found`
                                        );
                                        return [];
                                    }
                                    if (!peergroup) {
                                        console.warn(
                                            `peergroup for plugin ${definition.plugin} not found`
                                        );
                                        return [];
                                    }
                                    if (!summary) {
                                        console.warn(
                                            `summary for metric ${definition.id} not found`
                                        );
                                        return [];
                                    }
                                    if (!configuration) {
                                        console.warn(
                                            `configuration for metric ${definition.id} not found`
                                        );
                                        return [];
                                    }
                                    return [
                                        {
                                            definition,
                                            plugin,
                                            traits,
                                            connection,
                                            peergroup,
                                            summary,
                                            configuration,
                                            view,
                                        },
                                    ];
                                })
                                .orderBy(
                                    (item) => [
                                        plugins.data?.findIndex(
                                            (candidate) => candidate.id === item.plugin.id
                                        ),
                                        METRIC_ORDER.findIndex(
                                            (candidate) =>
                                                candidate === item.definition.kind
                                        ),
                                    ],
                                    ['asc', 'asc']
                                )
                                .value(),
                        };
                        return aggregate;
                    },
                }),
            };
        },
    };
}
