import { isPluginIntegrationGroupItem } from '../../../api';
import { Integration, IntegrationDefinition } from '../../assets';
import { AnyResolvedType, PropertyMap } from '../../attributes';
import { PluginConnection } from '../../metrics';
import { PluginEntity } from './pluginModel';

/**
 * Returns true if a plugin is installed in a workspace
 * @param item
 */
export function isInstalled(item: {
    plugin: PluginEntity;
    connection: PluginConnection;
}) {
    return item.connection.status !== 'pending';
}

export function getPluginTraits(
    plugin: PluginEntity<AnyResolvedType>
): PropertyMap<AnyResolvedType> {
    return plugin.traits.reduce((acc, trait) => ({ ...acc, [trait.key]: trait }), {});
}


export function IsPluginAvailable(
    definitions: IntegrationDefinition[],
    integrations: Integration[],
    item: PluginEntity
) {
    const method = item.dependencies.mode === 'all' ? 'every' : 'some';
    return item.dependencies.items[method]((dependency) => {
        if (isPluginIntegrationGroupItem(dependency)) {
            return definitions.some(
                (candidate) => candidate.kind === dependency.integration
            );
        }
        const method = item.dependencies.mode === 'all' ? 'every' : 'some';
        return dependency.items[method]((reference) => {
            const definition = definitions.find(
                (candidate) => candidate.kind === reference.integration
            );
            if (!definition) {
                return false;
            }
            const integration = integrations.find(
                (candidate) => candidate.definitionId === definition.id
            );
            if (!integration) {
                return false;
            }
            return true;
        });
    });
}
