import { useQuery, useQueryClient } from '@tanstack/react-query';
import { assert } from '../../../util/assert';
import { SurveyAdapter } from './surveyAdapter';
import { SurveyRepository } from './surveyInterface';

export function createSurveyRepository(adapter: SurveyAdapter): SurveyRepository {
    const PREFIX = ['survey'];
    return {
        useFind(context, query, options) {
            const queryClient = useQueryClient();
            const result = useQuery({
                staleTime: Infinity,
                refetchOnWindowFocus: false,
                ...options,
                queryKey: [...PREFIX, 'find', context.account, query],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
                onSuccess(data) {
                    for (const row of data) {
                        queryClient.setQueryData(
                            [...PREFIX, 'lookup', context.account, row.id],
                            row
                        );
                    }
                },
            });
            return result;
        },
        useLookup(context, query, options) {
            const result = useQuery({
                staleTime: Infinity,
                refetchOnWindowFocus: false,
                ...options,
                enabled: query !== null,
                queryKey: [...PREFIX, 'lookup', context.account, query?.id],
                async queryFn() {
                    assert(query, 'expected disabled query');
                    const response = await adapter.lookup(context, query);
                    return response;
                },
            });
            return result;
        },
    };
}
