import { ApplicationPluginProvider } from '../entrypoint';
import { ApplicationPluginConfig } from './applicationPluginConfig';
import { createMobilePlugin } from './mobile/mobilePluginProvider';
import { createOnboardingPlugin } from './onboarding';

export function createApplicationPlugins(
    config: ApplicationPluginConfig
): ApplicationPluginProvider[] {
    return [createMobilePlugin(config.mobile), createOnboardingPlugin(config.onboarding)];
}
