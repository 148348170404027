import { useLocation, useNavigate } from 'react-router';
import React from 'react';
import { ErrorViewProps } from '../../../../../base';
import { AssistantContainerBaseConfig } from '../../../base';
import { AssistantErrorContainerProps } from './assistantErrorProps';

export function createAssistantErrorContainer(
    config: AssistantContainerBaseConfig,
    View: React.FC<ErrorViewProps>
): React.FC<AssistantErrorContainerProps> {
    const controller = config.kernel.provider.createErrorController({
        id: 'assistant',
    });
    return ({ ...containerProps }) => {
        const navigate = useNavigate();
        const location = useLocation();
        const props = controller.useProps({ ...containerProps, location, navigate });
        return <View {...props} />;
    };
}
