import { AccountScope } from '../../../../app';
import { getSubscription, PortalDto, SubscriptionDto } from '../../../../../api';
import { SubscriptionAdapter, SubscriptionAdapterV2 } from '../../../../app/billing';
import { BillingSubscription } from '../../../../domain/billing';
import { SubscriptionApiAdapterConfig } from './subscriptionApiConfig';
import { AxiosResponse } from 'axios';

export function createSubscriptionApiImpl(
    config: SubscriptionApiAdapterConfig
): SubscriptionAdapter {
    return {
        async getSubscription(workspace) {
            const response = await getSubscription(config.axios);
            return response;
        },
    };
}

export function createSubscriptionV2Impl(
    config: SubscriptionApiAdapterConfig
): SubscriptionAdapterV2 {
    async function getHeaders(context: AccountScope) {
        if (context.auth.scheme.kind === 'legacy') {
            await context.auth.scheme.store.waitAuthenticated();
            return {
                Authorization: `bearer ${context.auth.scheme.store.authToken}`,
            };
        }
        throw Error('scheme not implemented ' + context.auth.scheme.kind);
    }
    return {
        async lookup(context) {
            const headers = await getHeaders(context);
            const response = await config.axios.get<
                unknown,
                AxiosResponse<BillingSubscription>
            >(`/api/v1/billing/subscription`, { headers });
            return response.data;
        },
        async update(context, subscriptionId, payload, options) {
            const headers = await getHeaders(context);
            console.log('payload', payload);
            await config.axios.patch<unknown, AxiosResponse<BillingSubscription>>(
                `/api/v1/billing/subscription/${subscriptionId}`,
                { plan: payload.plan, add_ons: payload.add_ons },
                { params: options, headers }
            );
        },
        async createPortal(context, portal) {
            const headers = await getHeaders(context);
            const response = await config.axios.post<unknown, AxiosResponse<PortalDto>>(
                `/api/v1/billing/portal`,
                portal,
                {
                    headers,
                }
            );
            return response.data;
        },
        async refresh(context, subscriptionId) {
            const headers = await getHeaders(context);
            const resp = await config.axios.post<
                unknown,
                AxiosResponse<BillingSubscription>
            >(`/api/v1/billing/subscription/${subscriptionId}/refresh`, {}, { headers });
            return resp.data;
        },
    };
}
