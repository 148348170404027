import { createContext, useContext } from 'react';
import {
    Workspace,
    Dashboard,
    DashboardReference,
    WorkspaceReference,
    AccountReference,
    PluginEntity,
} from '../../../domain';
import { WorkspaceContextV2 } from '../../platform';
import { ChartAdapter } from '../chart';
import { DashboardConfigurationAdapter } from '../configuration';
import { PreferencesAdapter } from '../preferences';
import { DashboardAdapter } from './dashboardAdapter';
import { AnyResolvedType } from '../../../domain/attributes';
import { PeerGroup } from '../../../domain/peers';
import { UseQueryResult } from '@tanstack/react-query';

export interface DashboardContextValue {
    workspace: Workspace;
    dashboard: Dashboard;
    plugin: PluginEntity<AnyResolvedType> | null;
    plugins: UseQueryResult<PluginEntity<AnyResolvedType>[]>;
    peergroup: PeerGroup | null;
    refetchPeerGroup: () => void;
    adapter: {
        dashboard: DashboardAdapter;
        chart: ChartAdapter;
        preferences: PreferencesAdapter;
        configuration: DashboardConfigurationAdapter;
    };
}

export interface DashboardContextValueV2 extends WorkspaceContextV2 {
    dashboard: DashboardReference;
}

export const DashboardContext = createContext<DashboardContextValue | null>(null);

export const useDashboardContext = () => {
    const context = useContext(DashboardContext);
    if (!context) {
        throw new Error(`dashboard context not available`);
    }
    return context;
};
