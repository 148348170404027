import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import React, { useMemo } from 'react';
import { Box, Tooltip } from '@chakra-ui/react';
import { LockIcon } from '@chakra-ui/icons';
import { SubcategoriesAccessRequest } from '../../../../../base';
import { useAuthenticatedContext } from '../../../../../container';
import { ApplicationEntryEnhancer } from '../../../../entrypoint';
import { TreeInputProps } from '../../../../ui';
import { mapEnum } from '../../../../domain/attributes';
import { useAccountContextV2 } from '../../../../context';
import { PropertyInputController } from '../../../input';
import { FeatureSetRepository } from '../../../../app';
import { FeatureSet } from '../../../../domain';
import { OnboardingAssetOptionsSchema } from '../../../../service/onboarding';

export function createFeatureSubcategoryStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        const {
            route: { createDashboardRoute },
        } = config;

        function enhanceRepository(
            repository: FeatureSetRepository
        ): FeatureSetRepository {
            return {
                ...repository,
                useLookup(...args) {
                    const [context] = args;
                    const location = useLocation();
                    const [searchParams] = useSearchParams();

                    const options = useMemo(() => {
                        const raw = Object.fromEntries(searchParams.entries());
                        const parsed = OnboardingAssetOptionsSchema.safeParse(raw);
                        if (!parsed.success) {
                            return null;
                        }
                        return parsed.data;
                    }, [searchParams]);

                    const preferences =
                        instance.repository.platform.clientPreference.useLookup(
                            context,
                            {},
                            {}
                        );

                    const base = repository.useLookup(...args);

                    const transformed = useMemo<FeatureSet>(() => {
                        if (options?.mode !== 'report') {
                            return base.data ?? {};
                        }
                        if (!location.pathname.includes('onboarding')) {
                            // only override features on the onboarding step
                            return base.data ?? {};
                        }
                        // if (preferences.data?.layout !== 'light') {
                        //     console.info(
                        //         'light layout not set, skipping feature logic...'
                        //     );
                        //     return base.data ?? {};
                        // }
                        // console.info('light layout applied, force enabling features...');
                        console.info(
                            'report mode found, force enabling peer group features...'
                        );
                        return {
                            ...base.data,
                            subcategories: base.data?.subcategories
                                ? {
                                      ...base.data.subcategories,
                                      enabled: true,
                                  }
                                : undefined,
                            numeric_trait_filters: base.data?.numeric_trait_filters
                                ? {
                                      ...base.data.numeric_trait_filters,
                                      enabled: true,
                                  }
                                : undefined,
                        };
                    }, [
                        base.data?.subcategories,
                        preferences.data?.layout,
                        options,
                        location.pathname,
                    ]);

                    // if (!location.pathname.includes('onboarding')) {
                    //     // only override features on the onboarding step
                    //     return base;
                    // }

                    if (base.status !== 'success') {
                        return base;
                    }

                    if (preferences.status !== 'success') {
                        return base;
                    }

                    return { ...base, data: transformed };
                },
            };
        }

        function enhanceController(
            controller: PropertyInputController
        ): PropertyInputController {
            return {
                ...controller,
                useProps(props) {
                    const {
                        data: {
                            // features: { data: featureset },
                            features: featureset,
                        },
                    } = useAccountContextV2();

                    const context = useAuthenticatedContext();
                    const original = controller.useProps(props);

                    if (original.type !== 'tree' || !featureset) {
                        return original;
                    }

                    if (!featureset?.subcategories) {
                        return original;
                    }

                    if (featureset?.subcategories?.enabled) {
                        return original;
                    }

                    const subverticalKeys = useMemo(
                        () =>
                            new Set(
                                original.options.flatMap((option) =>
                                    option.children.map((child) => child.value)
                                )
                            ),
                        [original.options]
                    );

                    const request: SubcategoriesAccessRequest = {
                        ...featureset.subcategories,
                        value: {
                            value: '',
                            parent: null,
                        },
                    };

                    const restrict = () => {
                        context.access.restrict({
                            request,
                            restricted: true,
                        });
                    };

                    const modified: TreeInputProps = {
                        ...original,
                        // value: modifiedValue,
                        options: original.options.map((option) => ({
                            ...option,
                            icons: option.disabled
                                ? [
                                      ...(option.icons ?? []),
                                      <Tooltip
                                          placement="top"
                                          label={
                                              <Box p={2}>Upgrade to use subverticals</Box>
                                          }
                                      >
                                          <LockIcon
                                              color="gray.500"
                                              cursor="pointer"
                                              onClick={restrict}
                                          />
                                      </Tooltip>,
                                  ]
                                : option.icons,
                            children: option.children.map((child) => ({
                                ...child,
                                icons: child.disabled
                                    ? [
                                          ...(child.icons ?? []),
                                          <Tooltip
                                              placement="top"
                                              label={
                                                  <Box p={2}>
                                                      Upgrade to use subverticals
                                                  </Box>
                                              }
                                          >
                                              <LockIcon
                                                  color="gray.500"
                                                  cursor="pointer"
                                                  onClick={restrict}
                                              />
                                          </Tooltip>,
                                      ]
                                    : child.icons,
                            })),
                        })),
                        onChange(values) {
                            if (values.some((value) => subverticalKeys.has(value))) {
                                context.access.restrict({ request, restricted: true });
                                return;
                            }
                            return original.onChange(values);
                        },
                    };
                    return modified;
                },
            };
        }

        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                attributes: {
                    ...config.controller.attributes,
                    createPropertyInput(...args) {
                        const controller =
                            config.controller.attributes.createPropertyInput(...args);
                        return enhanceController(controller);
                    },
                },
            },
            repository: {
                ...config.repository,
                platform: {
                    ...config.repository.platform,
                    createFeatureSet(...args) {
                        const repository = config.repository.platform.createFeatureSet(
                            ...args
                        );
                        return enhanceRepository(repository);
                    },
                },
                attributes: {
                    ...config.repository.attributes,
                    createMember(adapter) {
                        const repository =
                            config.repository.attributes.createMember(adapter);

                        return {
                            ...repository,
                            useFind(...args) {
                                const {
                                    data: {
                                        // features: { data: featureset },
                                        features: featureset,
                                    },
                                } = useAccountContextV2();
                                const query = repository.useFind(...args);
                                if (!query.data) {
                                    return query;
                                }
                                if (featureset?.subcategories?.enabled) {
                                    return query;
                                }
                                return {
                                    ...query,
                                    data:
                                        query.data?.map((item) =>
                                            mapEnum(item, (member, parent) => {
                                                if (!parent) {
                                                    return member;
                                                }
                                                // if member has no parent it's a subcategory
                                                return {
                                                    ...member,
                                                    isDisabled: true,
                                                    statusReason: 'feature locked',
                                                };
                                            })
                                        ) ?? [],
                                };
                            },
                        };
                    },
                },
            },
            route: {
                ...config.route,
                createDashboardRoute(config) {
                    return createDashboardRoute({
                        ...config,
                        controller: {
                            ...config.controller,
                            useSegmentItem(...args) {
                                const context = useAuthenticatedContext();
                                // const api = useFeatureApi();
                                // const featureset = api.getFeatureset();
                                const {
                                    data: {
                                        // features: { data: featureset },
                                        features: featureset,
                                    },
                                } = useAccountContextV2();

                                const mapped = config.controller.useSegmentItem(...args);

                                if (!featureset?.subcategories) {
                                    return mapped;
                                }

                                const request: SubcategoriesAccessRequest = {
                                    ...featureset.subcategories,
                                    value: {
                                        value: '',
                                        parent: null,
                                    },
                                };

                                if (!featureset.subcategories?.enabled) {
                                    const restrict = () => {
                                        context.access.restrict({
                                            request,
                                            restricted: true,
                                        });
                                    };

                                    return {
                                        ...mapped,
                                        getInputProps(props) {
                                            // if (
                                            //     isCurrencyProperty(filter.property) &&
                                            //     props.type === 'range'
                                            // ) {
                                            //     return mapped.getInputProps({
                                            //         ...props,
                                            //         isDisabled: true,
                                            //     });
                                            // }

                                            if (props.type !== 'tree') {
                                                return mapped.getInputProps(props);
                                            }

                                            const subverticalKeys = new Set(
                                                props.options.flatMap((option) =>
                                                    option.children.map(
                                                        (child) => child.value
                                                    )
                                                )
                                            );

                                            const valueUncheckedSubcategories: Array<
                                                string | number
                                            > =
                                                props.value?.filter(
                                                    (value) => !subverticalKeys.has(value)
                                                ) ?? [];

                                            return {
                                                ...props,
                                                type: 'tree',
                                                onChange(values) {
                                                    const includesSubvertical =
                                                        values.some((value) =>
                                                            subverticalKeys.has(value)
                                                        );
                                                    if (includesSubvertical) {
                                                        // do not propagate event
                                                        return restrict();
                                                    }
                                                    return props.onChange(values);
                                                },
                                                // uncheck locked values
                                                value: valueUncheckedSubcategories,
                                                options: (
                                                    props as TreeInputProps
                                                ).options.map((option) => {
                                                    if (
                                                        subverticalKeys.has(option.value)
                                                    ) {
                                                        return {
                                                            ...option,
                                                            disabled: true,
                                                            allowClickWhenDisabled: true,
                                                            icons: [
                                                                ...(option.icons ?? []),
                                                                <Tooltip
                                                                    placement="top"
                                                                    label={
                                                                        <Box p={2}>
                                                                            Upgrade to use
                                                                            subverticals
                                                                        </Box>
                                                                    }
                                                                >
                                                                    <LockIcon
                                                                        onClick={restrict}
                                                                    />
                                                                </Tooltip>,
                                                            ],
                                                        };
                                                    }
                                                    return {
                                                        ...option,
                                                        children: option.children.map(
                                                            (child) => ({
                                                                ...child,
                                                                disabled: true,
                                                                allowClickWhenDisabled:
                                                                    true,
                                                                icons: [
                                                                    ...(child.icons ??
                                                                        []),
                                                                    <Tooltip
                                                                        placement="top"
                                                                        label={
                                                                            <Box p={2}>
                                                                                Upgrade to
                                                                                use
                                                                                subverticals
                                                                            </Box>
                                                                        }
                                                                    >
                                                                        <LockIcon
                                                                            onClick={
                                                                                restrict
                                                                            }
                                                                        />
                                                                    </Tooltip>,
                                                                ],
                                                            })
                                                        ),
                                                    };
                                                }),
                                            };
                                        },
                                    };
                                }
                                return mapped;
                            },
                        },
                    });
                },
            },
        });

        return instance;
    };
}
