import { useQuery } from '@tanstack/react-query';
import { chain, groupBy } from 'lodash';
import { useMemo } from 'react';
import { CollaboratorEntity } from '../../../../app';
import { PluginConnection } from '../../../../domain/metrics';
import { Asset } from '../../../../domain/assets';
import { ReportListConfig } from './reportListConfig';
import { ReportListLoader } from './reportListInterface';
import { ReportListItemAggregate } from './reportListModel';

export function createReportListLoader(config: ReportListConfig): ReportListLoader {
    const { repository } = config;
    return {
        useLoad(context, props) {
            const reports = repository.report.useFind(
                context,
                {
                    limit: 100,
                },
                {}
            );
            const assets = repository.asset.useFind(context, {}, { staleTime: Infinity });

            const assetIds = useMemo(
                () => new Set(reports.data?.map((item) => item.asset.id)),
                [reports.data]
            );

            const workspaces = useMemo(
                () =>
                    chain(assets.data ?? [])
                        .filter((item) => assetIds.has(item.id))
                        .map((item) => item.id)
                        .value(),
                [assets.data, assetIds]
            );

            const collaborators = repository.collaborator.useFind(
                context,
                {
                    workspaces,
                },
                {
                    staleTime: Infinity,
                    enabled: reports.status === 'success' && assets.status === 'success',
                }
            );

            const connections = repository.connection.useFind(
                context,
                {
                    workspaces: workspaces.map((workspace) => ({ id: workspace })),
                },
                {
                    staleTime: Infinity,
                    enabled: reports.status === 'success' && assets.status === 'success',
                }
            );

            const collaboratorById = useMemo(
                () =>
                    collaborators.data?.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, CollaboratorEntity | undefined>
                    ) ?? {},
                [collaborators.data]
            );

            const assetById = useMemo(
                () =>
                    assets.data?.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, Asset | undefined>
                    ) ?? {},
                [assets.data]
            );

            const connectionsByWorkspace = useMemo(
                () =>
                    groupBy(
                        connections.data ?? [],
                        (item) => item.workspace.id
                    ) as Record<string, PluginConnection[] | undefined>,
                [connections.data]
            );

            const status = [
                reports.status,
                collaborators.status,
                connections.status,
                assets.status,
            ].some(
                (status) =>
                    // TODO unify these models
                    status === 'loading'
            )
                ? 'loading'
                : 'success';

            return {
                status,
                item: useQuery({
                    queryKey: ['report', 'list', context.account.id],
                    queryFn() {
                        const aggregates = reports.data?.flatMap(
                            (report): ReportListItemAggregate[] => {
                                const collaborator =
                                    collaboratorById[report.creator.id] ?? null;
                                const asset = assetById[report.asset.id] ?? null;
                                const connections =
                                    connectionsByWorkspace[report.asset.id];
                                if (!connections) {
                                    console.warn(
                                        `no connections found for workspace '${report.asset.id}'`
                                    );
                                    return [];
                                }
                                if (!asset) {
                                    console.warn(`no asset found'${report.asset.id}'`);
                                    return [];
                                }
                                return [
                                    {
                                        report,
                                        collaborator,
                                        connections,
                                        asset,
                                    },
                                ];
                            }
                        );

                        return aggregates;
                    },
                    enabled: status === 'success',
                }),
            };
        },
    };
}
