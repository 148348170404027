import { z } from 'zod';
import { ReportNewLoaderProps } from './reportNewProps';

export const ReportNewUrlParamsSchema = z.object({
    template: z.string(),
});
// .transform(
//     (item): ReportNewLoaderProps => ({
//         template: {
//             slug: item.template,
//         },
//     })
// );
