import React from 'react';
import { DashboardContext } from '../../../hooks';
import { QUERYBUILDER, useAuthenticatedContext } from '../../../container';
import { DashboardOnboarding } from '../../../domain';
import { useDashboardDepsViewProps } from '../../../views';
import { DashboardConnectionListView } from '../../../views/dashboard/deps/DashboardConnectionListView';
import { useWorkspaceApi, useDashboardApi } from '../../app';
import { InstallRouteConfig } from './installRouteConfig';

// const Z_INDEX_MODAL = 175;

export interface InstallViewProps {}

export interface ConnectionListContainerProps {
    as: React.FC<{ children?: React.ReactNode | undefined } & InstallViewProps>;
}

const ConnectionListContainer: React.FC<
    { children?: React.ReactNode | undefined } & ConnectionListContainerProps
> = ({ as: Component, ...props }) => {
    return <Component>{props.children}</Component>;
};

const ConnectionListView: React.FC<
    { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
> = (props) => {
    const api = { workspace: useWorkspaceApi(), dashboard: useDashboardApi() };

    const workspace = api.workspace.getWorkspace();
    const dashboard = api.dashboard.getDashboard();

    const context: DashboardContext = {
        ...useAuthenticatedContext(),
        querybuilder: QUERYBUILDER,
        assetId: workspace.id as number,
        slug: dashboard.id,
    };
    
    const { status: dashboardStatus, ...depViewProps } = useDashboardDepsViewProps(
        context,
        {
            requireMarkNotUsed: false,
            suspense: true,
        }
    );
    return (
        <DashboardOnboarding
            containerProps={{
                borderRadius: [4, 4, 4, 10],
                p: [4, 6, 8, 10, 12, 12],
                maxW: [
                    '100%',
                    '100%',
                    '100%',
                    '100%',
                    depViewProps.integrations.integrations.length === 1
                        ? '800px'
                        : '1200px',
                ],
            }}
            {...depViewProps}
            ctaText={depViewProps.mainCta}
            ctaError={depViewProps.integrationRequirementsErrorText}
            dashboard={{
                name: dashboard.title,
                description: dashboard.description ?? undefined,
            }}
            dashboardStatus={dashboardStatus}
            initialLocked={depViewProps.isLocked}
            // onClick={handleAccessClick}
            // containerProps={finalContainerProps}
            content={
                <DashboardConnectionListView
                    listProps={{
                        borderRadius: [4, 4, 4, 10],
                        p: [4, 6, 8, 10, 12, 12],
                        spacing: 5,
                    }}
                    context={context}
                    dashboardIntegrations={depViewProps.integrations}
                    // renderErrors={!props.primaryReason}
                    // listProps={finalConnectionListProps}
                />
            }
        >
            {props.children}
        </DashboardOnboarding>
    );
};

export function createInstallRoute(
    config: InstallRouteConfig
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    const {
        components: { layout: Layout },
        ...rest
    } = config;
    return (props) => {
        return (
            <Layout.Wrapper style={config.style}>
                <Layout.Main>
                    <ConnectionListContainer as={ConnectionListView} />
                </Layout.Main>
            </Layout.Wrapper>
        );
    };
}
