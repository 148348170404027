import { z } from 'zod';

// HACK server does not currently return dates with UTC timezone encoded
const UTCDateWithoutTimezoneSchema = z.union([z.date(), z
    .string()
    .transform((value) => (value.endsWith('Z') ? value : `${value}Z`))
    .pipe(z.date({ coerce: true }))
]);

export const IntegrationStatusDtoSchema = z.object({
    type: z.literal('priority'),
    started_at: z.date({ coerce: true }),
    closed_at: z.nullable(z.date({ coerce: true })),
    state: z.union([z.literal('running'), z.literal('completed'), z.literal('failed')]),
});

export const IntegrationLastCollectionDtoSchema = z.object({
    id_type: z.string(),
    result: z.discriminatedUnion('state', [
        z.object({
            state: z.literal('success'),
            executed_at: UTCDateWithoutTimezoneSchema,
            mappables_hash: z.nullable(z.string()),
        }),
        z.object({
            state: z.literal('not_available_yet'),
            executed_at: UTCDateWithoutTimezoneSchema,
        }),
        z.object({
            state: z.literal('failure'),
            executed_at: UTCDateWithoutTimezoneSchema,
            message: z.string(),
        }),
    ]),
});

export const IntegrationDtoSchema = z.object({
    id: z.number(),
    key: z.nullable(z.string()),
    organization_id: z.number(),
    name: z.nullable(z.string()),
    implementation: z.object({
        integration_definition_id: z.number(),
        slug: z.nullable(z.string()),
    }),
    is_connected: z.boolean(),
    is_active: z.boolean(),
    is_deleted: z.boolean(),
    is_data_synced: z.boolean(),
    is_historical_syncing: z.boolean(),
    last_error_reason: z.nullable(
        z.union([z.string(), z.record(z.string(), z.unknown())])
    ),
    sync_status: z.nullable(z.union([z.string(), IntegrationStatusDtoSchema])),
    last_mappable_collection: z
        .nullable(z.array(IntegrationLastCollectionDtoSchema))
        .default(null),
    last_sync: z.nullable(UTCDateWithoutTimezoneSchema),
    created_at: UTCDateWithoutTimezoneSchema,
    updated_at: UTCDateWithoutTimezoneSchema,
});

export const IntegrationListResponseSchema = z.object({
    count: z.number(),
    items: z.array(IntegrationDtoSchema),
    num_pages: z.number(),
    page: z.number(),
    page_size: z.number(),
});
