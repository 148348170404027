import { StudiesBaseRouteConfig } from './studiesBaseConfig';
import { StudiesContextProvider } from './studiesBaseInterface';

export function createStudiesContextProvider(
    config: Pick<StudiesBaseRouteConfig, 'hook'>
): StudiesContextProvider {
    const { hook } = config;
    return {
        useContext() {
            return {
                account: hook.useAccount(),
                workspace: hook.useOptionalWorkspaceContext(),
                auth: hook.useAuth(),
            };
        },
    };
}
