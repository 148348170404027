import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AccountScope } from '../../platform';
import { PeerGroupAdapter } from './groupAdapter';
import { PeerGroupRepository } from './groupInterface';

export function createPeerGroupRepository(
    adapter: PeerGroupAdapter
): PeerGroupRepository {
    function getPrefix(context: AccountScope) {
        return ['peergroups', context.account];
    }
    return {
        useFind(context, query, options) {
            return useQuery({
                queryKey: [...getPrefix(context), query],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
                refetchOnWindowFocus: false,
                ...options,
            });
        },
        useUpsert(context) {
            const client = useQueryClient();
            return useMutation({
                async mutationFn(group) {
                    const response = await adapter.putAll(context, [group]);
                    return response;
                },
                async onSuccess(data, variables) {
                    await client.refetchQueries({
                        queryKey: getPrefix(context),
                    });
                },
            });
        },
        useUpsertAll(context) {
            const client = useQueryClient();
            return useMutation({
                async mutationFn(groups) {
                    const response = await adapter.putAll(context, groups);
                    return response;
                },
                async onSuccess(data, variables) {
                    await client.refetchQueries({
                        queryKey: getPrefix(context),
                    });
                },
            });
        },
    };
}
