import { FormFieldItemController } from './formFieldItemInterface';

export function createFormFieldItemController(): FormFieldItemController {
    return {
        useProps(props) {
            return {
                name: props.name,
                label: props.label,
                description: props.description ?? null,
                error: props.error ?? null,
                isInline: props.isInline ?? false,
                isReadonly: props.isReadonly ?? false,
            };
        },
    };
}
