import { HelpConfig } from './helpConfig';
import { createHelpContainer } from './helpContainer';
import { createHelpController } from './helpController';
import { Help } from './helpInterface';

export function createHelp(config: HelpConfig): Help {
    return {
        adapter: config.adapter,
        Container: createHelpContainer(createHelpController(config)),
    };
}
