import { ApplicationThemeConfig, ApplicationThemeInit } from './applicationThemeConfig';
import { ApplicationTheme, ApplicationThemeEnhancer } from './applicationThemeInterface';
import { ApplicationThemeViewConfig } from './base';
import { createApplicationTabLayoutTheme } from './layout';
import {
    createApplicationShellDesktopView,
    createApplicationShellMobileView,
    createApplicationShellErrorView,
} from './shell';

export function createApplicationTheme(
    config: ApplicationThemeInit,
    enhancer?: ApplicationThemeEnhancer
): ApplicationTheme {
    return create({ ...config }, enhancer);
}

function create(
    config: ApplicationThemeConfig,
    enhancer?: ApplicationThemeEnhancer
): ApplicationTheme {
    if (enhancer) {
        return enhancer(create)(config);
    }
    const viewConfig: ApplicationThemeViewConfig = {
        UI: config.UI,
    };
    return {
        Shell: {
            Desktop: {
                Shell: createApplicationShellDesktopView(viewConfig),
                Error: createApplicationShellErrorView(viewConfig),
            },
            Mobile: {
                Shell: createApplicationShellMobileView(viewConfig),
                Error: createApplicationShellErrorView(viewConfig),
            },
        },
        Layout: {
            Tab: createApplicationTabLayoutTheme(viewConfig),
        },
    };
}
