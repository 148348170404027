import { AxiosInstance } from "axios";
import { FileResource } from "./fileInterface";
import { LegacyAuthenticationScheme } from "../common";
import { FileDtoSchema } from "./fileSchema";

export function createFileResource(client: AxiosInstance): FileResource {
    return {
        async fetch(context, fileId) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get(`/api/v1/files/${fileId}`, {
                params: {
                    // expand: ['definition'],
                    ...params,
                },
                headers: {
                    ...headers,
                },
            });
            try {
                const parsed = FileDtoSchema.parse(response.data);
                return parsed;
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async upload(context, dto) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }
            const formData = new FormData();
            formData.append('upload_file', dto.file, dto.file.name);
            const response = await client.post(`/api/v1/files`, formData, {
                params: {
                    ...params,
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    ...headers,
                },
            });
            try {
                const parsed = FileDtoSchema.parse(response.data);
                return parsed;
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
    }
}