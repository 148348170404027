import { Suspense } from 'react';
import {
    Box,
    Button,
    Center,
    Grid,
    GridItem,
    HStack,
    useToken,
    VStack,
} from '@chakra-ui/react';
import { CardSpinner } from '../../../../../domain';
import { TabLayoutTheme } from '../../../../layout';
import { ApplicationLayoutThemeConfig } from './applicationThemeConfig';

export function createApplicationTabLayoutTheme(
    config: ApplicationLayoutThemeConfig
): TabLayoutTheme {
    const {
        UI: {
            Application: { Link },
        },
    } = config;
    return {
        Desktop(props) {
            // const { navigationListProps } = props;
            const scrollbarBg = useToken('colors', 'whiteAlpha.400');
            const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');

            // const iconProps = props.emptyStateProps?.icon?.props ?? {
            //     as: AiOutlineSearch,
            // };

            return (
                <Box
                    w="full"
                    h="full"
                    overflowY="auto"
                    pb={24}
                    css={{
                        scrollbarGutter: 'stable both-edges',
                        '&::-webkit-scrollbar': {
                            '-webkit-appearance': 'none',
                            width: '0.5rem',
                        },
                        '&::-webkit-scrollbar-track': {},
                        '&::-webkit-scrollbar-corner': {
                            '-webkit-appearance': 'none',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '1rem',
                            background: scrollbarBg,
                        },
                        '&::-webkit-scrollbar-thumb:hover': {
                            // background: scrollbarBg,
                            background: scrollbarHoverBg,
                        },
                    }}
                >
                    <Grid
                        w="full"
                        h="full"
                        templateColumns={{
                            base: '1fr 4fr',
                            lg: '1fr 3fr',
                            xl: '1fr 3fr',
                        }}
                        templateRows="1fr"
                        maxW="96rem"
                        mx="auto"
                    >
                        <GridItem
                            borderRightWidth={3}
                            borderStyle="solid"
                            borderColor="whiteAlpha.300"
                            my={12}
                            position="relative"
                        >
                            <VStack
                                p={12}
                                w="full"
                                align="end"
                                fontWeight="medium"
                                position="sticky"
                                top={12}
                            >
                                <VStack w="fit-content" align="end" spacing={0}>
                                    {props.items.map((item) => {
                                        return (
                                            <Link key={item.id} {...item.link}>
                                                <Button
                                                    aria-selected={item.isActive}
                                                    minW="12rem"
                                                    w="full"
                                                    bg="none"
                                                    py={0}
                                                    px={6}
                                                    borderLeftWidth={3}
                                                    borderStyle="solid"
                                                    borderColor="transparent"
                                                    color="whiteAlpha.800"
                                                    _focus={{ outline: 'none' }}
                                                    _hover={{ bg: 'whiteAlpha.200' }}
                                                    _selected={{
                                                        bg: 'whiteAlpha.300',
                                                        borderColor: 'blue.300',
                                                    }}
                                                    borderRadius="none"
                                                    justifyContent="start"
                                                    // textAlign="right"
                                                    // justifyContent="end"
                                                >
                                                    {item.label}
                                                </Button>
                                            </Link>
                                        );
                                    })}
                                </VStack>
                            </VStack>
                        </GridItem>
                        <GridItem p={12} pb={64}>
                            <Box w="full" h="full" maxW="56rem">
                                <Suspense
                                    fallback={
                                        <Center w="full" h="full">
                                            <CardSpinner />
                                        </Center>
                                    }
                                >
                                    {props.children}
                                </Suspense>
                            </Box>
                        </GridItem>
                    </Grid>
                </Box>
            );
        },
        Mobile(props) {
            return (
                <Grid w="full" h="full" templateRows="min-content 1fr" gap={3}>
                    <GridItem>
                        <HStack overflowX="auto" spacing={2} px={3}>
                            {props.items.map((item) => (
                                <Link key={item.id} {...item.link}>
                                    <Button
                                        aria-selected={item.isActive}
                                        bg="none"
                                        size="sm"
                                        borderRadius="full"
                                        outline="none"
                                        color="whiteAlpha.600"
                                        px={4}
                                        _focus={{ outline: 'none' }}
                                        _hover={{ bg: 'whiteAlpha.200' }}
                                        _selected={{
                                            bg: 'whiteAlpha.100',
                                            color: 'whiteAlpha.900',
                                        }}
                                    >
                                        {item.label}
                                    </Button>
                                </Link>
                            ))}
                        </HStack>
                    </GridItem>
                    <GridItem>
                        <Suspense
                            fallback={
                                <Center w="full" h="full" position="relative" top={-12}>
                                    <CardSpinner />
                                </Center>
                            }
                        >
                            <HStack h="full" w="full" px={3} pb={12} align="start">
                                {props.children}
                            </HStack>
                        </Suspense>
                    </GridItem>
                </Grid>
            );
        },
    };
}
