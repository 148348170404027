import { useMemo } from 'react';
import { ListItemAggregate, ListItemViewProps } from './item';
import { ListConfig } from './listViewConfig';
import { ListController } from './listViewInterface';
import { ListControllerProps } from './listViewProps';

export function createListController<TItem>(
    config: ListConfig<TItem>
): ListController<TItem> {
    function buildItemProps(
        props: ListControllerProps<TItem>,
        item: ListItemAggregate
    ): ListItemViewProps {
        return {
            id: item.id,
            label: item.name,
        };
    }
    return {
        useProps(props) {
            const transformed = useMemo(
                () =>
                    props.items.map((item, index, array) =>
                        config.transform(item, index, array)
                    ),
                [props.items]
            );
            const items = useMemo(
                () => transformed.map((item) => buildItemProps(props, item)),
                [transformed, props]
            );
            return {
                items,
            };
        },
    };
}
