import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { OrganizationType } from '../../../api/platform/me';
import { useStore } from '../../../../stores/setupContext';
import { AccountProfile, UserProfileCreateProps, UserProfile } from '../../../domain/accounts';
import { AccountScope } from '../../platform';
import { AccountProfileAdapter } from './profileAdapter';
import { AccountProfileRepository } from './profileInterface';

export function createAccountProfileRepository(
    adapter: AccountProfileAdapter
): AccountProfileRepository {
    const PREFIX = ['account-profile'];
    function getPrefix(context: Pick<AccountScope, 'auth'>) {
        return [...PREFIX];
    }
    return {
        useLookup(context, options) {
            const store = useStore();
            const result = useQuery({
                queryKey: [...getPrefix(context)],
                async queryFn() {
                    const { user, current_account: currentAccount } = await store.auth.getMyAccount();
                    const organization = user?.memberships?.find(x => x.account_id == currentAccount?.id);
                    
                    const mapped: UserProfile = {
                        firstname: user?.first_name ?? null,
                        lastname: user?.last_name ?? null,
                        account: null,
                        personalProfile: user.personal_profile
                    };
                    if(currentAccount) {
                        if(currentAccount.kind === 'organization') {
                            mapped.account = {
                                profile: currentAccount?.profile ?? null,
                                kind: 'organization',
                                role: organization?.role || 'standard',
                                organizationName: currentAccount.name,
                                organizationType: (currentAccount?.profile?.tags['company_type'] || 'ecommerce_website') as OrganizationType,
                                domain: currentAccount.domain,
                                finishedOnboarding: currentAccount.finished_on_boarding
                            };
                        } else if (currentAccount.kind === 'user') {
                            mapped.account = {
                                kind: 'individual',
                                profile: currentAccount.profile,
                                finishedOnboarding: currentAccount.finished_on_boarding
                            };
                        }
                    }
                    return mapped;
                },
                refetchOnWindowFocus: false,
                ...options,
            });
            return result;
            // return lookupFromQuery(result);
        },
        useCreate(context) {
            const client = useQueryClient();
            return useMutation({
                async mutationFn(props) {
                    const response = await adapter.put(context, props);
                    return response;
                },
                onSuccess(data, variables, context) {
                    client.refetchQueries(PREFIX);
                },
            });
        },
        useUpdate(context) {
            const lookup = this.useLookup(context);
            const client = useQueryClient();
            return useMutation({
                async mutationFn(props) {
                    if (lookup.status !== 'success') {
                        throw new Error('not loaded');
                    }
                    
                    const response = await adapter.put(context, {
                        ...lookup.data,
                        ...props
                    })
                    return response;
                },
                onSuccess(data, variables, context) {
                    client.refetchQueries(PREFIX);
                },
            });
        },
    };
}
