import { createCompanyListBoxMatchController, createCompanyListBox } from './companies';
import { ApplicationMacroConfig, ApplicationMacroInit } from './macroConfig';
import { ApplicationMacro, ApplicationMacroEnhancer } from './macroInterface';
import { createVisualizationChartMacro } from './visualizations';

export function createApplicationMacros(
    init: ApplicationMacroInit,
    enhancer?: ApplicationMacroEnhancer
): ApplicationMacro {
    return create(
        {
            ...init,
            provider: {
                listbox: {
                    createMatchController: createCompanyListBoxMatchController,
                },
            },
        },
        enhancer
    );
}

export function create(
    config: ApplicationMacroConfig,
    enhancer?: ApplicationMacroEnhancer
): ApplicationMacro {
    if (enhancer) {
        return enhancer(create)(config);
    }
    const { provider } = config;
    return {
        // common: {
        //     list: createListMacro({ view: config.view }),
        // },
        company: {
            select: createCompanyListBox({
                ...config.company,
                provider: {
                    createPeerCompanySearchController:
                        provider.listbox.createMatchController,
                },
            }),
        },
        visualization: {
            chart: createVisualizationChartMacro(
                config.visualization.config,
                config.visualization.enhancer
            ),
        },
    };
}
