import { ReportControllerBaseConfig } from '../../../base';
import { ReportNewController } from './reportNewInterface';
import { ReportNewViewProps } from './reportNewProps';

export function createReportNewController(
    config: ReportControllerBaseConfig
): ReportNewController {
    return {
        useProps(context, item, props): ReportNewViewProps {
            return {
                report: {
                    name: item.template.name,
                    link: config.navigation.report.content(item.template),
                },
                cancel: {
                    link: {
                        ...config.navigation.report.content(item.template),
                        replace: true,
                    },
                },
            };
        },
    };
}
