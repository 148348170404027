import { useQuery } from '@tanstack/react-query';
import { AccountScope  } from '../../platform';
import { DashboardAdapter } from './dashboardAdapter';
import { DashboardRepository } from './dashboardInteface';
import { DashboardQuery } from './dashboardQuery';

export function createDashboardRepository(adapter: DashboardAdapter): DashboardRepository {
    const PREFIX = ['dashboards', 'v2'];
    function getPrefix(context: AccountScope, dashQuery: DashboardQuery) {
        return [...PREFIX, context.account.id, dashQuery];
    }
    return {
        useFind(context, query, options) {
            const { includePlugins, ignorePlugins } = query;
            const queryKey = getPrefix(context, query);
            return useQuery({
                queryKey,
                async queryFn() {
                    const response = await adapter.listDashboards(context, query.workspace);
                    let acc = response;
                    if (includePlugins) {
                        acc = response.filter((candidate) =>
                            includePlugins.includes(candidate.pluginId)
                        );
                    }
                    if (ignorePlugins) {
                        acc = response.filter(
                            (candidate) => !ignorePlugins.includes(candidate.pluginId)
                        );
                    }
                    return acc;
                },
                refetchOnWindowFocus: true,
                ...options,
            });
        },
    }
}