import React from 'react';
import {
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    IconButton,
    StackDivider,
    Text,
    VStack,
} from '@chakra-ui/react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { RegistrationViewConfig } from '../../../../../../base';
import { StepLayoutViewProps } from '../../../fragment/common/step/stepLayoutProps';
import { ChevronLeftIcon } from '@chakra-ui/icons';

export function createStepLayoutMobileView(
    config: Pick<RegistrationViewConfig, 'UI'>
): React.FC<{ children?: React.ReactNode | undefined } & StepLayoutViewProps> {
    const {
        UI: {
            Application: { Button },
        },
    } = config;
    return (props) => {
        return (
            <form
                style={{ height: '100%', width: '100%' }}
                {...props.form.getFormElementProps()}
            >
                <Grid
                    w="full"
                    h="full"
                    pt={6}
                    pb={12}
                    px={12}
                    gap={4}
                    templateRows="1fr min-content"
                >
                    <GridItem>
                        <VStack
                            align="start"
                            borderRadius="lg"
                            spacing={6}
                            divider={<StackDivider borderColor="gray.100" />}
                        >
                            <VStack align="start" spacing={2}>
                                <Heading fontSize="xl" color="black">
                                    {props.title}
                                </Heading>
                                {props.description && (
                                    <Text fontSize="lg" color="blackAlpha.700">
                                        {props.description}
                                    </Text>
                                )}
                            </VStack>
                            {props.children}
                        </VStack>
                    </GridItem>
                    <GridItem>
                        <VStack align="start" w="full" spacing={3}>
                            <Button
                                {...props.form.getSubmitButtonProps()}
                                colorScheme="green"
                                rightIcon={<Icon as={AiOutlineArrowRight} />}
                                borderRadius="full"
                                w="full"
                                _focus={{ outline: 'none' }}
                            />
                        </VStack>
                    </GridItem>
                </Grid>
            </form>
        );
    };
}
