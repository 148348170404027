import { useMutation } from '@tanstack/react-query';
import React, { useMemo, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { FormIds, PageIds } from '../../../../config';
import { PeerRouteConfig } from '../../base';
import {
    PeerGroupCreateController,
    PeerGroupCreateFormProvider,
    PeerGroupCreateLoader,
} from './peerGroupCreateInterface';
import { PeerGroupCreateFormValues } from './peerGroupCreateModel';
import { PeerGroupCreateViewProps } from './peerGroupCreateProps';

export function createPeerGroupCreateRoute(
    config: PeerRouteConfig,
    loader: PeerGroupCreateLoader,
    forms: PeerGroupCreateFormProvider,
    controller: PeerGroupCreateController,
    View: React.FC<{ children?: React.ReactNode | undefined } & PeerGroupCreateViewProps>
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    const {
        repository: { invitation: invitationRepository, peerset: peerSetRepository },
        infra: {
            toaster,
            options: { useOptions },
        },
        context,
        service: { peerset: peersetService },
    } = config;

    // const companies
    const Schema = z.object({
        company: z
            .string()
            .transform((item) => item.split(','))
            .pipe(z.array(z.string()))
            .optional(),
    });

    return (props) => {
        const toast = toaster.useToast();
        const navigate = useNavigate();
        const [searchParams, setSearchParams] = useSearchParams();
        const options = useOptions(Schema);

        // console.log('options', options);

        const viewContext = context.useContext();

        const viewForm = forms.useForm(viewContext, {
            initialValues: { companies: options.company ?? [] },
        });
        const viewFormValues = viewForm.watch();

        const viewData = loader.useLoad(viewContext, {
            form: viewForm,
        });

        const peersetMutation = peerSetRepository.useCreate(viewContext);
        const invitationMutation = invitationRepository.useBulk(viewContext);

        const mutationCreate = useMutation({
            mutationFn(values: PeerGroupCreateFormValues) {
                return peersetService.create(viewContext, {
                    companies: values.companies,
                    createPeerset(props) {
                        return peersetMutation.mutateAsync(props);
                    },
                    createInvitations(props) {
                        return invitationMutation.mutateAsync(props);
                    },
                });
            },
        });

        const selected = useMemo(
            () => new Set(viewFormValues.companies),
            [viewFormValues.companies]
        );

        const viewProps = controller.useProps(viewContext, viewData, {
            page: {
                item: {
                    id: PageIds.PEER_COMPETITIVE_SET_NEW,
                    title: 'New competitive set',
                    description: `Competitive sets enable filtering data by a known set of companies that you define`,
                    breadcrumbs: [],
                },
            },
            company: {
                match: {
                    company: {
                        metadata: {},
                    },
                    selected,
                    onAdd(item) {
                        const value = [
                            // add company to start of list for proper UX
                            item.company.id,
                            ...viewFormValues.companies,
                        ];
                        viewForm.setValue('companies', value);
                        searchParams.set('companies', value.join(','));
                        setSearchParams(searchParams, { replace: true });
                    },
                },
                select: {
                    selected: viewFormValues.companies,
                    onRemove(item) {
                        const value = viewFormValues.companies.filter(
                            (candidate) => candidate !== item.company.id
                        );
                        viewForm.setValue('companies', value);
                        searchParams.set('companies', value.join(','));
                        setSearchParams(searchParams, { replace: true });
                    },
                    onClear() {
                        viewForm.setValue('companies', []);
                    },
                },
                form: {
                    id: FormIds.PEER_GROUP_CREATE,
                    action: {
                        label: 'Create',
                    },
                    form: viewForm,
                    async onSubmit(values) {
                        const response = await mutationCreate.mutateAsync(values);
                        toast({
                            kind: 'success',
                            description: `Successfully created competitive set`,
                        });
                        navigate(`../competitive_sets/${response.id}`);
                    },
                    onSubmitError(error) {
                        console.log('error', error);
                        toast({
                            kind: 'error',
                            description: `Form submission failed`,
                        });
                    },
                },
            },
        });
        return <View {...viewProps} />;
    };
}
