import { compose } from 'lodash/fp';
import { createAutohideMiddleware } from './autohide';
import { ApplicationMiddlewareConfig } from './middlewareConfig';
import { createPersistedAlertsMiddleware } from './persisted-alerts';
import { createPreloadImagesMiddleware } from './preload-images';
import { createCompanyRecommendationEnhancer } from './recommendation';
import { createReportInvitationMiddleware } from './report-invitation';
import { createAccountActionsEnhancer } from './account-actions';
import { createAccountProfileEnhancer } from './account-profile';
import { createAssetClaimsEnhancer } from './claim-redirect';

export function createApplicationMiddleware(config: ApplicationMiddlewareConfig) {
    return compose(
        createAutohideMiddleware(config.autohide),
        createPreloadImagesMiddleware(),
        createCompanyRecommendationEnhancer(),
        createReportInvitationMiddleware(config.top),
        createPersistedAlertsMiddleware(),
        createAccountProfileEnhancer(),
        createAccountActionsEnhancer(),
        createAssetClaimsEnhancer()
    );
}
