import { PluginConnection } from '../../../../domain/metrics';
import { PluginDto } from '../../../../api';
import { PluginEntity } from '../../../../domain';
import { MetricStaticDeps } from '../../metricsConfig';
import { MetricCollectionEditLoader } from './collectionEditInterface';
import { MetricDefinitionListItem } from './collectionEditModel';
import { chain } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { MetricCollectionRepository, MetricDefinitionRepository } from '../../../../app/metrics';
import { PluginConnectionRepository, PluginRepository } from '../../../../app';

export function createMetricCollectionEditLoader(init: {
    repository: {
        collection: MetricCollectionRepository;
        // plugins: PluginRepository;
        // connection: PluginConnectionRepository;
        metricDefinition: MetricDefinitionRepository;
    };
}): MetricCollectionEditLoader {
    // init: MetricStaticDeps
    const { repository } = init;
    return {
        useLoad(context) {

            const pluginData = context.plugins; //.useCollection(context, {}, {});
            const connectionData = context.connections;
            const metricDefinitionData = repository.metricDefinition.useFind(context, {
                plugins: pluginData.data ?? [],
                assets: [context.workspace]
            }, {enabled: pluginData.status === 'success'});
            
            const connectionByPlugin = useMemo(
                () =>
                    connectionData.data?.reduce(
                        (acc, item) => ({ ...acc, [item.plugin.id]: item }),
                        {} as Record<string, PluginConnection | undefined>
                    ) ?? {},
                [connectionData.data]
            );
            const pluginsById = useMemo(
                () =>
                    pluginData.data?.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, PluginEntity | undefined>
                    ) ?? {},
                [pluginData]
            );

            const aggregate = useMemo(
                () =>
                    chain(metricDefinitionData.data ?? [])
                        .flatMap((item): MetricDefinitionListItem[] => {
                            const plugin = pluginsById[item.plugin];
                            if (!plugin) {
                                console.warn(
                                    `plugin ${item.plugin} referenced from metric definition ${item.id} not found. skipping...`,
                                    pluginsById
                                );
                                return [];
                            }
                            if (!plugin.iconUrl) {
                                console.warn(
                                    `plugin ${item.plugin} does not have an icon configured. skipping...`,
                                    pluginsById
                                );
                                return [];
                            }
                            const connection = connectionByPlugin[item.plugin];
                            return [
                                {
                                    metric: item,
                                    plugin,
                                    connection: connection
                                        ? connection
                                        : {
                                              workspace: context.workspace,
                                              plugin: {
                                                  id: item.plugin,
                                              },
                                              status: 'pending',
                                              dependencies: [
                                                  {
                                                      name: 'Integrations',
                                                      status: 'unfulfilled',
                                                  },
                                                  {
                                                      name: 'Data Sync',
                                                      status: 'unfulfilled',
                                                  },
                                              ],
                                          },
                                },
                            ];
                        })
                        .value(),
                [
                    metricDefinitionData.data,
                    pluginData.data,
                    connectionByPlugin,
                    context.workspace,
                ]
            );

            const status = [pluginData, connectionData].every(
                (item) => item.status === 'success'
            )
                ? 'success'
                : 'loading';

            return {
                status,
                item: {
                    status,
                    data: aggregate,
                },
                update: repository.collection.useUpdate(context),
            };
        },
    };
}
