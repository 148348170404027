import { createContext, useContext } from 'react';
import {
    AuthenticationV2,
    User,
    UserReference,
    WorkspaceReference,
} from '../../../domain';

export interface UserContextV2 {
    user: UserReference;
    workspaces: WorkspaceReference[];
    auth: AuthenticationV2;
}

export interface UserContextValue {
    user: User;
    assets: Array<{ id: number, claimActive: boolean }>;
}

export const UserContext = createContext<UserContextValue | null>(null);

export const useUserContext = () => {
    const context = useContext(UserContext);
    if (!context) {
        throw new Error(`user context not available`);
    }
    return context;
};
