import { useQueryClient } from '@tanstack/react-query';
import { BookmarkCreateRequest, Bookmark, ReportSdk } from '@varos/report-sdk';
import { Kernel } from '../../../base';
import { ReportBookmarkRepository } from './reportBookmarkInterface';

export function createReportBookmarkRepository(
    kernel: Kernel,
    api: ReportSdk
): ReportBookmarkRepository {
    const {
        infra: { repository },
    } = kernel;
    const PREFIX = ['report', 'bookmark'];

    return {
        useFind(context, props, options) {
            const query = repository.useQuery({
                ...options,
                queryKey: [...PREFIX, props],
                async queryFn() {
                    const response = await api.bookmarkList(props);
                    return response.data.data;
                },
            });
            return query;
        },
        useCreate(context) {
            const client = useQueryClient();
            const mutation = repository.useMutation(context, {
                async mutationFn(props: BookmarkCreateRequest) {
                    const response = await api.bookmarkCreate({
                        bookmarkCreateRequest: props,
                    });
                    return response.data;
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(PREFIX);
                },
            });
            return mutation;
        },
        useDelete(context) {
            const client = useQueryClient();
            const mutation = repository.useMutation(context, {
                async mutationFn(props: Bookmark) {
                    const response = await api.bookmarkDelete({
                        bookmarkId: props.id,
                    });
                    return;
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(PREFIX);
                },
            });
            return mutation;
        },
    };
}
