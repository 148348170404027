import { chain } from 'lodash';
import { AxiosInstance } from 'axios';
import { getAuthenticatedApi } from '../../../../../infra';
import { ReportSegmentAdapter } from '../../../../app/_reportold';
import { applyTypeToCondition } from '../../../../domain/attributes';
import { ReportSegment } from '../../../../domain/report';
import { CohortState, DashboardPreferences } from '../../../../domain/dashboard';
import { createPreferencesApiImpl } from '../../preferences';
import { createApiConfigurationImpl } from '../../configuration';
import { getDefaultQuerySegments } from '../../../../app';
import { useSystemContextV2 } from '../../../../context';
import { ViewDto } from '../../../../api';

export function createSegmentStatusImpl(config: {
    axios: AxiosInstance;
}): ReportSegmentAdapter {
    let { axios } = config;
    let overrides: { apiKey: string; accountId: string } | undefined = undefined;
    return {
        async findByWorkspace(context, query) {
            if (context.auth.scheme.kind === 'legacy') {
                axios = getAuthenticatedApi(context.auth.scheme.store);
            } else {
                overrides = {
                    apiKey: context.auth.scheme.token,
                    accountId: context.account.id,
                };
            }

            const adapter = {
                configuration: createApiConfigurationImpl({
                    axios,
                    loadEnums: true,
                    overrides,
                }),
                preferences: createPreferencesApiImpl({
                    axios,
                    overrides,
                }),
            };

            // const allPreferences = await adapter.preferences.listPreferences(
            //     query.workspace,
            //     query.dashboards
            // );

            const {
                views: { data: views = [] },
            } = useSystemContextV2().data;

            const preferences = await Promise.all(
                query.dashboards.map(async (dashboard) => {
                    const configuration = await adapter.configuration.getConfiguration(
                        views,
                        query.workspace,
                        dashboard.id
                    );
                    return {
                        dashboard,
                        configuration,
                        preferences: await adapter.preferences.getPreferences(
                            query.workspace,
                            dashboard,
                            configuration
                        ),
                    };
                })
            );

            // const viewsById = views.reduce(
            //     (acc, view) => ({ ...acc, [view.id]: view }),
            //     {} as Record<string, ViewDto | undefined>
            // );

            const preferencesByDashboard = preferences.reduce(
                (acc, item) => {
                    const { configuration, preferences, dashboard } = item;

                    const cohort = getDefaultQuerySegments(
                        context.account.id,
                        dashboard,
                        configuration,
                        preferences.cohort ?? undefined
                    );

                    // console.log('conf', dashboard.id, configuration, preferences.filters);

                    return {
                        ...acc,
                        [dashboard.id]: {
                            ...preferences,
                            filters: preferences.filters.map((filter) => {
                                // HACK the filter key is currently the type slug but it should be the actual key of the property.
                                // currently we assume that the type slug is just the property key prefixed by the plugin id
                                const [, keyWithoutPrefix] = filter.key.includes('.')
                                    ? filter.key.split('.')
                                    : [undefined, filter.key];
                                return {
                                    ...filter,
                                    key: keyWithoutPrefix,
                                };
                            }),
                            cohort,
                            segment: preferences.segment.flatMap((condition) => {
                                const property = configuration.properties.traits.find(
                                    (trait) => trait.property.key === condition.key
                                )?.property;
                                if (!property) {
                                    console.warn(
                                        `condition '${condition.key}' has not matching property`
                                    );
                                    return [condition];
                                }
                                const applied = applyTypeToCondition(
                                    condition,
                                    property.type
                                );
                                return applied ? [applied] : [];
                            }),
                        },
                    };
                },
                {} as Record<string, DashboardPreferences | undefined>
            );

            const segments = chain(query.dashboards)
                .map((dashboard): ReportSegment => {
                    return {
                        name: 'default',
                        description: null,
                        default: true,
                        dashboard,
                        segment: preferencesByDashboard[dashboard.id]?.segment ?? [],
                        filters: preferencesByDashboard[dashboard.id]?.filters ?? [],
                        cohort: preferencesByDashboard[dashboard.id]?.cohort ?? null,
                    };
                })
                .value();

            return segments;
        },
    };
}
