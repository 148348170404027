import { CompanyAdapter } from '../../app/companies';
import { ReportV2Adapter } from '../../app';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { PreloadImagesMiddlewareConfig } from './preloadImagesMiddlewareConfig';
import { preloadImages } from './preloadImagesMiddlewareHelper';

export function createPreloadImagesMiddleware(
    init: PreloadImagesMiddlewareConfig = {}
): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function enhanceCompanyAdapter(adapter: CompanyAdapter): CompanyAdapter {
            return {
                ...adapter,
                async search(...args) {
                    const response = await adapter.search(...args);
                    const urls: string[] = [];
                    for (const report of response) {
                        if (report.logoUrl) {
                            urls.push(report.logoUrl);
                        }
                    }
                    try{
                        await preloadImages(urls);
                    }catch(e) {}
                    return response;
                },
            };
        }
        function enhanceReportAdapter(adapter: ReportV2Adapter): ReportV2Adapter {
            return {
                ...adapter,
                async find(...args) {
                    const response = await adapter.find(...args);
                    const urls: string[] = [];
                    for (const report of response) {
                        urls.push(report.thumbnail.url);
                        urls.push(...report.images.map((item) => item.url));
                    }
                    await preloadImages(urls);
                    return response;
                },
            };
        }
        return create({
            ...config,
            adapter: {
                ...config.adapter,
                companies: {
                    ...config.adapter.companies,
                    createCompany(...args) {
                        const adapter = config.adapter.companies.createCompany(...args);
                        return enhanceCompanyAdapter(adapter);
                    },
                },
                reports: {
                    ...config.adapter.reports,
                    createReport(...args) {
                        const adapter = config.adapter.reports.createReport(...args);
                        return enhanceReportAdapter(adapter);
                    },
                },
            },
        });
    };
}
