import { AxiosInstance } from 'axios';
import { MeResourceV2 } from './meInterfaceV2';
import { MeDtoSchema } from '../me/meSchema';

export function createMeResourceV2(client: AxiosInstance): MeResourceV2 {
    return {
        async get(options) {
            const response = await client.get(`/api/v1/users/me`, {
                headers: {
                    // 'x-varos-account': options.account,
                },
            });
            const parsed = MeDtoSchema.parse(response.data);
            return parsed;
        },
    };
}
