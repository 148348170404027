import { PageIds } from '../../../../config';
import { OrganizationMappingController } from '../../../../view/assets';
import { PermissionMiddleware } from '../../permissionInterface';

export function createPermissionMappingMiddleware(): PermissionMiddleware {
    return (api) => (create) => (config) => {
        function enhanceController(
            controller: OrganizationMappingController
        ): OrganizationMappingController {
            return {
                ...controller,
                useProps(...args) {
                    const [context] = args;

                    const {
                        data: { roles },
                    } = instance.hook.useAccountContext();

                    const permissionProps = api.controller.useProps(context, {
                        page: PageIds.SETTINGS_ASSET_MAPPING,
                        item: {
                            roles,
                        },
                    });

                    const viewProps = controller.useProps(...args);

                    if (permissionProps.access === 'full') {
                        return viewProps;
                    }

                    return {
                        ...viewProps,
                        getSubmitTooltipProps() {
                            return {
                                label: 'You do not have access to perform this action',
                                isDisabled: false,
                            };
                        },
                        getSubmitButtonProps() {
                            return {
                                ...viewProps.getSubmitButtonProps(),
                                isDisabled: true,
                            };
                        },
                    };
                },
            };
        }
        const instance = create({
            ...config,
            controller: {
                ...config.controller,
                assets: {
                    ...config.controller.assets,
                    createMapping(...args) {
                        const controller = config.controller.assets.createMapping(
                            ...args
                        );
                        return enhanceController(controller);
                    },
                },
            },
        });

        return instance;
    };
}
