import thumbnail from '../../../../images/report_thumbnail_creative_strategy.png';
import screenshot_number_of_ads from '../../../../images/report_detail_creative_stratey_number_of_ads.png';
import screenshot_number_of_new_ads from '../../../../images/report_detail_creative_stratey_number_of_new_ads.png';
import screenshot_percent_agency from '../../../../images/report_detail_creative_stratey_percent_agency.png';
import screenshot_percent_creartive_production from '../../../../images/report_detail_creative_stratey_percent_creative_production.png';
import { AnyReportConfiguration } from '../../../middleware';
import { ReportV2Visualization } from '../../../api';
import { DatasetSlugs } from '../../dataset';

export const AIInitiatives: AnyReportConfiguration = {
    kind: 'dataset',
    entity: {
        id: 'ai_initiatives',
        name: 'AI Initiatives',
        status: 'draft',
        tagline: `Discover AI-driven use-cases`,
        description: `This report offers a detailed analysis of AI initiatives that have successfully scaled and delivered on their promises. It is based on in-depth interviews with individuals directly involved in developing AI products across various companies. Participants in the report will receive free access to it.`,
        category: 'marketing',
        dataset: DatasetSlugs.AI_INITIATIVES,
        traits: [],
        tags: ['strategy', 'ai'],
        dependencies: [
            {
                kind: 'survey',
                dataset: DatasetSlugs.AI_INITIATIVES,
            },
        ],
        thumbnail: {
            description: null,
            url: thumbnail,
        },
        images: [],
        visualizations: [],
    },
};
