import React from 'react';
import {
    ApplicationShellContextProvider,
    ApplicationShellContext,
} from './applicationContextInterface';
import { ApplicationShellContextContainerProps } from './applicationContextProps';

export function createApplicationShellContextProvider(
    Container: React.FC<ApplicationShellContextContainerProps>,
    Context: React.Context<ApplicationShellContext | null>
): ApplicationShellContextProvider {
    return {
        Provider: Container,
        useContext() {
            const context = React.useContext(Context);
            if (!context) {
                throw new Error('not inside report context');
            }
            return context;
        },
    };
}
