import {
    Box,
    Button,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Image,
    StackDivider,
    Tag,
    Text,
    VStack,
    Wrap,
    Alert,
    AlertTitle,
    AlertIcon,
    useToken,
    Drawer,
    DrawerContent,
    DrawerBody,
    DrawerCloseButton,
    DrawerHeader,
    AlertDescription,
    Collapse,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineInfoCircle, AiOutlineLock } from 'react-icons/ai';
import { ChevronRightIcon } from '@chakra-ui/icons';
import { VAROS_YELLOW } from '../../../../../../config';
import { Icons } from '../../../../../../../config';
import { assertNever } from '../../../../../../util';
import { StudyDependencyItemProps } from '../../../../../../view/studies';
import { StudiesDetailRouteConfig } from '../../studiesReportDetailConfig';
import { StudiesDetailViewProps } from '../../studiesReportDetailProps';
import { MdRadioButtonUnchecked, MdSubdirectoryArrowRight } from 'react-icons/md';
import { StudiesViewBaseConfig } from '../../../../base';
import { BsArrowRightShort } from 'react-icons/bs';

export function createStudiesDetailMobileView(
    config: StudiesViewBaseConfig
): React.FC<StudiesDetailViewProps> {
    const {
        UI: {
            Application: { Link, Tooltip, Anchor },
        },
    } = config;
    return (props) => {
        return (
            <VStack
                h="full"
                w="full"
                pb={24}
                px={4}
                overflowY="scroll"
                spacing={6}
                divider={<StackDivider borderWidth={1} borderColor="whiteAlpha.100" />}
            >
                <VStack align="start" w="full" spacing={4}>
                    {props.status?.alert && (
                        <Alert {...props.status.alert}>
                            <AlertTitle>{props.status.description}</AlertTitle>
                        </Alert>
                    )}
                    <HStack w="full" spacing={4} align="start">
                        <Box p={4} bg="whiteAlpha.50" borderRadius="xl">
                            <Icon w={6} h={6} as={Icons.Reports.Report}></Icon>
                        </Box>
                        <VStack align="start" spacing={3} justify="space-between">
                            <VStack align="start" spacing={0}>
                                <Text fontSize="lg" fontWeight="semibold">
                                    {props.report.title}
                                </Text>
                                {props.report.tagline && (
                                    <HStack>
                                        <Text color="whiteAlpha.700">
                                            {props.report.tagline}
                                        </Text>
                                    </HStack>
                                )}
                            </VStack>
                            <HStack>
                                <Button
                                    {...props.open.button}
                                    rightIcon={
                                        props.open.Icon ? (
                                            <Icon as={props.open.Icon} />
                                        ) : undefined
                                    }
                                    size="sm"
                                    colorScheme="blue"
                                    borderRadius="full"
                                    textTransform="uppercase"
                                    fontSize="sm"
                                    letterSpacing="wide"
                                    fontWeight="semibold"
                                    h={7}
                                >
                                    {props.open.label}
                                </Button>
                            </HStack>
                        </VStack>
                    </HStack>
                </VStack>
                {props.requirements.items.length > 0 && (
                    <VStack align="start" w="full" spacing={3}>
                        <HStack w="full" justify="space-between">
                            <HStack spacing={3} align="center">
                                <Heading
                                    fontSize="lg"
                                    fontWeight="semibold"
                                    color="whiteAlpha.900"
                                >
                                    Requirements
                                </Heading>
                                <Box
                                    w={2.5}
                                    h={2.5}
                                    borderRadius="full"
                                    bg={`${props.requirements.status.colorScheme}.300`}
                                />
                            </HStack>
                            <Link to="">
                                <Text
                                    color="blue.300"
                                    // fontWeight="medium"
                                    // color="whiteAlpha.600"
                                    cursor="pointer"
                                    _hover={{ textDecor: 'underline' }}
                                >
                                    Learn more
                                </Text>
                            </Link>
                        </HStack>
                        <VStack align="start" w="full" spacing={2}>
                            {props.requirements.items.map((item) => (
                                <VStack
                                    key={item.id}
                                    w="full"
                                    align="start"
                                    bg="whiteAlpha.50"
                                    borderRadius="lg"
                                    divider={
                                        <StackDivider
                                            borderColor="whiteAlpha.50"
                                            borderWidth={1}
                                        />
                                    }
                                    spacing={0}
                                >
                                    <VStack
                                        align="start"
                                        w="full"
                                        spacing={1}
                                        py={4}
                                        px={4}
                                    >
                                        <HStack w="full" justify="space-between">
                                            <Text
                                                fontWeight="semibold"
                                                color="whiteAlpha.800"
                                            >
                                                {item.label}
                                            </Text>
                                            <Tag
                                                {...item.status.tag}
                                                flexShrink={0}
                                                size="md"
                                                variant="subtle"
                                            >
                                                {item.status.label}
                                            </Tag>
                                        </HStack>
                                        {item.description && (
                                            <Text color="whiteAlpha.600">
                                                {item.description}
                                            </Text>
                                        )}
                                    </VStack>
                                    {!item.status.isFulfilled && item.action && (
                                        <HStack py={4} px={4} w="full" justify="end">
                                            <Link {...item.action.link}>
                                                <Button
                                                    color="blue.300"
                                                    size="sm"
                                                    borderRadius="full"
                                                    textTransform="uppercase"
                                                    fontSize="sm"
                                                    letterSpacing="wide"
                                                    fontWeight="semibold"
                                                    variant="link"
                                                    rightIcon={
                                                        <Icon
                                                            fontSize="xl"
                                                            as={BsArrowRightShort}
                                                        />
                                                    }
                                                >
                                                    {item.action.label}
                                                </Button>
                                            </Link>
                                        </HStack>
                                    )}
                                </VStack>
                            ))}
                            <HStack
                                color="whiteAlpha.500"
                                fontSize="sm"
                                spacing={2}
                                align="center"
                            >
                                <Icon as={props.requirements.hint.Icon}></Icon>
                                <Text fontWeight="medium">
                                    {props.requirements.hint.description}
                                </Text>
                            </HStack>
                        </VStack>
                    </VStack>
                )}
                <HStack w="full">
                    <Text color="whiteAlpha.700">{props.report.description}</Text>
                </HStack>
                <VStack align="start" w="full" spacing={3}>
                    <HStack
                        w="full"
                        bg="whiteAlpha.50"
                        borderRadius="lg"
                        justify="space-between"
                        py={3}
                        px={4}
                    >
                        <Text
                            letterSpacing="wide"
                            fontSize="sm"
                            fontWeight="medium"
                            color="whiteAlpha.600"
                        >
                            Category
                        </Text>
                        <Text color="whiteAlpha.800">{props.report.category}</Text>
                    </HStack>
                    <HStack
                        w="full"
                        bg="whiteAlpha.50"
                        borderRadius="lg"
                        justify="space-between"
                        py={3}
                        px={4}
                    >
                        <Text
                            letterSpacing="wide"
                            fontSize="sm"
                            fontWeight="medium"
                            color="whiteAlpha.600"
                        >
                            Terms of service
                        </Text>
                        <Link {...props.legal.terms.link}>
                            <Text
                                color="blue.300"
                                size="sm"
                                textTransform="uppercase"
                                fontWeight="semibold"
                                fontSize="sm"
                                letterSpacing="wide"
                            >
                                Open
                            </Text>
                        </Link>
                    </HStack>
                </VStack>
            </VStack>
        );
    };
}
