import { AiOutlineReload, AiOutlineWarning } from 'react-icons/ai';
import {
    Button,
    ButtonGroup,
    Center,
    HStack,
    Icon,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { CardSpinner } from '../../../../../../../domain';
import { ReportViewBaseConfig } from '../../../../base';
import { ReportInstanceDetailStatusViewProps } from './reportStatusProps';
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs';

export function createReportInstanceDetailStatusView(
    config: ReportViewBaseConfig
): React.FC<ReportInstanceDetailStatusViewProps> {
    const { Layout } = config;

    return (props) => {
        return (
            <Layout>
                <HStack h="full" w="full" justify="center" align="center" py={8}>
                    <Center w="full" h="full">
                        <VStack
                            align="start"
                            p={12}
                            position="relative"
                            top={-24}
                            spacing={6}
                            // borderWidth={2}
                            // borderColor="whiteAlpha.300"
                            // borderRadius="lg"
                            // bg="whiteAlpha.50"
                        >
                            {/* <Icon w={12} h={12} color="red.500" as={AiOutlineWarning} /> */}
                            <CardSpinner speed={0.2} />
                            <VStack align="start" color="whiteAlpha.800" spacing={1}>
                                <Text fontWeight="medium" letterSpacing="wide">
                                    Creating report
                                </Text>
                                <Text color="whiteAlpha.700">
                                    Report is being created and will be ready in a couple
                                    of minutes
                                </Text>
                            </VStack>
                            {props.back && (
                                <ButtonGroup spacing={6}>
                                    <Button
                                        {...props.back.button}
                                        // colorScheme="blue"
                                        variant="outline"
                                        borderColor="whiteAlpha.300"
                                        bg="none"
                                        borderRadius="full"
                                        // rightIcon={<Icon as={BsArrowRight} />}
                                        leftIcon={<Icon as={BsArrowLeft} />}
                                        _hover={{ bg: 'whiteAlpha.50' }}
                                    >
                                        {props.back.label}
                                    </Button>
                                </ButtonGroup>
                            )}
                        </VStack>
                    </Center>
                </HStack>
            </Layout>
        );
    };
}
