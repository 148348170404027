import { useMutation } from '@tanstack/react-query';
import { isLoadedLookup } from '../../../base';
import { AccountProfileServiceConfig } from './profileConfig';
import { AccountProfileService } from './profileInterface';

export function createAccountProfileService(
    config: AccountProfileServiceConfig
): AccountProfileService {
    const { repository } = config;
    return {
        useLookup(context) {
            return repository.profile.useLookup(context);
        },
        useCreate(context) {
            return repository.profile.useCreate(context);
        },
        useUpdate(context) {
            return repository.profile.useUpdate(context);
        },
        useUpsert(context) {
            const lookup = repository.profile.useLookup(context);
            const create = repository.profile.useCreate(context);
            const update = repository.profile.useUpdate(context);
            return useMutation({
                async mutationFn(props) {
                    if (lookup.status !== 'success') {
                        throw new Error('not loaded');
                    }
                    if (lookup.data) {
                        return update.mutateAsync(props);
                        // return update.mutateAsync({
                        //     ...props,
                        //     // do not override onboarding status after it has been finished
                        //     finishedOnboarding: lookup.data.finishedOnboarding,
                        // });
                    }
                    return create.mutateAsync(props);
                },
            });
        },
    };
}
