import { compose } from '@varos/util-typescript';
import { ApplicationEntryEnhancer } from '../applicationMiddleware';
import { ApplicationPluginConfig } from './applicationPluginConfig';
import { ApplicationPluginProvider } from './applicationPluginInterface';

export function applyApplicationPlugins(
    ...providers: ApplicationPluginProvider[]
): ApplicationEntryEnhancer {
    return (create) => (outerConfig) => {
        const pluginConfig: ApplicationPluginConfig = {};
        const plugins = providers.map((item) => item.create(pluginConfig));

        const rootEnhancer = compose(
            ...plugins.flatMap((plugin) => (plugin.root ? plugin.root : []))
        );
        const createComposed = rootEnhancer(create);
        return createComposed({
            ...outerConfig,
            enhancer: {
                shell: [
                    ...outerConfig.enhancer.shell,
                    ...plugins.flatMap((plugin) => (plugin.shell ? plugin.shell : [])),
                ],
                layout: [
                    ...outerConfig.enhancer.layout,
                    ...plugins.flatMap((plugin) => (plugin.layout ? plugin.layout : [])),
                ],
            },
        });
    };
}
