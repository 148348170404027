import { useMemo } from 'react';
import { assert } from '../../../util/assert';
import { useLocation, Location, resolvePath } from 'react-router-dom';
import { DomainLayoutConfig } from '../domainLayoutConfig';
import { DomainNavigationListController } from './domainNavigationInterface';
import { DomainLayoutNavigationItemProps } from './domainNavigationProps';
import { isWorkspaceContext } from '../../../app';
import { DomainLayoutNavigationItem } from './domainNavigationModel';

export function createDomainNavigationController(
    config: Pick<DomainLayoutConfig, 'items'>
): DomainNavigationListController {
    function buildItemProps(
        basePath: string,
        location: Location,
        item: DomainLayoutNavigationItem
    ): DomainLayoutNavigationItemProps {
        const path = resolvePath(item.path, basePath);
        return {
            id: item.id,
            label: item.title,
            isActive: location.pathname.includes(path.pathname),
            linkProps: {
                to: path,
            },
        };
    }
    return {
        useProps(context) {
            const basePath = useMemo(() => {
                if(isWorkspaceContext(context)) {
                    return `/u/assets/${context.workspace.id}`;
                }
                else {
                    return '/u/account'
                }
            }, [context.account.id]);
            const location = useLocation();
            const itemPropsById = useMemo(() => {
                return config.items.reduce(
                    (acc, item) => ({
                        ...acc,
                        [item.id]: buildItemProps(basePath, location, item),
                    }),
                    {} as Record<string, DomainLayoutNavigationItemProps | undefined>
                );
            }, [config.items, context, location]);
            return {
                items: config.items,
                getItemProps(item) {
                    const itemProps = itemPropsById[item.id];
                    assert(itemProps, 'missing item props');
                    return itemProps;
                },
            };
        },
    };
}
