import { useForm } from 'react-hook-form';
import { UserInviteFormConfig } from './userInviteFormConfig';
import { UseInviteUserFormHook } from './userInviteInterface';
import { InviteUserFormState } from './userInviteFormModel';
import { isErrorInfo } from '../../../../../utils/axiosUtils';

export function createUserInviteFormController(
    config: UserInviteFormConfig
): UseInviteUserFormHook {
    const {
        service: {
            workspaces: { useEnhancedList: useWorkspaces },
        },
        hook: {
            useUser
        }
    } = config;
    const TITLES = [
        'Agency - CEO',
        'Agency - VP or Director or C-Suite',
        'Agency - Account Manager or Other',
        'Brand - Marketer or other',
        'Brand - CMO/CFO, VP or Director',
        'Brand - CEO',
        'SaaS - CEO',
        'SaaS - VP or Director or C-Suite',
        'SaaS - Marketer or Other',
        'Other',
    ];
    return {
        useProps(context, input) {
            
            const { onSubmit } = input;
            const currentUser = useUser();
            const workspaces = useWorkspaces(context, { page: 1, page_size: 500 });

            const form = useForm<InviteUserFormState>({
                mode: 'all',
                defaultValues: {
                    role: currentUser.user.isAdmin ? 'admin': 'standard',
                },
            });
            const error = form.watch('_error');
            return {
                ...form,
                isOpen: input.isOpen,
                onOpen: input.onOpen,
                error,
                titles: TITLES,
                allowFullWorkspaceAccess: currentUser.user.isAdmin,
                onClose() {
                    form.reset();
                    input.onClose();
                },
                workspacesOptions: workspaces.data.map((ws) => ({
                    label: ws.name,
                    value: ws.id,
                })),
                onSubmit: form.handleSubmit(async (values) => {
                    try {
                        await onSubmit({
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            title: values.title?.value,
                            role: values.role,
                            access_asset_ids:
                                values.role == 'admin'
                                    ? []
                                    : values.workspaces?.map((x) => x.value),
                        });
                        input.onClose();
                    } catch (error) {
                        if (error instanceof Error) {
                            // NOTE this is a hack, really we should have a way
                            // of setting a global form error, but this is not yet supported
                            // on our version of react-hook-forms
                            form.setValue('_error', {
                                message: error.message,
                            });
                            return;
                        } else if (isErrorInfo(error)) {
                            if (error.message) {
                                form.setValue('_error', {
                                    message: error.message,
                                });
                            }
                            if (error.fieldErrors) {
                                error.fieldErrors.forEach((fieldErr) => {
                                    form.setError(
                                        fieldErr.loc[1] as keyof InviteUserFormState,
                                        {
                                            message: fieldErr.msg,
                                        }
                                    );
                                });
                            }
                        }
                    }
                }),
            };
        },
    };
}
