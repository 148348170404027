import { useMemo } from 'react';
import { ReportControllerBaseConfig } from '../../../../base';
import {
    ReportChapterViewProps,
    ReportSectionViewProps,
    ReportInsightViewProps,
    ReportInsightContainerProps,
    ReportInsightLoaderProps,
} from '../../../../view';
import { ReportInstanceDetailContentController } from './reportContentInterface';
import { assert } from '@varos/util-typescript';

export function createReportInstanceDetailContentController(
    config: ReportControllerBaseConfig
): ReportInstanceDetailContentController {
    const {
        kernel: {
            infra: { formatter },
            provider: { createPageController, createActionController },
        },
    } = config;

    const pageController = createPageController({
        id: 'report',
    });

    const subscribeController = createActionController({ id: 'subscribe' });

    return {
        useProps(context, item, props) {
            const page = pageController.useProps(
                { entity: { id: item.report.id, object: 'report' } },
                {}
            );

            const subscribe = subscribeController.useProps(props.subscribe);

            // console.log('item.bookmarks', item.bookmarks);

            const [first, ...rest] = item.content.chapters;
            assert(rest.length === 0, 'multiple chapters not yet implemented');

            const chapter = useMemo(
                (): ReportChapterViewProps => ({
                    id: first.key,
                    subtitle: first.description,
                    content: {
                        items: first.sections.map((section) => ({
                            label: section.title,
                            onClick() {
                                const el = document.getElementById(
                                    [first.key, section.key].join('_')
                                );
                                if (el) {
                                    el.scrollIntoView({ behavior: 'smooth' });
                                }
                            },
                        })),
                    },
                    sections: first.sections.map((section): ReportSectionViewProps => {
                        return {
                            id: [first.key, section.key].join('_'),
                            title: section.title,
                            description: section.description,
                            insights: section.insights.map(
                                (insight): ReportInsightLoaderProps => {
                                    return {
                                        item: {
                                            version: item.version,
                                            bookmark:
                                                item.bookmarks.find(
                                                    (candidate) =>
                                                        insight.key == candidate.key
                                                ) ?? null,
                                            insight,
                                        },
                                    };
                                }
                            ),
                        };
                    }),
                }),
                [first, item.bookmarks, props.period]
            );

            return {
                page,
                chapter,
                subscribe,
                report: {
                    title: item.content.chapters[0].title,
                    period: {
                        label: formatter.daterange({
                            start: new Date(item.content.period_start_at),
                            end: new Date(item.content.period_end_at),
                        }),
                    },
                    updated: {
                        label: [
                            'Data updated',
                            formatter
                                .timeago(item.content.created_at, {
                                    granularity: 'day',
                                })
                                .toLowerCase(),
                        ].join(' '),
                    },
                },
                customize: {
                    link: config.navigation.report.customize(item.report),
                },
            };
        },
    };
}
