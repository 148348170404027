import React from 'react';
import { useNavigate } from 'react-router';
import { RegistrationContainerConfig } from '../../../../../base';
import { isExistingUserInvitation, isOrganizationInvitation } from '../../../../../../../domain/accounts/invitation';
import { RegistrationAcceptContextProvider } from '../../context';
import { RegistrationAcceptProfileController } from './acceptProfileInterface';
import { AcceptProfileContainerProps } from './acceptProfileProps';
import { buildServicePayload } from './acceptProfileFactory';

export function createRegistrationAcceptProfileContainer(
    config: RegistrationContainerConfig & {
        accept: RegistrationAcceptContextProvider;
    },
    controller: RegistrationAcceptProfileController
): React.FC<AcceptProfileContainerProps> {
    const {
        infra: { toaster },
        service: { invitation: invitationService },
        accept: { useContext },
    } = config;
    return ({ as: View, ...containerProps }) => {
        const toast = toaster.useToast();
        const context = useContext();
        const isOrganization = isOrganizationInvitation(context.data.invitation);
        const isUserExists = isExistingUserInvitation(context.data.invitation);
        const mutation = invitationService.useAccept();
        
        const props = controller.useProps(context, {
            stepper: context.stepper,
            form: {
                action: {
                    label: isOrganization ? 'Continue' : 'Finish',
                },
                readonly: isUserExists,
                id: 'invite_accept_profile_step',
                form: context.form.profile,
                async onSubmit(values) {
                    if (isOrganization) {
                        await new Promise((resolve) => setTimeout(resolve, 500));
                        context.stepper.onChange(context.stepper.selected + 1);
                    } else {
                        const payload = buildServicePayload(context, {
                            step: context.stepper.selected,
                            token: context.token,
                            invitation: context.invitation,
                            profile: context.form.profile.getValues(),
                        });
                        const response = await mutation.mutateAsync(payload);
                        context.stepper.onChange(context.stepper.selected + 1);
                    }
                },
                onSubmitError(error) {
                    if (error.message) {
                        toast({
                            kind: 'error',
                            description: `Form submission failed: ${error.message}`,
                            durationMs: 20000,
                        });
                    } else {
                        toast({
                            kind: 'error',
                            description: `Form submission failed`,
                            durationMs: 5000,
                        });
                    }
                },
                metadata: context.metadata,
            },
            layout: {
                stepper: context.stepper,
                navigate: useNavigate(),
            },
            page: {
                metadata: context.metadata,
            },
        });
        return <View {...props} />;
    };
}
