import { useLocalStorage } from 'react-use';
import React, { useEffect } from 'react';
import { CardSpinner } from '../../../domain';
import { ApiContext } from '../../api';
import { LocalStorageKeys } from '../../config';
import { BootstrappedContextProvider } from '../../base';
import { AccountScope } from '../../app';
import { AccountContext, useAccountContextV2 } from './organizationContext';
import { AccountContextAggregate } from './organizationModel';
import { OrganizationContextConfig } from './organizationConfig';

export function createOrganizationProviderV2(
    config: OrganizationContextConfig
): BootstrappedContextProvider<AccountContextAggregate> {
    const {
        deps: { hook },
        provider: { createLoader },
    } = config;
    const loader = createLoader(config);
    return {
        Provider(props) {
            const auth = hook.useAuth();
            const account = hook.useAccount();

            const [localAccountId, setLocalAccountId] = useLocalStorage(
                LocalStorageKeys.ACCOUNT_ID
            );

            useEffect(() => {
                if (account.id !== localAccountId) {
                    // TODO encapsulate local organization state properly
                    setLocalAccountId(account.id);
                    console.info('organization changed, resetting local state...');
                    window.localStorage.removeItem(LocalStorageKeys.ONBOARDING_STATE);
                    window.localStorage.removeItem(LocalStorageKeys.HOME_HIDDEN);
                }
            }, [account.id, localAccountId]);

            const context: AccountScope = { auth, account };
            const value = loader.useLoad(context);
            if (value.status === 'loading') {
                return <CardSpinner />;
            }
            return (
                <AccountContext.Provider value={value.data}>
                    {props.children}
                </AccountContext.Provider>
            );
        },
        useContext() {
            return useAccountContextV2();
            // throw new Error(`not impl`);
        },
    };
}
