import React, { useState } from 'react';
import { AsyncSelect as Select } from 'chakra-react-select';
import { capitalize } from 'lodash';
import {
    InputGroup,
    FormErrorMessage,
    FormLabel,
    VStack,
    ModalHeader,
    ModalCloseButton,
    Modal,
    ModalContent,
    ModalBody,
    ModalFooter,
    ButtonGroup,
    ModalOverlay,
    FormControl,
} from '@chakra-ui/react';
import { ApplicationUI } from '../../../../ui';
import { EditWorkspaceFormViewProps } from './workspaceEditProps';
import { Controller } from 'react-hook-form';
import { WorkspaceFormPermittedUserOption } from './workspaceEditModel';

export function createWorkspaceEditView(config: {
    UI: ApplicationUI;
}): React.FC<{ children?: React.ReactNode | undefined } & EditWorkspaceFormViewProps> {
    const {
        UI: { Button },
    } = config;
    return (props) => {
        return (
            <>
                <Modal
                    isOpen={props.isOpen}
                    onClose={props.onCancel}
                    closeOnEsc={true}
                    closeOnOverlayClick={true}
                >
                    <ModalOverlay />
                    <ModalContent>
                        <form onSubmit={props.onSubmit}>
                            <ModalHeader>Edit Brand Access</ModalHeader>
                            <ModalCloseButton />
                            <ModalBody>
                                <FormControl isInvalid={!!props.error}>
                                    <InputGroup
                                        orientation="vertical"
                                        flexDirection="column"
                                    >
                                        <FormLabel>Users</FormLabel>
                                        <Controller
                                            control={props.control}
                                            name="users"
                                            render={({ field: { onChange, value } }) => (
                                                <Select
                                                    isMulti={true}
                                                    isClearable={false}
                                                    value={value}
                                                    onChange={(vals) =>
                                                        onChange(
                                                            props.users.transformSelection(
                                                                vals as WorkspaceFormPermittedUserOption[]
                                                            )
                                                        )
                                                    }
                                                    defaultOptions={props.users.options}
                                                    loadOptions={props.users.onSearch}
                                                />
                                            )}
                                        />
                                    </InputGroup>
                                    {props.error ? (
                                        <FormErrorMessage>
                                            {props.error.message}
                                        </FormErrorMessage>
                                    ) : null}
                                </FormControl>
                            </ModalBody>
                            <ModalFooter>
                                <ButtonGroup>
                                    <Button
                                        variant="ghost"
                                        size="sm"
                                        onClick={props.onCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        isLoading={props.formState.isSubmitting}
                                        type="submit"
                                        colorScheme="blue"
                                        variant="solid"
                                        size="sm"
                                    >
                                        Save
                                    </Button>
                                </ButtonGroup>
                            </ModalFooter>
                        </form>
                    </ModalContent>
                </Modal>
            </>
        );
    };
}
