import React from 'react';
import { ReportContainerBaseConfig } from '../../base';
import { ReportInsightController } from './reportInsightInterface';
import { ReportInsightContainerProps } from './reportInsightProps';
import { MdBookmark, MdBookmarkBorder } from 'react-icons/md';

export function createReportInsightContainer(
    config: ReportContainerBaseConfig,
    controller: ReportInsightController
): React.FC<ReportInsightContainerProps> {
    const {
        repository,
        context: { useContext },
    } = config;
    return ({ as: View, ...containerProps }) => {
        const context = useContext();
        const mutation = {
            bookmark: {
                create: repository.bookmark.useCreate(context),
                delete: repository.bookmark.useDelete(context),
            },
        };
        const props = controller.useProps(context, containerProps.item, {
            action: {
                bookmark: containerProps.item.bookmark
                    ? {
                          id: 'deletebookmark',
                          kind: 'custom',
                          name: 'Delete bookmark',
                          description: `Bookmark`,
                          isActive: true,
                          onPerform: mutation.bookmark.delete.mutateAsync.bind(
                              null,
                              containerProps.item.bookmark
                          ),
                          Icon: MdBookmark,
                      }
                    : {
                          id: 'addbookmark',
                          kind: 'custom',
                          name: 'Add bookmark',
                          description: `Bookmark`,
                          onPerform: mutation.bookmark.create.mutateAsync.bind(null, {
                              version: containerProps.item.version.id,
                              key: containerProps.item.insight.key,
                          }),
                          Icon: MdBookmarkBorder,
                      },
            },
        });
        return <View {...props} />;
    };
}
