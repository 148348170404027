import {
    Box,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { AiOutlineArrowLeft, AiOutlineEdit, AiOutlineUser } from 'react-icons/ai';
import { GoCheckCircle, GoCircle, GoOrganization } from 'react-icons/go';
import { Graphics } from '../../../../../../../../config/svg';
import { RegistrationViewConfig } from '../../../../../../base';
import { RegistrationAcceptCreateViewProps } from '../../../registrationAcceptCreateProps';
import {
    AcceptFinalizeContainerProps,
    AcceptOrganizationContainerProps,
    AcceptProfileContainerProps,
    AcceptWelcomeContainerProps,
} from '../../../fragment';
import { RegistrationAcceptTheme } from '../../../registrationAcceptCreateInterface';

export function createRegistrationAcceptCreateDesktopView(
    config: RegistrationViewConfig & {
        Container: {
            Welcome: React.FC<AcceptWelcomeContainerProps>;
            Profile: React.FC<AcceptProfileContainerProps>;
            Organization: React.FC<AcceptOrganizationContainerProps>;
            Finalize: React.FC<AcceptFinalizeContainerProps>;
        };
        Theme: RegistrationAcceptTheme;
    }
): React.FC<RegistrationAcceptCreateViewProps> {
    const {
        Theme,
        Container: {
            Welcome: WelcomeView,
            Profile: ProfileView,
            Organization: OrganizationView,
            Finalize: FinalizeView,
        },
        UI: {
            Application: {
                Link,
                Anchor,
                Stepper: {
                    Container: Stepper,
                    Step,
                    Status: StepStatus,
                    Indicator: StepIndicator,
                },
            },
        },
    } = config;

    return (props) => {
        const steps = [
            {
                id: 'welcome',
                title: 'Welcome',
                Icon: AiOutlineUser,
                children: <WelcomeView as={Theme.Welcome} />,
                isVisible: false,
            },
            {
                id: 'profile',
                title: 'User profile',
                Icon: AiOutlineUser,
                children: <ProfileView as={Theme.Profile} />,
            },
            ...(props.isOrganization
                ? [
                      {
                          id: 'organization',
                          title: 'Organization',
                          Icon: GoOrganization,
                          children: <OrganizationView as={Theme.Organization} />,
                      },
                  ]
                : []),
            {
                id: 'finalize',
                title: 'Finalize',
                Icon: AiOutlineEdit,
                children: <FinalizeView as={Theme.Finalize} />,
                isVisible: false,
            },
        ];
        return (
            <Grid bg="white" color="black" h="full" w="full" templateColumns="2fr 5fr">
                <GridItem p={6}>
                    <Grid
                        w="full"
                        h="full"
                        templateRows="min-content 1fr min-content"
                        bg="gray.50"
                        borderColor="gray.200"
                        borderWidth={1}
                        borderStyle="solid"
                        borderRadius="xl"
                        p={12}
                        gap={12}
                    >
                        <GridItem>
                            <Image
                                {...props.button.home}
                                src={Graphics.Brand.LogoLarge}
                                cursor="pointer"
                                maxWidth="100%"
                                width="auto"
                                h={10}
                                objectFit="contain"
                            />
                        </GridItem>
                        <GridItem>
                            <VStack h="full" w="full" align="start" spacing={6}>
                                <HStack>
                                    <Text color="gray.600" fontWeight="semibold">
                                        Account setup
                                    </Text>
                                </HStack>
                                <Stepper
                                    {...props.stepper}
                                    as={<VStack w="full" spacing={6} />}
                                >
                                    {steps.map(({ Icon: StepIcon, ...step }) => (
                                        <Step
                                            key={step.id}
                                            w="full"
                                            spacing={3}
                                            opacity={1}
                                            color="gray.800"
                                            isVisible={step.isVisible}
                                            _disabled={{ opacity: 0.3 }}
                                            _current={{ color: 'blue.600' }}
                                        >
                                            <Box
                                                p={2}
                                                borderWidth={1}
                                                bg="white"
                                                borderColor="gray.300"
                                                borderStyle="solid"
                                                borderRadius="md"
                                                shadow="sm"
                                            >
                                                <Icon as={StepIcon} />
                                            </Box>
                                            <HStack
                                                fontWeight="medium"
                                                userSelect="none"
                                                w="full"
                                                justify="space-between"
                                            >
                                                <Text whiteSpace="nowrap">
                                                    {step.title}
                                                </Text>
                                                <StepIndicator
                                                    _checked={{ color: 'green.600' }}
                                                    _current={{ color: 'blue.600' }}
                                                    _disabled={{ color: 'gray.600' }}
                                                >
                                                    <StepStatus
                                                        complete={
                                                            <Icon
                                                                w={5}
                                                                h={5}
                                                                as={GoCheckCircle}
                                                            />
                                                        }
                                                        incomplete={
                                                            <Icon
                                                                w={5}
                                                                h={5}
                                                                as={GoCircle}
                                                            />
                                                        }
                                                        active={
                                                            <Icon
                                                                w={5}
                                                                h={5}
                                                                as={GoCircle}
                                                            />
                                                        }
                                                    />
                                                </StepIndicator>
                                            </HStack>
                                        </Step>
                                    ))}
                                </Stepper>
                            </VStack>
                        </GridItem>
                        <GridItem>
                            <HStack w="full" justify="space-between" fontWeight="medium">
                                <Anchor {...props.anchor.home}>
                                    <HStack
                                        cursor="pointer"
                                        _hover={{ textDecor: 'underline' }}
                                    >
                                        <Icon as={AiOutlineArrowLeft}></Icon>
                                        <Text>Back to home</Text>
                                    </HStack>
                                </Anchor>
                                <Link {...props.link.login}>Sign in</Link>
                            </HStack>
                        </GridItem>
                    </Grid>
                </GridItem>
                <GridItem>
                    <Box h="full" py={16} w="full" mx="auto" maxW="42rem">
                        <Tabs index={props.stepper.index} isLazy={true}>
                            <TabPanels>
                                {steps.map(({ children, ...rest }) => (
                                    <TabPanel key={rest.id} p={0}>
                                        {children}
                                    </TabPanel>
                                ))}
                            </TabPanels>
                        </Tabs>
                    </Box>
                </GridItem>
            </Grid>
        );
    };
}
