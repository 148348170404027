import React, { useMemo } from 'react';
import { capitalize, chain } from 'lodash';
import {
    DateRangeValue,
    EnumMember,
    flattenEnumMembers,
    isTreeProperty,
} from '../../../../domain/attributes';
import { DateRange, periodToRange } from '../../../../../domain';
import {
    MetricDefinition,
    MetricCalculation,
    MetricCalculationSegment,
    getMetricBuckets,
    getCalculationStatus,
} from '../../../../domain/metrics';
import { DateConfigurationWithComparison } from '../../../../domain/query';
import { WorkspaceContextV2 } from '../../../../app';
import { MetricStaticDeps } from '../../metricsConfig';
import { BenchmarkListController, BenchmarkListLoader } from './benchmarkListInterface';
import {
    BenchmarkItemConnectedProps,
    BenchmarkItemProps,
    BenchmarkItemRangeProps,
    BenchmarkItemSegmentProps,
    BenchmarkListViewProps,
    BenchmarkPeerProps,
    BenchmarkPluginItemProps,
    BenchmarkStatus,
} from './benchmarkListProps';
import { QueryClient } from '@tanstack/react-query';
import {
    applyToBucket,
    Bucket,
    normalize,
    normalizeBuckets,
    Scale,
} from '../../../../domain/visualization';
import { Box, HStack } from '@chakra-ui/react';
import { MetricCollectionDto } from '../../../../api';
import { useWorkspaceContextV2, WorkspaceContextData } from '../../../../context';
import { assert } from '../../../../util/assert';
import {
    CONNECTION_STATUS_ORDERING,
    METRIC_ORDERING,
    PLUGIN_ORDERING,
} from '../../collection';
import { useLocation } from 'react-router';
import { buildPeerProps } from './benchmarkListFactory';

const KEY_PREFIX = 'metrics.list';

function rangeToValue(range: DateRange): DateRangeValue {
    return { from: range.start, to: range.end };
}

// function getQueryKey(
//     context: WorkspaceContextV2 & { data: WorkspaceContextData },
//     collection: MetricCollectionDto,
//     control: DateConfigurationWithComparison
// ) {
//     return [
//         KEY_PREFIX,
//         context.account.id,
//         context.workspace.id,
//         // context.data.connections.data,
//         // context.data.dashboards.data,
//         context.data.peergroups.data,
//         control.period,
//         control.comparison,
//         control.granularity,
//         collection,
//     ];
// }

function cancelQueries(client: QueryClient) {
    return client.cancelQueries({ queryKey: [KEY_PREFIX] });
}

export function createBenchmarkListController(
    init: MetricStaticDeps,
    loader: BenchmarkListLoader
): BenchmarkListController {
    const {
        hook: { useQueryClient, useQuery },
    } = init;
    return {
        useProps(deps, context, data, collection, control, options = {}) {
            // const queryClient = useQueryClient();
            const location = useLocation();
            // const { suspense = true } = options;
            const { formatter } = deps;


            // console.log('DEBUG query', data.item.data);
            // const query = useQuery({
            //     // refetchOnMount: 'always',
            //     queryKey: getQueryKey(context, collection, control),
            //     async queryFn({ signal }) {
            //         // console.log(
            //         //     'fetchhing query',
            //         //     getQueryKey(context, collection, control)
            //         // );
            //         cancelQueries(queryClient);
            //         return loader.useLoad(
            //             deps,
            //             context,
            //             collection,
            //             { date: control },
            //             { signal }
            //         );
            //     },
            //     retry: false,
            //     suspense,
            //     staleTime: Infinity,
            // });

            const { item: query } = data;

            const items = useMemo(() => {
                return chain(query.data ?? [])
                    .filter((item) => item.connection.status === 'ready')
                    .orderBy(
                        (item) => [
                            PLUGIN_ORDERING.findIndex(
                                (plugin) => plugin === item.plugin.id
                            ),
                            METRIC_ORDERING.findIndex(
                                (kind) => item.metric.kind === kind
                            ),
                        ],
                        ['asc', 'asc']
                    )
                    .value();
            }, [query.data]);
            const plugins = data.plugins;
            
            const subverticalsByValueByPlugin = useMemo(() => {
                const initial = {} as Record<
                    string,
                    Record<string, EnumMember | undefined> | undefined
                >;
                return (plugins.data || []).reduce((acc, item) => {
                    const [trait, ...rest] = item.traits.filter(isTreeProperty);
                    if (!trait) {
                        console.warn(`no traits found for plugin ${item.id}`);
                        return acc;
                    }
                    if (rest.length > 0) {
                        console.warn(
                            `unexpected multiple tree properties for plugin '${item.id}'`,
                            item
                        );
                        return acc;
                    }
                    const members = flattenEnumMembers(trait.type.members).reduce(
                        (acc, member) =>
                            member.parent === null
                                ? acc
                                : { ...acc, [member.value]: member },
                        {} as Record<string, EnumMember | undefined>
                    );
                    return {
                        ...acc,
                        [item.id]: members,
                    };
                }, initial);
            }, [plugins.data]);

            // console.log('membersByTraitKey', subverticalsByValueByPlugin);

            const peerPropsByMetric = useMemo(() => {
                return items.reduce(
                    (acc, item) => {
                        const subverticalsByValue =
                            subverticalsByValueByPlugin[item.plugin.id];
                        if (!subverticalsByValue) {
                            console.warn(
                                `members for plugin '${item.plugin.id}' not found`,
                                subverticalsByValue
                            );
                            return acc;
                        }
                        return {
                            ...acc,
                            [item.metric.id]: buildPeerProps(subverticalsByValue, item),
                        };
                    },
                    {} as Record<string, BenchmarkPeerProps | undefined>
                );
            }, [subverticalsByValueByPlugin, items]);

            // console.log('DEBUG peerPropsByMetric', peerPropsByMetric);

            // const plugins = useMemo(
            //     () =>
            //         chain(items)
            //             .uniqBy((item) => item.plugin.id)
            //             .map((item) => item.plugin)
            //             .value(),
            //     [items]
            // );

            const disabledPluginIds = useMemo(
                () =>
                    new Set(
                        (plugins.data || []).filter((item) => item.isDisabled).map((item) => item.id)
                    ),
                [plugins]
            );
            const disabledPlugins = useMemo(() => {
                return chain(plugins.data || [])
                    .filter((item) => item.isDisabled)
                    .map(
                        (item): BenchmarkPluginItemProps => ({
                            id: item.id,
                            label: item.name,
                            iconUrl: item.iconUrl,
                            isDisabled: true,
                            disabledReason: item.disabledReason ?? 'N/A',
                            link: null,
                        })
                    )
                    .value();
            }, [plugins, disabledPluginIds, items]);

            const enabledItems = useMemo(() => {
                return items.filter((item) => !disabledPluginIds.has(item.plugin.id));
            }, [items, disabledPluginIds]);

            const props: BenchmarkListViewProps = {
                plugins: {
                    disabled: disabledPlugins,
                },
                data: {
                    collection,
                },
                query: {
                    isLoading: query.isLoading,
                },
                datelabel: {
                    period: formatter.format(
                        'daterange',
                        rangeToValue(periodToRange(null, control.period))
                    ),
                    comparison: formatter.format(
                        'daterange',
                        rangeToValue(periodToRange(control.period, control.comparison))
                    ),
                },
                items: enabledItems,
                getPeerProps(item) {
                    const peerProps = peerPropsByMetric[item.metric.id];
                    if (!peerProps) {
                        throw new Error(
                            `peer props not found for metric '${item.metric.id}'`,
                            peerPropsByMetric
                        );
                    }
                    return peerProps;
                },
                getItemProps(item): BenchmarkItemProps {
                    const path = `/u/assets/${context.workspace.id}/dashboards/${item.configuration.dashboard.id}`;
                    const setPeerPath = `/u/assets/${context.workspace.id}/onboarding?step=peer_groups&success_path=${location.pathname}&cancel_path=${location.pathname}&plugin=${item.plugin.id}`;

                    const metricPath = path;

                    // const metricPath = `/u/assets/${context.workspace.id}/metrics/analyze?metric=${item.metric.id}`;
                    const icon = item.plugin.iconUrl;
                    if (item.connection.status !== 'ready') {
                        return {
                            metricPath,
                            peersPath: setPeerPath,
                            status: item.connection.status,
                            path,
                            label: item.metric.name,
                            icon: icon,
                        };
                    }
                    if (!item.calculation) {
                        return Error(`no calculation found for metric`);
                    }

                    const current = item.calculation.current;

                    const valueLabel = current?.value.base
                        ? formatter.format(item.metric.type, current.value.base, {
                              notation: 'short',
                          })
                        : 'N/A';

                    const buckets = getMetricBuckets(item.metric);
                    const absoluteStatus = getCalculationStatus(
                        buckets,
                        item.calculation
                    );

                    const itemProps: BenchmarkItemConnectedProps = {
                        peersPath: setPeerPath,
                        metricPath,
                        ranking: item.ranking,
                        status: item.connection.status,
                        path,
                        label: item.calculation.definition.name,
                        icon: icon,
                        value: current?.value.base ?? null,
                        valueLabel,
                        lowerLabel: null,
                        upperLabel: null,
                        buckets,
                        inverse: item.metric.inverse,
                        min: {
                            ...item.calculation.min.value,
                            label: formatter.format(
                                item.metric.type,
                                item.calculation.min.value.base
                            ),
                        },
                        low: {
                            ...item.calculation.low.value,
                            label: formatter.format(
                                item.metric.type,
                                item.calculation.low.value.base
                            ),
                        },
                        mid: {
                            ...item.calculation.mid.value,
                            label: formatter.format(
                                item.metric.type,
                                item.calculation.mid.value.base
                            ),
                        },
                        high: {
                            ...item.calculation.high.value,
                            label: formatter.format(
                                item.metric.type,
                                item.calculation.high.value.base
                            ),
                        },
                        max: {
                            ...item.calculation.max.value,
                            label: formatter.format(
                                item.metric.type,
                                item.calculation.max.value.base
                            ),
                        },
                        ranges: [
                            item.calculation.low,
                            item.calculation.mid,
                            item.calculation.high,
                        ].map(
                            (segment, index): BenchmarkItemRangeProps => ({
                                name: segment.definition.name,
                                status: ((
                                    {
                                        0: 'danger',
                                        1: 'warn',
                                        2: 'success',
                                    } as const
                                )[index] ?? 'success')!,
                                value: segment.value.base ?? null,
                                plot: true,
                                label: segment.value.base
                                    ? formatter.format(
                                          item.metric.type,
                                          segment.value.base,
                                          { notation: 'short' }
                                      )
                                    : 'N/A',
                                description: null,
                            })
                        ),
                        absolute: {
                            tooltip: {
                                segmentLabel: current.definition.name,
                                label: valueLabel,
                                status: absoluteStatus,
                                description:
                                    current.value.base === null ? null : (
                                        <>
                                            <Box as="span">Your </Box>
                                            <Box
                                                as="span"
                                                color="whiteAlpha.800"
                                                fontWeight="semibold"
                                                // fontStyle="normal"
                                            >
                                                {item.metric.name}
                                            </Box>{' '}
                                            <Box as="span">is</Box>{' '}
                                            <Box
                                                as="span"
                                                px={1}
                                                py={0.25}
                                                color="whiteAlpha.800"
                                                bg="blackAlpha.700"
                                                fontWeight="semibold"
                                                fontStyle="normal"
                                            >
                                                {valueLabel}
                                            </Box>{' '}
                                            <Box as="span">for the selected period</Box>
                                        </>
                                    ),
                            },
                        },
                        trend: {
                            segments: [
                                ...[item.calculation.current, item.calculation.mid].map(
                                    (segment, index): BenchmarkItemSegmentProps => {
                                        const segmentName = segment.definition.name;
                                        const descriptionPrefix =
                                            segment.definition.kind === 'asset'
                                                ? 'Your'
                                                : `${segment.definition.name} peer group`;

                                        const previousValue = segment.value.comparison;
                                        const currentValue = segment.value.base;

                                        const changeLabel =
                                            segment.value.change !== null
                                                ? formatter.format(
                                                      'percent',
                                                      Math.abs(segment.value.change),
                                                      { notation: 'long' }
                                                  )
                                                : 'N/A';

                                        const previousLabel =
                                            previousValue !== null
                                                ? formatter.format(
                                                      item.metric.type,
                                                      previousValue,
                                                      { notation: 'short' }
                                                  )
                                                : 'N/A';

                                        const currentLabel =
                                            currentValue !== null
                                                ? formatter.format(
                                                      item.metric.type,
                                                      currentValue,
                                                      { notation: 'short' }
                                                  )
                                                : 'N/A';

                                        const direction = getMovement(
                                            segment.value.change
                                        );

                                        const verb =
                                            direction === 'up'
                                                ? 'increased'
                                                : 'decreased';

                                        let changeSign = '';
                                        if (direction === 'up') {
                                            changeSign = '+';
                                        }
                                        if (direction === 'down') {
                                            changeSign = '-';
                                        }

                                        const includeDescription =
                                            segment.value.change !== null;

                                        return {
                                            direction,
                                            status: getRelativeStatus(
                                                item.metric,
                                                segment
                                            ),
                                            tooltipId:
                                                item.calculation?.current.definition
                                                    .kind === 'asset'
                                                    ? 'metric_list_trends_me_tooltip'
                                                    : 'metric_list_trends_peer_group_tooltip',
                                            name: segmentName,
                                            label: changeLabel,
                                            description: !includeDescription ? null : (
                                                <>
                                                    <Box as="span">
                                                        {descriptionPrefix}
                                                    </Box>{' '}
                                                    <HStack
                                                        display="inline-block"
                                                        align="center"
                                                        spacing={2}
                                                    >
                                                        <Box
                                                            as="span"
                                                            color="whiteAlpha.800"
                                                            fontWeight="semibold"
                                                            // fontStyle="normal"
                                                        >
                                                            {item.metric.name}
                                                        </Box>
                                                    </HStack>{' '}
                                                    <Box as="span">{verb} by</Box>{' '}
                                                    <Box
                                                        px={1}
                                                        py={0.25}
                                                        as="span"
                                                        color="whiteAlpha.800"
                                                        // fontWeight="semibold"
                                                        bg="blackAlpha.700"
                                                        fontWeight="semibold"
                                                        fontStyle="normal"
                                                    >
                                                        {changeSign}
                                                        {changeLabel}
                                                    </Box>{' '}
                                                    <Box as="span">
                                                        compared to the previous period,
                                                        going from
                                                    </Box>{' '}
                                                    <Box
                                                        px={1}
                                                        py={0.25}
                                                        as="span"
                                                        color="whiteAlpha.800"
                                                        // fontWeight="semibold"
                                                        bg="blackAlpha.700"
                                                        fontWeight="semibold"
                                                        fontStyle="normal"
                                                    >
                                                        {previousLabel}
                                                    </Box>{' '}
                                                    <Box as="span">to</Box>{' '}
                                                    <Box
                                                        px={1}
                                                        py={0.25}
                                                        as="span"
                                                        color="whiteAlpha.800"
                                                        // fontWeight="semibold"
                                                        bg="blackAlpha.700"
                                                        fontWeight="semibold"
                                                        fontStyle="normal"
                                                    >
                                                        {currentLabel}
                                                    </Box>
                                                </>
                                            ),
                                        };
                                    }
                                ),
                            ],
                        },
                    };
                    return itemProps;
                },
                getNameLabel(condition) {
                    return condition.key;
                },
                getOperatorLabel(condition) {
                    return String(condition.operator);
                },
                getValueLabel(condition) {
                    return JSON.stringify(condition.value);
                },
            };
            return props;
        },
    };
}

function getMovement(value: number | null): 'up' | 'down' | null {
    if (value === null) {
        return null;
    }
    if (value > 0) {
        return 'up';
    }
    if (value < 0) {
        return 'down';
    }
    return null;
}

function getRelativeStatus(
    metric: MetricDefinition,
    segment: MetricCalculationSegment
): BenchmarkItemRangeProps['status'] | null {
    if (!metric.inverse && segment.value.change && segment.value.change > 0) {
        return 'success';
    }
    if (!metric.inverse && segment.value.change && segment.value.change < 0) {
        return 'danger';
    }
    if (metric.inverse && segment.value.change && segment.value.change > 0) {
        return 'danger';
    }
    if (metric.inverse && segment.value.change && segment.value.change < 0) {
        return 'success';
    }
    return null;
}

// export function getAbsoluteStatus(
//     buckets: Array<Bucket & { status: BenchmarkStatus }>,
//     metric: MetricDefinition,
//     calculation: MetricCalculation
// ): BenchmarkStatus | null {
//     if (
//         !calculation.low.value.base ||
//         !calculation.mid.value.base ||
//         !calculation.high.value.base
//     ) {
//         return null;
//     }

//     const scale: Scale = {
//         points: [
//             calculation.low.value.base,
//             calculation.mid.value.base,
//             calculation.high.value.base,
//         ],
//     };

//     if (!calculation.current.value.base) {
//         return null;
//     }

//     // console.log('ASDADSDAS', metric.id, {
//     //     buckets: normalizeBuckets(buckets),
//     //     value: normalize(scale, calculation.current.value.base),
//     // });
//     const applied = applyToBucket(
//         normalizeBuckets(buckets),
//         normalize(scale, calculation.current.value.base)
//     );

//     return applied?.status ?? null;
// }
