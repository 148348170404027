// import { createListViewProvider } from './list';
import { ApplicationViewConfig } from './applicationViewConfig';
import { ApplicationView } from './applicationViewInterface';
import { createListController } from './list/listViewController';
import { createSearchV2Controller } from './search-v2';
import { createSelectV2Controller } from './select-v2';

export function createApplicationView(
    config: ApplicationViewConfig = {}
): ApplicationView {
    return {
        // list: createListViewProvider(),
        createList: createListController,
        createSearch: createSearchV2Controller,
        createSelect: createSelectV2Controller,
    };
}
