import React from 'react';
import { motion, useIsPresent, Variants } from 'framer-motion';
import { ToastViewProps } from '../../../../base';
import { ReportContainerBaseConfig } from '../../base';
import { ReportRootContainerProps } from './reportRootProps';
import { ReportErrorContainerProps } from '../common';
import {
    Box,
    ButtonProps,
    Fade,
    HStack,
    Icon,
    ScaleFade,
    StackProps,
    Text,
} from '@chakra-ui/react';
import { AiOutlineCheckCircle, AiOutlineWarning } from 'react-icons/ai';
import { IconType } from 'react-icons';

export function createReportRootContainer(
    config: ReportContainerBaseConfig,
    View: {
        Error: React.FC<
            { children?: React.ReactNode | undefined } & ReportErrorContainerProps
        >;
    }
): React.FC<{ children?: React.ReactNode | undefined } & ReportRootContainerProps> {
    const {
        kernel: {
            infra: {
                toasterV2: { Container: ToastContainer },
            },
        },
        UI: {
            Application: { createErrorBoundary },
        },
    } = config;

    const ErrorBoundary = createErrorBoundary({
        errorClass: Error,
    });

    const Toast: React.FC<ToastViewProps> = (props) => {
        const containerProps = (
            props.status === 'success'
                ? {
                      color: 'green',
                      Icon: AiOutlineCheckCircle,
                  }
                : {
                      color: 'red',
                      Icon: AiOutlineWarning,
                  }
        ) satisfies { color: ButtonProps['colorScheme']; Icon: IconType };
        return (
            <Box position="fixed" bottom={12} right={12}>
                <ScaleFade initialScale={0.9} in={true}>
                    <HStack
                        bg={`${containerProps.color}.400`}
                        px={4}
                        py={3}
                        borderRadius="sm"
                        minW="16rem"
                        spacing={3}
                    >
                        <Icon fontSize="lg" as={containerProps.Icon} />
                        <Text fontWeight="semibold">{props.title}</Text>
                    </HStack>
                </ScaleFade>
            </Box>
        );
    };

    return ({ children, ...containerProps }) => {
        return (
            <ErrorBoundary fallback={(info) => <View.Error info={info} />}>
                {children}
                <ToastContainer as={Toast} />
            </ErrorBoundary>
        );
    };
}
