import WhiteText from '../../../svg/varos-logo-small-white.svg';
import LogoLarge from '../../../svg/varos-logo-large.svg';
import BrandIcon from '../../../svg/varos-icon.svg';
import Visa from '../../../svg/payment/visa.svg';
import MasterCard from '../../../svg/payment/mastercard.svg';
import AmericanExpress from '../../../svg/payment/american-express.svg';

export const Graphics = {
    Visa,
    MasterCard,
    AmericanExpress,
    Brand: {
        Icon: BrandIcon,
        WhiteText,
        LogoLarge,
    },
};
