import { AnyAccountInvitationEntity, InvitationUser } from '../../../domain/accounts';
import { AccountInvitationDto, AccountInvitationCreateRequest } from '../../../api';
import {
    AccountInvitationAdapter,
    AccountInvitationBulkCreateItemProps,
    AccountInvitationBulkDeleteItemProps,
    AnyAccountInvitationCreateProps,
    AnyAccountInvitationBulkItemProps,
} from '../../../app/accounts';
import { AccountInvitationImplConfig } from './invitationImplConfig';
import { assert } from '../../../util/assert';
import { assertNever } from '../../../util';

export function createAccountInvitationImpl(
    config: AccountInvitationImplConfig
): AccountInvitationAdapter {
    const {
        api: { platform: api },
    } = config;

    function toEntity(dto: AccountInvitationDto): AnyAccountInvitationEntity {
        const source: AnyAccountInvitationEntity['source'] =
            dto.metadata.source?.kind === 'group'
                ? { kind: 'group', id: dto.metadata.source.group_ids[0] }
                : null;

        assert(!source || (source && source.id), 'invalid group');
        if (dto.data.kind === 'company') {
            return {
                kind: dto.data.kind,
                id: dto.id,
                source,
                company: {
                    id: dto.data.company_id,
                },
                status: dto.metadata.status,
                createdAt: dto.created_at,
                updatedAt: dto.updated_at ?? dto.created_at,
                lastEmailSent: dto.metadata.last_sent,
            };
        }
        if (dto.data.kind === 'external') {
            let invitee: Partial<InvitationUser> | null = null;
            if (dto.data.invitee_user_id || dto.data.invitee_account_id) {
                invitee = {
                    user: dto.data.invitee_user_id
                        ? {
                              id: dto.data.invitee_user_id,
                          }
                        : undefined,
                    account: dto.data.invitee_account_id
                        ? {
                              id: dto.data.invitee_account_id,
                          }
                        : undefined,
                };
            }
            return {
                kind: dto.data.kind,
                id: dto.id,
                source,
                email: dto.data.email,
                account: dto.data.account,
                first_name: dto.data.first_name ?? null,
                last_name: dto.data.last_name ?? null,
                company: dto.data.company_id
                    ? {
                          id: dto.data.company_id,
                      }
                    : null,
                status: dto.metadata.status,
                createdAt: dto.created_at,
                updatedAt: dto.updated_at ?? dto.created_at,
                invitee,
                inviter:
                    dto.metadata.inviter_user_id && dto.metadata.inviter_account_id
                        ? {
                              user: {
                                  id: dto.metadata.inviter_user_id,
                              },
                              account: {
                                  id: dto.metadata.inviter_account_id,
                              },
                          }
                        : null,
                lastEmailSent: dto.metadata.last_sent
            };
        }
        if (dto.data.kind === 'member') {
            return {
                kind: dto.data.kind,
                id: dto.id,
                source,
                email: dto.data.email,
                first_name: dto.data.first_name ?? null,
                last_name: dto.data.last_name ?? null,
                role: dto.data.role,
                inviteeUserId: dto.data.invitee_user_id,
                status: dto.metadata.status,
                createdAt: dto.created_at,
                updatedAt: dto.updated_at ?? dto.created_at,
                lastEmailSent: dto.metadata.last_sent,
                memberProfile: dto.data.member_profile
            };
        }
        return {
            kind: dto.data.kind,
            id: dto.id,
            source,
            status: dto.metadata.status,
            createdAt: dto.created_at,
            updatedAt: dto.updated_at ?? dto.created_at,
            lastEmailSent: dto.metadata.last_sent
        };
    }


    function propsToApi(props: AnyAccountInvitationCreateProps): AccountInvitationCreateRequest {
        if (props.kind === 'member') {
            return {
                data: {
                    kind: props.kind,
                    first_name: props.firstName,
                    last_name: props.lastName,
                    email: props.email,
                    role: props.role === 'admin' ?  'admin' : {
                        kind: 'standard',
                        collaborations: props.accessAssetIds.map(asset_id => ({
                            asset_id,
                            role: 'manager'
                        }))
                    },
                    member_profile:  props.title ? {
                        title: props.title
                    }: null,
                }
            }
        }
        else if(props.kind === 'company') {
            return {
                data: {
                    kind: 'company',
                    company_id: props.id,
                    source:
                        props.source.kind === 'group'
                            ? {
                                kind: 'group',
                                group_ids: [props.source.id],
                            }
                            : undefined,
                },
            }
        }
        else if(props.kind === 'fuzzy') {
            return {
                data: {
                    kind: 'fuzzy',
                    text: props.text,
                    source:
                        props.source.kind === 'group'
                            ? {
                                kind: 'group',
                                group_ids: [props.source.id],
                            }
                            : undefined,
                },
            }
        } else {
            throw new Error('unknown prop kind')
        }
    }
    return {
        async send(context, invitationId) {
            return api.invitation.send(context, invitationId);
        },
        async find(context, query) {
            if(query.source) {
                const [first, ...rest] = query.source;
                if (first && rest.length > 0) {
                    // multi lookup on source
                    const response = await Promise.all(
                        [first, ...rest].map((item) => {
                            assert(
                                item.kind === 'group',
                                'only group-based invitations are implemented'
                            );
                            return api.invitation.list(context, {
                                source: item.id,
                                statuses: query.statuses,
                                kinds: query.members? ['member']: ['external', 'fuzzy', 'company'],
                                page_size: query.limit,
                            });
                        })
                    );
                    const merged = response.flatMap((item) => item.data);
                    return {
                        total: merged.length,
                        limit: query.limit ?? 10,
                        items: merged.map(toEntity),
                    };
                }
                if (first) {
                    // lookup by single source
                    assert(
                        first.kind === 'group',
                        'only group-based invitations are implemented'
                    );
                    const response = await api.invitation.list(context, {
                        source: first.id,
                        statuses: query.statuses,
                        kinds: query.members? ['member']: ['external', 'fuzzy', 'company'],
                        page_size: query.limit,
                    });
                    return {
                        total: response.data.length,
                        limit: query.limit ?? 10,
                        items: response.data.map(toEntity),
                    };
                }
                // NOTE there is currently an issue with fetching non-groups based invitations
                return {
                    total: 0,
                    limit: query.limit ?? 10,
                    items: [],
                };
            } else {
                const response =  await api.invitation.list(context, {
                    kinds: query.members? ['member']: ['external', 'fuzzy', 'company'],
                        page_size: query.limit,
                    statuses: query.statuses
                });
                return {
                    total: response.data.length,
                    limit: query.limit ?? 10,
                    items: response.data.map(toEntity),
                };
            }
        },
        async findOne(query) {
            const verified = await api.invitation.verify(query);
            return toEntity(verified);
        },
        async create(context, props) {
            const response = await api.invitation.create(context, propsToApi(props));
            const entity = toEntity(response);
            return entity;
        },
        async bulk(context, props) {
            async function create(
                props: AccountInvitationBulkCreateItemProps
            ): Promise<AnyAccountInvitationEntity & { deleted?: boolean }> {
                const response = await api.invitation.create(context, propsToApi(props.data));
                const entity = toEntity(response);
                return entity;
            }
            async function remove(
                props: AccountInvitationBulkDeleteItemProps
            ): Promise<AnyAccountInvitationEntity & { deleted?: boolean }> {
                const response = await api.invitation.delete(context, props.entity.id);
                return { ...props.entity, deleted: true };
            }
            return Promise.all(
                props.items.map((item) => {
                    if (item.kind === 'create') {
                        return create(item);
                    }
                    if (item.kind === 'delete') {
                        return remove(item);
                    }
                    assertNever(item);
                })
            );
        },

        accept(payload) {
            return api.invitation.accept(payload);
        },
    };
}
