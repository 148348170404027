import React from 'react';
import {
    Button,
    Grid,
    GridItem,
    HStack,
    Icon,
    Image,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { BsArrowLeft } from 'react-icons/bs';
import { Graphics } from '../../../../../../config/svg';
import { AssistantLayoutViewProps } from '../assistantLayoutProps';
import { AssistantViewBaseConfig } from '../../../../base';
import { GoSidebarCollapse, GoSidebarExpand } from 'react-icons/go';

export function createAssistantDesktopLayoutView(
    config: Pick<AssistantViewBaseConfig, 'UI'>
): React.FC<{ children?: React.ReactNode | undefined } & AssistantLayoutViewProps> {
    const {
        UI: {
            Application: { Link },
        },
    } = config;
    return (props) => {
        return (
            <Grid
                w="100vw"
                h="100vh"
                templateRows="min-content 1fr"
                templateColumns="1fr"
            >
                {/* Header */}
                <GridItem
                    py={4}
                    px={6}
                    borderBottomWidth={2}
                    borderColor="whiteAlpha.300"
                >
                    <SimpleGrid columns={3}>
                        <HStack w="full" justify="space-between">
                            <HStack spacing={12}>
                                <Link {...props.link.home}>
                                    <Image src={Graphics.Brand.WhiteText} alt="brand" />
                                </Link>
                                {props.back && (
                                    <Button
                                        {...props.back.button}
                                        variant="link"
                                        leftIcon={<Icon as={BsArrowLeft} />}
                                        _focus={{ outline: 'none' }}
                                    >
                                        Go back
                                    </Button>
                                )}
                            </HStack>
                        </HStack>
                        <HStack w="full" justify="center">
                            {props.title && (
                                <VStack align="center" w="full" spacing={0}>
                                    <Text fontSize="lg" fontWeight="semibold">
                                        {props.title}
                                    </Text>
                                </VStack>
                            )}
                        </HStack>
                        <HStack justify="end" spacing={6}>
                            {props.actions.map((action) => (
                                <Button
                                    {...action.button}
                                    key={action.id}
                                    variant="link"
                                    borderRadius="full"
                                    aria-label={action.label}
                                    leftIcon={<Icon as={action.Icon} />}
                                    _focus={{ outline: 'none' }}
                                >
                                    {action.label}
                                </Button>
                            ))}
                        </HStack>
                    </SimpleGrid>
                </GridItem>
                <GridItem>
                    {!props.detail.container.isVisible && (
                        <Grid w="full" h="full">
                            <GridItem>{props.children}</GridItem>
                        </Grid>
                    )}
                    {props.detail.container.isVisible && props.sidebar && (
                        <Grid
                            w="full"
                            h="full"
                            templateColumns={{
                                base: '3fr minmax(20rem, 1fr)',
                                xl: '3fr minmax(24rem, 1fr)',
                                '2xl': '3fr minmax(32rem, 1fr)',
                            }}
                        >
                            <GridItem>{props.children}</GridItem>
                            <GridItem>
                                <VStack
                                    align="start"
                                    w="full"
                                    h="full"
                                    p={{
                                        base: 6,
                                        xl: 6,
                                        '2xl': 12,
                                    }}
                                    borderLeftWidth={2}
                                    borderColor="whiteAlpha.200"
                                >
                                    {props.sidebar}
                                </VStack>
                            </GridItem>
                        </Grid>
                    )}
                </GridItem>
            </Grid>
        );
    };
}
