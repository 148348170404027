import { assertNever } from '@varos/util-typescript';
import { z } from 'zod';
import { ContextFreePeriod } from '../../../../../../domain';
import { ReportInstanceDetailLoaderProps } from './reportDetailProps';

export const InstanceReportDetailParameterSchema = z
    .object({
        reportId: z.string(),
        timeframe: z.enum(['week', 'month', 'quarter', 'year']).default('year'),
    })
    .transform((item): ReportInstanceDetailLoaderProps => {
        let period: ContextFreePeriod | null = null;
        if (item.timeframe === 'week') {
            period = { amount: 7, interval: 'day' };
        } else if (item.timeframe === 'month') {
            period = { amount: 6, interval: 'week' };
        } else if (item.timeframe === 'quarter') {
            period = { amount: 12, interval: 'week' };
        } else if (item.timeframe === 'year') {
            period = { amount: 12, interval: 'month' };
        } else {
            assertNever(item.timeframe);
        }
        return {
            report: {
                id: item.reportId,
            },
            period,
            granularity: period.interval,
        };
    });
