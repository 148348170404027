import { useDisclosure } from '@chakra-ui/react';
import React, { useState } from 'react';
import { AssetMappingContainerConfigV2 } from '../../onboardingMappingConfig';
import { OnboardingEntitySelectDeps } from './entitySelectInterface';
import { OnboardingEntitySelectControllerProps } from './entitySelectProps';
import { createOnboardingEntitySelectView } from './entitySelectView';
import { createOnboardingEntitySelectController } from './entitySelectController';

export function createOnboardingEntitySelectContainer(
    config: Pick<AssetMappingContainerConfigV2, 'controller' | 'hooks'>
): React.FC<
    { children?: React.ReactNode | undefined } & OnboardingEntitySelectControllerProps
> {
    const { hooks } = config;
    const controller = createOnboardingEntitySelectController(config);
    const Component = createOnboardingEntitySelectView();
    return (props) => {
        const [search, setSearch] = useState('');
        const account = hooks.useAccount();
        const auth = hooks.useAuth();
        const context = { account, auth };
        const deps: OnboardingEntitySelectDeps = {
            control: {
                disclosure: useDisclosure(),
                state: {
                    search: {
                        value: search,
                        onChange: setSearch,
                    },
                },
            },
        };
        const viewProps = controller.useProps(context, deps, props);
        return <Component {...viewProps} />;
    };
}
