import { makeAutoObservable } from 'mobx';
import { Toast } from './toastModel';
import { ToastStore } from './toastInterface';

export function createToastStore(): ToastStore {
    const store = makeAutoObservable<ToastStore>({
        toasts: [] as Toast[],
        addToast(toast: Toast) {
            store.toasts.push(toast);
        },
        removeToast(id: string) {
            store.toasts = store.toasts.filter((t) => t.id !== id);
        },
    });
    return store;
}
