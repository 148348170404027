import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { UrlOptionParser } from '../../base';
import { mapValues } from 'lodash';

export function createUrlOptionParser(): UrlOptionParser {
    return {
        useOptions(schema) {
            const [searchParams] = useSearchParams();
            const params = useParams();
            const parsed = useMemo(() => {
                const cleaned = mapValues(
                    Object.fromEntries(searchParams.entries()),
                    (value, key) => (value === 'null' ? null : value)
                );
                return schema.safeParse({
                    ...params,
                    ...cleaned,
                });
            }, [searchParams]);
            if (!parsed.success) {
                console.error('option parse error', parsed.error);
                return parsed.error;
            }
            return parsed.data;
        },
    };
}
