import React from 'react';
import { BeatLoader } from 'react-spinners';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    BoxProps,
    Button,
    Flex,
    Grid,
    GridItem,
    HStack,
    Icon,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Stack,
    SystemProps,
    Text,
    Textarea,
    Tooltip,
    useToken,
    VStack,
    Wrap,
} from '@chakra-ui/react';
import { AiOutlineSend } from 'react-icons/ai';
import { Graphics } from '../../../../../config/svg';
import { assertNever } from '../../../../../util';
import { AssistantViewBaseConfig } from '../../../base';
import { createThreadInfoView } from './info';
import { MessageItemViewProps } from './message';
import { ThreadDetailViewProps } from './threadDetailProps';
import { ButtonElementContainerProps } from '../../../view/button';
import { VisualizationBlockContainerProps } from '../../../view';
import { createBlockView } from './block';

export function createThreadDetailView(
    config: AssistantViewBaseConfig,
    ElementUI: {
        Button: React.FC<
            { children?: React.ReactNode | undefined } & ButtonElementContainerProps
        >;
        Visualization: React.FC<
            { children?: React.ReactNode | undefined } & VisualizationBlockContainerProps
        >;
    }
): React.FC<{ children?: React.ReactNode | undefined } & ThreadDetailViewProps> {
    const { Layout } = config;

    const Detail = createThreadInfoView(config);

    const commonStyle = {
        gutter: { base: 3, lg: 0 },
    } satisfies {
        gutter: BoxProps['width'];
    };

    const containerStyle = {
        maxW: '42rem',
    } satisfies BoxProps;

    const messageStyle = {
        maxW: '32rem',
    } satisfies BoxProps;

    const Block = createBlockView(
        { ...config, style: { container: containerStyle } },
        ElementUI
    );

    const SystemMessageGroup: React.FC<
        { children?: React.ReactNode | undefined } & MessageItemViewProps
    > = (props) => {
        return (
            <Stack align="start" w="full" spacing={6} direction="column-reverse">
                <HStack w="full" spacing={{ base: 3, lg: 6 }} align="start">
                    <HStack
                        align="center"
                        justify="center"
                        position="relative"
                        // left={-14}
                        top={{ base: -0.5, lg: -1 }}
                        flexShrink={0}
                        // bg="whiteAlpha.300"
                        borderWidth={2}
                        borderColor="whiteAlpha.300"
                        borderRadius="full"
                        h={{ base: 7, lg: 8 }}
                        w={{ base: 7, lg: 8 }}
                    >
                        <Image
                            position="relative"
                            h={{ base: 2.5, lg: 3 }}
                            w={{ base: 2.5, lg: 3 }}
                            top="1px"
                            right="1px"
                            src={Graphics.Brand.Icon}
                            alt="system"
                            // filter="grayscale(100%)"
                        />
                    </HStack>
                    <VStack w="full" align="start" spacing={3}>
                        {props.content
                            .filter(
                                // NOTE we render select blocks as suggestions
                                (block) =>
                                    !(block.kind === 'input' && block.type === 'select')
                            )
                            .flatMap((block, index) => (
                                <HStack
                                    key={index}
                                    w="full"
                                    maxW={messageStyle.maxW}
                                    // maxW={
                                    //     block.kind === 'custom'
                                    //         ? undefined
                                    //         : messageStyle.maxW
                                    // }
                                >
                                    <Block data={block} />
                                </HStack>
                            ))}
                    </VStack>
                </HStack>
            </Stack>
        );
    };

    const UserMessageGroup: React.FC<
        { children?: React.ReactNode | undefined } & MessageItemViewProps
    > = (props) => {
        return (
            <VStack w="full" align="end" spacing={3}>
                {props.content.map((block, index) => {
                    if (block.kind === 'event') {
                        return <Block key={index} data={block} />;
                    }
                    if (block.kind !== 'text') {
                        return (
                            <React.Fragment key={index}>
                                user message type '{block.kind}' not implemented
                            </React.Fragment>
                        );
                    }
                    return (
                        <HStack key={index} w="full" justify="end">
                            <HStack
                                w="fit-content"
                                maxW={messageStyle.maxW}
                                bg="whiteAlpha.200"
                                borderRadius="lg"
                                px={{ base: 4, lg: 6 }}
                                py={{ base: 3, lg: 4 }}
                            >
                                <Text color="whiteAlpha.800">{block.text}</Text>
                            </HStack>
                        </HStack>
                    );
                })}
            </VStack>
        );
    };

    const MessageGroup: React.FC<
        { children?: React.ReactNode | undefined } & MessageItemViewProps
    > = (props) => {
        if (props.author.kind === 'system') {
            return <SystemMessageGroup {...props} />;
        }
        if (props.author.kind === 'user') {
            return <UserMessageGroup {...props} />;
        }
        assertNever(props.author.kind);
    };

    return (props) => {
        const colorSpinner = useToken('colors', 'whiteAlpha.400');
        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');
        return (
            <Layout title="Join survey" sidebar={<Detail {...props.detail} />}>
                <Grid
                    w="full"
                    h="full"
                    templateColumns="1fr"
                    templateRows="1fr min-content"
                    templateAreas={`
                        "conversation"
                        "composer"
                    `}
                >
                    {/* Messages */}
                    <GridItem gridArea="conversation" position="relative">
                        <Stack
                            position="absolute"
                            top={0}
                            bottom={0}
                            left={0}
                            right={0}
                            align="center"
                            w="full"
                            h="fit-content"
                            maxH="full"
                            spacing={{ base: 6, lg: 12 }}
                            // pl={8}
                            pt={8}
                            pb={{
                                base: 16,
                                lg: 32,
                                '2xl': 64,
                            }}
                            overflowY="scroll"
                            direction="column-reverse"
                            onScroll={props.chat.onScroll}
                            ref={props.chat.containerRef}
                            css={{
                                scrollbarGutter: 'stable both-edges',
                                '&::-webkit-scrollbar': {
                                    '-webkit-appearance': 'none',
                                    width: '0.5rem',
                                },
                                '&::-webkit-scrollbar-track': {},
                                '&::-webkit-scrollbar-corner': {
                                    '-webkit-appearance': 'none',
                                },
                                '&::-webkit-scrollbar-thumb': {
                                    borderRadius: '1rem',
                                    background: scrollbarBg,
                                },
                                '&::-webkit-scrollbar-thumb:hover': {
                                    // background: scrollbarBg,
                                    background: scrollbarHoverBg,
                                },
                            }}
                        >
                            {/* Loading indicator */}
                            {props.isLoading && (
                                <HStack
                                    maxW={containerStyle.maxW}
                                    w="full"
                                    px={commonStyle.gutter}
                                >
                                    <HStack
                                        w="full"
                                        spacing={{ base: 3, lg: 6 }}
                                        maxW={messageStyle.maxW}
                                    >
                                        <HStack
                                            align="center"
                                            justify="center"
                                            position="relative"
                                            // left={-14}
                                            top={{ base: -0.5, lg: -1 }}
                                            flexShrink={0}
                                            // bg="whiteAlpha.300"
                                            borderWidth={2}
                                            borderColor="whiteAlpha.300"
                                            borderRadius="full"
                                            h={{ base: 7, lg: 8 }}
                                            w={{ base: 7, lg: 8 }}
                                        >
                                            <Image
                                                position="relative"
                                                h={{ base: 2.5, lg: 3 }}
                                                w={{ base: 2.5, lg: 3 }}
                                                top="1px"
                                                right="1px"
                                                src={Graphics.Brand.Icon}
                                                alt="system"
                                                // filter="grayscale(100%)"
                                            />
                                        </HStack>
                                        <HStack
                                            w="full"
                                            maxW={containerStyle.maxW}
                                            color="whiteAlpha.800"
                                            position="relative"
                                            top={{ base: -0.5, lg: -1 }}
                                        >
                                            <BeatLoader
                                                color={colorSpinner}
                                                speedMultiplier={0.7}
                                                size="1rem"
                                            />
                                        </HStack>
                                    </HStack>
                                </HStack>
                            )}
                            {props.message.items.map((item, index) => (
                                <HStack
                                    key={item.id}
                                    maxW={containerStyle.maxW}
                                    w="full"
                                    px={commonStyle.gutter}
                                >
                                    <MessageGroup {...item} />
                                </HStack>
                            ))}
                            {props.message.items.length > 0 && (
                                <HStack
                                    w="full"
                                    justify="center"
                                    fontWeight="medium"
                                    fontSize="sm"
                                    color="whiteAlpha.400"
                                >
                                    <Text>Start of conversation</Text>
                                </HStack>
                            )}
                        </Stack>
                    </GridItem>
                    {/* Composer */}
                    <GridItem gridArea="composer">
                        <HStack w="full" justify="center">
                            <VStack
                                align="start"
                                w="full"
                                h="full"
                                spacing={4}
                                pb={{ base: 4, lg: 8 }}
                                maxW={containerStyle.maxW}
                                px={commonStyle.gutter}
                            >
                                <Wrap w="full" shouldWrapChildren={true} spacing={2}>
                                    {props.suggestion.items.map((item) => (
                                        <Button
                                            key={item.id}
                                            bg="none"
                                            {...item.button}
                                            borderRadius="full"
                                            borderWidth={2}
                                            borderStyle="dashed"
                                            // borderColor="blue.300"
                                            // color="blue.300"
                                            _hover={{ bg: 'whiteAlpha.50' }}
                                            _active={{ bg: 'whiteAlpha.50' }}
                                            _focus={{ outline: 'none' }}
                                        >
                                            {item.label}
                                        </Button>
                                    ))}
                                </Wrap>
                                {props.composer.error && (
                                    <Alert status="error">
                                        <AlertIcon />
                                        <AlertTitle>
                                            {props.composer.error.message}
                                        </AlertTitle>
                                    </Alert>
                                )}
                                <InputGroup>
                                    <Textarea
                                        {...props.chat.input}
                                        tabIndex={1}
                                        resize="none"
                                        ref={props.chat.composerRef}
                                        placeholder="Send a message"
                                        transition="all 0.3s ease"
                                        _readOnly={{
                                            borderColor: 'whiteAlpha.300',
                                            _focus: {
                                                borderColor: 'whiteAlpha.300',
                                            },
                                        }}
                                        _disabled={{
                                            borderColor: 'whiteAlpha.300',
                                            _focus: {
                                                borderColor: 'whiteAlpha.300',
                                            },
                                        }}
                                        _focus={{
                                            outline: 'none',
                                            borderColor: 'whiteAlpha.400',
                                        }}
                                        _focusVisible={{
                                            outline: 'none',
                                            borderColor: 'whiteAlpha.400',
                                        }}
                                    />
                                    <InputRightElement m={5}>
                                        <Tooltip
                                            arrowShadowColor="gray.100"
                                            {...props.composer.tooltip}
                                            shouldWrapChildren={true}
                                            py={2}
                                            px={3}
                                            placement="top"
                                            hasArrow={true}
                                            bg="gray.100"
                                            color="gray.900"
                                        >
                                            <IconButton
                                                {...props.chat.submit}
                                                aria-label="submit"
                                                borderRadius="full"
                                                icon={
                                                    <Icon
                                                        fontSize="lg"
                                                        as={AiOutlineSend}
                                                    />
                                                }
                                                bg="white"
                                                color="black"
                                                _disabled={{
                                                    opacity: 0.33,
                                                    cursor: 'default',
                                                }}
                                                _hover={{ bg: 'white' }}
                                                _focus={{ outline: 'none' }}
                                            />
                                        </Tooltip>
                                    </InputRightElement>
                                </InputGroup>
                            </VStack>
                        </HStack>
                    </GridItem>
                </Grid>
            </Layout>
        );
    };
}
