import React, { useMemo } from 'react';
import {
    buildStudyItemViewProps,
    StudyDependencyItemProps,
} from '../../../../view/studies';
import { PageIds } from '../../../../config';
import { assert } from '../../../../util/assert';
import { ApplicationLinkProps } from '../../../../ui';
import { DemographicsKPIReport } from '../../../../config/report/fixture';
import { StudiesDetailController } from './studiesReportDetailInterface';
import { StudiesDetailViewProps } from './studiesReportDetailProps';
import { StudiesControllerBaseConfig } from '../../base';
import { isWorkspaceContext } from '../../../../app';
import { AiOutlineInfoCircle, AiOutlineLock } from 'react-icons/ai';

export function createStudiesDetailController(
    config: StudiesControllerBaseConfig
): StudiesDetailController {
    const {
        kernel: {
            controller: { browser: browserController, navigation: navigationController },
        },
        controller: { help: helpController, image: imageConroller },
        provider: { createPageController },
        navigate,
    } = config;

    const pageController = createPageController({
        id: PageIds.REPORTS_DETAIL,
        title: 'Report',
        description: null,
        provider: config.provider,
    });

    return {
        useProps(context, item, props): StudiesDetailViewProps {
            const {
                report: { dataset },
            } = item;
            const pageProps = pageController.useProps(
                {
                    entity: item.report.report,
                },
                {}
            );

            const browser = browserController.useProps({});
            const navigation = navigationController.useProps({
                location: props.location,
                navigate: props.navigate,
            });

            const imageProps = imageConroller.useProps(item.image, {});

            const helpProps = helpController.useProps({
                id: 'request_a_report',
            });

            const studyProps = useMemo(
                () => buildStudyItemViewProps(context, item.report),
                [context, item.report]
            );
            const assetId = useMemo(() => {
                if (isWorkspaceContext(context)) {
                    return context.workspace.id as number;
                }
                return null;
            }, [context]);

            return {
                page: pageProps,
                browser,
                image: imageProps,
                back: {
                    button: navigation.back.button,
                    drawer: {
                        onClose() {
                            props.navigate(
                                // @ts-expect-error
                                -1,
                                { replace: true }
                            );
                        },
                    },
                },
                open: {
                    label: 'Open',
                    button: {
                        isDisabled: studyProps.status.access.kind !== 'available',
                        onClick() {
                            if (
                                item.report.report.kind === 'external' &&
                                item.report.report.id === DemographicsKPIReport.entity.id
                            ) {
                                return props.navigate(`/u/reports/demographics`);
                            }
                            if (item.report.report.kind === 'external') {
                                return window.open(item.report.report.url, '_blank');
                            }
                            if (item.report.report.kind === 'dataset') {
                                return props.navigate(
                                    assetId
                                        ? `/u/assets/${assetId}/reporting/reports/${item.report.report.id}/data`
                                        : `/u/account/reporting/reports/${item.report.report.id}/data`
                                );
                            }
                        },
                    },
                    Icon:
                        studyProps.status.access.kind === 'available'
                            ? null
                            : AiOutlineLock,
                },
                report: {
                    title: studyProps.label,
                    description: studyProps.description,
                    tagline: studyProps.tagline,
                    category: studyProps.category.label,
                },
                status:
                    studyProps.status.access.kind === 'not_enough_data'
                        ? {
                              alert: {
                                  status: 'warning',
                              },
                              description: 'Report data not yet available',
                          }
                        : studyProps.dependencies.status.isFulfilled
                          ? null
                          : {
                                alert: {
                                    status: 'warning',
                                },
                                description: `Report requirements not fulfilled`,
                            },
                requirements: {
                    hint: {
                        Icon: AiOutlineInfoCircle,
                        description: `Fulfill requirements to access report`,
                    },
                    status: studyProps.dependencies.status.isFulfilled
                        ? {
                              isFulfilled: true,
                              description: `Report requirements fulfilled`,
                              alert: null,
                              colorScheme: 'green',
                          }
                        : {
                              isFulfilled: false,
                              description: `Report requirements not fulfilled`,
                              alert: {
                                  status: 'warning',
                              },
                              colorScheme: 'orange',
                          },
                    items: studyProps.dependencies.items.map((item, index) => {
                        let link: ApplicationLinkProps | null = null;
                        if (item.kind === 'interview') {
                            assert(
                                dataset,
                                'expected dataset to have been loaded for interview dependency'
                            );
                            link = {
                                id: 'report_dependency_start_link',
                                ...navigate.assistant.thread.create({
                                    location: props.location,
                                    thread: {
                                        type: 'interview',
                                        data: {
                                            interview: dataset.id,
                                            asset: assetId,
                                        },
                                    },
                                }),
                            };
                        }
                        if (item.kind === 'survey') {
                            link = item.link.form;
                        }
                        return {
                            id: index.toString(),
                            label: item.label,
                            description: item.description,
                            status: {
                                isFulfilled: item.isFulfilled,
                                label: item.status.label,
                                tag: {
                                    colorScheme: item.status.color,
                                },
                            },
                            action: link
                                ? {
                                      label: 'Start',
                                      kind: 'link' as const,
                                      link: link,
                                  }
                                : null,
                        };
                    }),
                },
                study: {
                    ...studyProps,
                    dependencies: {
                        ...studyProps.dependencies,
                        items: studyProps.dependencies.items.map(
                            (dependency): StudyDependencyItemProps => {
                                if (dependency.kind === 'interview') {
                                    assert(
                                        dataset,
                                        'expected dataset to have been loaded for interview dependency'
                                    );
                                    return {
                                        ...dependency,
                                        link: {
                                            ...dependency.link,
                                            form: {
                                                id: 'report_dependency_start_link',
                                                ...navigate.assistant.thread.create({
                                                    location: props.location,
                                                    thread: {
                                                        type: 'interview',
                                                        data: {
                                                            interview: dataset.id,
                                                            asset: assetId,
                                                        },
                                                    },
                                                }),
                                            },
                                        },
                                    };
                                }
                                return dependency;
                            }
                        ),
                    },
                },
                legal: {
                    terms: {
                        link: navigate.legal.termsOfService({
                            location: props.location,
                        }),
                    },
                },
                help: helpProps,
                highlight:
                    item.report.report.kind === 'external'
                        ? {
                              label: 'Beta',
                              description:
                                  'This report is in beta and is actively being tested using internal tools',
                          }
                        : null,
                actions: {
                    open:
                        item.report.report.kind === 'external' &&
                        item.report.report.id === DemographicsKPIReport.entity.id
                            ? // TODO proper feature flag
                              {
                                  caption: null,
                                  links: {
                                      response: {
                                          to: `/u/reports/demographics`,
                                      },
                                  },
                                  anchors: {
                                      response: null,
                                  },
                              }
                            : item.report.report.kind === 'dataset'
                              ? {
                                    caption: null,
                                    links: {
                                        response: {
                                            to: assetId
                                                ? `/u/assets/${assetId}/reporting/reports/${item.report.report.id}/data`
                                                : `/u/account/reporting/reports/${item.report.report.id}/data`,
                                        },
                                    },
                                    anchors: {
                                        response: null,
                                    },
                                }
                              : {
                                    caption: {
                                        label: 'External report',
                                        description: `This report is in beta will opened in a separate tab using the Hex data platform`,
                                    },
                                    links: {
                                        response: null,
                                    },
                                    anchors: {
                                        response: {
                                            id: 'open_report',
                                            href: item.report.report.url,
                                            target: '_blank',
                                        },
                                    },
                                },
                },
            };
        },
    };
}
