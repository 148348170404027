import { useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { useNavigate } from 'react-router';
import { AccountScope } from '../../../../app';
import { PlanModalConfig } from './planModalConfig';
import { PlanModalDeps } from './planModalInterface';
import { createPlanModalView } from './planModelView';

export function createPlanModalRoute(
    config: PlanModalConfig
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    const {
        hook: { useAuth, useAccount: useOrganization },
        state: { paywall: paywallState },
        controller,
        loader,
    } = config;

    const View = createPlanModalView(config);

    return paywallState.connect((props) => {
        const viewContext: AccountScope = {
            auth: useAuth(),
            account: useOrganization(),
        };
        const viewState = paywallState.useState();
        const viewData = loader.useLoad(viewContext, viewState);
        const viewDeps: PlanModalDeps = {
            modal: {
                disclosure: useDisclosure({
                    isOpen: !!viewState.value.featureId,
                    onClose: viewState.onChange.bind(null, {
                        featureId: null,
                    }),
                }),
            },
            navigate: useNavigate(),
        };
        const viewProps = controller.useProps(viewContext, viewDeps, {
            item: viewData.data,
            isAddOnRequired: viewState.value.source === 'add_on',
        });
        return <View {...viewProps} />;
    });
}
