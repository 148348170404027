import React from 'react';
import { HelpContainerProps } from './helpProps';
import { HelpController } from './helpInterface';
import { useLocation } from 'react-router';

export function createHelpContainer(
    controller: HelpController
): React.FC<HelpContainerProps> {
    return ({ ...containerProps }) => {
        const location = useLocation();
        const props = controller.useProps({ location });
        return <></>;
    };
}
