import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { periodToRange } from '../../../../domain';
import { OpportunityItemAggregate } from '../../../view';
import { isEmptyCollection, isErrorCollection } from '../../../base';
import { InsightRouteConfigV2 } from './insightRouteConfig';
import { InsightRouteLoaderV2 } from './insightRouteInterface';

export function createInsightRouteLoaderV2(
    config: Pick<InsightRouteConfigV2['deps'], 'loader' | 'strategy' | 'repository'>
): InsightRouteLoaderV2 {
    const { repository } = config;
    return {
        useLoad(context, state) {
            const plugins = repository.plugin.useFind(
                context,
                {},
                { staleTime: Infinity }
            );

            const applied = useMemo(() => {
                return {
                    period: state.period ?? { amount: 4, interval: 'week' },
                    sorting: state.sorting ?? 'relevance',
                };
            }, [state]);

            const range = periodToRange(null, applied.period);

            const opportunities = repository.insight.opportunity.useFind(
                context,
                {
                    since: range.start,
                    before: range.end,
                    limit: 25,
                    orderBy:
                        applied.sorting === 'relevance'
                            ? [
                                  {
                                      key: 'priority',
                                      direction: 'desc',
                                  },
                                  {
                                      key: 'period_end_at',
                                      direction: 'desc',
                                  },
                              ]
                            : [
                                  {
                                      key: 'period_end_at',
                                      direction: 'desc',
                                  },
                              ],
                    aggregate: { keys: ['definition', 'kind'], take: 1 },
                },
                {
                    staleTime: Infinity,
                }
            );

            const metrics = repository.metric.definition.useFind(
                context,
                {
                    plugins: plugins.data ?? [],
                    assets: [context.workspace]
                },
                {
                    staleTime: Infinity,
                    suspense: true,
                    enabled: plugins.status === 'success',
                }
            );

            const pluginsById = useMemo(
                () => keyBy(plugins.data, (item) => item.id),
                [plugins.data]
            );

            // const detail = loader.report.useLoad(context, props.report);

            // const status = [detail].every((item) => item.status === 'success')
            //     ? 'success'
            //     : 'loading';

            if (
                plugins.status === 'loading' ||
                opportunities.status === 'loading' ||
                metrics.status === 'loading'
            ) {
                return {
                    items: {
                        status: 'loading',
                        data: null,
                    },
                    metrics: metrics.data ?? [],
                };
            }

            if (isEmptyCollection(opportunities) || isErrorCollection(opportunities)) {
                return {
                    items: {
                        status: 'empty',
                        data: null,
                    },
                    metrics: metrics.data ?? [],
                };
            }

            return {
                items: {
                    status: 'loaded',
                    data: {
                        total: opportunities.data.total ?? 0,
                        limit: opportunities.data.limit ?? 0,
                        items:
                            opportunities.data.items.flatMap(
                                (item): OpportunityItemAggregate[] => {
                                    const plugin = pluginsById[item.definition.plugin];
                                    if (!plugin) {
                                        return [];
                                    }
                                    return [
                                        {
                                            plugin,
                                            insight: item,
                                        },
                                    ];
                                }
                            ) ?? [],
                    },
                },
                metrics: metrics.data ?? [],
            };
        },
    };
}
