import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { getAppliedConditions } from '../../../domain/peers';
import { MetricDefinition } from '../../../domain/metrics';
import { GroupEditFormValues } from '../../../view/peer';
import { assert } from '../../../util/assert';
import { useSystemContextV2 } from '../../../context';
import { isReferenceProperty } from '../../../domain/attributes';
import { AnalysisRouteLoader } from './analysisRouteInterface';
import { AnalysisRouteDeps } from './analysisRouteConfig';
import { AnalysisRouteFormValues } from './analysisRouteModel';
import { buildAnalysisVisualization } from './analysisRouteFactory';

export function createAnalysisRouteLoader(
    deps: AnalysisRouteDeps
    // repository: {
    //     competitive: CompetitiveSetRepository;
    // }
): AnalysisRouteLoader {
    const { hook, repository } = deps;
    return {
        useData(context, props) {
            const {
                data: {
                    views: { data: views = [] },
                },
            } = useSystemContextV2();

            const { data: plugins = [] } = deps.service.plugin.useResolvedList(context, {}, {suspense: true});
            const peergroups = deps.repository.peergroup.useFind(context, 
                {workspace: context.workspace,
                    plugins: plugins.map(x => x.id)
                }, {}
            );
            const { data: metrics = [] } = deps.repository.metric.definition.useFind(context, {plugins, assets: [context.workspace]});

            const metricByKey = useMemo(
                () =>
                    metrics.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, MetricDefinition | undefined>
                    ),
                [metrics]
            );

            const selected = useMemo(
                () =>
                    metrics.filter((item) =>
                        props.visualization.metrics.some(
                            (candidate) => candidate.id === item.id
                        )
                    ) ?? null,
                [metrics, props.visualization.metrics]
            );

            // console.log('DEBUG selected', selected);

            if (selected.length === 0) {
                throw new Error(`no metrics selected`);
            }

            const view = useMemo(() => {
                const { breakdown, view } = props.visualization;
                // console.log('DEBUG props.visualization', props.visualization);

                if (view) {
                    return views.find((item) => item.id === view);
                }

                // view not explicitly configured, so resolve best match

                const directMatch = views.find((item) =>
                    selected.every((metric) =>
                        item.columns.some(
                            (column) =>
                                column.key === metric.key && item.plugin === metric.plugin
                        )
                    )
                );
                if (!breakdown) {
                    return directMatch;
                }
                if (
                    directMatch &&
                    breakdown.properties.every((facet) =>
                        directMatch.columns.some((property) => property.key === facet.key)
                    )
                ) {
                    return directMatch;
                }
                const found = views
                    .filter((view) => view.plugin === selected?.[0].plugin)
                    .find((view) =>
                        breakdown.properties.every((facet) =>
                            view.columns.some((property) => property.key === facet.key)
                        )
                    );
                return found ?? null;
            }, [views, props.visualization.breakdown?.properties, selected]);

            // console.log('DEBUG props', props);
            // console.log('DEBUG view', view);

            // console.log('DEBUG mathced', view);

            if (!view) {
                console.info('visualization', props.visualization, views);
                throw new Error('view could not be matched for requested visualization');
            }

            // const view = useMemo(
            //     () => views.find((item) => item.id === selected?.view) ?? null,
            //     [views, selected?.view]
            // );

            const selectedPlugin = selected?.[0].plugin;
            const plugin = useMemo(
                () => plugins.find((item) => item.id === selectedPlugin) ?? null,
                [views, selectedPlugin]
            );
            assert(plugin, `no plugin found for metric ${selectedPlugin}`);

            const peergroup = useMemo(
                () =>
                    peergroups.data?.find((item) => item.plugin.id === plugin?.id) ??
                    null,
                [peergroups.data, plugin]
            );

            const form = {
                visualization: useForm<AnalysisRouteFormValues>({
                    defaultValues: {},
                }),
                group: useForm<GroupEditFormValues>({
                    defaultValues: {
                        conditions:
                            plugin && peergroup
                                ? getAppliedConditions(plugin, peergroup)
                                : [],
                    },
                    reValidateMode: 'onChange',
                }),
            };

            const formValues = {
                visualization: form.visualization.watch(),
                group: form.group.watch(),
            };

            if (!plugin) {
                throw new Error(`missing plugin`);
            }

            const dimensions = useMemo(
                () => view?.columns.filter(isReferenceProperty),
                [view?.columns]
            );

            const query = {
                member: repository.member.useFind(
                    context,
                    {
                        workspace: { id: context.workspace.id },
                        types: dimensions?.map((item) => item.type) ?? [],
                    },
                    {
                        suspense: true,
                        staleTime: Infinity,
                    }
                ),
                competitive: repository.competitive.useLookup(
                    context,
                    {
                        plugin: plugin,
                        conditions: formValues.group.conditions,
                    },
                    {
                        suspense: false,
                        staleTime: Infinity,
                        retry: false,
                        keepPreviousData: true,
                    }
                ),
            };

            const visualization = useMemo(() => {
                return buildAnalysisVisualization(
                    formValues.visualization,
                    props.visualization.metrics.map((metric) => {
                        const found = metricByKey[metric.id];
                        assert(found, `metric ${metric.id} not found`);
                        return found;
                    }),
                    props.visualization
                );
            }, [metricByKey, props.visualization, formValues.visualization]);

            // console.log('DEBUG formValues.visualization', formValues.visualization);
            // console.log('DEBUG props.visualization', props.visualization);
            // console.log('DEBUG visualization', visualization);

            return {
                plugins,
                views,
                plugin,
                matched: view,
                metrics,
                selected,
                query,
                form,
                visualization,
            };
        },
    };
}
