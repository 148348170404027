import { createLocalStorageHook } from '../../util/react';
import { HomeRouteConfig } from './homeRouteConfig';
import { HomeData, HomeRouteLoader, HomeRouteQueries } from './homeRouteInterface';

export function createHomeRouteLoader(config: HomeRouteConfig): HomeRouteLoader {
    const {
        deps: { loader, hook, repository, service },
    } = config;
    return {
        useData(context, control): HomeRouteQueries {
            const [hidden, setHidden] = hook.useLocalStorage(
                // scope the state to the organization
                context.account.id.toString()
            );
            const plugins = service.plugins.useResolvedList(context, {}, {staleTime: Infinity, refetchOnWindowFocus: false});
            const connections = repository.connection.useFind(context, {workspaces: [context.workspace]}, {});
            const peergroups = repository.peers.useFind(context, {
                plugins: plugins.data?.map(x => x.id) ?? [],
                workspace: context.workspace
            }, {enabled: plugins.status === 'success'});

            const data: HomeData = {
                plugins,
                connections,
                peergroups
            }
            return {
                ...data,
                page: {
                    alert: {
                        items: [],
                    },
                    entity: null,
                },
                integration: loader.integration.useData(context, data),
                destination: loader.destination.useData(context, data),
                opportunity: loader.opportunity.useData(context, {
                    ...data,
                    period: control.date.period,
                }),
                alert: loader.alert.useData(context, {
                    period: control.date.period,
                }),
                guide: loader.guide.useLoad(context),
                hidden: {
                    value: hidden,
                    onChange: setHidden,
                },
            };
        },
    };
}
