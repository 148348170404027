import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AccountScope } from '../../platform';
import { MappingConnectionAdapter } from './mappingAdapter';
import { MappingConnectionQuery } from './mappingQuery';
import { MappingConnectionRepository } from './mappingInterface';
import { MappingConnectionBulk } from './mappingModel';
import { MappingCreateProps } from './mappingProps';
import { MappingConnection } from 'src/v2/domain/assets';

export function createMappingConnectionRepository(
    adapter: MappingConnectionAdapter
): MappingConnectionRepository {
    const PREFIX = ['mapping', 'connections'];
    function getPrefix(context: AccountScope, query: MappingConnectionQuery) {
        return [...PREFIX, context.account, query];
    }
    return {
        useFind(context, query, options) {
            const applied: MappingConnectionQuery = {
                limit: query.limit ?? 1000,
                ...query,
            };
            const response = useQuery({
                queryKey: getPrefix(context, applied),
                async queryFn() {
                    const response = await adapter.find(context, applied);
                    return response;
                },
                refetchOnWindowFocus: false,
                ...options,
            });
            return response;
        },
        useCreate(context) {
            const queryClient = useQueryClient();
            return useMutation({
                async mutationFn(props: MappingCreateProps) {
                    const response = await adapter.create(context, props);
                    return;
                },
                async onSuccess(data, variables, context) {
                    await queryClient.refetchQueries(PREFIX);
                },
            });
        },
        useDelete(context) {
            const queryClient = useQueryClient();
            return useMutation({
                async mutationFn(entity: MappingConnection) {
                    const response = await adapter.delete(context, entity);
                    return;
                },
                async onSuccess(data, variables, context) {
                    await queryClient.refetchQueries(PREFIX);
                },
            });
        },
        useBulk(context) {
            const queryClient = useQueryClient();
            return useMutation({
                async mutationFn(bulk: MappingConnectionBulk) {
                    const response = await adapter.bulk(context, bulk);
                    return response;
                },
                async onSuccess(data, variables, context) {
                    await queryClient.refetchQueries(PREFIX);
                },
            });
        },
    };
}
