import thumbnail from '../../../../images/report_thumbnail_agency.png';
import screenshot_spend_over_revenue from '../../../../images/report_detail_agency_compensation_spend_over_revenue.png';
import screenshot_spend_over_budget from '../../../../images/report_detail_agency_compensation_spend_over_budget.png';
import screenshot_compensation_model from '../../../../images/report_detail_agency_compensation_model.png';
import { AnyReportConfiguration } from '../../../middleware';

export const TopCompaniesReport: AnyReportConfiguration = {
    kind: 'external',
    entity: {
        id: 'top_companies',
        name: 'Top companies',
        tagline: `Qui est ipsum Lorem`,
        status: 'draft',
        description: `Elit quis eu nulla magna mollit voluptate culpa in exercitation duis. Elit ad cupidatat fugiat commodo sunt et eiusmod eiusmod id nostrud proident proident. Ullamco officia id laboris aliqua exercitation sit ipsum. Sunt non nostrud aliqua proident cupidatat ipsum et aliqua qui et et`,
        category: 'marketing',
        tags: ['compensation'],
        dependencies: [
            {
                kind: 'invite',
                label: 'Top performer',
                description: `Be among top-performing companies on Varos`,
            },
        ],
        thumbnail: {
            description: null,
            url: thumbnail,
        },
        images: [
            {
                description: null,
                url: screenshot_spend_over_revenue,
            },
            {
                description: null,
                url: screenshot_spend_over_budget,
            },
            {
                description: null,
                url: screenshot_compensation_model,
            },
        ],
    },
    getTargetUrl(context) {
        return {
            url: `https://app.hex.tech/2a598c0b-ad4c-479d-a508-b19f5909d4dc/app/924f316e-8798-4ffe-97f9-0c60e2db0cc6/latest`,
            params: { asset: context.asset, token: context.token },
        };
    },
};
