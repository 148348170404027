import { observer } from 'mobx-react';
import * as React from 'react';
import { useStore } from '../../../stores/setupContext';
import {
    AccountFormProps,
    AdvancedProps,
    AdvancedRegistrationForm,
    COMPANY_TYPES,
} from '../../../components/Registration/Advanced/Advanced';
import { useNavigate } from 'react-router-dom';
import { Flex, Heading, Spinner } from '@chakra-ui/react';

export interface AdvancedFormProps {
    onContinue: () => void;
    formProps: AdvancedProps;
}

export const AdvancedRegisterWrapper: React.FC<
    { children?: React.ReactNode } & AdvancedProps
> = (props) => {
    return (
        <Flex justifyContent="center" alignItems="center" height="100%" flexDir="column">
            {/* <Heading fontSize="lg" marginBottom={4}>
                Account Information
            </Heading> */}
            <AdvancedRegistrationForm {...props} />
        </Flex>
    );
};

const Observed = observer(AdvancedRegisterWrapper);

const WithStoreConnection = () => {
    const {
        registration,
        auth: { authToken, loading: authLoading, currentUser, loggedInAccount },
    } = useStore();
    const { isLoading, err, updateUser } = registration;
    const navigate = useNavigate();

    React.useEffect(() => {
        if (!authToken && !currentUser && !authLoading) {
            navigate('../login');
        }
    }, [authToken, authLoading, currentUser]);

    const accountProps:  Partial<AccountFormProps> = React.useMemo(() => {
        const retVal = {
            ...currentUser,
            companyType: COMPANY_TYPES[0].value,
            ...(
                loggedInAccount?.kind === 'organization' ? {
                    organizationName: loggedInAccount.name,
                    domain: loggedInAccount.domain,
                } : {}
            )
        };
        return retVal;
    }, []);
    const updateFormCb = React.useCallback((props: AccountFormProps) => {
        updateUser({
            first_name: props.firstName,
            last_name: props.lastName,
            title: props.title,
            organization: {
                role: 'admin',
                organization: {
                    name: props.organizationName,
                    domain: props.domain,
                    created_at: new Date(),
                    company_type: props.companyType
                }
            }

        })
    }, []);

    if (currentUser) {
        return (
            <Observed
                {...{
                    updateUser: updateFormCb,
                    props: accountProps,
                    isLoading,
                    err,
                }}
            />
        );
    }
    return <Spinner />;
};

export default observer(WithStoreConnection);
