import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce } from 'react-use';
import { SearchV2ControllerConfig } from './searchV2Config';
import { SearchV2Controller } from './searchV2Interface';
import { SearchV2ViewProps } from './searchV2Props';

export function createSearchV2Controller<TItem>(
    config: SearchV2ControllerConfig<TItem>
): SearchV2Controller<TItem> {
    const { debounceMs } = config;
    return {
        useProps(props): SearchV2ViewProps<TItem> {
            const [localValue, setLocalValue] = useState(props.value);

            const mapped = useMemo(() => props.items.map(config.getProps), [props.items]);

            useEffect(() => {
                if (props.value === '') {
                    setLocalValue('');
                }
            }, [props.value === '']);

            const [] = useDebounce(
                () => {
                    props.onChange(localValue.trim());
                },
                debounceMs ?? 0,
                [localValue]
            );

            const appliedValue = localValue;

            const filtered = useMemo(
                () =>
                    mapped.filter((candidate) =>
                        // TODO materialize search term for faster performance
                        candidate.term.includes(props.value)
                    ),
                [mapped, props.value]
            );

            const ids = useMemo(
                () => new Set(filtered.map((item) => item.id)),
                [filtered]
            );

            const getItemProps: SearchV2ViewProps<TItem>['getItemProps'] = (item) => {
                const mapped = config.getProps(item);
                const isVisible = ids.has(mapped.id);
                return {
                    id: mapped.id,
                    isVisible,
                };
            };

            return {
                getItemProps,
                input: {
                    value: appliedValue,
                    onChange(event) {
                        setLocalValue(event.target.value);
                    },
                    placeholder: config.placeholder,
                },
                empty: filtered.length === 0 ? { label: config.empty } : null,
                clear:
                    appliedValue.length === 0
                        ? null
                        : {
                              onClick() {
                                  setLocalValue('');
                                  props.onChange('');
                              },
                          },
            };
        },
    };
}
