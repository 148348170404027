import { createAccountProvider } from '../../app';
import { createChartApiImpl } from '../../impl';

export function configureLegacyOrganizationProvider() {
    return createAccountProvider({
        getDeps: (config) => {
            return {
                chart: createChartApiImpl({
                    axios: config.api,
                }),
            };
        },
    });
}
