import { assert } from '@varos/util-typescript';
import { MeDto } from '../../../api/platform/me';
import { MeAdapter, MeEntity } from '../../../app/platform';
import { collaboratorImplConfig } from './meImplConfig';

export function createMeImpl(config: collaboratorImplConfig): MeAdapter {
    const { api } = config;
    function toEntity(dto: MeDto): MeEntity {
        const membership = dto.user.memberships.find(
            (candidate) => candidate.account_id === dto.current_account?.id
        );
        assert(dto.current_account, 'no account found for user');
        assert(membership, 'no account membership found for user');
        return {
            id: dto.user.id.toString(),
            kind: dto.current_account.kind,
            account: {
                id: dto.current_account.id,
                role: membership.role,
            },
            firstName: dto.user.first_name,
            lastName: dto.user.last_name,
            email: dto.user.email,
        };
    }
    return {
        async lookup(context) {
            const response = await api.platform.meV2.get({
                account: context.principal.account,
            });
            return toEntity(response);
        },
    };
}
