import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { runQuery } from '../../../../api';
import { createReportQueryFromMetrics } from '../../../config/report-legacy';
import { ReportQueryAdapter } from '../../../app/_reportold';
import { mapMetricsToProperties } from '../../../view';
import { QueryRequest, AnyQueryResponse, isSuccessResponse } from '../../../domain/query';
import { castQueryToApi, castResponseToDomain } from '../../api/query';
import { ReportChartImplConfig } from './emailQueryConfig';

export function createEmailQueryAdapter(init: ReportChartImplConfig): ReportQueryAdapter {
    const {
        partitionKeyByView,
        hooks: { useWorkspace, useHttp, useAuth },
    } = init;
    return {
        useResponse(chart) {
            const auth = useAuth();
            const workspace = useWorkspace();
            const http = useHttp();

            const queries = createReportQueryFromMetrics(
                {
                    partitionKeyByView,
                },
                {
                    date: chart.date,
                },
                chart.metrics,
                chart.segments
            );

            const scheme = auth.scheme;
            if (scheme.kind !== 'apiKey') {
                throw new Error(`expected api-key based auth`);
            }
            const requestAndResponses = useQuery({
                queryKey: ['reports', 'charts'],
                async queryFn(context) {
                    return await Promise.all(
                        queries.map(async (queryDomain): Promise<AnyQueryResponse> => {
                            const { segment, ...mapped } = castQueryToApi(queryDomain);
                            try {
                                const response = await runQuery(
                                    http,
                                    {
                                        asset: workspace.id as number,
                                        card: null,
                                        query: mapped,
                                        segment,
                                    },
                                    {
                                        account:
                                            scheme.assume?.kind === 'account'
                                                ? scheme.assume.id
                                                : undefined,
                                        apiKey: scheme.token,
                                        signal: context.signal,
                                    }
                                );
                                return {
                                    error: null,
                                    request: queryDomain,
                                    response: castResponseToDomain(response),
                                };
                            } catch (error) {
                                if (error instanceof Error) {
                                    return {
                                        error,
                                        request: queryDomain,
                                    };
                                }
                                throw error;
                            }
                        })
                    );
                },
                suspense: true,
                staleTime: Infinity,
            });
            const responses = useMemo(
                () => mapMetricsToProperties(requestAndResponses.data ?? []),
                [requestAndResponses.data]
            );
            return responses;
        },
    };
}
