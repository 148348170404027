import { ButtonProps } from '@chakra-ui/react';
import { ReportControllerBaseConfig } from '../../../../base';
import { ReportInstanceDetailStatusController } from './reportStatusInterface';
import { ReportInstanceDetailStatusViewProps } from './reportStatusProps';

export function createReportInstanceDetailStatusController(
    config: ReportControllerBaseConfig
): ReportInstanceDetailStatusController {
    const {
        kernel: {
            infra: { formatter },
            provider: { createPageController },
        },
    } = config;
    const pageController = createPageController({
        id: 'report.status',
    });
    return {
        useProps(context, item, props) {
            const page = pageController.useProps(
                { entity: { id: item.report.id, object: 'report' } },
                {}
            );

            let back: ReportInstanceDetailStatusViewProps['back'] | null = null;

            if (props.location.key !== 'default') {
                back = {
                    button: {
                        onClick() {
                            props.navigate(-1);
                        },
                    },
                    label: 'Go back',
                };
            } else {
                back = {
                    button: {
                        onClick() {
                            props.navigate('/u');
                        },
                    },
                    label: 'Go back',
                };
            }
            return {
                page,
                back,
            };
        },
    };
}
