import { AccountAggregate, AccountType } from './accountsModel';

export function isAgency(item: AccountAggregate) {
    // return profile.profile.organizationType === 'ad_agency';
    // return item.assets.filter((candidate) => candidate.visible).length > 1;
    return item.profile?.account?.kind === 'organization' && item.profile.account.organizationType === 'ad_agency';
}

export function getAccountType(item: AccountAggregate): AccountType {
    return isAgency(item)
        ? {
              kind: 'agency',
          }
        : {
              kind: 'brand',
          };
}

export const AccountsHelpers = { isAgency, getAccountType };
