import React from 'react';
import { HomeRouteItemProps } from '../../../../route';
import { ApplicationUI } from '../../../../ui';
import { HomeMetricConfig } from '../homeMetricConfig';
import { MetricMainProps } from './metricMainProps';

export function createMetricMainContainer(
    config: HomeMetricConfig & { UI: ApplicationUI }
): React.FC<
    { children?: React.ReactNode | undefined } & HomeRouteItemProps<MetricMainProps>
> {
    const {
        createView,
        deps: { hooks, adapter },
    } = config;

    const {
        benchmark: {
            controller: benchmarkController,
            loader: benchmarkLoader,
            Component: BenchmarkView,
        },
    } = createView(config);

    return (props) => {
        const { context } = props;
        const formatter = hooks.useValueFormatter();
        const builder = hooks.useQueryBuilder(context);
        const calculations = hooks.useCalculations({ builder }, context);
        // const connections = adapter.connections.useAdapter(context);
        // const configurations = adapter.configurations.useAdapter(context);

        const viewData = benchmarkLoader.useLoad(
            {
                // formatter,
                calculations,
                // configurations,
            },
            {
                ...context,
                ...context.data
            },
            props.data.collection,
            { date: props.control.date },
            {}
        );

        const viewProps = benchmarkController.useProps(
            {
                formatter,
                calculations,
                // connections,
                // configurations,
            },
            context,
            viewData,
            props.data.collection,
            props.control.date
        );
        return <BenchmarkView {...viewProps} />;
    };
}
