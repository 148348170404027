import { ToastController } from './toastInterface';

export function createToastController(): ToastController {
    return {
        useProps(props) {
            const [first] = props.toasts;
            if (!first) {
                return null;
            }
            return {
                title: first.title,
                description: first.description,
                status: first.status,
            };
        },
    };
}
