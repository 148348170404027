import { z } from 'zod';
import { TLD_DOMAIN, SUBDOMAIN } from '../../../../../../../vendor/common-regex';

export const AcceptOrganizationFormValuesSchema = z.object({
    name: z.string().trim().nonempty({ message: 'Required' }),
    website: z
        .string()
        .trim()
        .nonempty({ message: 'Required' })
        .transform((item) => item.replace(/^https?:\/\//, ''))
        .refine((value) => !SUBDOMAIN.test(value), {
            message: 'Must not be a subdomain',
        })
        .refine((val) => TLD_DOMAIN.test(val), {
            message: 'Must be a valid top-level domain',
        }),
});
