import { compose } from 'lodash/fp';
import { useAuthenticatedContext } from '../../../container';
import { useAccountContextV2 } from '../../context';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { FeatureApi, FeatureMiddleware } from './featureInterface';

export function applyFeatureMiddleware(
    ...middlewares: FeatureMiddleware[]
): ApplicationEntryEnhancer {
    const api: FeatureApi = {
        useFeature(id) {
            const {
                data: {
                    // features: { data: features },
                    features: features,
                },
            } = useAccountContextV2();
            const context = useAuthenticatedContext();
            return {
                feature: features?.[id] ?? null,
                restrict(request) {
                    context.access.restrict({
                        request,
                        restricted: true,
                    });
                },
            };
        },
    };
    const enhancers = middlewares.map((middleware) => middleware(api));
    return compose(...enhancers);
}
