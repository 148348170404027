import React from 'react';
import { useNavigate } from 'react-router';
import { RegistrationContainerConfig } from '../../../../../base';
import { RegistrationAcceptContextProvider } from '../../context';
import { RegistrationAcceptFinalizeController } from './acceptFinalizeInterface';
import { AcceptFinalizeContainerProps } from './acceptFinalizeProps';

export function createRegistrationAcceptFinalizeContainer(
    config: RegistrationContainerConfig & {
        accept: RegistrationAcceptContextProvider;
    },
    controller: RegistrationAcceptFinalizeController
): React.FC<AcceptFinalizeContainerProps> {
    const {
        accept: { useContext },
    } = config;
    return ({ as: View, ...containerProps }) => {
        const context = useContext();
        const props = controller.useProps({
            stepper: context.stepper,
            navigate: useNavigate(),
            page: {
                metadata: context.metadata,
            },
        });
        return <View {...props} />;
    };
}
