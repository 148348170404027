import { useEffect } from 'react';
import { OnboardingReportConfig } from './onboardingReportConfig';
import { OnboardingReportController } from './onboardingReportInterface';
import { OnboardingReportViewProps } from './onboardingReportProps';

export function createOnboardingReportController(
    config: OnboardingReportConfig
): OnboardingReportController {
    const {
        controller,
        hooks: { useLocalStorage },
    } = config;
    return {
        useProps(deps, context, data, form, props): OnboardingReportViewProps {
            const reportProps = controller.report.useProps(
                deps,
                context,
                data.report,
                form,
                props
            );
            const prepareProps = controller.prepare.useProps(
                context,
                data.prepare,
                props
            );

            const [, setOnboardingState] = useLocalStorage(
                context.account.id.toString()
            );

            useEffect(() => {
                setOnboardingState({
                    step: 'report',
                    account: context.account.id,
                    asset: context.workspace.id as number,
                });
            }, []);

            if (
                data.report.status !== 'success' ||
                data.prepare.isLoading ||
                reportProps.status !== 'success'
            ) {
                return {
                    status: 'loading',
                };
            }

            if (prepareProps.status !== 'success') {
                return {
                    status: 'loading',
                };
            }

            // const handleSubmitClick = async () => {
            //     const response = await formProps.onSubmit();
            //     props.onContinue?.();
            // };

            const statusProps = prepareProps.getStatusProps();

            return {
                status: 'success',
                getReportProps() {
                    return reportProps;
                },
                getPrepareProps() {
                    return prepareProps;
                },
                getFormProps() {
                    const formProps = reportProps.getFormProps();
                    const isSubmittable =
                        statusProps.status === 'ready' &&
                        data.report.item.status === 'success';
                    return {
                        ...formProps,
                        isSubmittable: isSubmittable,
                        isSubmitting: formProps.isSubmitting,
                        disabledText: isSubmittable
                            ? null
                            : `Please wait for your data to sync before continuing`,
                        onSubmit: formProps.form.handleSubmit(async (values) => {
                            return formProps.onSubmit(values);
                        }),
                    };
                },
            };
        },
    };
}
