import moment from 'moment';
import { AccountEntity } from '../../domain';
import { AutohideMiddlewareConfig } from './autohideConfig';

export function isAutohidden(
    config: AutohideMiddlewareConfig,
    organization: AccountEntity,
    now: Date
) {
    const diff = moment(now).diff(moment(organization.created_at), 'days');
    return diff > config.autohideAfterDays;
}
