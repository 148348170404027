import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { BillingPlanResource } from './planInterface';
import { BillingPlanListDtoSchema } from './planSchema';

export function createBillingPlanResource(client: AxiosInstance): BillingPlanResource {
    return {
        async find(context, query) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            params['version'] = query.version.toString();

            const response = await client.get(`/api/v1/billing/plans`, {
                params,
                headers,
            });

            const parsed = BillingPlanListDtoSchema.parse(response.data);
            return {
                data: parsed.items,
            };
        },
    };
}
