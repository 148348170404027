import { useQuery } from '@tanstack/react-query';
import { InsightCreatePayload } from '../../../api';
import { collectionFromQuery, lookupFromQuery } from '../../../base';
import { WorkspaceContextV2 } from '../../platform';
import { OpportunityAdapter } from './opportunityAdapter';
import { OpportunityRepository } from './opportunityInterface';
import { OpportunityLookup, OpportunityQuery } from './opportunityQuery';

export function createOpportunityRepository(
    adapter: OpportunityAdapter
): OpportunityRepository {
    function getReadPrefix(
        context: WorkspaceContextV2,
        query: OpportunityQuery | OpportunityLookup
    ) {
        return ['opportunity', context.account, context.workspace, query];
    }
    function getWritePrefix(context: WorkspaceContextV2, command: InsightCreatePayload) {
        return ['opportunity', context.account, context.workspace, command];
    }
    return {
        useFind(context, query, options) {
            const applied: OpportunityQuery = {
                ...query,
                limit: query.limit ?? 10,
                orderBy: query.orderBy ?? [
                    {
                        key: 'period_end_at',
                        direction: 'desc',
                    },
                ],
            };

            const data = useQuery({
                ...options,
                queryKey: [...getReadPrefix(context, applied)],
                async queryFn() {
                    const response = await adapter.find(context, applied);
                    return response;
                },
                refetchOnWindowFocus: false,
            });

            return collectionFromQuery(data, { keepData: false });
        },
        useLookup(context, query, options) {
            const data = useQuery({
                queryKey: [...getReadPrefix(context, query)],
                async queryFn() {
                    const response = await adapter.findOne(context, query);
                    return response;
                },
                refetchOnWindowFocus: false,
                ...options,
            });
            return lookupFromQuery(data);
        },
        useCreate(context, payload, options) {
            const data = useQuery({
                queryKey: [...getWritePrefix(context, payload)],
                async queryFn() {
                    const response = await adapter.create(context, payload);
                    return response;
                },
                refetchOnWindowFocus: false,
                ...options,
            });
            return collectionFromQuery(data, { keepData: false });
        },
    };
}
