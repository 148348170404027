import { assert } from '@varos/util-typescript';
import { ReportLoaderBaseConfig } from '../../../base';
import { ReportInstanceDetailLoader } from './reportDetailInterface';

export function createReportInstanceDetailLoader(
    config: ReportLoaderBaseConfig
): ReportInstanceDetailLoader {
    const { repository } = config;
    return {
        useLoad(context, props) {
            const reportQuery = repository.instance.useLookup(
                context,
                {
                    report: props.report,
                },
                { suspense: true, staleTime: Infinity }
            );
            assert(reportQuery.status === 'success', 'expected suspense');
            const versionQuery = repository.version.useLookup(
                context,
                {
                    version: {
                        id: reportQuery.data.version,
                    },
                },
                {
                    suspense: true,
                    staleTime: Infinity,
                    refetchInterval(data, query) {
                        if (!data?.content) {
                            return 2500;
                        }
                        return false;
                    },
                }
            );
            assert(versionQuery.status === 'success', 'expected suspense');

            return {
                content: versionQuery.data.content
                    ? {
                          report: reportQuery.data,
                          version: versionQuery.data,
                          content: {
                              id: versionQuery.data.content,
                          },
                      }
                    : null,
                status: {
                    report: reportQuery.data,
                },
            };
        },
    };
}
