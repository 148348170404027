import React from 'react';
import { HelpConfig } from './helpConfig';
import { HelpController } from './helpInterface';
import { HelpControllerProps, HelpViewProps } from './helpProps';

export function createHelpController(config: HelpConfig): HelpController {
    const { adapter, exclude } = config;

    return {
        useProps(props: HelpControllerProps): HelpViewProps {
            const { location } = props;
            const pathname = location?.pathname || '';

            // Determine if the widget should be hidden for this path
            const isExcluded = React.useMemo(() => {
                return exclude.some((pattern) => pathname.includes(pattern));
            }, [pathname, exclude]);

            // Perform side effects in a useEffect, not in render
            React.useEffect(() => {
                if (isExcluded) {
                    adapter.hide();
                } else {
                    adapter.show();
                }
            }, [isExcluded, adapter]);

            // Return only the props needed for rendering
            return {
                open: {
                    button: {
                        onClick: adapter.open,
                    },
                },
            };
        },
    };
}
