import React from 'react';
import { observer } from 'mobx-react';
import { ToastController, ToastStore } from './toastInterface';
import { ToastContainerProps } from './toastProps';
import { Box, Fade } from '@chakra-ui/react';

export function createToastContainer(
    store: ToastStore,
    controller: ToastController
): React.FC<ToastContainerProps> {
    return observer(({ as: View, ...containerProps }) => {
        const props = controller.useProps({ toasts: store.toasts });
        if (props === null) {
            return <></>;
        }
        return <View {...props} />;
    });
}
