import React from 'react';
import { useForm } from 'react-hook-form';
import { OrganizationMappingFormValues } from '../../../../view/assets';
import { SettingAssetItemProviderConfig } from '../../../../route';
import {
    SettingAssetProfileController,
    SettingAssetProfileDeps,
    SettingAssetProfileLoader,
} from './profileInterface';
import { SettingAssetProfileContainerProps } from './profileProps';
import { SettingAssetProfileConfig } from './profileConfig';

export function createSettingAssetProfileContainer(
    init: Pick<SettingAssetProfileConfig, 'service' | 'form'>,
    config: Pick<SettingAssetItemProviderConfig, 'api'>,
    loader: SettingAssetProfileLoader,
    controller: SettingAssetProfileController
): React.FC<
    { children?: React.ReactNode | undefined } & SettingAssetProfileContainerProps
> {
    const { service, form } = init;
    const {
        api: { useContext },
    } = config;

    return ({ as: Component, children, ...props }) => {
        const viewContext = useContext();
        const viewData = loader.useLoad(viewContext);
        const updateMutation = service.asset.useUpdate(viewContext);
        const deps: SettingAssetProfileDeps = {
            edit: {
                update: updateMutation,
                form: form.asset.edit.useForm(viewContext, { item: viewData }),
            },
            evidence: {
                form: form.asset.evidence.useForm(viewContext, {item: viewData}),
                update: updateMutation,
            }
        };
        const viewProps = controller.useProps(viewContext, deps, {
            item: viewData,
        });
        return <Component {...viewProps}>{children}</Component>;
    };
}
