import { useQuery } from '@tanstack/react-query';
import { MetricConfigurationRepositoryConfig } from './configurationConfig';
import { MetricConfigurationRepository } from './configurationInterface';

export function createMetricConfigurationRepository(
    config: MetricConfigurationRepositoryConfig
): MetricConfigurationRepository {
    const { adapter } = config;
    return {
        useFind(context, query, options) {
            return useQuery({
                ...options,
                queryKey: ['metric', 'configuration', context.account.id, query],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
            });
        },
    };
}
