import { useRef } from 'react';
import { ShellLayoutConfig } from '../../applicationShellConfig';
import { ShellLayoutController } from './applicationShellInterface';
import {
    ApplicationAccountController,
    ApplicationNavigationController,
    ApplicationWorkspaceListAggregate,
    ApplicationWorkspaceController,
    ApplicationWorkspaceItem,
    ApplicationShortcutViewProps,
} from '../../view';
import { AiOutlinePlus } from 'react-icons/ai';

export function createApplicationShellController(
    config: ShellLayoutConfig & {
        controller: {
            navigation: ApplicationNavigationController;
            account: ApplicationAccountController;
            workspace: ApplicationWorkspaceController;
        };
    }
): ShellLayoutController {
    const {
        view,
        kernel: {
            controller,
            infra: {
                overlay: { Portal },
            },
        },
        controller: {
            navigation: navigationController,
            account: accountController,
            workspace: workspaceController,
        },
    } = config;

    return {
        useProps(context, item, props) {
            const ref = useRef<HTMLButtonElement>(null);
            const browser = controller.browser.useProps({});
            const navigation = navigationController.useProps(
                context,
                {},
                props.navigation
            );
            const account = accountController.useProps(
                context,
                item.account,
                props.account
            );
            const workspace = workspaceController.useProps(
                context,
                item.workspace,
                props.workspace
            );

            const shortcuts: ApplicationShortcutViewProps = {
                trigger: {
                    Icon: AiOutlinePlus,
                    button: {},
                },
                groups: props.shortcuts.groups.map((group, index) => ({
                    id: index.toString(),
                    label: group.label,
                    items: group.items.map((item) => ({
                        id: item.id,
                        label: item.label,
                        Icon: item.Icon,
                        link: {
                            to: item.path,
                        },
                        item: {
                            onClick() {
                                props.navigation.navigate(item.path);
                            },
                        },
                    })),
                })),
            };

            return {
                browser,
                workspace: props.workspace.visible ? workspace : null,
                home: props.home,
                shortcuts: shortcuts.groups.length > 0 ? shortcuts : null,
                status: props.status
                    ? {
                          alert: {
                              status: props.status.kind,
                          },
                          label: props.status.label,
                          action: props.status.action,
                      }
                    : null,
                navigation: {
                    view: navigation,
                    trigger: {
                        ref: ref,
                        button: {
                            onClick() {
                                props.navigation.disclosure.onOpen();
                            },
                        },
                    },
                    menu: {
                        isOpen: props.navigation.disclosure.isOpen,
                        onOpen: props.navigation.disclosure.onOpen,
                        onClose: props.navigation.disclosure.onClose,
                    },
                },
                account: {
                    view: account,
                    trigger: {
                        ref: ref,
                        button: {
                            onClick() {
                                props.account.disclosure.onOpen();
                            },
                        },
                    },
                    menu: {
                        isOpen: props.account.disclosure.isOpen,
                        onOpen: props.account.disclosure.onOpen,
                        onClose: props.account.disclosure.onClose,
                    },
                },
            };
        },
    };
}
