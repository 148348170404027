import {
    QueryClient,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { toJS } from 'mobx';

import {
    MetricCollectionDto,
    MetricCollectionUpdatePayload,
} from '../../../api';
import {
    MetricCollectionAdapterProvider,
    MetricCollectionAdapter,
} from '../../../app/metrics';
import { CollectionImplConfig } from './collectionConfig';

export function createCollectionImpl(
    config: CollectionImplConfig
): MetricCollectionAdapterProvider {
    const {
        axios,
        api: {
            metric: { collections: collectionResource },
        },
    } = config;
    return {
        useAdapter(context) {
            const queryclient = useQueryClient();

            const authToken =
                context.auth.scheme.kind === 'legacy' &&
                context.auth.scheme.store.authToken;

            if (!authToken) {
                throw new Error('only legacy auth supported');
            }

            // const contextdata = useWorkspaceContextV2();
            
            const queryKey = [
                'metric',
                'collection',
                context.account.id,
                context.workspace.id,
                // contextdata.metric.collections,
                // contextdata.metric.definitions,
            ];

            const response = useQuery({
                queryKey,
                async queryFn() {
                    // const [responseCollections, responseDefinitions] = await Promise.all([
                    //     collections.list(context, {
                    //         asset: context.workspace.id as number,
                    //     }),
                    //     definitions.list(context, {
                    //         kinds: [],
                    //     }),
                    // ]);
                    const responseCollections = await collectionResource.list(context, {
                        asset: context.workspace.id as number
                    }, {});

                    // let defaultCollection =
                    //     responseCollections.data?.find((item) => item.default) ?? null;

                    // if (!defaultCollection) {
                    //     console.info('creating default collection...');
                    //     defaultCollection = await collections.create(context, {
                    //         asset: context.workspace.id as number,
                    //         name: 'Default',
                    //         default: true,
                    //         metrics: chain(responseDefinitions.data)
                    //             .filter((item) => item.kind === 'performance')
                    //             .uniqBy((item) => item.plugin)
                    //             .take(4)
                    //             .map<MetricCollectionMetricCreatePayload>((item) => ({
                    //                 name: null,
                    //                 definition: item.id,
                    //             }))
                    //             .value(),
                    //     });
                    // }
                    return {
                        collections: responseCollections,
                        // defaultCollection,
                    };
                },
                staleTime: Infinity,
                retry: false,
                suspense: true,
            });

            const mutation = {
                update: useMutation({
                    async mutationFn(
                        input: [
                            collection: MetricCollectionDto,
                            payload: MetricCollectionUpdatePayload
                        ]
                    ) {
                        const [collection, payload] = input;
                        const response = collectionResource.update(context, collection, payload);
                        return response;
                    },
                    async onSuccess(data, variables, _context) {
                        // queryclient.invalidateQueries({
                        //     queryKey: contextdata.metric.collections.queryKey,
                        // });
                        await queryclient.refetchQueries({
                            queryKey: ['metric', 'collection'],
                        });
                        return data;
                    },
                }),
            };

            const instance: MetricCollectionAdapter = {
                find(context, query) {
                    return response.data?.collections.data ?? [];
                },
                get(context, query) {
                    if (query.default === true) {
                        return (
                            response.data!.collections?.data?.find((item) => item.default) ??
                            null
                        );
                    }
                    throw new Error('not impl');
                },
                async update(context, collection, payload) {
                    console.log('updating', collection, payload);
                    const response = await mutation.update.mutateAsync([
                        collection,
                        payload,
                    ]);
                    return response;
                },
            };
            return instance;
        },
    };
}
