import { z } from 'zod';

export const CursorPaginationSchema = z.object({
    count_left: z.number(),
    has_more: z.boolean(),
    after_id: z.optional(z.nullable(z.union([z.number(), z.string()]))),
    page_size: z.number()
})


export const OffsetPaginationSchema = z.object({
    page_size: z.number(),
    page: z.number(),
    count: z.number(),
    num_pages: z.number(),
})

export const CursorTokenPaginationSchema = z.object({
    page_size: z.number(),
    has_more: z.boolean(),
    count_left: z.number(),
    previous_page_token: z.nullable(z.string()),
    next_page_token: z.nullable(z.string()),
});