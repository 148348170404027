import { useQuery } from '@tanstack/react-query';
import { collectionFromQuery } from '../../../base';
import { AccountScope } from '../../platform';
import { InsightDefinitionAdapter } from './definitionAdapter';
import { InsightDefinitionRepository } from './definitionInterface';
import { InsightDefinitionQuery } from './definitionQuery';

export function createInsightDefinitionRepository(
    adapter: InsightDefinitionAdapter
): InsightDefinitionRepository {
    function getReadPrefix(context: AccountScope, query: InsightDefinitionQuery) {
        return ['insight', 'definition', context.account, query];
    }
    return {
        useFind(context, query, options) {
            const data = useQuery({
                ...options,
                queryKey: [...getReadPrefix(context, query)],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
                refetchOnWindowFocus: false,
            });

            return collectionFromQuery(data, { keepData: false });
        },
    };
}
