import { z } from 'zod';
import { OrganizationRoleSchema } from '../me';
import { UserIdentitySchema } from '../account';
import { CursorTokenPaginationSchema } from '../../common';

export const UserDetailsSchema = z.object({
    first_name: z.nullable(z.string()),
    last_name: z.nullable(z.string()),
    identities: z.array(UserIdentitySchema),
});

export const UserSchema = z.object({
    id: z.number(),
    details: UserDetailsSchema,
    memberships: z.array(z.object({
        account_id: z.string(),
        role: OrganizationRoleSchema,
        last_login: z.nullable(z.date({coerce: true})),
        profile: z.nullable(z.object({
            title: z.string()
        }))
    })),
    last_login: z.nullable(z.date({coerce: true})),
    created_at: z.date({coerce: true}),
    updated_at: z.nullable(z.date({coerce: true})),
    assets: z.nullable(z.object({
        items: z.array(z.object({
            id: z.number(),
            name: z.string()
        })),
        count: z.number(),
    }))
});

export const UserListSchema = z.object({
    data: z.array(UserSchema),
    pagination: CursorTokenPaginationSchema,
});

