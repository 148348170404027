import { StudiesControllerBaseConfig } from '../base';
import { SurveyRootController } from './surveyRootInterface';

export function createSurveyRootController(
    config: StudiesControllerBaseConfig
): SurveyRootController {
    const {
        layout: { tab },
    } = config;
    return {
        useProps(props) {
            const layout = tab.controller.useProps({
                items: [],
                location: props.location,
            });
            return { layout };
        },
    };
}
