import { useMemo } from 'react';
import { MetricConfiguration, MetricDimension } from '../../../domain/metrics';
import { MetricConfigurationProvider } from '../../../app/metrics';
import {
    DashboardReference,
    AnyRanking,
    buildFixedRanking,
    ViewEntity,
} from '../../../domain/dashboard';
import { MetricConfigurationConfig } from './configurationConfig';
import { ViewDto } from '../../../api';
import { DashboardSlugs } from '../../../../config/dashboard';
import { ViewIds } from '../../../../config/view';
import {
    AnyResolvedType,
    isEnumType,
    isReferenceType,
    isStringType,
    Property,
} from '../../../domain/attributes';
import { castTypeToDomain, castViewPropertyToDomain } from '../../api';
import { PluginEntity } from '../../../domain';
import { useSystemContextV2 } from '../../../context';
import { getAppliedConditions } from '../../../domain/peers';
import { DashboardsByPlugin } from '../../../../config';

export const DashboardByViewId: Record<string, DashboardReference | undefined> = {
    [ViewIds.FACEBOOK]: {
        id: DashboardSlugs.FACEBOOK_V2,
    },
    [ViewIds.FACEBOOK_VIDEO]: {
        id: DashboardSlugs.FACEBOOK_VIDEO,
    },
    [ViewIds.FACEBOOK_CPA]: {
        id: DashboardSlugs.FACEBOOK_CPA_V2,
    },
    [ViewIds.GOOGLE]: {
        id: DashboardSlugs.GOOGLE_V2,
    },
    [ViewIds.GOOGLE_ADS_BRANDED]: {
        id: DashboardSlugs.GOOGLE_V2,
    },
    [ViewIds.GOOGLE_KEYWORDS]: {
        id: DashboardSlugs.GOOGLE_V2,
    },
    [ViewIds.EXECUTIVE_SUMMARY]: {
        id: DashboardSlugs.EXECUTIVE_SUMMARY,
    },
    [ViewIds.TIKTOK]: {
        id: DashboardSlugs.TIKTOK_V2,
    },
    [ViewIds.GOOGLE_ANALYTICS_USERS]: {
        id: DashboardSlugs.GA4_TRAFFIC,
    },
    [ViewIds.GOOGLE_ANALYTICS_ECOMMERCE]: {
        id: DashboardSlugs.GA4_ECOMMERCE,
    },
    [ViewIds.SHOPIFY_ORDERS]: {
        id: DashboardSlugs.SHOPIFY_V2,
    },
    [ViewIds.SHOPIFY_ABANDONED_CHECKOUT]: {
        id: DashboardSlugs.SHOPIFY_V2,
    },
    [ViewIds.IT_BENCHMARKS]: {
        id: DashboardSlugs.IT_BENCHMARKS_PERFORMANCE,
    },
};

export const RANKING_BY_DASHBOARD: Record<string, AnyRanking | undefined> = {
    [DashboardSlugs.FACEBOOK_V2]: buildFixedRanking({
        mode: 'fixed',
        comparison: 'cost_per_purchase',
    }),
    [DashboardSlugs.GOOGLE_V2]: buildFixedRanking({
        mode: 'fixed',
        comparison: 'cost_per_purchase',
    }),
    [DashboardSlugs.TIKTOK_V2]: buildFixedRanking({
        mode: 'fixed',
        comparison: 'cost_per_conversion',
    }),
    [DashboardSlugs.EXECUTIVE_SUMMARY]: buildFixedRanking({
        mode: 'fixed',
        comparison: 'marketing_efficiency_ratio',
    }),
    [DashboardSlugs.IT_BENCHMARKS_PERFORMANCE]: buildFixedRanking({
        mode: 'fixed',
        comparison: 'revenue',
    }),
    [DashboardSlugs.IT_BENCHMARKS_SPEND_DIST]: buildFixedRanking({
        mode: 'fixed',
        comparison: 'revenue',
    }),
};

export function createMetricConfigurationImpl(
    config: MetricConfigurationConfig
): MetricConfigurationProvider {
    const {
        hooks: { useQuery },
        api: {},
        repository: {
            peergroup
        },
        service: {
            plugin
        }
    } = config;
    return {
        useAdapter(context) {
            // const response = useQuery({
            //     queryKey: ['statistics', 'views'],
            //     async queryFn() {
            //         const [responseView] = await Promise.all([
            //             config.api.dashboard.views.list(context, {}),
            //         ]);
            //         return {
            //             views: responseView.data,
            //         };
            //     },
            //     staleTime: Infinity,
            //     retry: false,
            //     suspense: true,
            // });
            
            const contextdata = useSystemContextV2().data;

            
            const plugins = plugin.useResolvedList(context, {}, {suspense: true});
            const peergroups = peergroup.useFind(context, {workspace: context.workspace, plugins: plugins.data?.map(x => x.id) ?? []});
            
            const pluginsById = useMemo(
                () =>
                    plugins.data?.reduce(
                        (acc, plugin) => ({ ...acc, [plugin.id]: plugin }),
                        {} as Record<string, PluginEntity<AnyResolvedType>>
                    ) ?? {},
                [plugins.data]
            );

            const traitsByKey = useMemo(
                () =>
                    plugins.data?.reduce(
                        (acc, plugin) =>
                            plugin.traits.reduce(
                                (acc, trait) => ({ ...acc, [trait.key]: trait }),
                                acc
                            ),
                        {} as Record<string, Property<AnyResolvedType> | undefined>
                    ) ?? {},
                [plugins.data]
            );

            const columnByViewId = useMemo(() => {
                return (
                    contextdata.views.data?.reduce(
                        (accView, itemView) =>
                            itemView.columns.reduce((accColumn, itemColumn) => {
                                // const property = castViewPropertyToDomain(itemColumn);
                                const id = `${itemView.plugin}.${itemColumn.key}`;
                                if (accColumn[id]) {
                                    // we might have duplicate columns across views
                                    // so we assume the first one has priority
                                    return accColumn;
                                }
                                return {
                                    ...accColumn,
                                    [id]: {
                                        ...itemColumn,
                                        view: itemView,
                                    },
                                };
                            }, accView),
                        {} as Record<
                            string,
                            (Property & { view: ViewEntity }) | undefined
                        >
                    ) ?? {}
                );
            }, [contextdata.views.data]);

            return {
                async find(context, query) {
                    return query.metrics.flatMap((item): MetricConfiguration[] => {
                        const column = columnByViewId[item.id];
                        if (!column) {
                            console.info(`view column for metric ${item.id} not found`);
                            return [];
                        }
                        const { view } = column;
                        const plugin = pluginsById[item.plugin];
                        const dashboard =
                            DashboardByViewId[column.view.id] ??
                            DashboardsByPlugin[plugin.id]
                                ? { id: DashboardsByPlugin[plugin.id]! }
                                : null;

                        const peergroup = peergroups.data?.find(
                            (item) => item.plugin.id === view.plugin
                        );

                        if (!dashboard) {
                            console.info(
                                `dashboard for view ${column.view.id} / ${plugin.id} not found`,
                                DashboardByViewId,
                                DashboardsByPlugin
                            );
                            return [];
                        }

                        if (!plugin) {
                            console.info(`plugin for view ${column.view.id} not found`);
                            return [];
                        }

                        return [
                            {
                                // dashboard: dashboard ? { id: dashboard.id } : null,
                                dashboard,
                                definition: { id: item.id },
                                filters: [],
                                dimensions: [
                                    ...view.trait_filters.map(
                                        (key): MetricDimension => ({
                                            kind: 'trait',
                                            key,
                                        })
                                    ),
                                    ...view.columns.flatMap(
                                        (column): MetricDimension[] => {
                                            // const type = castTypeToDomain(column.type, {
                                            //     downgradeReference: true,
                                            // });
                                            // console.log('DEBUG asdads', column.key, type);
                                            if (
                                                isEnumType(column.type) ||
                                                isReferenceType(column.type) ||
                                                isStringType(column.type)
                                            ) {
                                                return [
                                                    { kind: 'data', key: column.key },
                                                ];
                                            }

                                            return [];
                                        }
                                    ),
                                ],
                                ranking: (dashboard
                                    ? RANKING_BY_DASHBOARD[dashboard.id]
                                    : null) ?? {
                                    kind: 'dynamic',
                                    cohorts: [
                                        {
                                            name: 'Low performers',
                                            value: 25,
                                            visible: true,
                                        },
                                        {
                                            name: 'Median',
                                            value: 50,
                                            visible: true,
                                        },
                                        {
                                            name: 'High performers',
                                            value: 75,
                                            visible: true,
                                        },
                                    ],
                                },
                                competitiveSet: {
                                    conditions: peergroup
                                        ? getAppliedConditions(plugin, peergroup)
                                        : [],
                                },
                            },
                        ];
                    });
                },
            };
        },
    };
}
