import React from 'react';
import { SurveyRootViewProps } from './surveyRootProps';
import { StudiesViewBaseConfig } from '../base';

export function createSurveyRootView(
    config: StudiesViewBaseConfig
): React.FC<SurveyRootViewProps> {
    const {
        layout: {
            tab: { Container: TabLayout },
        },
    } = config;
    return (props) => {
        return <TabLayout {...props.layout}>{props.children}</TabLayout>;
    };
}
