import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { DatasetImportAdapter } from './importAdapter';
import { DatasetImportRepository } from './importInterface';
import { DatasetImportCreateProps } from './importProps';

export function createDatasetImportRepository(
    adapter: DatasetImportAdapter
): DatasetImportRepository {
    const PREFIX = ['dataset', 'imports'];
    return {
        useFind(context, query, options) {
            const result = useQuery({
                ...options,
                retry: false,
                queryKey: [...PREFIX, context.account, query],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
            });
            return result;
        },
        useCreate(context) {
            const client = useQueryClient();
            const result = useMutation({
                async mutationFn(props: DatasetImportCreateProps) {
                    const response = await adapter.create(context, props);
                    return response;
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(PREFIX);
                },
            });
            return result;
        },
    };
}
