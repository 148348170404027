import { AccountExternalInvitation, AnyAccountInvitationEntity, InvitationUser } from './invitationModel';

export function isPlatformInvitation(
    item: AnyAccountInvitationEntity
): item is AccountExternalInvitation & { invitee: null } {
    if(item.kind === 'external') {
        return item.invitee === null
    }
    return false;
}

export function isExistingUserInvitation(
    item: AnyAccountInvitationEntity
): item is AccountExternalInvitation & { invitee: InvitationUser } {
    if(item.kind === 'external') {
        return !!item.invitee?.user;
    }
    else if(item.kind === 'member') {
        return !!item.inviteeUserId
    }
    return false;
}

export function isExistingAccountInvitation(
    item: AnyAccountInvitationEntity
): item is AccountExternalInvitation & { invitee: InvitationUser } {
    if(item.kind === 'external') {
        return !!item.invitee?.account;
    }
    else if(item.kind === 'member') {
        return true;
    }
    return false;
}


export function isOrganizationInvitation(
    item: AnyAccountInvitationEntity
): item is AccountExternalInvitation & { account: { kind: 'organization', domain: string, name: string }} {
    return item.kind === 'external' && item.account.kind == 'organization';
}
