import { useEffect, useMemo, useState } from 'react';
import { isPeerGroupPending } from '../../../domain/peers';
import { useAccountContextV2, useWorkspaceContextV2 } from '../../../context';
import { AssetOnboardingState, AnyOnboardingAssetStep } from '../../../domain/onboarding';
import { OnboardingAssetStateServiceConfig } from './assetConfig';
import { OnboardingAssetStateService } from './assetInterface';
import { useSearchParams } from 'react-router-dom';
import { fromPairs } from 'lodash';
import { OnboardingAssetOptionsSchema } from './assetSchema';
import { OnboardingAssetOptions } from './assetModel';
import { PluginSlugs } from '../../../../config';

export function createOnboardingAssetStateService(
    config: OnboardingAssetStateServiceConfig
): OnboardingAssetStateService {
    const { repository } = config;
    return {
        useLookup(context) {
            const {
                data: { workspaces },
            } = useAccountContextV2();
            
            const [searchParams] = useSearchParams();

            const onboardingOptions = useMemo(() => {
                const raw = fromPairs([...searchParams.entries()]);
                const parsed = OnboardingAssetOptionsSchema.safeParse(raw);
                if (!parsed.success) {
                    return null;
                }
                const options: OnboardingAssetOptions = parsed.data;
                return options;
            }, [searchParams]);
            const plugins = config.repository.plugins.useCollection(context, {}, {suspense: true})
            const query = config.repository.peergroup.useFind(context, {workspace: context.workspace,
                plugins: plugins.data?.ids ?? []
            });

            const preferences = repository.preference.useLookup(context, {}, {});
            // console.log('preferences', preferences.data);
            const [poll, setPoll] = useState<number | null>(null);
            
            const connections = repository.connection.useFind(
                context,
                {
                    workspaces: [context.workspace],
                },
                {
                    keepPreviousData: true,
                    refetchInterval: poll ?? undefined,
                    staleTime: Infinity,
                }
            );

            useEffect(() => {
                if (
                    connections.data?.some(
                        (item) =>
                            item.status === 'syncing' &&
                            // HACK we should model the "fasy-sync" capability on the plugin like we
                            // do on the integration definition model
                            item.plugin.id === PluginSlugs.FACEBOOKADS
                    )
                ) {
                    console.log(
                        'fast-sync enabled integrations syncing, enabling polling...'
                    );
                    setPoll(15000);
                } else if (
                    connections.data?.some(
                        (item) =>
                            item.status === 'syncing' &&
                            // HACK media mix dependencies are messed up for some reason so
                            // we ignore it
                            item.plugin.id !== PluginSlugs.MEDIA_MIX
                    )
                ) {
                    console.log('integrations syncing, enabling polling...');
                    setPoll(
                        // refetch every 5 minutes
                        60000 * 5
                    );
                } else {
                    setPoll(null);
                }
                return;
            }, [connections.data]);

            const shouldRedirectToPeers = useMemo(
                () =>
                    query.data?.every(isPeerGroupPending) &&
                    connections.data?.some((item) => item.status === 'ready'),
                [query.data, connections.data]
            );

            const onboardingstate = useMemo((): AssetOnboardingState => {
                let steps: AnyOnboardingAssetStep[] = [
                    {
                        id: 'mapping',
                        slug: 'mapping',
                        status: 'force',
                        config: null,
                    },
                ];
                // if (shouldRedirectToPeers) {
                steps = [
                    ...steps,
                    {
                        id: 'peer_groups',
                        slug: 'peer_groups',
                        status: shouldRedirectToPeers ? 'force' : 'required',
                        config: null,
                    },
                ];

                if (true) {
                    steps = [
                        ...steps,
                        {
                            id: 'competitive_set',
                            slug: 'competitive_set',
                            status: 'optional',
                            config: null,
                        },
                    ];
                }

                if (
                    preferences.data?.layout !== 'light' &&
                    onboardingOptions?.mode !== 'report'
                ) {
                    // skip the metric step for light layout mode as we currently
                    // use all available metrics for generating the audit report
                    steps = [
                        ...steps,
                        {
                            id: 'metrics',
                            slug: 'metrics',
                            status: 'required',
                            config: null,
                        },
                    ];
                }

                if (
                    preferences.data?.layout === 'light' ||
                    onboardingOptions?.mode === 'report'
                ) {
                    steps = [
                        ...steps,
                        {
                            id: 'report',
                            slug: 'report',
                            status: 'force',
                            config: null,
                        },
                    ];
                } else {
                    steps = [
                        ...steps,
                        {
                            id: 'prepare',
                            slug: 'prepare',
                            status: 'force',
                            config: null,
                        },
                    ];
                }
                return {
                    scope: 'asset',
                    asset: {
                        id: context.workspace.id as number,
                    },
                    steps,
                };
            }, [workspaces, preferences.data]);

            // console.log('onboarding state', onboardingstate);

            return {
                status: 'loaded',
                data: onboardingstate,
            };
        },
    };
}
