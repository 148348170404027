import {
    Alert,
    AlertTitle,
    Tooltip,
    Button,
    Input,
    InputGroup,
    Select,
    Switch,
    HStack,
    Box,
    VStack,
    Text,
    Spinner,
    Tag,
    Table,
    Thead,
    Th,
    Tbody,
    Tr,
    Td,
    IconButton,
    List as CList,
    ListItem,
    ListIcon
} from '@chakra-ui/react';
import { AiOutlineReload, AiOutlineExclamationCircle } from "react-icons/ai";
import React from 'react';
import { SettingAssetItemProviderConfig } from '../../../../route';
import { SettingAssetProfileConfig } from './profileConfig';
import { SettingAssetProfileViewProps } from './profileProps';
import moment from 'moment';

  
export function createSettingAssetProfileView(
    init: SettingAssetProfileConfig,
    config: SettingAssetItemProviderConfig
): React.FC<{ children?: React.ReactNode | undefined } & SettingAssetProfileViewProps> {
    const {
        Layout: {
            Container,
            Content,
            Panel,
            List,
            Header: {
                Container: Header,
                Breadcrumbs: { List: BreadcrumbsList, Item: BreadcrumbsItem },
            },
        },
    } = config;
    
    return (props) => {
        const pageProps = props.getPageProps();
        const breadcrumbsProps = pageProps.getBreadcrumbProps();
        const editProps = props.getEditProps();
        const alertProps = props.getAlertProps();
        const claimProps = props.getClaimProps();
        const refreshProps = claimProps.getRefreshProps();
        const SaveProfileButton = <Button
            {...editProps.getSubmitButtonProps()}
            colorScheme="blue"
            _focus={{ outline: 'none' }}
            >
            Save
        </Button>
        const scrollTo = React.useRef<HTMLDivElement | null>(null);
        const claimStateProps = claimProps.getStateProps();
        React.useEffect(() => {
            if(scrollTo.current && claimStateProps.claim.kind === 'inactive') {
                scrollTo.current.scrollIntoView({behavior: 'smooth'});
            }
        }, [claimStateProps.claim.kind, scrollTo.current])
        const claimAlertProps = claimProps.getAlertProps();
        return (
            <Container>
                <Header>
                    <BreadcrumbsList>
                        {breadcrumbsProps.items.map((item, index) => (
                            <BreadcrumbsItem key={index} to={item.to}>
                                {item.label}
                            </BreadcrumbsItem>
                        ))}
                    </BreadcrumbsList>
                </Header>
                <Content>
                    <Panel.List>
                        <form
                            style={{ width: '100%' }}
                            {...editProps.getFormElementProps()}
                        >
                            <Panel.Item title="Profile">
                                <Panel.Action.List>
                                    { editProps.isEditDetailsAllowed  ? SaveProfileButton : <Tooltip hasArrow label='Only asset owner account can edit details' shouldWrapChildren mt='3'>
                                        {SaveProfileButton}
                                    </Tooltip>}
                                </Panel.Action.List>
                                {alertProps.items.map((item) => (
                                    <Panel.Alert
                                        key={item.label}
                                        status={item.status ?? undefined}
                                        label={item.label}
                                    />
                                ))}
                                <Panel.FormControl
                                    {...props.getFormControlProps('name')}
                                    label="Company name"
                                    description="The name of the company"
                                >
                                    <InputGroup>
                                        <Input
                                            {...editProps.getInputProps('name')}
                                            isDisabled={true}
                                            autoComplete="disabled"
                                            borderColor="whiteAlpha.400"
                                            _disabled={{
                                                cursor: 'not-allowed',
                                                color: 'whiteAlpha.700',
                                                borderColor: 'whiteAlpha.400',
                                            }}
                                        />
                                    </InputGroup>
                                </Panel.FormControl>
                                <Panel.FormControl
                                    {...props.getFormControlProps('url')}
                                    label="Website URL"
                                    description="The website for the company"
                                >
                                    <InputGroup>
                                        <Input
                                            {...editProps.getInputProps('url')}
                                            autoComplete="disabled"
                                            // readOnly={true}
                                            // isDisabled={true}
                                            isDisabled={true}
                                            borderColor="whiteAlpha.400"
                                            _disabled={{
                                                cursor: 'not-allowed',
                                                color: 'whiteAlpha.700',
                                                borderColor: 'whiteAlpha.400',
                                            }}
                                        />
                                    </InputGroup>
                                </Panel.FormControl>
                                <Panel.FormControl
                                    label="Company type"
                                    description="The type of company"
                                >
                                    <InputGroup>
                                        <Input
                                            {...editProps.getInputProps('kind')}
                                            isDisabled={true}
                                            borderColor="whiteAlpha.400"
                                            _disabled={{
                                                cursor: 'not-allowed',
                                                color: 'whiteAlpha.700',
                                                borderColor: 'whiteAlpha.400',
                                            }}
                                        />
                                    </InputGroup>
                                </Panel.FormControl>
                            </Panel.Item>
                        </form>
                        <Panel.Item title='Claim' ref={scrollTo}>
                            <List.Container>
                                <List.Item
                                    key='desc'
                                    label={
                                        <Text fontSize='xs'>Varos' goal is to build reliable and accurate datasets. In order to do that, we need to verify the ownership or accessibility of users to their companies.</Text>
                                    }>
                                        <></>
                                </List.Item>
                                <List.Item
                                    key='status'
                                    label='Status'
                                >
                                    <Tag
                                        colorScheme={claimStateProps.colorScheme}
                                        size="lg"
                                        userSelect="none"
                                        >
                                        <HStack spacing={3}>
                                            <Text whiteSpace="nowrap">
                                                {claimStateProps.label}
                                            </Text>
                                            {claimStateProps.isLoading && (
                                                <Spinner size="sm" speed="3.5s" />
                                            )}
                                        </HStack>
                                    </Tag>
                                </List.Item>
                                { claimStateProps.detail ? <List.Item key='detail' label='Reason'>
                                    <Text whiteSpace='nowrap'>{claimStateProps.detail}</Text>
                                </List.Item> : <></> }
                                { claimStateProps.expiresAt ? <List.Item key='expiry' label='Expires At'>
                                    <Text whiteSpace='nowrap'>{moment(claimStateProps.expiresAt).format('MM/DD/YYYY')}</Text>
                                </List.Item> : <></>}
                                { claimStateProps.claim.evidences.length > 0 ? <List.Item key='evidences' label='Evidences:'><></></List.Item>: <></>}
                                {/* { refreshProps.result ? <List.Item key='refresh' label='Refresh Result:'>
                                    <Text>{refreshProps.result.status}</Text>
                                    {
                                        (refreshProps.result.status === 'failure' || refreshProps.result.status === 'pending') && refreshProps.result.instructions && refreshProps.result.instructions.length > 0 &&
                                         <VStack>{refreshProps.result.instructions.map(i => (
                                            <Text key={i}>{i}</Text>
                                         ))}
                                         </VStack>
                                    }                                    
                                </List.Item>: <></>} */}
                            </List.Container>
                            

                            { refreshProps.result ? <Panel.Content>
                                <VStack alignItems='start' p={4} borderWidth={1} w='100%'>
                                <Text>Result: {refreshProps.result.status}</Text>
                                {(refreshProps.result.status === 'failure' || refreshProps.result.status === 'pending') && refreshProps.result.instructions && refreshProps.result.instructions.length > 0 &&
                                <>
                                <Text fontSize='sm'>Test Details & instructions:</Text>
                                <CList spacing={3}>
                                    {refreshProps.result.instructions.map(i => (
                                        <ListItem fontSize='sm'>
                                        <ListIcon as={AiOutlineExclamationCircle} color='red.500' />
                                        {i}
                                      </ListItem>
                                    ))}
                                    </CList>
                                </>
                                }
                                </VStack>
                                </Panel.Content>: <></>
                            }

                            { refreshProps.error && refreshProps.error.message ? <Panel.Alert
                                    key={refreshProps.error.message}
                                    status='error'
                                    label={refreshProps.error.message}
                                />: <></>}
                            <Panel.Content>
                                { claimStateProps.claim.evidences.length > 0 ? (<VStack align='start'>
                                    {/* <Text>Evidences</Text> */}
                                    <Table fontSize='xs'>
                                        <Thead>
                                            <Tr>
                                                <Th>
                                                    Uploaded At
                                                </Th>
                                                <Th>
                                                    Note
                                                </Th>
                                                <Th>
                                                    Status
                                                </Th>
                                                <Th>
                                                    Last Update
                                                </Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                        {claimStateProps.claim.evidences.map(ev => (
                                            <Tr key={ev.material.created_at.getTime()}>
                                                <Td>
                                                    {moment(ev.material.created_at).fromNow()}
                                                </Td>
                                                <Td>
                                                    {ev.material.note || '-'}
                                                </Td>
                                                <Td>
                                                    {ev.status}
                                                </Td>
                                                <Td>
                                                    {ev.updated_at ? moment(ev.updated_at).fromNow() : '-'}
                                                </Td>
                                            </Tr>
                                        ))}
                                        </Tbody>
                                    </Table>
                                    </VStack>
                                ) : <></> }
                                </Panel.Content>
                                <Panel.Action.List>
                                    <Button {...refreshProps.getRefreshButtonProps()}
                                            colorScheme="gray"
                                            aria-label='Refresh'
                                            leftIcon={<AiOutlineReload />}>Refresh</Button>
                                </Panel.Action.List>
                        </Panel.Item>
                        <form {...claimProps.getFormElementProps()}>
                            <Panel.Item
                                title='New Evidence'
                            >
                                {claimAlertProps ?  (
                                    <Panel.Alert
                                        status={claimAlertProps.status ?? undefined}
                                        label={claimAlertProps.label}
                                    />
                                ): <></>}
                                <Panel.FormControl
                                        {...claimProps.getFormControlProps('evidence')}
                                        label="Screenshot"
                                        description="A screenshot of domain ownership, ad account, or anything that links you to the ownership of the asset"
                                    >
                                        <InputGroup>
                                            <Input 
                                                {...claimProps.getInputProps('evidence')}
                                                type='file'
                                                accept='image/*'
                                                >
                                            </Input>
                                        </InputGroup>
                                </Panel.FormControl>
                                 <Panel.FormControl label='Note (optional)' {...claimProps.getFormControlProps('note')}>
                                    <InputGroup>
                                        <Input
                                            {...claimProps.getInputProps('note')}
                                            noOfLines={2}                                            
                                            borderColor="whiteAlpha.400"
                                            _disabled={{
                                                cursor: 'not-allowed',
                                                color: 'whiteAlpha.700',
                                                borderColor: 'whiteAlpha.400',
                                            }}
                                            />
                                    </InputGroup>
                                 </Panel.FormControl>
                                 <Panel.Action.List>
                                    <Button {...claimProps.getSubmitButtonProps()}
                                            colorScheme="blue"
                                            _focus={{ outline: 'none' }}>Save</Button>
                                </Panel.Action.List>
                            </Panel.Item>
                        </form>
                        <Panel.Item title="Preferences">
                            <Panel.FormControl
                                label="Visible"
                                description="A company that is not visible will be hidden
                                in the rest of the platform"
                            >
                                <Switch
                                    isDisabled={true}
                                    isReadOnly={true}
                                    isChecked={true}
                                    size="md"
                                    _focus={{ outline: 'none' }}
                                />
                            </Panel.FormControl>
                        </Panel.Item>
                    </Panel.List>
                </Content>
            </Container>
        );
    };
}
