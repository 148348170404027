import { ApiContext, LegacyAuthenticationScheme } from '../../v2/api';
import { AxiosInstance, AxiosResponse } from 'axios';
import { FeatureDto, FeatureListDto } from './featureDto';

export async function listFeatures(
    context: ApiContext | null,
    api: AxiosInstance,
    assetId: number | string | null
): Promise<FeatureListDto> {
    let headers = {};
    let params = {};

    if(assetId) {
        params = { asset: assetId};
    }
    if (context?.auth.scheme.kind === 'legacy') {
        const scheme = context.auth.scheme as LegacyAuthenticationScheme;
        await scheme.store.waitAuthenticated();
        headers = {
            ...headers,
            Authorization: `bearer ${scheme.store.authToken}`,
        };
    } else if (context?.auth.scheme.kind === 'apiKey') {
        if (context.auth.scheme.assume?.id) {
            headers = {
                ...headers,
                'x-varos-account': context.auth.scheme.assume?.id,
            };
        }
        params = { ...params, 'api-key': context.auth.scheme.token };
    }

    const response = await api.get<unknown, AxiosResponse<FeatureListDto>>(
        `/api/v1/features`,
        { headers, params }
    );
    return response.data;
}
