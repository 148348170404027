import React from 'react';
import { Videos } from '../../../../../../../config';
import { RegistrationContainerConfig } from '../../../../../base';
import { RegistrationAcceptContextProvider } from '../../context';
import { RegistrationAcceptWelcomeController } from './acceptWelcomeInterface';
import {
    AcceptWelcomeContainerProps,
    AcceptWelcomeViewProps,
} from './acceptWelcomeProps';

export function createRegistrationAcceptWelcomeContainer(
    config: RegistrationContainerConfig & {
        accept: RegistrationAcceptContextProvider;
    },
    controller: RegistrationAcceptWelcomeController
): React.FC<AcceptWelcomeContainerProps> {
    const {
        accept: { useContext },
    } = config;
    return ({ as: View, ...containerProps }) => {
        const context = useContext();

        const props = controller.useProps(context, {
            video: Videos.INVITE_WELCOME,
            stepper: context.stepper,
            page: {
                metadata: context.metadata,
            },
        });
        return <View {...props} />;
    };
}
