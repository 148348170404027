import { useEffect, useMemo } from 'react';
import { keyBySafe } from '../../../../util';
import { MetricItemAggregate } from '../../../../view';
import { assert } from '../../../../util/assert';
import {
    ReferenceType,
    isReferenceType,
    resolveProperties,
    AnyResolvedType,
    AnyCondition,
} from '../../../../domain/attributes';
import { PeerFilterListAggregate } from '../../../../view/peer';
import { PluginEntity } from '../../../../domain';
import { lookupFromQuery } from '../../../../base';
import { NetworkBaseRouteConfig } from '../../base';
import { NetworkRequestNewLoader } from './networkRequestNewInterface';
import { NetworkRequestNewAggregate } from './networkRequestNewModel';

export function createNetworkRequestNewLoader(
    config: Pick<NetworkBaseRouteConfig, 'repository'>
): NetworkRequestNewLoader {
    const {
        repository: {
            metric: metricRepository,
            plugin: pluginRepository,
            connection: connectionRepository,
            member: memberRepository,
            status: statusRepository,
            peer: peerGroupRepository,
        },
    } = config;

    return {
        useLoad(context, deps): NetworkRequestNewAggregate {
            const pluginQuery = pluginRepository.useFind(
                context,
                {},
                { suspense: true, staleTime: Infinity }
            );
            assert(pluginQuery.status === 'success', 'expected suspense');

            const connectionQuery = connectionRepository.useFind(
                context,
                { workspaces: [{ id: context.workspace.id }] },
                { suspense: true, staleTime: Infinity }
            );

            const metricQuery = metricRepository.useFind(
                context,
                {
                    plugins: pluginQuery.data,
                    assets: [context.workspace]
                },
                { suspense: true, staleTime: Infinity }
            );

            const peerGroupQuery = peerGroupRepository.useFind(
                context,
                {
                    workspace: { id: context.workspace.id as number },
                    plugins: pluginQuery.data.map((item) => item.id),
                },
                { suspense: true }
            );

            const memberQuery = memberRepository.useFind(
                context,
                {
                    workspace: { id: context.workspace.id as number },
                    types: pluginQuery.data
                        .flatMap((plugin) =>
                            plugin.traits.filter((trait) => isReferenceType(trait.type))
                        )
                        .map(
                            (trait): ReferenceType => ({
                                kind: 'reference',
                                id: trait.key,
                            })
                        ),
                },
                { suspense: true, staleTime: Infinity }
            );

            assert(connectionQuery.status === 'success', 'expected suspense');
            assert(metricQuery.status === 'success', 'expected suspense');
            assert(memberQuery.status === 'success', 'expected suspense');
            assert(peerGroupQuery.status === 'success', 'expected suspense');

            const resolvedPlugins = useMemo(
                () =>
                    pluginQuery.data.map(
                        (plugin): PluginEntity<AnyResolvedType> => ({
                            ...plugin,
                            traits: resolveProperties(memberQuery.data, plugin.traits),
                        })
                    ),
                [pluginQuery.data]
            );

            const pluginsById = useMemo(
                () => keyBySafe(resolvedPlugins, (item) => item.id),
                [resolvedPlugins]
            );

            const peerGroupByPlugin = useMemo(
                () => keyBySafe(peerGroupQuery.data, (item) => item.plugin.id),
                [peerGroupQuery.data]
            );

            const connectionByPlugin = useMemo(
                () => keyBySafe(connectionQuery.data, (item) => item.plugin.id),
                [connectionQuery.data]
            );

            const selected = useMemo(() => {
                const metric =
                    metricQuery.data.find((item) => item.id === deps.formValues.metric) ??
                    null;
                const plugin =
                    resolvedPlugins.find((item) => item.id === metric?.plugin) ?? null;
                return {
                    metric,
                    plugin,
                };
            }, [metricQuery.data, resolvedPlugins, deps.formValues.metric]);

            const statusQuery = statusRepository.useLookup(
                context,
                {
                    plugin: selected.plugin!,
                    conditions: deps.formValues.filters as AnyCondition[],
                },
                { refetchOnWindowFocus: false }
            );

            const peer = useMemo(
                (): PeerFilterListAggregate => ({
                    traits: selected.plugin?.traits ?? [],
                    status: lookupFromQuery(statusQuery),
                }),
                [selected.plugin?.traits, statusQuery.data, deps.formValues.filters]
            );

            const metric = useMemo(
                () => ({
                    items: metricQuery.data
                        .filter(
                            (item) =>
                                item.kind === 'outcome' || item.kind === 'performance'
                        )
                        .flatMap((item): MetricItemAggregate[] => {
                            const plugin = pluginsById[item.plugin];
                            const connection = connectionByPlugin[item.plugin];
                            if (!plugin || !connection) {
                                return [];
                            }
                            if (connection.status === 'pending') {
                                return [];
                            }
                            return [{ definition: item, plugin, connection }];
                        }),
                }),
                [metricQuery.data, pluginsById, connectionByPlugin]
            );

            useEffect(() => {
                deps.form.setValue('metric', metric.items[0]?.definition.id);
            }, []);

            useEffect(() => {
                if (!selected.plugin) {
                    return;
                }
                const group = peerGroupByPlugin[selected.plugin.id];
                if (!group) {
                    return;
                }
                deps.form.setValue(
                    'filters',
                    // @ts-expect-error
                    group.conditions
                );
            }, [selected.plugin]);

            return {
                peer,
                metric,
                preview: {
                    item: {
                        plugin: selected.plugin,
                        conditions: deps.formValues.filters as AnyCondition[],
                    },
                    query: {
                        competitive: statusQuery,
                    },
                },
            };
        },
    };
}
