import { useMemo } from 'react';
import { keyBySafe } from '../../../../util';
import { assert } from '../../../../util/assert';
import { getCollectionItems } from '../../../../base';
import {
    ReportItemAggregate,
    StudyFilterAggregate,
    StudyListAggregate,
} from '../../../../view/studies';
import { getReportDependencyStatus, isWorkspaceContext } from '../../../../app';
import { StudiesExploreRouteConfig } from './studiesReportListConfig';
import { StudiesExploreLoader } from './studiesReportListInterface';
import { StudiesExploreAggregate } from './studiesReportListModel';

export function createStudiesExploreLoader(
    config: Pick<StudiesExploreRouteConfig, 'repository'>
): StudiesExploreLoader {
    const {
        repository: {
            dataset: datasetRepository,
            count: countRepository,
            submission: submissionRepository,
            view: viewRepository,
            report: reportRepository,
            invitation: invitationRepository,
        },
    } = config;
    const filters: StudyFilterAggregate[] = [
        // {
        //     property: {
        //         name: 'Provider',
        //         key: 'provider',
        //     },
        //     members: [
        //         {
        //             label: 'My studies',
        //             value: 'my studies',
        //         },
        //         {
        //             label: 'Varos',
        //             value: 'varos',
        //         },
        //         {
        //             label: 'Custom',
        //             value: 'custom',
        //         },
        //     ],
        // },
        // {
        //     property: {
        //         name: 'Industry',
        //         key: 'industry',
        //     },
        //     members: [
        //         {
        //             label: 'Marketing',
        //             value: 'marketing',
        //         },
        //         {
        //             label: 'IT',
        //             value: 'it',
        //         },
        //         {
        //             label: 'Finance',
        //             value: 'finance',
        //         },
        //         {
        //             label: 'Manufacturing',
        //             value: 'manufacturing',
        //         },
        //     ],
        // },
        // {
        //     property: {
        //         name: 'Privacy',
        //         key: 'privacy',
        //     },
        //     members: [
        //         {
        //             label: 'Public',
        //             value: 'public',
        //         },
        //         {
        //             label: 'Private',
        //             value: 'private',
        //         },
        //         {
        //             label: 'Sharing',
        //             value: 'sharing',
        //         },
        //     ],
        // },
    ];

    return {
        useLoad(context) {
            const reportsQuery = reportRepository.useCollection(
                context,
                { asset: context.workspace?.id ?? null },
                { suspense: true }
            );
            const datasetsQuery = datasetRepository.useFind(
                context,
                {
                    category: 'survey',
                },
                { suspense: true, retry: false }
            );
            const viewQuery = viewRepository.useFind(context, {
                assets: context.workspace ? [context.workspace]: undefined
            }, { suspense: true });
            const submissionQuery = submissionRepository.useFind(
                context,
                {
                    asset: context.workspace?.id ? {
                        id: context.workspace.id as number,
                    } : null,
                },
                { suspense: true }
            );
            const invitationQuery = invitationRepository.useCollection(
                context,
                { report: reportsQuery.data?.ids ?? [] },
                { suspense: true }
            );

            assert(viewQuery.status === 'success', 'expected suspese');
            assert(datasetsQuery.status === 'success', 'expected suspese');
            assert(submissionQuery.status === 'success', 'expected suspese');
            assert(reportsQuery.status === 'success', 'expected suspese');
            assert(invitationQuery.status === 'success', 'expected suspese');

            const countQuery = countRepository.useLookup(
                context,
                datasetsQuery.data.map((item) => ({
                    kind: 'data_ingestion.dataset_member',
                    object: item.id,
                })),
                { suspense: true }
            );
            assert(countQuery.status === 'success', 'expected suspese');

            const countsByDataset = useMemo(
                () => keyBySafe(countQuery.data, (item) => item.object),
                [countQuery.data]
            );

            const invitationByReport = useMemo(
                () =>
                    keyBySafe(
                        getCollectionItems(invitationQuery.data),
                        (item) => item.report.id
                    ),
                [invitationQuery.data]
            );

            const viewsById = useMemo(
                () => keyBySafe(viewQuery.data, (item) => item.id),
                [viewQuery.data]
            );

            const datasetsById = useMemo(
                () => keyBySafe(datasetsQuery.data, (item) => item.id),
                [datasetsQuery.data]
            );

            const submissionsByDataset = useMemo(
                () => keyBySafe(submissionQuery.data, (item) => item.dataset.id),
                [submissionQuery.data]
            );

            const aggregate = useMemo((): StudiesExploreAggregate => {
                const base: StudyListAggregate = {
                    items: getCollectionItems(reportsQuery.data).flatMap(
                        (report): ReportItemAggregate[] => {
                            const dataset =
                                report.kind === 'dataset'
                                    ? datasetsById[report.dataset.id] ?? null
                                    : null;
                            // if (!dataset) {
                            //     console.warn(
                            //         // @ts-expect-error
                            //         `report dataset '${report.dataset?.id}' not found`,
                            //         datasetsById
                            //     );
                            //     return [];
                            // }
                            const count = dataset
                                ? countsByDataset[dataset.id] ?? null
                                : null;
                            const submission = dataset
                                ? submissionsByDataset[dataset.id] ?? null
                                : null;

                            if (!count) {
                                console.warn(
                                    `count for dataset ${dataset?.id} not found`
                                );
                            }
                            return [
                                {
                                    asset: context.workspace?.id ? { id: context.workspace.id as number } : null,
                                    report,
                                    dataset,
                                    count: { respondents: count },
                                    submission,
                                    // HACK right now we assume the view is the same as the
                                    // dataset
                                    view: dataset ? viewsById[dataset.id] ?? null : null,
                                    invitation: invitationByReport[report.id] ?? null,
                                },
                            ];
                        }
                    ),
                };
                return {
                    study: {
                        fulfilled: {
                            ...base,
                            items: base.items.filter(
                                (item) => getReportDependencyStatus(item) === 'fulfilled'
                            ),
                        },
                        unfulfilled: {
                            ...base,
                            items: base.items.filter(
                                (item) =>
                                    getReportDependencyStatus(item) === 'unfulfilled'
                            ),
                        },
                    },
                    filter: {
                        items: filters,
                    },
                };
            }, [
                context.workspace?.id,
                reportsQuery.data,
                datasetsById,
                countsByDataset,
                submissionsByDataset,
                filters,
                invitationByReport,
            ]);

            return aggregate;
        },
    };
}
