import { Asset, AssetClaim } from '../../../../domain/assets';
import { assert } from '../../../../util/assert';
import { SettingAssetConfig } from '../assetConfig';
import { SettingAssetPageLoader } from './pageInterface';

export function createSettingAssetLoader(
    config: Pick<SettingAssetConfig['deps'], 'repository'>
): SettingAssetPageLoader {
    const { repository } = config;
    return {
        useLoad(context) {
            const data = repository.asset.useFind(
                context,
                {expand: ['claim'],
                    activeClaim: false
                },
                { suspense: true, staleTime: Infinity }
            );
            assert(data.status === 'success', 'expected suspense query');
            const asset = data.data.find((item) => item.id === context.workspace.id);
            assert(asset, () => `asset ${context.workspace.id} not found`);
            assert(!!asset.claim, () => `asset claim for ${context.workspace.id} not found`)
            return { asset: (asset as Asset & { claim: AssetClaim }) };
        },
    };
}
