import { MetricDefinition } from '../../../domain/metrics';
import { MetricDefinitionAdapter } from '../../../app/metrics/definition';
import { castTypeToDomain } from '../../api';
import { MetricDefinitionImplConfig } from './definitionConfig';

export function createMetricDefinitionImpl(
    config: MetricDefinitionImplConfig
): MetricDefinitionAdapter {
    const { api } = config;
    return {
        async find(context, query) {
            const response = await api.metric.definitions.list(context, {
                plugins: query.plugins.map((item) => item.id),
                views: query.views?.map((item) => item.id),
                assets: query.assets?.map((item) => item.id.toString()),
            });
            return response.data
                .filter(
                    (item) =>
                        query.plugins.some((candidate) => candidate.id === item.plugin) ||
                        query.views?.some((candidate) => candidate.id === item.view)
                )
                .map((item): MetricDefinition => {
                    return { ...item, type: castTypeToDomain(item.type) };
                });
            }
        }
}
