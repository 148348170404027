import React from 'react';
import { EmailInsightRouteConfig } from './emailInsightConfig';
import { EmailInsightInputSchema } from './emailInsightSchema';

export function createEmailInsightRoute(init: EmailInsightRouteConfig) {
    const {
        View,
        hook: { useQueryParams },
        provider: {
            Auth: { Provider: AuthProvider },
            Organization: { Provider: OrganizationProvider },
            Workspace: { Provider: WorkspaceProvider },
        },
    } = init;
    return () => {
        const params = useQueryParams(EmailInsightInputSchema);
        return (
            <AuthProvider
                value={{
                    user: null,
                    scheme: {
                        kind: 'apiKey',
                        token: params['api-key'],
                        assume: null,
                        // assume: {
                        //     kind: 'organization',
                        //     id: params.organization,
                        // },
                    },
                }}
            >
                <OrganizationProvider
                    value={{
                        id: params.account,
                    }}
                >
                    <WorkspaceProvider
                        value={{
                            id: params.workspace,
                        }}
                    >
                        <View
                            insight={{ id: params.insight }}
                            workspace={{ id: params.workspace }}
                        />
                    </WorkspaceProvider>
                </OrganizationProvider>
            </AuthProvider>
        );
    };
}
