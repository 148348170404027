import { Toast } from './toastModel';
import { ToastTriggerProps } from './toastProps';

let counter = 0;

export function buildToast(props: ToastTriggerProps): Toast {
    return {
        id: (counter++).toString(),
        title: props.title,
        description: props.description ?? null,
        status: props.status,
        duration: props.duration ?? 5000,
        closeable: props.closeable ?? false,
    };
}
