import { capitalize } from 'lodash';
import { RoleAdapter } from '../../../app/permissions';
import { PermissionRoleImplConfig } from './permissionRoleConfig';

export function createPermissionRoleImpl(config: PermissionRoleImplConfig): RoleAdapter {
    const {
        api: { platform: api },
    } = config;
    return {
        async find(context, query) {
            const response = await api.me.get(context);
            const organization = response.user.memberships.find(
                (candidate) => candidate.account_id === query.account.id
            );
            if (!organization) {
                return [];
            }
            return [
                {
                    id: organization.role === 'owner' ? 'admin' : organization.role,
                    name: capitalize(organization.role),
                    kind: 'organization',
                },
            ];
        },
    };
}
