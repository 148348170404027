import React, { useContext } from 'react';
import { Alert, AlertIcon, Button, HStack, Text } from '@chakra-ui/react';
import { Navigate } from 'react-router';
import { isAssetIssue } from '../../domain';
import {
    useControlApi,
    useDashboardApi,
    useFeatureApi,
    useUserApi,
    useWorkspaceApi,
} from '../../app';
import {
    CardListContainer,
    CardItemContainer,
    ChartContainer,
    ActionContainer,
    SegmentListContainer,
    SegmentItemContainer,
    FilterListContainer,
    FilterItemContainer,
    FilterDateContainer,
    CompetitiveSetContainer,
    createDashboardGroupContainer,
} from '../../controller';
import { DashboardLayoutProps } from '../../layout';
import { LazyLoad } from '../../util';
import {
    DashboardRouteApi,
    DashboardRouteConfig,
    DashboardRouteItemProps,
} from './dashboardRouteConfig';
import { SegmentListAggregate } from '../../view';
import { FeatureFlagContext } from '../../middleware/feature-flag/featureFlagConstant';
import { observer } from 'mobx-react';

export function createDashboardDefaultRedirect(config: Pick<DashboardRouteConfig, 'hook' | 'repository'>): React.FC<
    { children?: React.ReactNode | undefined } & {
        children?: React.ReactNode;
    }
> {
    return () => {
        const workspace = config.hook.useWorkspace();
        const dashboards = config.repository.dashboard.useFind({
            auth: config.hook.useAuth(),
            account: config.hook.useAccount()
        }, { workspace: {...workspace, name: 'N/A'}}, {suspense: workspace.claimActive, enabled: workspace.claimActive});
        const dashboardUrl = React.useMemo(() => {
            const [first] = dashboards.data ?? [];
            if(first) {
                return `/u/assets/${workspace.id}/dashboards/${first.id}`;
            }
            return null;
        }, [dashboards.data, workspace.id]);
        return <Navigate to={dashboardUrl ?? '/u'} replace={true} />;
    };
}

export function createDashboardRoute(
    config: DashboardRouteConfig
): React.FC<{ children?: React.ReactNode | undefined } & { children?: React.ReactNode }> {
    const { Issue: IssueView } = config.views;
    const {
        Layout: {
            UI: Layout,
            controller: { useProps: useLayout },
        },
        controller: Controller,
        repository: { asset: assetRepository },
        views: View,
        hook,
        ...props
    } = config;

    const settingsItems = config.settings.map((item) => item.create(config.api));
    const controlItems = config.controls.map((item) => {
        const control = item.create(config.api);
        return control;
    });

    // console.log('controlItems', controlItems);

    function getSettingsItemProps(
        layoutProps: DashboardLayoutProps
    ): DashboardRouteItemProps {
        return { onClose: layoutProps.disclosure.onClose };
    }

    function getControlItemProps(
        layoutProps: DashboardLayoutProps
    ): DashboardRouteItemProps {
        return { onClose: layoutProps.disclosure.onClose };
    }

    const GroupContainer = createDashboardGroupContainer({
        UI: config.UI,
        hook,
        repository: {
            peerset: config.repository.peerset,
            healthcheck: config.repository.healthcheck,
        },
    });

    return observer(() => {
        const organization = hook.useAccount();
        const workspace = hook.useWorkspace();
        const auth = hook.useAuth();
        const layoutProps = useLayout({ account: organization, workspace, auth });
        const issueProps = Controller.useIssue();
        const dashboard = useDashboardApi();
        const control = useControlApi();
        const assets = assetRepository.useFind(
            { account: organization, auth },
            {},
            { suspense: true }
        );
        const item: SegmentListAggregate = {
            current: { id: workspace.id as number },
            assets: assets.data ?? [],
        };

        // NOTE temp hack
        // const featureflags = useContext(FeatureFlagContext);

        return (
            <Layout.Wrapper
                {...layoutProps}
                style={props.style?.layout}
                stickyControls={true}
                // showGroups={featureflags?.flags.peers?.enabled ?? false}
                showGroups={true}
                mode={control.getFilterMode()}
                onModeChange={control.setFilterMode}
            >
                <Layout.CompetitiveSet>
                    <CompetitiveSetContainer as={View.CompetitiveSet} />
                </Layout.CompetitiveSet>
                {isAssetIssue(issueProps.issue) ? (
                    <Layout.Status>
                        <IssueView issue={issueProps.issue} />
                    </Layout.Status>
                ) : (
                    <></>
                )}
                <Layout.Segment>
                    <SegmentListContainer
                        as={View.Segment.List}
                        useController={Controller.useSegmentList}
                        renderItem={(filter, index) => (
                            <SegmentItemContainer
                                as={View.Segment.Item}
                                item={item}
                                useController={Controller.useSegmentItem}
                                filter={filter}
                                index={index}
                            />
                        )}
                    />
                </Layout.Segment>
                <Layout.Group>
                    <GroupContainer as={View.Group} />
                </Layout.Group>
                <Layout.Actions>
                    <ActionContainer
                        as={View.Actions}
                        useController={Controller.useActions}
                    />
                </Layout.Actions>
                <Layout.Control.List>
                    {controlItems.map(({ Component, ...item }) => (
                        <Layout.Control.Item key={item.id} {...item}>
                            <Component dashboard={dashboard.getDashboard()} />
                        </Layout.Control.Item>
                    ))}
                </Layout.Control.List>
                <Layout.Filters>
                    <FilterListContainer
                        as={View.Filter.List}
                        useController={Controller.useFilterList}
                        renderFilter={(filter, index) => (
                            <FilterItemContainer
                                index={index}
                                filter={filter}
                                as={View.Filter.Item}
                                useController={Controller.useFilterItem}
                            />
                        )}
                    />
                </Layout.Filters>
                <Layout.Date>
                    <FilterDateContainer
                        as={View.Date}
                        useController={Controller.useDateFilter}
                    />
                </Layout.Date>
                <Layout.Cards>
                    <CardListContainer
                        as={View.Card.List}
                        useController={Controller.useCardList}
                        renderItem={(card) => (
                            <CardItemContainer
                                card={card}
                                as={View.Card.Item}
                                useController={Controller.useCardItem}
                                renderChart={(section) => (
                                    <ChartContainer
                                        card={card}
                                        section={section}
                                        as={View.Chart}
                                        useController={Controller.useChart}
                                        renderLine={(visualProps) => (
                                            <View.Visualization.Line
                                                card={card}
                                                {...visualProps}
                                            />
                                        )}
                                        renderTable={(visualProps) => (
                                            <View.Visualization.Table
                                                {...visualProps}
                                                overrides={
                                                    props.style?.visualization?.table
                                                }
                                            />
                                        )}
                                        renderStat={(visualProps) => (
                                            <View.Visualization.Stat {...visualProps} />
                                        )}
                                        renderBar={(visualProps) => (
                                            <View.Visualization.Bar {...visualProps} />
                                        )}
                                    />
                                )}
                            />
                        )}
                    />
                </Layout.Cards>
            </Layout.Wrapper>
        );
    });
}
