import { DashboardResource } from './dashboardInterface';
import { listDashboards } from '../../../../api/v2/dashboard';
import { LegacyAuthenticationScheme } from '../../common';
import { AxiosInstance } from 'axios';

export function createDashboardResource (client: AxiosInstance): DashboardResource {
    return {
        async list(context, query) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }
            
            const responseDashboards = await listDashboards(
                client,
                [query.assetId],
                undefined,
                100,
                'any',
                ['exclusive_access', 'is_highlighted'],
                {
                    callerName: 'api impl',
                    auth: context.auth.scheme,
                }
            );

            return responseDashboards;
        },

    }
}