import { PageIds } from '../../../../../config';
import { AssistantControllerBaseConfig } from '../../../base';
import { ThreadNewController } from './threadNewInterface';

export function createThreadNewController(
    config: AssistantControllerBaseConfig
): ThreadNewController {
    const {
        kernel: {
            provider: { createFormController, createPageController },
        },
    } = config;
    const formController = createFormController({});
    const pageControler = createPageController({
        id: PageIds.ASSISTANT_THREAD_NEW,
    });
    return {
        useProps(context, item, props) {
            const form = formController.useProps(props.form);
            const page = pageControler.useProps({}, {});
            return {
                form,
                terms: {
                    link: config.infra.navigation.legal.termsOfService({
                        location: props.location,
                    }),
                },
                workflow: {
                    title: item.workflow.tagline,
                    description: item.workflow.summary,
                    button: {
                        label: item.workflow.action,
                    },
                },
            };
        },
    };
}
