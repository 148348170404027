import StoreBase from './StoreBase';
import { action, computed, keys, makeObservable, observable, runInAction } from 'mobx';
import { resolveState, confirmLink } from '../api/linkConfirm';
import { IntegrationConf } from '../models/integration';

export default class IntegrationConfirmStore extends StoreBase {
    isLoading: boolean = false;
    confirmedStates: Set<string> = new Set([]);
    currentIntegration: IntegrationConf | null = null;
    confirmedIntegrationId: number | null = null;

    constructor() {
        super();
        makeObservable(this, {
            // Observables
            isLoading: observable,
            confirmedStates: observable,
            currentIntegration: observable,
            confirmedIntegrationId: observable,

            // Actions
            setLoading: action,
            addConfirmedState: action,
            resolveState: action,
            confirm: action,

            // XHR -
            //   getInvoiceData: action,
            //   payWithCreditCard: action,
        });
    }
    setIntegrationId(integId: number | null) {
        this.confirmedIntegrationId = integId;
    }
    setLoading(flag: boolean) {
        this.isLoading = flag;
    }

    setIntegDefinition(definition: IntegrationConf) {
        this.currentIntegration = definition;
    }

    addConfirmedState(state: string) {
        this.confirmedStates.add(state);
    }

    resolveState = async (state: string) => {
        this.setLoading(true);
        this.setIntegrationId(null);
        try {
            const { data } = await resolveState(state);
            this.setIntegDefinition(data);
        } catch (e) {
            this.handleAxiosError(e);
        } finally {
            this.setLoading(false);
        }
    };

    confirm = async (state: string, code: string, qs: string) => {
        this.setLoading(true);
        try {
            const { data } = await confirmLink(state, code, qs);
            this.addConfirmedState(state);
            this.setIntegrationId(data.integration_id)
            if (data.login_link) {
                window.location.href = data.login_link;
            } else if (this.rootStore.auth.authToken) {
                await this.rootStore.auth.getMyAccount();
            }
        } catch (e) {
            const isDone = await this.rootStore.auth.doCommonUnauthErrorHandling(e);
            if (isDone) {
                await this.confirm(state, code, qs);
            } else {
                this.handleAxiosError(e);
            }
        } finally {
            this.setLoading(false);
        }
    };
}
