import React, { useRef } from 'react';
import { ApplicationShellContextContainerProps } from './applicationContextProps';
import {
    ApplicationShellContext,
    ApplicationShellContextController,
    ApplicationShellContextLoader,
} from './applicationContextInterface';
import { BaseApplicationShellContainerConfig } from '../../../base';
import { ApplicationShellContextUrlParamsSchema } from './applicationContextSchema';

export function createApplicationShellContextContainer(
    config: Pick<BaseApplicationShellContainerConfig, 'kernel'>,
    Context: React.Context<ApplicationShellContext | null>,
    loader: ApplicationShellContextLoader,
    controller: ApplicationShellContextController
): React.FC<ApplicationShellContextContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
    } = config;
    return ({ children, ...containerProps }) => {
        const overlayRef = useRef<HTMLDivElement>(null);
        const options = useOptions(ApplicationShellContextUrlParamsSchema);
        const data = loader.useLoad(options);
        const props = controller.useProps(data, {
            overlayRef,
        });
        return <Context.Provider {...props.provider}>{children}</Context.Provider>;
    };
}
