import { createContext, useContext } from 'react';
import { SubscriptionAdapter } from '../../billing';
import { FeatureSetAdapter } from '../../platform';
import { Workspace } from '../../../domain';
import { DashboardAdapter } from '..';

export interface WorkspaceAdapter {
    dashboard: DashboardAdapter;
    subscription: SubscriptionAdapter;
    featureset: FeatureSetAdapter;
}

export interface WorkspaceContextValue {
    workspace: Workspace & { claimActive: boolean };
    adapter: WorkspaceAdapter;
}

export interface WorkspaceContextDeps {
    adapter: WorkspaceAdapter;
}

export const WorkspaceContext = createContext<WorkspaceContextValue | null>(null);

export const useWorkspaceContext = () => {
    const context = useContext(WorkspaceContext);
    if (!context) {
        throw new Error(`workspace context not found`);
    }
    return context;
};

export const useMaybeWorkspaceContext = () => {
    const context = useContext(WorkspaceContext);
    return context;
};
