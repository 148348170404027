import { z } from 'zod';
import { ApplicationShellContextLoaderProps } from './applicationContextProps';

export const ApplicationShellContextUrlParamsSchema = z
    .object({
        assetId: z.number({ coerce: true }).optional(),
    })
    .transform(
        (item): ApplicationShellContextLoaderProps => ({
            asset: item.assetId ? { id: item.assetId } : null,
        })
    );
