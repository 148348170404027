import React from 'react';
import { SurveyRootContainerProps, SurveyRootViewProps } from './surveyRootProps';
import { SurveyRootController } from './surveyRootInterface';
import { useLocation } from 'react-router';

export function createSurveyRootContainer(
    controller: SurveyRootController,
    View: React.FC<SurveyRootViewProps>
): React.FC<SurveyRootContainerProps> {
    return ({ children, ...containerProps }) => {
        const location = useLocation();
        const props = controller.useProps({ location });
        return <View {...props}>{children}</View>;
    };
}
