import { FormUIConfig } from '../../formConfig';
import { FormFieldListController } from './formFieldListInterface';

export function createFormFieldListController(
    config: Pick<FormUIConfig, 'kernel'>
): FormFieldListController {
    const {
        kernel: { controller },
    } = config;
    return {
        useProps(props) {
            const browser = controller.browser.useProps({});
            return {
                ...props,
                columns: props.columns ?? 1,
                browser,
            };
        },
    };
}
