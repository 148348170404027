import pluralize from 'pluralize';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { StudiesControllerBaseConfig } from '../../base';
import { SurveyDetailController } from './surveyDetailInterface';
import { SurveyResponseMode } from './surveyDetailModel';
import { SurveyDetailViewProps } from './surveyDetailProps';
import { useMemo } from 'react';

export function createSurveyDetailController(
    config: StudiesControllerBaseConfig
): SurveyDetailController {
    const {
        controller: {
            submissions: { list: submissionController },
        },
        provider: { createOptionController },
    } = config;

    const optionController = createOptionController<SurveyResponseMode>({
        id: 'survey_start_mode_option',
    });

    return {
        useProps(context, item, props): SurveyDetailViewProps {
            const submission = submissionController.useProps(context, item.submission);
            const mode = optionController.useProps(props.mode);
            const [tag] = item.survey.tags;
            const linkPrefix = useMemo(() => {
                if(context.workspace) {
                    return `/u/assets/${context.workspace.id}`;
                }
                return '/u/account'
            }, [context.workspace])
            return {
                survey: {
                    title: item.survey.name,
                    description: item.survey.description,
                },
                submission,
                option: {
                    mode,
                },
                button: {
                    join: {
                        label:
                            props.mode.value === 'form'
                                ? 'Start survey'
                                : 'Start interview',
                    },
                },
                tag: {
                    items: [
                        {
                            Icon: AiOutlineClockCircle,
                            label: [
                                item.survey.estimate.amount,
                                pluralize(
                                    item.survey.estimate.interval,
                                    item.survey.estimate.amount
                                ),
                            ].join(' '),
                            tooltip: null,
                        },
                        ...(tag
                            ? [
                                  {
                                      Icon: AiOutlineClockCircle,
                                      label: tag.value,
                                      tooltip: null,
                                  },
                              ]
                            : []),
                    ],
                },
                link: {
                    new: {
                        id: 'survey_detail_new_submission_link',
                        to:
                            props.mode.value === 'interview'
                                ? props.link.thread.to
                                : `${linkPrefix}/reporting/surveys/submissions/new?survey=${item.survey.id}`,
                    },
                    overview: {
                        to: `${linkPrefix}/reporting/surveys/submissions`,
                    },
                },
            };
        },
    };
}
