import React, { useEffect, useMemo } from 'react';
import { Overlay, OverlayContextValue } from './overlayInterface';
import { Portal } from '@chakra-ui/react';
import { assert } from '@varos/util-typescript';
import { createOverlayController } from './overlayController';
import { createOverlayContainer } from './overlayContainer';
import { useSearchParams } from 'react-router-dom';
import { createOverlayStateProvider } from './overlayState';
import { OverlayConfig } from './overlayConfig';

export function createOverlay(config: OverlayConfig): Overlay {
    const Context = React.createContext<OverlayContextValue | null>(null);
    const states = createOverlayStateProvider(config);
    const controller = createOverlayController();
    const Container = createOverlayContainer(config, Context, states, controller);
    return {
        Container: Container,
        Provider(props) {
            const value: OverlayContextValue = { overlayRef: props.overlayRef };
            return <Context.Provider value={value}>{props.children}</Context.Provider>;
        },
        Portal(props) {
            // const [searchParams, setSearchParams] = useSearchParams();
            const context = React.useContext(Context);
            assert(context, 'not inside overlay context');
            // useEffect(() => {
            //     console.log('portal spawned');
            //     searchParams.set(config.stateKey, 'true');
            //     setSearchParams(searchParams, { replace: true });
            //     return () => {
            //         console.log('portal away');
            //         searchParams.delete(config.stateKey);
            //         props.onClose?.();
            //         setSearchParams(searchParams, { replace: true });
            //     };
            // }, [searchParams, setSearchParams]);
            return <Portal containerRef={context.overlayRef}>{props.children}</Portal>;
        },
    };
}
