import { useColorMode } from '@chakra-ui/react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useAccountContextV2 } from '../../context';
import { assert } from '../../util/assert';
import { useSessionApi } from '../../app';
import {
    OnboardingAccountRouteConfig,
    OnboardingAssetRouteConfig,
} from './onboardingRouteConfig';
import {
    OnboardingAssetController,
    OnboardingOrganizationController,
} from './onboardingRouteInterface';
import {
    OnboardingAssetRouteParamsSchema,
    OnboardingAssetRouteSearchParams,
    OnboardingOrganizationRouteSearchParams,
} from './onboardingRouteSchema';
import {
    OnboardingAccountRouteStrategy,
    OnboardingAssetRouteStrategy,
} from './strategy';

export function createOnboardingOrganizationRouteController(
    config: OnboardingAccountRouteConfig,
    instances: Array<OnboardingAccountRouteStrategy>
): OnboardingOrganizationController {
    const { hook, service } = config;
    return {
        useProps(props) {
            const session = useSessionApi();
            const { colorMode, setColorMode } = useColorMode();

            const [searchParams, setSearchParams] = useSearchParams();

            const mapped = [...searchParams.entries()].reduce(
                (acc, [key, value]) => ({ ...acc, [key]: value }),
                {} as Record<string, unknown>
            );

            // this is only safe because al the query parameters are optional
            const parsed = OnboardingOrganizationRouteSearchParams.parse(mapped);

            const handleSignout = async () => {
                if (colorMode === 'dark') {
                    setColorMode('light');
                }

                // this is a hack to fix this issue
                // https://github.com/chakra-ui/chakra-ui/issues/5925
                if (localStorage.getItem('chakra-ui-color-mode') === 'dark') {
                    localStorage.setItem('chakra-ui-color-mode', 'light');
                }

                await session.signout();
                // TODO: for some reason navigate does not work properly here
                window.location.href = '/login';
                // navigate('/login', { replace: true });
            };

            return {
                workspace: null,
                backPath:
                    !parsed.required && parsed.cancel_path ? parsed.cancel_path : null,
                onSignout: handleSignout,
                selected: parsed.step ?? instances[0].id,
            };
        },
    };
}

export function createOnboardingAssetRouteController(
    config: OnboardingAssetRouteConfig,
    instances: Array<OnboardingAssetRouteStrategy>
): OnboardingAssetController {
    const { hook, service } = config;
    return {
        useProps(props) {
            const session = useSessionApi();
            const { colorMode, setColorMode } = useColorMode();

            const {
                data: {
                    // workspaces: { data: workspaces = [] },
                    workspaces,
                },
            } = useAccountContextV2();

            const [searchParams, setSearchParams] = useSearchParams();
            const params = useParams();

            const mapped = [...searchParams.entries()].reduce(
                (acc, [key, value]) => ({ ...acc, [key]: value }),
                {} as Record<string, unknown>
            );

            // this is only safe because al the query parameters are optional
            const parsed = OnboardingAssetRouteSearchParams.parse(mapped);
            const parsedParams = OnboardingAssetRouteParamsSchema.parse(params);

            // useEffectOnce(() => {
            //     if (!parsed.step) {
            //         searchParams.set('step', instances[0].id);
            //         setSearchParams(searchParams, { replace: true });
            //     }
            // });

            const handleSignout = async () => {
                if (colorMode === 'dark') {
                    setColorMode('light');
                }

                // this is a hack to fix this issue
                // https://github.com/chakra-ui/chakra-ui/issues/5925
                if (localStorage.getItem('chakra-ui-color-mode') === 'dark') {
                    localStorage.setItem('chakra-ui-color-mode', 'light');
                }

                await session.signout();
                // TODO: for some reason navigate does not work properly here
                window.location.href = '/login';
                // navigate('/login', { replace: true });
            };

            // console.log('workspaces', workspaces);

            // console.log('PARSED', parsed, workspaces);

            // console.log('controller data', data, props);

            const workspace = workspaces.find(
                (workspace) => Number(workspace.id) === parsedParams.workspaceId
            );

            assert(workspace, `workspace ${parsedParams.workspaceId} not found`);

            return {
                workspace,
                backPath:
                    !parsed.required && parsed.cancel_path ? parsed.cancel_path : null,
                onSignout: handleSignout,
                selected: parsed.step ?? instances[0].id,
            };
        },
    };
}
