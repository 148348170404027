import { assert } from '@varos/util-typescript';
import { BaseApplicationShellControllerConfig } from '../../../base';
import {
    ApplicationShellContext,
    ApplicationShellContextController,
} from './applicationContextInterface';

export function createApplicationContextController(
    config: BaseApplicationShellControllerConfig
): ApplicationShellContextController {
    return {
        useProps(item, props) {
            const auth = config.hook.useAuth();
            assert(auth.user?.id, 'missing user id');
            const value: ApplicationShellContext = item.asset
                ? {
                      kind: 'workspace',
                      principal: {
                          kind: 'user',
                          id: auth.user.id,
                          account: 'acct_adasasdas',
                      },
                      organization: null,
                      asset: item.asset,
                      auth,
                  }
                : {
                      kind: 'organization',
                      principal: {
                          kind: 'user',
                          id: auth.user.id,
                          account: 'acct_adasasdas',
                      },
                      organization: null,
                      auth,
                  };
            return {
                provider: {
                    value,
                },
            };
        },
    };
}
