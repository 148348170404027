import { TagProps } from '@chakra-ui/react';
import {
    AnyVisualization,
    LineVisualizationResult,
} from '../../../../domain/visualization';
import { ValueFormatter } from '../../../../app/attributes';
import { ChartSeriesProps, LineChartControllerProps } from '../../../../ui';
import { AccountScope } from '../../../../app';
import { LineVisualizationResultViewProps } from './lineProps';
import { buildLegendProps } from '../legend';
import { VisualizationResultDateProps } from '../date';
import moment from 'moment';
import { VisualizationResultRouter } from '../resultInterface';

export function buildLineVisualizationViewProps(
    // router: VisualizationResultRouter,
    formatter: ValueFormatter,
    // context: OrganizationContextV2,
    visualization: AnyVisualization,
    item: LineVisualizationResult
): LineVisualizationResultViewProps {
    const COLORS: TagProps['colorScheme'][] = ['purple', 'green'];
    const [sample] = item.series;

    const labelByValue = item.series.reduce(
        (acc, series) =>
            series.data.reduce(
                (acc, entry) =>
                    entry.value === null
                        ? acc
                        : {
                              ...acc,
                              [entry.value]: formatter.format(item.type, entry.value),
                          },
                acc
            ),
        {} as Record<number, string | undefined>
    );

    const xAxisLabelsShort = sample.data.map((item) =>
        formatter.format('date', item.date, { notation: 'short' })
    );

    const xAxisLabelByValue = sample.data.reduce((acc, entry, index) => {
        const label = xAxisLabelsShort[index];
        const start = entry.date;
        const end = moment(start).add(1, visualization.granularity).toDate();
        return {
            ...acc,
            [label]: formatter.format(
                'daterange',
                {
                    from: start,
                    to: end,
                },
                { notation: 'long' }
            ),
        };
    }, {} as Record<string, string | undefined>);

    const chartProps: LineChartControllerProps = {
        labels: xAxisLabelsShort,
        series: item.series.map((series, index): ChartSeriesProps => {
            return {
                name: series.name,
                data: series.data.map((row) => ({ value: row.value })),
                colorScheme: COLORS[index % item.series.length] ?? 'gray',
            };
        }),
        formatValue(value) {
            const label = labelByValue[value];
            if (label === undefined) {
                // fallback to handle dynamic axes
                return formatter.format(item.type, value);
            }
            return label;
        },
        formatRange(value, options) {
            if (options.format === 'short') {
                // the value has already been formatted
                return value;
            }
            const label = xAxisLabelByValue[value];
            return label ?? value;
        },
        formatTooltip(value) {
            const label = labelByValue[value];
            if (label === undefined) {
                // fallback to handle dynamic axes
                return formatter.format(item.type, value);
            }
            return label;
        },
    };

    const legendProps = buildLegendProps(visualization, item, { colors: COLORS });

    const dateProps: VisualizationResultDateProps = {
        period: {
            label: formatter.format('daterange', item.period, {
                notation: 'short',
            }),
        },
    };

    return {
        kind: 'line',
        getDateProps() {
            return dateProps;
        },
        getLegendProps() {
            return legendProps;
        },
        getChartProps() {
            return chartProps;
        },
    };
}
