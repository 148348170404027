import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { collectionFromQuery, lookupFromQuery } from '../../../base';
import { AccountScope } from '../../platform';
import { GroupAdapter } from './groupAdapter';
import { GroupRepository } from './groupInterface';
import {
    GroupCreatePayload,
    GroupLookupQuery,
    GroupFindQuery,
    GroupRemovePayload,
} from './groupQuery';
import { GroupEntity } from '../../../domain/groups';
import { GroupCreatePayloadV2 } from '../../../api';

export function createGroupRepository(adapter: GroupAdapter): GroupRepository {
    function getReadPrefix(context: AccountScope, query: GroupFindQuery) {
        return ['groups', context.account.id, query];
    }
    function getLookupPrefix(context: AccountScope, query: GroupLookupQuery) {
        return ['groups', context.account.id, query];
    }
    function getWritePrefix(context: AccountScope, command: GroupCreatePayload) {
        return ['groups', context.account.id, command];
    }
    return {
        useFind(context, query, options) {
            const queryClient = useQueryClient();
            const applied: GroupFindQuery = {
                page_size: query.page_size ?? 20,
                ...query,
            };
            const data = useQuery({
                queryKey: [...getReadPrefix(context, applied)],
                async queryFn() {
                    const response = await adapter.find(context, applied);
                    return response;
                },
                refetchOnWindowFocus: false,
                staleTime: Infinity,
                onSuccess(data) {
                    for (const group of data.items) {
                        const queryKey = getLookupPrefix(context, group);
                        queryClient.setQueryData(queryKey, group);
                    }
                },
                ...options,
            });
            return data;
        },
        useLookup(context, query, options) {
            const data = useQuery({
                queryKey: [...getLookupPrefix(context, query)],
                async queryFn() {
                    const response = await adapter.lookup(context, query);
                    return response;
                },
                refetchOnWindowFocus: false,
                ...options,
            });
            return data;
        },
        useCreate(context, options) {
            const client = useQueryClient();
            const mutationState = useMutation<GroupEntity, Error, GroupCreatePayload>({
                mutationFn(payload) {
                    return adapter.create(context, payload);
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(['groups']);
                },
            });
            return mutationState;
        },
        useRemove(context, options) {
            const client = useQueryClient();
            const mutationState = useMutation<void, Error, GroupRemovePayload>({
                mutationFn(payload) {
                    return adapter.remove(context, payload);
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(['groups']);
                },
            });
            return mutationState;
        },
    };
}
