import { capitalize } from 'lodash';
import { BaseApplicationShellControllerConfig } from '../../base';
import { ApplicationAccountController } from './applicationAccountInterface';
import { ApplicationAccountViewProps } from './applicationAccountProps';

export function createApplicationAccountController(
    config: BaseApplicationShellControllerConfig
): ApplicationAccountController {
    const {
        kernel: { controller },
        legacy: {
            controller: { invite: inviteController },
        },
    } = config;

    const logoutController = config.kernel.provider.createActionController({
        id: 'logout',
    });

    return {
        useProps(context, item, props): ApplicationAccountViewProps {
            const browser = controller.browser.useProps({});
            const logout = logoutController.useProps(props.action.logout);
            const invite = inviteController.useProps(context, props.invite);
            return {
                browser,
                logout,
                invite: {
                    view: invite,
                    trigger: {
                        button: {
                            onClick() {
                                props.invite.onOpen();
                            },
                        },
                    },
                },
                actions: {
                    items: props.actions.map((item) => ({
                        ...item,
                        link: { to: item.path },
                    })),
                },
                account: {
                    label: item.me.firstName
                        ? [item.me.firstName, item.me.lastName].filter(Boolean).join(' ')
                        : item.me.email,
                    role: capitalize(item.me.account.role),
                },
                drawer: {
                    isOpen: props.disclosure.isOpen,
                    onOpen: props.disclosure.onOpen,
                    onClose: props.disclosure.onClose,
                },
            };
        },
    };
}
