import React from 'react';
import { Navigate, Outlet, Route } from 'react-router';
import { ReportRouterConfig, ReportRouterInit } from './reportRouterConfig';
import { ReportRouter, ReportRouterEnhancer } from './reportRouterInterface';
import {
    ReportContainerBaseConfig,
    ReportContextBaseConfig,
    ReportControllerBaseConfig,
    ReportLoaderBaseConfig,
    ReportViewBaseConfig,
} from './base';
import {
    createReportErrorContainer,
    createReportErrorController,
    createReportErrorView,
    createReportInstanceDetailContainer,
    createReportInstanceDetailContentController,
    createReportInstanceDetailLoader,
    createReportInstanceDetailContentView,
    createReportLayoutContainer,
    createReportLayoutController,
    createReportLayoutLoader,
    createReportLayoutStateProvider,
    createReportLayoutView,
    createReportRootContainer,
    createReportInstanceDetailController,
    createReportInstanceDetailContentContainer,
    createReportInstanceDetailContentLoader,
    createReportInstanceDetailStatusContainer,
    createReportInstanceDetailStatusController,
    createReportInstanceDetailStatusView,
    createReportNewContainer,
    createReportNewLoader,
    createReportNewController,
    createReportNewView,
    ReportNewUrlProps,
} from './page';
import { createReportContextProvider } from './page';
import {
    createReportCategoryChartContainer,
    createReportCategoryChartController,
    createReportInsightContainer,
    createReportInsightController,
} from './view';
import {
    createReportTrendChartContainer,
    createReportTrendChartController,
} from './view/chart';

export function createReportRouter(
    config: ReportRouterInit,
    enhancer?: ReportRouterEnhancer
) {
    return create({ ...config }, enhancer);
}

function create(
    config: ReportRouterConfig,
    enhancer?: ReportRouterEnhancer
): ReportRouter {
    if (enhancer) {
        return enhancer(create)(config);
    }

    const contextConfig: ReportContextBaseConfig = {
        hook: config.hook,
    };

    // const TabLayout = config.layout.tab.create({
    //     Theme: {
    //         Desktop() {
    //             return <></>;
    //         },
    //         Mobile() {
    //             return <></>;
    //         },
    //     },
    // });

    const Context = {
        Root: createReportContextProvider(contextConfig),
    };

    const containerConfig: ReportContainerBaseConfig = {
        context: Context.Root,
        kernel: config.kernel,
        repository: config.repository,
        UI: config.UI,
    };

    const loaderConfig: ReportLoaderBaseConfig = {
        repository: config.repository,
    };

    const controllerConfig: ReportControllerBaseConfig = {
        navigation: {
            report: {
                content(props) {
                    return {
                        to: `/u/reports/${props.slug}`,
                    };
                },
                customize(props) {
                    const options: ReportNewUrlProps = {
                        template: props.slug,
                    };
                    const params = new URLSearchParams(Object.entries(options));
                    return {
                        to: `/u/reports/new?${params.toString()}`,
                    };
                },
            },
        },
        kernel: config.kernel,
        theme: {
            colors: [
                'red.400',
                'orange.400',
                'yellow.400',
                'indigo.400',
                'blue.400',
                'green.400',
                'teal.400',
            ],
        },
    };

    const viewConfig: ReportViewBaseConfig = {
        UI: config.UI,
        Layout: createReportLayoutContainer(
            containerConfig,
            createReportLayoutStateProvider(containerConfig),
            createReportLayoutLoader(),
            createReportLayoutController(controllerConfig),
            createReportLayoutView({
                UI: config.UI,
            })
        ),
    };

    const Routes = {
        Root: createReportRootContainer(containerConfig, {
            Error: createReportErrorContainer(
                containerConfig,
                createReportErrorController(),
                createReportErrorView(viewConfig)
            ),
        }),
        Report: {
            New: createReportNewContainer(
                containerConfig,
                createReportNewLoader(loaderConfig),
                createReportNewController(controllerConfig),
                createReportNewView(viewConfig)
            ),
            Detail: createReportInstanceDetailContainer(
                containerConfig,
                createReportInstanceDetailLoader(loaderConfig),
                createReportInstanceDetailController(controllerConfig),
                {
                    Content: createReportInstanceDetailContentContainer(
                        containerConfig,
                        createReportInstanceDetailContentLoader(loaderConfig),
                        createReportInstanceDetailContentController(controllerConfig),
                        createReportInstanceDetailContentView({
                            ...viewConfig,
                            Container: {
                                CategoryChart: createReportCategoryChartContainer(
                                    containerConfig,
                                    createReportCategoryChartController(controllerConfig)
                                ),
                                TrendChart: createReportTrendChartContainer(
                                    containerConfig,
                                    createReportTrendChartController(controllerConfig)
                                ),
                                Insight: createReportInsightContainer(
                                    containerConfig,
                                    createReportInsightController(controllerConfig)
                                ),
                            },
                        })
                    ),
                    Status: createReportInstanceDetailStatusContainer(
                        containerConfig,
                        createReportInstanceDetailStatusController(controllerConfig),
                        createReportInstanceDetailStatusView(viewConfig)
                    ),
                }
            ),
        },
    };

    return {
        element: (
            <Route
                path={config.mount}
                element={
                    <Context.Root.Provider>
                        <Routes.Root>
                            <Outlet />
                        </Routes.Root>
                    </Context.Root.Provider>
                }
            >
                <Route index={true} element={<Navigate to="/u" replace={true} />} />
                <Route path="new" element={<Routes.Report.New />} />
                <Route path=":reportId" element={<Routes.Report.Detail />} />
            </Route>
        ),
    };
}
