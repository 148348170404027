import { assert } from '@varos/util-typescript';
import React, { useContext } from 'react';
import {
    RegistrationAcceptContextProvider,
    RegistrationInvitationContext,
} from './acceptContextInterface';

export function createAcceptContextProvider(): RegistrationAcceptContextProvider {
    const Context = React.createContext<RegistrationInvitationContext | null>(null);
    return {
        Provider: Context.Provider,
        useContext() {
            const value = useContext(Context);
            assert(value, 'not inside accept context');
            return value;
        },
    };
}
