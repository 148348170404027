import React, { useMemo } from 'react';
import { Icon, Tooltip } from '@chakra-ui/react';
import { LockIcon } from '@chakra-ui/icons';
import { useAuthenticatedContext } from '../../../../container';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { useFeatureApi } from '../../../app';
import { AssetListController } from '../../../view/assets';
import { FeatureMiddleware } from '../featureInterface';
import {
    ApplicationWorkspaceController,
    ApplicationWorkspaceControllerProps,
    ApplicationWorkspaceListAggregate,
    ApplicationWorkspaceViewProps,
    ShellLayoutEnhancer,
} from '../../../shell';

export function createFeatureMultiAssetMiddleware(): FeatureMiddleware {
    return (api) => (create) => (config) => {
        function enhanceController(controller: AssetListController): AssetListController {
            return {
                ...controller,
                useProps(...args) {
                    const [context, deps, props] = args;
                    const viewProps = controller.useProps(...args);

                    const { feature, restrict } = api.useFeature('multi_assets');

                    if (feature?.enabled) {
                        return viewProps;
                    }

                    return {
                        ...viewProps,
                        getItemProps(...args) {
                            const itemProps = viewProps.getItemProps(...args);
                            if (itemProps.isDefault) {
                                return itemProps;
                            }
                            return {
                                ...itemProps,
                                getDataSourceProps() {
                                    const sourceProps = itemProps.getDataSourceProps();
                                    if (sourceProps === null) {
                                        return sourceProps;
                                    }
                                    return { ...sourceProps, to: null };
                                },
                                getButtonTooltipProps() {
                                    return {
                                        label: 'Upgrade for multiple assets',
                                        isDisabled: false,
                                    };
                                },
                                getButtonProps() {
                                    return {
                                        ...itemProps.getButtonProps(),
                                        children: 'Manage',
                                        leftIcon: <Icon as={LockIcon} />,
                                        onClick(event) {
                                            event.preventDefault();
                                            event.stopPropagation();
                                            restrict({
                                                ...feature!,
                                                value: null,
                                            });
                                        },
                                    };
                                },
                            };
                        },
                    };
                },
            };
        }

        function createShellEnhancer(): ShellLayoutEnhancer {
            return (create) => (config) => {
                function enhanceWorkspaceController(
                    instance: ApplicationWorkspaceController
                ): ApplicationWorkspaceController {
                    return {
                        ...instance,
                        useProps(context, item, props) {
                            const { feature, restrict } = api.useFeature('multi_assets');
                            const { account } = item;
                            const disabledIds = useMemo(() => {
                                if (feature?.enabled) {
                                    return new Set([]);
                                }
                                const defaultAsset =
                                    item.workspaces.find(
                                        (candidate) =>
                                            candidate.asset.id ===
                                            account?.state.defaultAsset?.id
                                    ) ?? null;
                                return new Set(
                                    item.workspaces
                                        .filter((candidate, index) =>
                                            // for demo account there's an issue where the default asset
                                            // is not part of the assumed accoun
                                            defaultAsset
                                                ? // disable assets that are not the default asset
                                                  candidate.asset.id !==
                                                  defaultAsset.asset.id
                                                : index >= 1
                                        )
                                        .map((item) => item.asset.id.toString())
                                );
                            }, [feature?.enabled, item.workspaces]);

                            const viewProps = instance.useProps(context, item, {
                                ...props,
                                select: {
                                    ...props.select,
                                    onChange(value) {
                                        const [first] = value;
                                        if (disabledIds.has(first)) {
                                            return restrict({
                                                ...feature!,
                                                value: null,
                                            });
                                        }
                                        return props.select.onChange(value);
                                    },
                                },
                            });

                            const mappedProps =
                                useMemo((): ApplicationWorkspaceViewProps => {
                                    return {
                                        ...viewProps,
                                        items: viewProps.items.map((item, index) => {
                                            if (!disabledIds.has(item.id)) {
                                                return item;
                                            }
                                            return {
                                                ...item,
                                                disabled: {
                                                    tooltip: {
                                                        label: 'Upgrade for multiple assets',
                                                    },
                                                },
                                            };
                                        }),
                                    };
                                }, [disabledIds, viewProps]);

                            return mappedProps;
                        },
                    };
                }
                return create({
                    ...config,
                    provider: {
                        ...config.provider,
                        createWorkspaceController(...args) {
                            const instance = config.provider.createWorkspaceController(
                                ...args
                            );
                            return enhanceWorkspaceController(instance);
                        },
                    },
                });
            };
        }
        return create({
            ...config,
            enhancer: {
                ...config.enhancer,
                shell: [...config.enhancer.shell, createShellEnhancer()],
            },
            controller: {
                ...config.controller,
                assets: {
                    ...config.controller.assets,
                    createList(...args) {
                        const controller = config.controller.assets.createList(...args);
                        return enhanceController(controller);
                    },
                },
            },
        });
    };
}

export function createFeatureMultiAssetStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        return create({
            ...config,
            // module: {
            //     ...config.module,
            //     createWorkspaceSelectionModule(moduleConfig) {
            //         return config.module.createWorkspaceSelectionModule({
            //             ...moduleConfig,
            //             provider: {
            //                 ...moduleConfig.provider,
            //                 createController(controllerConfig) {
            //                     const controller =
            //                         moduleConfig.provider.createController(
            //                             controllerConfig
            //                         );
            //                     return {
            //                         useProps(context) {
            //                             const mapped = controller.useProps(context);
            //                             const appcontext = useAuthenticatedContext();
            //                             const api = useFeatureApi();
            //                             const featureset = api.getFeatureset();
            //                             const response = appcontext.access.canAccess({
            //                                 ...featureset.multi_assets!,
            //                                 value: null,
            //                             });
            //                             const singleAllowdWorkspaceId =
            //                                 React.useMemo(() => {
            //                                     if (response.restricted) {
            //                                         return (
            //                                             mapped.workspaces.find(
            //                                                 (x) =>
            //                                                     x.claim.kind === 'active'
            //                                             )?.id ?? null
            //                                         );
            //                                     }
            //                                     return null;
            //                                 }, [response.restricted, mapped.workspaces]);

            //                             return {
            //                                 ...mapped,
            //                                 // getAddButtonProps() {
            //                                 //     if (response.restricted) {
            //                                 //         return {
            //                                 //             ...mapped.getAddButtonProps?.(),
            //                                 //             allowClickWhenDisabled: true,
            //                                 //             disabled: true,
            //                                 //             tooltipProps: {
            //                                 //                 label: 'Upgrade for multiple assets',
            //                                 //             },
            //                                 //             onClick: api.restrictClick(
            //                                 //                 featureset.multi_assets!
            //                                 //             ),
            //                                 //         };
            //                                 //     }
            //                                 //     return mapped.getAddButtonProps?.() ?? {};
            //                                 // },
            //                                 getItemProps(workspace, index) {
            //                                     const original = mapped.getItemProps?.(
            //                                         workspace,
            //                                         index
            //                                     );
            //                                     if (!singleAllowdWorkspaceId) {
            //                                         return original || {};
            //                                     }
            //                                     if (
            //                                         workspace.id ===
            //                                         singleAllowdWorkspaceId
            //                                     ) {
            //                                         return (
            //                                             mapped.getItemProps?.(
            //                                                 workspace,
            //                                                 index
            //                                             ) ?? {}
            //                                         );
            //                                     }
            //                                     return {
            //                                         ...original,
            //                                         disabled: true,
            //                                         allowClickWhenDisabled: true,
            //                                         rightSection: (
            //                                             <Tooltip label="Upgrade for multiple assets">
            //                                                 <LockIcon />
            //                                             </Tooltip>
            //                                         ),
            //                                         onClick: api.restrictClick(
            //                                             featureset.multi_assets!
            //                                         ),
            //                                     };
            //                                 },
            //                             };
            //                         },
            //                     };
            //                 },
            //             },
            //         });
            //     },
            // },
            // controller: {
            //     ...config.controller,
            //     workspaceSelection: {
            //         useProps() {
            //             const context = useAuthenticatedContext();
            //             const mapped = config.controller.workspaceSelection.useProps();
            //             const api = useFeatureApi();
            //             const featureset = api.getFeatureset();
            //             const response = context.access.canAccess({
            //                 ...featureset.multi_assets,
            //                 value: null,
            //             });
            //             return {
            //                 ...mapped,
            //                 getAddButtonProps() {
            //                     if (response.restricted) {
            //                         return {
            //                             ...mapped.getAddButtonProps?.(),
            //                             allowClickWhenDisabled: true,
            //                             disabled: true,
            //                             tooltipProps: {
            //                                 label: 'Upgrade for multiple assets',
            //                             },
            //                             onClick: api.restrictClick(
            //                                 featureset.multi_assets
            //                             ),
            //                         };
            //                     }
            //                     return mapped.getAddButtonProps?.() ?? {};
            //                 },
            //                 getItemProps(workspace, index) {
            //                     if (response.restricted && index > 0) {
            //                         return {
            //                             ...mapped.getItemProps?.(workspace, index),
            //                             rightSection: (
            //                                 <Tooltip label="Upgrade for multiple assets">
            //                                     <LockIcon />
            //                                 </Tooltip>
            //                             ),
            //                             onClick: api.restrictClick(
            //                                 featureset.multi_assets
            //                             ),
            //                         };
            //                     }

            //                     return mapped.getItemProps?.(workspace, index) ?? {};
            //                 },
            //             };
            //         },
            //     },
            // },
        });
    };
}
