import { assert } from '@varos/util-typescript';
import { AssistantLoaderBaseConfig } from '../../../base';
import { ThreadNewLoader } from './threadNewInterface';

export function createConversationNewLoader(
    config: AssistantLoaderBaseConfig
): ThreadNewLoader {
    const {
        repository: { workflow: workflowRepository },
    } = config;
    return {
        useLoad(context, props) {
            const workflowQuery = workflowRepository.useLookup(context, props.workflow, {
                suspense: true,
            });
            assert(workflowQuery.status === 'success', 'expected suspense');
            return {
                workflow: workflowQuery.data,
            };
        },
    };
}
