import { createFormContainerContainer, createFormContainerController } from './container';
import {
    createFormFieldItemContainer,
    createFormFieldItemController,
    createFormFieldListContainer,
    createFormFieldListController,
} from './field';
import { FormUIConfig, FormUIInit } from './formConfig';
import { FormUI, FormUIEnhancer } from './formInterface';

export function configureFormUI(init: FormUIInit, enhancer?: FormUIEnhancer) {
    return createFormUI(
        {
            ...init,
            provider: {
                createFormController: createFormContainerController,
                createFieldListController: createFormFieldListController,
                createFieldItemController: createFormFieldItemController,
            },
        },
        enhancer
    );
}

function createFormUI(config: FormUIConfig, enhancer?: FormUIEnhancer): FormUI {
    const { Theme, provider } = config;

    if (enhancer) {
        return enhancer(createFormUI)(config);
    }

    const Container = createFormContainerContainer(
        provider.createFormController(),
        Theme.Container
    );

    const FieldList = createFormFieldListContainer(
        provider.createFieldListController(config),
        Theme.Field.List
    );

    const FieldItem = createFormFieldItemContainer(
        provider.createFieldItemController(),
        Theme.Field.Item
    );

    return {
        Container,
        FieldList: FieldList,
        FieldItem: FieldItem,
        Input: Theme.Input,
        InputGroup: Theme.InputGroup,
        InputLeftAddon: Theme.InputLeftAddon,
        InputRightAddon: Theme.InputRightAddon,
        Select: Theme.Select,
    };
}
