import { chain } from 'lodash';
import pluralize from 'pluralize';
import { useMemo } from 'react';
import { assert } from '../../../../util/assert';
import { buildSubmissionItemViewProps, SubmissionItemViewProps } from '../item';
import { SubmissionListControllerConfig } from './submissionListConfig';
import { SubmissionListController } from './submissionListInterface';

export function createSubmissionListController(
    config: Pick<SubmissionListControllerConfig, 'infra'>
): SubmissionListController {
    const {
        infra: { formatter },
    } = config;
    return {
        useProps(context, item) {
            const items = useMemo(() => chain(item.items).value(), [item.items]);

            const itemPropsById = useMemo(
                () =>
                    items.reduce(
                        (acc, item) => ({
                            ...acc,
                            [item.submission.id]: buildSubmissionItemViewProps(
                                formatter,
                                context,
                                item
                            ),
                        }),
                        {} as Record<string, SubmissionItemViewProps | undefined>
                    ),
                [context, items]
            );

            const collection = useMemo(() => {
                const count = items.length;
                const plural = pluralize('submission', count);
                return {
                    kind: 'loaded',
                    label: `${count} ${plural} found`,
                } as const;
            }, [items]);

            return {
                items,
                getItemProps(item) {
                    const itemProps = itemPropsById[item.submission.id];
                    assert(itemProps, `no item props`);
                    return itemProps;
                },
                collection,
                emptyState:
                    items.length > 0
                        ? null
                        : {
                              title: 'No submissions found',
                              description: `You have not yet submitted any studies`,
                              linkProps: {
                                  to: context.workspace ? `/u/assets/${context.workspace.id}/reporting/reports?kind=study`: `/u/account/reporting/reports?kind=study`,
                              },
                              buttonProps: {
                                  children: 'Explore studies',
                              },
                          },
            };
        },
    };
}
