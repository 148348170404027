import { useQuery, useQueryClient } from '@tanstack/react-query';
import { normalizeCollection } from '../../../base';
import { isWorkspaceContext } from '../../../app';
import { ReportInvitationAdapter } from './reportInvitationAdapter';
import { ReportInvitationRepository } from './reportInvitationInterface';
import { ReportInvitationQuery } from './reportInvitationQuery';

export function createReportInvitationRepository(
    adapter: ReportInvitationAdapter
): ReportInvitationRepository {
    const PREFIX = ['report', 'invitation'];
    return {
        useCollection(context, query, options) {
            const applied: ReportInvitationQuery = {
                report: query.report ?? null,
                asset: isWorkspaceContext(context) ? (context.workspace.id as number): null,
            };
            const result = useQuery({
                ...options,
                queryKey: [...PREFIX, 'find', context.account.id, applied],
                async queryFn() {
                    const response = await adapter.find(context, applied);
                    return normalizeCollection(response);
                },
            });
            return result;
        },
    };
}
