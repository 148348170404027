import { AxiosInstance } from 'axios';
import { EnumMember } from './memberModel';

export async function fetchMembers(
    api: AxiosInstance,
    typeId: string,
    assetId: number | null,
    dashboard: string | null,
    options?: {
        apiKey?: string;
        account?: string;
        headers?: Record<string, string>;
    }
): Promise<Array<EnumMember>> {
    const params: Record<string, string | number> = {};
    const headers: Record<string, string> = { ...options?.headers };
    if (options?.apiKey) {
        params['api-key'] = options.apiKey;
    }
    if (dashboard) {
        params['dashboard'] = dashboard;
    }
    if (options?.account) {
        headers['x-varos-account'] = options.account.toString();
    }
    if(assetId) {
        params['asset_id'] = assetId;
    }
    const response = await api.get<Array<EnumMember>>(`/api/v2/types/members`, {
        headers,
        params: {
            ...params,
            asset_id: assetId,
            type_id: typeId,
        },
    });
    return response.data;
}
