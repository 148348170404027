import React from 'react';
import { Graphics } from '../../../../../../../../config/svg';
import { RegistrationViewConfig } from '../../../../../../base';
import {
    Box,
    Heading,
    HStack,
    Icon,
    Image,
    StackDivider,
    Text,
    VStack,
} from '@chakra-ui/react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { AcceptWelcomeViewProps } from '../../../fragment/welcome/acceptWelcomeProps';

export function createRegistrationAcceptWelcomeDesktopView(
    config: RegistrationViewConfig
): React.FC<AcceptWelcomeViewProps> {
    const {
        UI: {
            Application: { Button },
        },
    } = config;
    return (props) => {
        return (
            <VStack
                align="center"
                justify="center"
                borderRadius="lg"
                py={6}
                px={12}
                spacing={12}
                h="full"
                divider={<StackDivider borderColor="gray.200" borderWidth={1} />}
            >
                <VStack align="center" spacing={6} position="relative" top={-24}>
                    <Image w={10} h={10} alt="brand" src={Graphics.Brand.Icon} />
                    <VStack w="full" spacing={3}>
                        <Heading color="black">{props.page.header.title}</Heading>
                        {props.page.header.description && (
                            <Text
                                fontSize="lg"
                                fontWeight="medium"
                                color="blackAlpha.700"
                            >
                                {props.page.header.description}
                            </Text>
                        )}
                    </VStack>
                    <HStack w="full" justify="center" maxW="72%">
                        <Button
                            {...props.button}
                            size="lg"
                            w="full"
                            colorScheme="green"
                            rightIcon={<Icon as={AiOutlineArrowRight} />}
                        >
                            Get started
                        </Button>
                    </HStack>
                </VStack>
            </VStack>
        );
    };
}
