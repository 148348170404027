import { WarningIcon } from '@chakra-ui/icons';
import { capitalize } from 'lodash';
import React, { useEffect } from 'react';
import { isRelativePeriod } from '../../../../domain';
import { ApplicationError } from '../../../base';
import { createEmail } from '../../../vendor';
// @ts-expect-error
import CSS from '!!raw-loader!./emailReportView.css';
import { EmailReportViewProps } from './emailReportProps';
import { EmailReportConfig } from './emailReportConfig';

const Email = createEmail({
    css: CSS,
    frameName: 'template',
});

export function createEmailReportView(
    config: EmailReportConfig
): React.FC<{ children?: React.ReactNode | undefined } & EmailReportViewProps> {
    const {
        ui: {
            EmptyState,
            Table,
            Tbody,
            Tr,
            Td,
            Button,
            Text,
            Heading,
            Link,
            createErrorBoundary,
        },
        component: {
            Chart: { Table: TableVisualization },
        },
    } = config;
    const ErrorBoundary = createErrorBoundary({
        errorClass: ApplicationError,
    });
    return (props) => {
        const {
            formatPeriod = ({ start, end }) =>
                `${start.toISOString()} - ${end.toISOString()}`,
            formatError = (error) => ({ name: error.name, description: error.message }),
        } = props;
        if (props.report.status === 'empty') {
            return (
                <Email.Html id="template" className="template-container">
                    <Email.Meta
                        title={[
                            `${props.report.title} (${formatPeriod(
                                props.report.absolute
                            )})`,
                        ]}
                        metadata={props.metadata}
                    />
                    <Email.Container className="container">
                        <div id="container-bar" />
                        <Email.Section className="section">
                            <Email.Image
                                style={{ margin: '0 auto' }}
                                width={120}
                                height={40}
                                src="https://cdn.varos.io/assets/common/varos-logo-text.png"
                            />

                            <Email.Heading level={3}>
                                {formatPeriod(props.report.absolute)} &#x2022;{' '}
                                {isRelativePeriod(props.report.period) ? (
                                    <>
                                        {capitalize(props.report.period.interval)} in
                                        review &#x2022;{' '}
                                    </>
                                ) : null}
                                {props.workspace.name}
                            </Email.Heading>
                        </Email.Section>
                        <Email.Divider className="divider" />
                        <Email.Section>
                            <EmptyState
                                title={`You don't have any active integrations`}
                                description="Connect an active account with Varos to receive
                scheduled reports directly in your inbox"
                            >
                                <Link
                                    className="button button-cta"
                                    to={props.emptyStateHref}
                                >
                                    Connect an Account
                                </Link>
                            </EmptyState>
                        </Email.Section>
                    </Email.Container>
                </Email.Html>
            );
        }
        return (
            <Email.Html id="template" className="template-container">
                <Email.Meta
                    title={[
                        `${props.report.title} (${formatPeriod(props.report.absolute)})`,
                    ]}
                    metadata={props.metadata}
                />
                <Email.Container className="container">
                    <div id="container-bar" />
                    <Email.Section className="section">
                        <Email.Image
                            style={{ margin: '0 auto' }}
                            width={120}
                            height={40}
                            src="https://cdn.varos.io/assets/common/varos-logo-text.png"
                        />

                        <Email.Heading level={3}>
                            {formatPeriod(props.report.absolute)} &#x2022;{' '}
                            {isRelativePeriod(props.report.period) ? (
                                <>
                                    {capitalize(props.report.period.interval)} in review{' '}
                                    &#x2022;{' '}
                                </>
                            ) : null}
                            {props.workspace.name}
                        </Email.Heading>
                    </Email.Section>
                    <Email.Divider className="divider" />
                    {props.insightItems.length > 0 && (
                        <Email.Section>
                            <Email.Row>
                                <Email.Column spacing={24}>
                                    <Email.Row width="100%">
                                        <Email.Column>
                                            <Email.Heading level={2}>
                                                Opportunities
                                            </Email.Heading>
                                        </Email.Column>
                                    </Email.Row>
                                    <Email.Row>
                                        <Email.Column spacing={24}>
                                            {props.insightItems.map((item) => {
                                                const itemProps =
                                                    props.getInsightProps(item);
                                                return (
                                                    <Email.Row key={item.insight.id}>
                                                        <Email.Column spacing={8}>
                                                            <Email.Row width="inherit">
                                                                {item.plugin.iconUrl && (
                                                                    <Td>
                                                                        <table>
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td
                                                                                        style={{
                                                                                            borderRadius: 3,
                                                                                            padding: 8,
                                                                                            background:
                                                                                                '#eaeaea',
                                                                                            lineHeight: 0,
                                                                                        }}
                                                                                    >
                                                                                        <Email.Image
                                                                                            width={
                                                                                                24
                                                                                            }
                                                                                            height={
                                                                                                24
                                                                                            }
                                                                                            src={
                                                                                                item
                                                                                                    .plugin
                                                                                                    .iconUrl
                                                                                            }
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table>
                                                                    </Td>
                                                                )}
                                                                <Td
                                                                    style={{
                                                                        paddingLeft: 12,
                                                                    }}
                                                                >
                                                                    <Email.Text
                                                                        style={{
                                                                            fontWeight: 500,
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.insight
                                                                                .title
                                                                        }
                                                                    </Email.Text>
                                                                </Td>
                                                                <Td></Td>
                                                            </Email.Row>
                                                            <Email.Row>
                                                                <Email.Column>
                                                                    <Email.Text
                                                                        style={{
                                                                            color: '#8a8997',
                                                                        }}
                                                                    >
                                                                        {
                                                                            item.insight
                                                                                .body
                                                                        }
                                                                    </Email.Text>
                                                                </Email.Column>
                                                            </Email.Row>
                                                            <Email.Row>
                                                                <Email.Column>
                                                                    <Email.Link
                                                                        style={{
                                                                            color: '#3182ce',
                                                                            fontWeight: 500,
                                                                        }}
                                                                        href={
                                                                            itemProps.path
                                                                        }
                                                                    >
                                                                        Open
                                                                    </Email.Link>
                                                                </Email.Column>
                                                            </Email.Row>
                                                        </Email.Column>
                                                    </Email.Row>
                                                );
                                            })}
                                        </Email.Column>
                                    </Email.Row>
                                </Email.Column>
                            </Email.Row>
                            <Email.Row>
                                <Email.Column align="center">
                                    <Email.Link
                                        className="button button-cta"
                                        href={props.insightButtonHref}
                                    >
                                        {props.insightButtonLabel}
                                    </Email.Link>
                                </Email.Column>
                            </Email.Row>
                        </Email.Section>
                    )}
                    {props.report.blocks.map((block, index) => (
                        <Email.Section key={index} className="section">
                            <ErrorBoundary
                                fallback={({ error }) => {
                                    console.log('falling back');
                                    const info = formatError(error);
                                    return (
                                        <EmptyState
                                            title={info.name}
                                            description={info.description}
                                        >
                                            <Link to="/">Update competitive set</Link>
                                        </EmptyState>
                                    );
                                }}
                            >
                                <TableVisualization
                                    className="visualization image-container"
                                    segments={props.report.segments}
                                    metrics={block.metrics}
                                    control={props.control}
                                />
                                <Table
                                    className="visualization"
                                    style={{ width: '100%' }}
                                >
                                    <Tbody>
                                        {props.report.dependencies
                                            .filter(
                                                (dependency) =>
                                                    dependency.status === 'disconnected'
                                            )
                                            .map((dependency) => (
                                                <Tr key={dependency.dashboard.id}>
                                                    <Td>
                                                        <div
                                                            style={{
                                                                // TODO this styling should be extracted out of the component
                                                                // so that it matches the visualization table
                                                                paddingTop: 8,
                                                                paddingBottom: 8,
                                                                display: 'flex',
                                                            }}
                                                        >
                                                            <Email.Image
                                                                width={24}
                                                                height={24}
                                                                src={
                                                                    dependency.dashboard
                                                                        .iconUrl ??
                                                                    undefined
                                                                }
                                                            />
                                                            <div
                                                                style={{
                                                                    marginLeft: 12,
                                                                }}
                                                            >
                                                                <p>
                                                                    {
                                                                        dependency
                                                                            .dashboard
                                                                            .name
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </Td>
                                                    <Td>
                                                        <a
                                                            href={
                                                                props.getDependencyProps(
                                                                    dependency
                                                                ).path
                                                            }
                                                        >
                                                            <Button
                                                                // leftIcon={
                                                                //     <WarningIcon color="red.400" />
                                                                // }
                                                                colorScheme="red"
                                                                variant="outline"
                                                            >
                                                                Reconnect
                                                            </Button>
                                                        </a>
                                                    </Td>
                                                    <Td></Td>
                                                </Tr>
                                            ))}
                                    </Tbody>
                                </Table>
                                {props.report.status !== 'empty' && (
                                    <Email.Section className="section">
                                        <Email.Link
                                            className="button button-cta"
                                            href={props.reportHref}
                                        >
                                            Go deeper
                                        </Email.Link>
                                    </Email.Section>
                                )}
                            </ErrorBoundary>
                        </Email.Section>
                    ))}
                </Email.Container>
            </Email.Html>
        );
    };
}
