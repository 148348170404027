import { PageIds } from '../../../../config';
import { PageAlertProps } from '../../../../view/page';
import { SettingAssetItemProviderConfig } from '../../../../route';
import { SettingAssetProfileConfig } from './profileConfig';
import { SettingAssetProfileController } from './profileInterface';
import { isAssetOwner } from '../../../../domain/assets';

export function createSettingAssetProfileController(
    init: Pick<SettingAssetProfileConfig, 'controller'>,
    config: SettingAssetItemProviderConfig
): SettingAssetProfileController {
    const {
        controller: {
            asset: { edit: editController, claim: claimController },
        },
    } = init;
    const {
        api: { controller: pageController },
    } = config;
    return {
        useProps(context, deps, props) {
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.SETTINGS_ASSET_GENERAL_SETTINGS,
                    breadcrumbs: [
                        {
                            label: 'General',
                        },
                    ],
                },
            });
            const editProps = editController.useProps(context, deps.edit, props);
            const claimProps = claimController.useProps(context, deps.evidence, props);
            return {
                getClaimProps () {
                    return claimProps;
                },
                getEditProps() {
                    return editProps;
                },
                getPageProps() {
                    return pageProps;
                },
                getFormControlProps(key) {
                    const controlProps = editProps.getFormControlProps(key);
                    const errorProps = editProps.getErrorMessageProps(key);
                    return {
                        isInvalid: controlProps.isInvalid,
                        error: errorProps,
                    };
                },
                getAlertProps(): PageAlertProps {
                    const alertProps = pageProps.getAlertProps();
                    const editAlertProps = editProps.getAlertProps();
                    return {
                        ...alertProps,
                        items: editAlertProps
                            ? [
                                  ...alertProps.items,
                                  {
                                      status: editAlertProps.status,
                                      label: editAlertProps.label,
                                  },
                              ]
                            : alertProps.items,
                    };
                },
            };
        },
    };
}
