import { ModalIds } from '../../../../config';
import { PlanModalController } from './planModalInterface';
import { PlanModalConfig, PlanModalControllerConfig } from './planModalConfig';
import { useLocation } from 'react-router';

export function createPlanModalController(
    config: PlanModalControllerConfig
): PlanModalController {
    const {
        getIntercom,
        controller: {
            plan: { select: selectController },
            modal: modalController,
        },
    } = config;
    return {
        useProps(context, deps, props) {
            const location = useLocation();
            const modalProps = modalController.useProps(deps.modal, {
                id: ModalIds.PAYWALL,
                trigger: null,
            });
            const selectProps = selectController.useProps(context, {
                item: props.item.select,
                value: props.item.select.subscription.plan,
                onChange(value) {
                    if (location.pathname.includes('onboarding')) {
                        // HACK there is a bug where the subscription modal is not closable when navigating from onboarding
                        window.open(`/u/settings/subscription?plan=${value}`, '_blank');
                    } else {
                        deps.navigate(`/u/settings/subscription?plan=${value}`);
                    }
                    modalProps.containerProps.onClose();
                },
            });
            return {
                isAddOnRequired: props.isAddOnRequired,
                modalProps,
                selectProps,
                settingsLinkProps: {
                    id: 'paywall_modal_manage_subscription_link',
                    to: '/u/settings/subscription',
                    onClick() {
                        modalProps.containerProps.onClose();
                    },
                },
                contactButtonProps: {
                    onClick() {
                        getIntercom()?.('show');
                    },
                },
            };
        },
    };
}
