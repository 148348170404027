import { ReportSdk } from '@varos/report-sdk';
import { ReportInstanceCreateProps } from './reportInstanceProps';
import { ReportInstanceRepository } from './reportInstanceInterface';
import { Kernel } from '../../../base';

export function createReportInstanceRepository(
    kernel: Kernel,
    api: ReportSdk
): ReportInstanceRepository {
    const {
        infra: { repository },
    } = kernel;
    const PREFIX = ['report', 'instance'];
    return {
        useLookup(context, props, options) {
            const mutation = repository.useQuery({
                ...options,
                queryKey: [...PREFIX, context.principal?.id, props],
                async queryFn() {
                    const response = await api.reportRetrieve({
                        reportId: props.report.id,
                    });
                    return response.data;
                },
            });
            return mutation;
        },
        useCreate(context) {
            const mutation = repository.useMutation(context, {
                async mutationFn(props: ReportInstanceCreateProps) {
                    throw new Error('not impl');
                },
            });
            return mutation;
        },
    };
}
