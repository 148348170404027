import { AssetCreateProps } from '../../../domain/assets';
import { AccountProfileConfig } from './profileConfig';
import { shouldAutoCreateAsset } from './profileHelper';
import { AccountProfileController } from './profileInterface';
import { AccountProfileViewProps } from './profileProps';

export function createAccountProfileController(
    config: AccountProfileConfig
): AccountProfileController {
    return {
        useProps(data, form, props): AccountProfileViewProps {
            const values = form.watch();
            // console.log('data sub', data.billing.subscription.data);
            return {
                getStatusProps() {
                    return {
                        status: [data.profile.lookup.status].every(
                            (status) => status === 'success'
                        )
                            ? 'ready'
                            : 'loading',
                    };
                },
                getFormProps() {
                    return {
                        value: values,
                        error: data.profile.upsert.error,
                        async onSubmit(values) {
                            const response = await data.profile.upsert.mutateAsync({
                                firstname: values.firstname,
                                lastname: values.lastname,
                                account: {
                                    kind: 'organization',
                                    organizationName: values.companyName,
                                    organizationType: values.isAgency
                                        ? 'ad_agency'
                                        : 'website',
                                    domain: values.companyWebsite,
                                    finishedOnboarding: false,
                                    profile: {
                                        industry: 'commerce',
                                        tags: {}
                                    }
                                },
                            });

                            // if (shouldAutoCreateAsset(values)) {
                            //     const payload: AssetCreateProps = {
                            //         name: values.companyName,
                            //         url: values.companyWebsite,
                            //         type: 'website',
                            //     };
                            //     console.info(`auto-creating asset for brand`, payload);
                            //     await data.asset.create.mutateAsync(payload);
                            // }
                            return response;
                        },
                    };
                },
                getControlProps(key) {
                    const error = form.formState.errors[key] ?? null;
                    return {
                        isInvalid: !!error,
                        error,
                    };
                },
                getInputProps(key) {
                    return form.register(key);
                },
                getBooleanInputProps(key) {
                    return form.register(key);
                },
            };
        },
    };
}
