import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { GroupMemberBulkSavePayload } from '../../../api';
import { AccountScope } from '../../platform';
import { GroupMemberAdapter } from './groupMemberAdapter';
import { GroupMemberRepository } from './groupMemberInterface';
import { GroupMemberBulkProps } from './groupMemberProps';
import { GroupMemberQuery } from './groupMemberQuery';

export function createGroupMemberRepository(
    adapter: GroupMemberAdapter
): GroupMemberRepository {
    const PREFIX = ['groups', 'members'];

    function getReadPrefix(context: AccountScope, query: GroupMemberQuery) {
        return [...PREFIX, context.account, query];
    }

    return {
        useFind(context, query, options) {
            const applied: GroupMemberQuery = {
                ...query,
                page: query.page ?? 1,
                page_size: query.page_size ?? 100,
            };

            const data = useQuery({
                ...options,
                queryKey: [...getReadPrefix(context, applied)],
                async queryFn() {
                    const response = await adapter.find(context, applied);
                    return response;
                },
                refetchOnWindowFocus: false,
            });

            return data;
        },
        useBulk(context, options) {
            const client = useQueryClient();
            const mutationState = useMutation({
                mutationFn(payload: GroupMemberBulkProps) {
                    return adapter.bulk(context, payload);
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(PREFIX);
                },
            });
            return mutationState;
        },
        useBulkLegacy(context, options) {
            const client = useQueryClient();
            const mutationState = useMutation({
                mutationFn(payload: GroupMemberBulkSavePayload) {
                    return adapter.bulkLegacy(context, payload);
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(PREFIX);
                },
            });
            return mutationState;
        },
    };
}
