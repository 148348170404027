import { useQuery } from '@tanstack/react-query';
import { fetchWorkspace } from '../../../../api';
import { ReportWorkspaceAdapter } from '../../../app/_reportold';
import { ReportWorkspaceImplConfig } from './emailWorkspaceConfig';

export function createEmailWorkspaceAdapter(
    init: ReportWorkspaceImplConfig
): ReportWorkspaceAdapter {
    const {
        hooks: { useHttp, useAuth, useOrganization },
    } = init;
    return {
        useWorkspace(reference) {
            const auth = useAuth();
            const http = useHttp();
            const org = useOrganization();

            const scheme = auth.scheme;
            if (scheme.kind !== 'apiKey') {
                throw new Error(`adapter expects api-key based auth`);
            }

            const response = useQuery({
                queryKey: ['reports', 'workspace', reference.workspace.id],
                async queryFn() {
                    const response = await fetchWorkspace(
                        http,
                        reference.workspace.id as number,
                        {
                            apiKey: scheme.token,
                            account: org.id,
                        }
                    );
                    return response;
                },
                suspense: true,
                staleTime: Infinity,
            });

            return { ...response.data!, visible: true };
        },
    };
}
