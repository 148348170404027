import {
    isSingle,
    MetadataRule,
    SingleLevelFilterRule,
    MultiFilterRule,
} from '../../../../../models/Metadata';
import {
    listColumns,
    getPreferences,
    listAssetDashboardPreferences,
    savePreferencesApi,
    AccountPreferenceDto,
    TraitFilterSection,
    DataFilterSection,
    SavedDataFilter,
    Column,
    QuerySegmentSection,
    AccountPreferenceSectionSaveDto,
    AccountPreferenceSaveDto,
} from '../../../../../api';
import { isNumericType, AnyCondition } from '../../../../domain/attributes';
import { PreferencesAdapter } from '../../../../app';
import { castConditionToApi, castConditionToDomain } from '../../condition';
import { PreferencesApiAdapterConfig } from './preferencesApiConfig';
import { rankingToSegments, segmentToRanking } from './preferencesApiMapper';

export function createPreferencesApiImpl(
    config: PreferencesApiAdapterConfig
): PreferencesAdapter {
    return {
        async listPreferences(workspace, dashboards) {
            const response = await listAssetDashboardPreferences(
                config.axios,
                ['trait_filters', 'data_filters', 'query_segments'],
                workspace.id,
                dashboards.map((item) => item.id),
                Array.from(new Set<string>(dashboards.map((item) => item.pluginId))),
                {
                    apiKey: config.overrides?.apiKey,
                    accountId: config.overrides?.accountId,
                }
            );
            return [];
        },
        async getPreferences(workspace, dashboard, configuration) {
            const [responsePreferences, responseColumns] = await Promise.all([
                getPreferences(
                    config.axios,
                    ['trait_filters', 'data_filters', 'query_segments'],
                    workspace.id,
                    dashboard.id,
                    dashboard.pluginId,
                    {
                        apiKey: config.overrides?.apiKey,
                        accountId: config.overrides?.accountId,
                    }
                ),
                listColumns(config.axios, dashboard.id, {
                    apiKey: config.overrides?.apiKey,
                }),
            ]);

            const preferencesTraits = responsePreferences.sections.find(
                (candidate): candidate is TraitFilterSection =>
                    candidate.section_type === 'trait_filters'
            );

            const preferencesData = responsePreferences.sections.find(
                (candidate): candidate is DataFilterSection =>
                    candidate.section_type === 'data_filters'
            );

            const preferencesSegments = responsePreferences.sections.find(
                (candidate): candidate is QuerySegmentSection =>
                    candidate.section_type === 'query_segments'
            );

            const traitRule = preferencesTraits?.value.rules ?? null;
            // TODO: Fix crash when no saved trait filter
            if (traitRule && traitRule.operator !== 'and') {
                throw new Error(`expected trait preference rule operator to be 'and'`);
            }

            const traitPreferencesByKey =
                traitRule?.sub_rules.filter(isSingle).reduce((acc, rule) => {
                    return { ...acc, [rule.metadata_definition_key]: rule };
                }, {} as Record<string, MetadataRule | undefined>) ?? {};

            const dataPreferencesByKey =
                preferencesData?.value.filters.reduce((acc, saved) => {
                    return { ...acc, [saved.type_slug]: saved };
                }, {} as Record<string, SavedDataFilter | undefined>) ?? {};

            // const columnsByKey = responseColumns.items.reduce(
            //     (acc, column) => ({ ...acc, [column.key]: column }),
            //     {} as Record<string, Column>
            // );

            // const timePreferences = {
            //     filter: preferencesData?.value.date_range,
            //     granularity: preferencesData?.value.granularity,
            // };

            // console.log(traitPreferencesByKey, dataPreferencesByKey);
            // const { segment, ...queryObject } = castQueryToApi(query);
            // const response = await runQuery(
            //     config.axios,
            //     {
            //         asset: workspace.id as number,
            //         card: card.id,
            //         query: queryObject,
            //         segment: segment,
            //     },
            //     options
            // );
            // return response;

            if (!configuration) {
                throw new Error(`missing configurartion`);
            }

            return {
                cohort: preferencesSegments
                    ? segmentToRanking(dashboard.id, preferencesSegments.value)
                    : null,
                filters: Object.values(dataPreferencesByKey).flatMap(
                    (saved): AnyCondition[] => {
                        if (!saved || saved.filter.operator !== 'in') {
                            console.warn(
                                `unexpected data filter saved operator ${JSON.stringify(
                                    saved
                                )}`
                            );
                            return [];
                        }

                        return [
                            {
                                key: saved.type_slug,
                                operator: saved.filter.operator,
                                value: saved.filter.values as string[],
                            },
                        ];
                    }
                ),
                segment: Object.values(traitPreferencesByKey).flatMap(
                    (rule): AnyCondition[] => {
                        if (!rule) {
                            return [];
                        }
                        const mapped = castConditionToDomain({
                            key: rule.metadata_definition_key,
                            operator: rule.operator as any,
                            value: rule.value,
                        });

                        if (!configuration) {
                            console.warn(
                                Error(
                                    `segment only available when dashboard configuratin is passed`
                                )
                            );
                            return [];
                        }

                        const filter = configuration.properties.traits.find(
                            (candidate) => candidate.property.key === mapped.key
                        );

                        if (!filter) {
                            const candidates = configuration.properties.traits
                                .map((filter) => filter.property.key)
                                .join(', ');
                            console.warn(
                                `segment condition '${mapped.key}' does not match any filters on dashboard.found ${candidates}`
                            );
                            return [];
                        }
                        if (
                            isNumericType(filter.property.type) &&
                            mapped.operator === 'in'
                        ) {
                            console.warn(`enum-based numeric values are not supported`);
                            return [];
                        }
                        return [mapped];
                    }
                ),
            };
        },
        async savePreferences(workspace, dashboard, preferences) {
            const columns = await listColumns(config.axios, dashboard.id);

            const columnsByKey = columns.items.reduce(
                (acc, column) =>
                    column.type_slug ? { ...acc, [column.type_slug]: column } : acc,
                {} as Record<string, Column>
            );

            const sectionData: AccountPreferenceSectionSaveDto = {
                scopes: [
                    {
                        subject_id: workspace.id,
                        subject_type: 'asset',
                    },
                    {
                        subject_id: dashboard.id,
                        subject_type: 'dashboard',
                    },
                ],
                config: {
                    section_type: 'data_filters',
                    value: {
                        filters: preferences.filters.flatMap<SavedDataFilter>(
                            (condition) => {
                                const column = columnsByKey[condition.key];
                                if (!column) {
                                    console.warn(
                                        `column '${condition.key}' not found for preference`
                                    );
                                    return [];
                                }
                                if (!column.type_slug) {
                                    console.warn(
                                        `column '${condition.key}' does not have a type slug`
                                    );
                                    return [];
                                }
                                if (condition.operator !== 'in') {
                                    console.log(
                                        'unexpected condition operator, only in supported'
                                    );
                                    return [];
                                }
                                return [
                                    {
                                        type_slug: column.type_slug,
                                        filter: {
                                            operator: 'in',
                                            values: condition.value as string[],
                                        },
                                    },
                                ];
                            }
                        ),
                    },
                },
            };
            const sectionTrait: AccountPreferenceSectionSaveDto = {
                scopes: [
                    {
                        subject_id: workspace.id,
                        subject_type: 'asset',
                    },
                    {
                        subject_id: dashboard.pluginId,
                        subject_type: 'plugin',
                    },
                ],
                config: {
                    section_type: 'trait_filters',
                    value: {
                        rules: {
                            operator: 'and',
                            sub_rules: preferences.segment.flatMap<MetadataRule>(
                                (condition) => {
                                    const mapped = castConditionToApi(condition);
                                    return {
                                        metadata_definition_key: mapped.key,
                                        operator: mapped.operator as any,
                                        value: mapped.value as string | string[],
                                    };
                                }
                            ),
                        },
                        is_customized: true,
                    },
                },
            };

            const sectionSegments: AccountPreferenceSectionSaveDto | null =
                preferences.cohort
                    ? {
                          scopes: [
                              { subject_id: workspace.id, subject_type: 'asset' },
                              { subject_id: dashboard.id, subject_type: 'dashboard' },
                          ],
                          config: {
                              section_type: 'query_segments',
                              value: rankingToSegments(preferences.cohort),
                          },
                      }
                    : null;

            const payload: AccountPreferenceSaveDto = {
                sections: sectionSegments
                    ? [sectionData, sectionTrait, sectionSegments]
                    : [sectionData, sectionTrait],
            };
            // console.log('saving', workspace.id, dashboard.id, preferences);
            // console.log('payload', payload);
            const response = await savePreferencesApi(config.axios, payload);
            return preferences;
        },
    };
}
