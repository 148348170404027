import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListResponse } from '../../common';
import { AccountResource } from './accountInterface';
import { AccountListSchema, AccountSchema } from './accountSchema';

export function createAccountResource(client: AxiosInstance): AccountResource {
    return {
        async list(context) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = {};

            const response = await client.get<unknown>(
                `/api/v1/accounts`,
                {
                    params,
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );
            try {
                const parsed = AccountListSchema.parse(response.data);
                return parsed;
            }catch(e) {
                console.log(e);
                throw e;
            }
        },
        async get(context, accountId) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = {};

            const response = await client.get<unknown>(
                `/api/v1/accounts/${accountId}`,
                {
                    params,
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );
            try {
                const parsed = AccountSchema.parse(response.data);
                return parsed;
            }catch(e) {
                console.log(e);
                throw e;
            }
        },
        async update(context, accountId, payload) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = {};

            const response = await client.patch(
                `/api/v1/accounts/${accountId}`,
                payload,
                {
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );

            const parsed = AccountSchema.parse(response.data);
            return parsed;
        },
    };
}
