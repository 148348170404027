import React from 'react';
import { ActionControllerConfigV2 } from './actionItemConfig';
import { ActionItemControllerV2 } from './actionItemInterface';
import { ActionItemViewPropsV2 } from './actionItemProps';

export function createActionItemControllerV2(
    config: ActionControllerConfigV2
): ActionItemControllerV2 {
    return {
        useProps(item): ActionItemViewPropsV2 {
            const [isLoading, setIsLoading] = React.useState(false);

            // This ref lets us track whether or not the component is still mounted.
            const mountedRef = React.useRef(false);

            React.useEffect(() => {
                mountedRef.current = true;
                return () => {
                    mountedRef.current = false;
                };
            }, []);

            if (item.kind === 'custom' || item.kind === 'delete') {
                return {
                    kind: item.kind,
                    id: item.id,
                    label: item.name,
                    Icon: item.Icon ?? null,
                    tooltip: {
                        label: item.description ?? null,
                        isDisabled: item.description === null,
                    },
                    button: {
                        isLoading,
                        isActive: item.isActive,
                        colorScheme:
                            item.colorScheme ??
                            (item.kind === 'delete' ? 'red' : undefined),
                        onClick: async () => {
                            if (!mountedRef.current) return;
                            try {
                                setIsLoading(true);
                                await item.onPerform();
                            } finally {
                                // Only update state if we're still mounted
                                if (mountedRef.current) {
                                    setIsLoading(false);
                                }
                            }
                        },
                    },
                };
            }

            throw new Error('not impl');
        },
    };
}
