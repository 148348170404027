import { useQuery } from '@tanstack/react-query';
import { RecommendationDto } from '../../../../api';
import { Property } from '../../../domain/attributes';
import { useDashboardApi, useWorkspaceApi } from '../../dashboard';
import { useRecommendationContext } from './recommendationContext';

export interface RecommendatinonApi {
    useRecommendations(property: Property): RecommendationDto[];
}

export const useRecommendationApi = (): RecommendatinonApi => {
    const context = useRecommendationContext();
    const api = {
        workspace: useWorkspaceApi(),
        dashboard: useDashboardApi(),
    };

    const entities = {
        workspace: api.workspace.getWorkspace(),
        dashboard: api.dashboard.getDashboard(),
    };

    return {
        useRecommendations(property) {
            const { data: recommendations } = useQuery({
                queryKey: [
                    'recommendations',
                    entities.workspace.id,
                    entities.dashboard.id,
                    property.key,
                ],
                async queryFn() {
                    try {
                        const response = await context.adapter.getRecommendations({
                            scope: {
                                kind: 'asset',
                                id: entities.workspace.id as number,
                            },
                            model: 'enum',
                            options: { type: property.key },
                        });
                        return response;
                    } catch (error) {
                        console.error(
                            'failed to get recommendationss from legacy api',
                            error
                        );
                        return [];
                    }
                },
                suspense: true,
                staleTime: Infinity,
            });
            return recommendations ?? [];
        },
    };
};
