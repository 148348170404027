import React from 'react';
import {
    Button,
    Grid,
    GridItem,
    HStack,
    Icon,
    IconButton,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    SimpleGrid,
    Text,
    VStack,
} from '@chakra-ui/react';
import { AiOutlineMenu } from 'react-icons/ai';
import { BsChevronLeft } from 'react-icons/bs';
import { AssistantLayoutViewProps } from '../assistantLayoutProps';
import { AssistantViewBaseConfig } from '../../../../base';

export function createAssistantMobileLayoutView(
    config: Pick<AssistantViewBaseConfig, 'UI'>
): React.FC<{ children?: React.ReactNode | undefined } & AssistantLayoutViewProps> {
    return (props) => {
        return (
            <Grid
                w="100vw"
                h="100vh"
                templateRows="min-content 1fr"
                templateColumns="1fr"
            >
                {/* Header */}
                <GridItem
                    py={2}
                    px={4}
                    borderBottomWidth={2}
                    borderColor="whiteAlpha.300"
                >
                    <SimpleGrid columns={3}>
                        <HStack w="full" justify="space-between">
                            <HStack spacing={12}>
                                {props.back.button &&
                                    !props.detail.container.isVisible && (
                                        <IconButton
                                            {...props.back.button}
                                            aria-label="go back"
                                            variant="link"
                                            icon={<Icon as={BsChevronLeft} />}
                                            _focus={{ outline: 'none' }}
                                        />
                                    )}
                                {/* when detail is open the back button should instead close the details */}
                                {props.detail.container.isVisible && (
                                    <IconButton
                                        {...props.detail.button}
                                        aria-label="close"
                                        variant="link"
                                        icon={<Icon as={BsChevronLeft} />}
                                        _focus={{ outline: 'none' }}
                                    />
                                )}
                            </HStack>
                        </HStack>
                        <HStack w="full" justify="center">
                            {props.title && (
                                <VStack align="center" w="full" spacing={0}>
                                    <Text
                                        fontSize={{ base: 'md', lg: 'lg' }}
                                        fontWeight="semibold"
                                    >
                                        {props.title}
                                    </Text>
                                </VStack>
                            )}
                        </HStack>
                        <HStack justify="end" spacing={6}>
                            <Menu>
                                <MenuButton
                                    as={IconButton}
                                    icon={<Icon as={AiOutlineMenu} />}
                                    bg="none"
                                    _hover={{ bg: 'whiteAlpha.50' }}
                                    _focus={{ outline: 'none' }}
                                />
                                <MenuList>
                                    {props.actions.map((action) => (
                                        <MenuItem
                                            key={action.id}
                                            icon={<Icon as={action.Icon} />}
                                            as={Button}
                                            {...action.button}
                                        >
                                            {action.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </Menu>
                        </HStack>
                    </SimpleGrid>
                </GridItem>
                <GridItem>
                    {props.detail.container.isVisible && props.sidebar && (
                        <VStack align="start" w="full" h="full" p={6}>
                            {props.sidebar}
                        </VStack>
                    )}
                    {!props.detail.container.isVisible && props.children}
                </GridItem>
            </Grid>
        );
    };
}
