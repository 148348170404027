import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { assert } from '../../../util/assert';
import { OrganizationRepositoryConfig as AccountRepositoryConfig } from './accountConfig';
import { AccountRepository } from './accountInterface';
import { AccountEntity, AccountUpdateProps } from '../../../domain/platform';

export function createAccountRepository(
    config: AccountRepositoryConfig
): AccountRepository {
    const { adapter } = config;
    return {
        useLookup(context, query, options) {
            const result = useQuery({
                ...options,
                queryKey: ['account-v2', 'lookup', query],
                async queryFn() {
                    const response = await adapter.lookup(context, query);
                    assert(response, `account ${query.id} not found`);
                    return response;
                },
            });
            return result;
        },
        useUpdate(context) {
            const client = useQueryClient();
            return useMutation<
                AccountEntity,
                Error,
                AccountUpdateProps>({
                async mutationFn(payload) {
                    const response = await adapter.update(context, payload);
                    return response;
                },
                async onSuccess() {
                    await client.refetchQueries(['account-v2', 'lookup']);
                    await client.refetchQueries(['account-profile']);
                },
            });
        },
    };
}
