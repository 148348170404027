import { useMemo } from 'react';
import { PluginEntity } from '../../../domain';
import { isLoadingLookup } from '../../../base';
import { AssetPrepareConfig } from './assetPrepareConfig';
import { AssetPrepareLoader } from './assetPrepareInterface';
import { getFastestIntegrationDefinition } from './assetPrepareHelper';
import { IntegrationDefinition } from '../../../../v2/domain/assets';

export function createAssetPrepareLoader(config: AssetPrepareConfig): AssetPrepareLoader {
    const { repository, service } = config;
    return {
        useData(context, props) {
            const lookup = {
                profile: service.profile.useLookup(context),
                aggregate: service.mapping.useLookup(context, {
                    staleTime: 0,
                    refetchOnWindowFocus: true,
                    refetchInterval: 15000,
                }),
            };

            const connections = repository.connection.useFind(
                context,
                { workspaces: [props.asset] },
                {}
            );

            const plugins = repository.plugin.useFind(context, {}, {});

            const pluginsById = useMemo(
                () =>
                    plugins.data?.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, PluginEntity | undefined>
                    ) ?? {},
                [plugins.data]
            );

            const definitionsByKind = useMemo(
                () =>
                    lookup.aggregate.data?.definitions.reduce(
                        (acc, item) => ({ ...acc, [item.kind]: item }),
                        {} as Record<string, IntegrationDefinition | undefined>
                    ) ?? {},
                [lookup.aggregate.data?.definitions]
            );

            const fastestIntegrationDefinition = useMemo(() => {
                return getFastestIntegrationDefinition(connections.data ?? [], {
                    pluginsById,
                    definitionsByKind,
                });
            }, [connections.data, pluginsById, definitionsByKind]);

            const updateAccount = config.repository.account.useUpdate(context);
            return {
                isLoading:
                    isLoadingLookup(lookup.aggregate) ||
                    plugins.status === 'loading' ||
                    connections.status === 'loading',
                aggregate: lookup.aggregate,
                profile: {
                    lookup: lookup.profile,
                    update: updateAccount,
                },
                connection: {
                    find: connections,
                },
                integration: {
                    definition: {
                        fastest: fastestIntegrationDefinition,
                    },
                },
            };
        },
    };
}
