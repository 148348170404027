import { useQuery } from '@tanstack/react-query';
import { AccountScope } from '../../platform';
import { IntegrationDefinitionAdapter } from './definitionAdapter';
import { IntegrationDefinitionRepository } from './definitionInterface';

export function createIntegrationDefinitionRepository(
    adapter: IntegrationDefinitionAdapter
): IntegrationDefinitionRepository {
    function getPrefix(context: AccountScope) {
        return ['integrations-definitions', context.account];
    }
    return {
        useFind(context, query, options) {
            const response = useQuery({
                queryKey: [...getPrefix(context)],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
                refetchOnWindowFocus: false,
                ...options,
            });
            return response;
        },
    };
}
