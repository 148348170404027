import {
    AiOutlineCalendar,
    AiOutlinePlus,
    AiOutlineReload,
    AiOutlineWarning,
} from 'react-icons/ai';
import {
    Box,
    BoxProps,
    Button,
    ButtonGroup,
    Center,
    FormControl,
    FormLabel,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Input,
    InputGroup,
    StackDivider,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    VStack,
} from '@chakra-ui/react';
import React from 'react';
import { BsArrowRight } from 'react-icons/bs';
import { BiChevronDown } from 'react-icons/bi';
import { Icons } from '../../../../../../config';
import { ReportViewBaseConfig } from '../../../base';
import { ReportNewViewProps } from './reportNewProps';

export function createReportNewView(
    config: ReportViewBaseConfig
): React.FC<ReportNewViewProps> {
    const {
        Layout,
        UI: {
            Application: { Link },
            Date: {
                DatePicker: {
                    Container: DateContainer,
                    Absolute: DateAbsolute,
                    Relative: DateRelative,
                },
            },
        },
    } = config;

    const containerStyle = {
        maxW: { base: '90vw', lg: '85vw', xl: '75vw', '2xl': '72rem' },
    } satisfies Partial<BoxProps>;

    return (props) => {
        return (
            <Layout>
                <VStack align="start" w="full" spacing={12} pt={0} pb="24rem">
                    <Grid
                        w="full"
                        templateRows={{
                            base: 'minmax(8rem, min-content) 1fr',
                            '2xl': 'minmax(8rem, min-content) 1fr',
                        }}
                        gap={8}
                    >
                        <GridItem
                            borderBottomWidth={3}
                            borderColor="whiteAlpha.200"
                            pb={8}
                        >
                            <HStack h="full" w="full" justify="center" align="end">
                                <HStack
                                    maxW={containerStyle.maxW}
                                    w="full"
                                    h="full"
                                    justify="space-between"
                                    align="end"
                                >
                                    <HStack spacing={6}>
                                        {/* <Box p={3} bg="whiteAlpha.300" borderRadius="md">
                                            <Icon
                                                fontSize="2xl"
                                                as={Icons.Reports.Report}
                                            />
                                        </Box> */}
                                        <VStack align="start" spacing={1}>
                                            <VStack align="start" spacing={0}>
                                                <Text
                                                    fontWeight="semibold"
                                                    color="blue.300"
                                                >
                                                    Reports
                                                </Text>
                                                <Heading
                                                    fontSize="3xl"
                                                    fontWeight="semibold"
                                                >
                                                    Customize report
                                                </Heading>
                                            </VStack>
                                            <Link {...props.report.link}>
                                                <HStack
                                                    fontSize="lg"
                                                    spacing={3}
                                                    color="whiteAlpha.600"
                                                    // fontWeight="medium"
                                                    cursor="pointer"
                                                    _hover={{ textDecor: 'underline' }}
                                                >
                                                    <Icon as={Icons.Reports.Report} />
                                                    <Text>{props.report.name}</Text>
                                                </HStack>
                                            </Link>
                                        </VStack>
                                    </HStack>
                                </HStack>
                            </HStack>
                        </GridItem>
                        <GridItem>
                            <HStack w="full" justify="center" pt={6}>
                                <HStack
                                    maxW={containerStyle.maxW}
                                    w="full"
                                    h="full"
                                    justify="space-between"
                                    align="end"
                                >
                                    <VStack
                                        align="start"
                                        w="full"
                                        spacing={12}
                                        divider={
                                            <StackDivider
                                                // borderBottomWidth={3}
                                                // borderTopWidth={0}
                                                borderWidth={1}
                                                borderColor="whiteAlpha.200"
                                                // borderStyle="dashed"
                                            />
                                        }
                                    >
                                        <VStack
                                            align="start"
                                            w="full"
                                            spacing={12}
                                            divider={
                                                <StackDivider
                                                    borderWidth={1}
                                                    borderColor="whiteAlpha.200"
                                                />
                                            }
                                        >
                                            {/* Report name */}
                                            <Grid
                                                w="full"
                                                templateColumns="1fr 1fr"
                                                gap={12}
                                            >
                                                <GridItem>
                                                    <VStack
                                                        align="start"
                                                        spacing={1}
                                                        // p={8}
                                                        // bg="whiteAlpha.50"
                                                        // borderRadius="xl"
                                                    >
                                                        <Text
                                                            fontSize="lg"
                                                            fontWeight="semibold"
                                                        >
                                                            Report name
                                                        </Text>
                                                        <Text color="whiteAlpha.700">
                                                            Aliqua mollit tempor magna
                                                            sint
                                                        </Text>
                                                    </VStack>
                                                </GridItem>
                                                <GridItem>
                                                    <VStack
                                                        h="full"
                                                        align="start"
                                                        justify="center"
                                                    >
                                                        <InputGroup w="full">
                                                            <Input placeholder="Enter report name" />
                                                        </InputGroup>
                                                    </VStack>
                                                </GridItem>
                                            </Grid>
                                            {/* Timeframe */}
                                            <Grid
                                                w="full"
                                                templateColumns="1fr 1fr"
                                                gap={12}
                                            >
                                                <GridItem>
                                                    <VStack
                                                        align="start"
                                                        spacing={1}
                                                        // p={8}
                                                        // bg="whiteAlpha.50"
                                                        // borderRadius="xl"
                                                    >
                                                        <Text
                                                            fontSize="lg"
                                                            fontWeight="semibold"
                                                        >
                                                            Time period
                                                        </Text>
                                                        <Text color="whiteAlpha.700">
                                                            Laborum nostrud proident
                                                            exercitation
                                                        </Text>
                                                    </VStack>
                                                </GridItem>
                                                <GridItem>
                                                    {/* <DateContainer>
                                                    <DateAbsolute></DateAbsolute>
                                                </DateContainer> */}
                                                    <ButtonGroup isAttached={true}>
                                                        <Button
                                                            rightIcon={
                                                                <Icon
                                                                    as={BiChevronDown}
                                                                />
                                                            }
                                                        >
                                                            Last 4 weeks
                                                        </Button>
                                                        <Button
                                                            leftIcon={
                                                                <Icon
                                                                    as={AiOutlineCalendar}
                                                                />
                                                            }
                                                        >
                                                            Nov 30 - Dec 30
                                                        </Button>
                                                    </ButtonGroup>
                                                </GridItem>
                                            </Grid>
                                            {/* Filters */}
                                            <Grid
                                                w="full"
                                                templateColumns="1fr 1fr"
                                                gap={12}
                                            >
                                                <GridItem>
                                                    <VStack
                                                        align="start"
                                                        spacing={1}
                                                        // p={8}
                                                        // bg="whiteAlpha.50"
                                                        // borderRadius="xl"
                                                    >
                                                        <Text
                                                            fontSize="lg"
                                                            fontWeight="semibold"
                                                        >
                                                            Data filters
                                                        </Text>
                                                        <Text color="whiteAlpha.700">
                                                            Exercitation deserunt mollit
                                                            pariatur cillum
                                                        </Text>
                                                    </VStack>
                                                </GridItem>
                                                <GridItem>
                                                    <Button
                                                        // colorScheme="blue"
                                                        bg="none"
                                                        borderColor="whiteAlpha.300"
                                                        variant="outline"
                                                        borderRadius="full"
                                                        borderStyle="dashed"
                                                        borderWidth={2}
                                                        rightIcon={
                                                            <Icon as={AiOutlinePlus} />
                                                        }
                                                        _hover={{ bg: 'whiteAlpha.50' }}
                                                    >
                                                        Add filter
                                                    </Button>
                                                </GridItem>
                                            </Grid>
                                        </VStack>
                                        <HStack w="full" justify="end">
                                            <ButtonGroup spacing={4}>
                                                <Link {...props.cancel.link}>
                                                    <Button variant="ghost" bg="none">
                                                        Cancel
                                                    </Button>
                                                </Link>
                                                <Button
                                                    // variant="outline"
                                                    colorScheme="green"

                                                    // borderRadius="full"
                                                >
                                                    Create report
                                                </Button>
                                            </ButtonGroup>
                                        </HStack>
                                    </VStack>
                                </HStack>
                            </HStack>
                        </GridItem>
                    </Grid>
                </VStack>
            </Layout>
        );
    };
}
