import { TagProps } from "@chakra-ui/react";
import { AssetClaim, isActiveAssetClaim } from "../../../../domain/assets";


export function claimToColor(claim: AssetClaim): TagProps['colorScheme'] {
    switch(claim.kind) {
        case 'active':
            return 'green';
        case 'inactive':
            switch(claim.status) {
                case 'expired':
                case 'blocked':
                    return 'red';
                case 'pending':
                    return 'orange';
            }
        default:
            return 'gray';
    }
}

export function claimStatus(claim: AssetClaim) {
    if(claim.kind === 'active') {
        return 'Active'
    }
    else {
        if (claim.status === 'pending') {
            return 'Pending';
        }
        return 'Not Active';
    }
}

export function isClaimInProcess(claim: AssetClaim) {
    return !isActiveAssetClaim(claim) && (claim.status === 'pending' && claim.evidences.some(x => x.status === 'pending'));
}

export function claimDetail(claim: AssetClaim): string | null {
    if(isActiveAssetClaim(claim)) {
        switch(claim.reason.kind) {
            case 'manual':
                return 'Verified by system';
            case 'integration':
                return 'Verified by integration';
            case 'email_domain_verification':
                return 'Verified by email address';
            case 'first_occurrence':
                return 'Temporarly claimed';
            default:
                return null;
        }
    } else {
        switch(claim.status) {
            case 'expired':
                return 'Expired';
            case 'blocked':
                return 'Blocked';
            case 'pending':
                if(claim.evidences.some(x => x.status === 'pending')) {
                    return 'Wating for review';
                }
                return 'Pending User Evidence';
            default:
                return claim.status;
        }
    }
}