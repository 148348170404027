import { useQuery } from '@tanstack/react-query';
import { useContext } from 'react';
import { PluginSlugs } from '../../../../config';
import {
    FindMetricDefinitionsQuery,
    MetricCollectionRepository,
    MetricDefinitionRepository,
} from '../../../app/metrics';
import {
    DashboardRepository,
    DashboardQuery
} from '../../../app/dashboard';
import { OpportunityRepository } from '../../../app/insight';
import { PageIds } from '../../../config';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { AnyCollection } from '../../../base';
import { InsightEntity } from '../../../domain/insights';
import { HomeRouteController } from '../../../route';
import { PluginRepository } from '../../../app';
import { FeatureFlagContext } from '../featureFlagConstant';
import { createMockCollections, MOCK_INSIGHTS } from './featureImportFixture';

export function createFeatureFlagImportsMiddleware(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        function enhanceDefinitionRepository(
            repository: MetricDefinitionRepository
        ): MetricDefinitionRepository {
            return {
                ...repository,
                useFind(...args) {
                    const [context, query, options] = args;
                    const flags = useContext(FeatureFlagContext);
                    if (!flags) {
                        throw new Error(`feature flag context not found`);
                    }
                    let applied: FindMetricDefinitionsQuery = query;
                    if (flags.flags.itbenchmarks?.enabled) {
                        applied = {
                            ...query,
                            plugins: [{ id: PluginSlugs.IT_BENCHMARKS }],
                        };
                    } else {
                        applied = {
                            ...query,
                            plugins: query.plugins.filter(
                                (candidate) => candidate.id !== PluginSlugs.IT_BENCHMARKS
                            ),
                        };
                    }
                    return repository.useFind(context, applied, options);
                },
            };
        }
        function enhanceDashboardRepository(
            repository: DashboardRepository
        ): DashboardRepository {
            return {
                ...repository,
                useFind(...args) {
                    const [context, query, options] = args;
                    const flags = useContext(FeatureFlagContext);
                    if (!flags) {
                        throw new Error(`feature flag context not found`);
                    }
                    let applied: DashboardQuery = query;
                    if (flags.flags.itbenchmarks?.enabled) {
                        applied = {
                            ...query,
                            includePlugins: [PluginSlugs.IT_BENCHMARKS],
                        };
                    } else {
                        applied = {
                            ...query,
                            ignorePlugins: [PluginSlugs.IT_BENCHMARKS],
                        };
                    }
                    return repository.useFind(context, applied, options);
                },
            };
        }
        function enhanceInsightRepository(
            repository: OpportunityRepository
        ): OpportunityRepository {
            return {
                ...repository,
                useFind(...args) {
                    const context = useContext(FeatureFlagContext);
                    if (!context) {
                        throw new Error(`feature flag context not found`);
                    }
                    const actualQuery = repository.useFind(...args);
                    const mockQuery = useQuery({
                        queryFn() {
                            return MOCK_INSIGHTS;
                        },
                    });
                    if (!context.flags.itbenchmarks?.enabled) {
                        return actualQuery;
                    }
                    const collection: AnyCollection<InsightEntity> = {
                        status: 'loaded',
                        data: {
                            total: 0,
                            limit: 10,
                            items: mockQuery.data ?? [],
                        },
                    };
                    return collection;
                },
            };
        }
        function enhanceCollectionRepository(
            repository: MetricCollectionRepository
        ): MetricCollectionRepository {
            return {
                ...repository,
                useFind(...args) {
                    const [_context, query] = args;
                    const context = useContext(FeatureFlagContext);
                    if (!context) {
                        throw new Error(`feature flag context not found`);
                    }
                    const actualQuery = repository.useFind(...args);
                    const mockQuery = useQuery({
                        queryFn() {
                            return createMockCollections({ asset: query.asset });
                        },
                    });
                    // return actualQuery;
                    if (!context.flags.itbenchmarks?.enabled) {
                        return actualQuery;
                    }
                    return mockQuery;
                },
            };
        }
        function enhanceHomeController(
            controller: HomeRouteController
        ): HomeRouteController {
            return {
                ...controller,
                useProps(...args) {
                    const viewProps = controller.useProps(...args);
                    const context = useContext(FeatureFlagContext);
                    if (!context) {
                        throw new Error(`feature flag context not found`);
                    }
                    return {
                        ...viewProps,
                        isVisible(item) {
                            if (item.id === 'summary') {
                                return context.flags.itbenchmarks?.enabled ?? false;
                            }
                            return viewProps.isVisible(item);
                        },
                    };
                },
            };
        }
        function enhancePluginRepository(repository: PluginRepository): PluginRepository {
            return {
                ...repository,
                useFind(context, query, ...args) {
                    const features = useContext(FeatureFlagContext);
                    if (!features) {
                        throw new Error(`feature flag context not found`);
                    }
                    return repository.useFind(
                        context,
                        features.flags.itbenchmarks?.enabled
                            ? { ...query, includeIds: [PluginSlugs.IT_BENCHMARKS] }
                            : { ...query, ignoreIds: [PluginSlugs.IT_BENCHMARKS] },
                        ...args
                    );
                },
            };
        }

        return create({
            ...config,
            route: {
                ...config.route,
                createHomeRoute(routeConfig) {
                    return config.route.createHomeRoute({
                        ...routeConfig,
                        providers: {
                            ...routeConfig.providers,
                            root: {
                                ...routeConfig.providers.root,
                                createController(...args) {
                                    const controller =
                                        routeConfig.providers.root.createController(
                                            ...args
                                        );
                                    return enhanceHomeController(controller);
                                },
                            },
                        },
                    });
                },
            },
            repository: {
                ...config.repository,
                platform: {
                    ...config.repository.platform,
                    createPlugin(...args) {
                        const repository = config.repository.platform.createPlugin(
                            ...args
                        );
                        return enhancePluginRepository(repository);
                    },
                    createDashboard(...args) {
                        const repository = config.repository.platform.createDashboard(
                            ...args
                        );
                        return enhanceDashboardRepository(repository);
                    },
                },
                // peers: {
                //     ...config.repository.peers,
                //     createPeerGroups(...args) {
                //         const repository = config.repository.peers.createPeerGroups(
                //             ...args
                //         );
                //         return enhancePeerGroupRepository(repository);
                //     },
                // },
                insights: {
                    ...config.repository.insights,
                    createOpportunity(...args) {
                        const repository = config.repository.insights.createOpportunity(
                            ...args
                        );
                        return enhanceInsightRepository(repository);
                    },
                },
                metric: {
                    ...config.repository.metric,
                    createDefinition(...args) {
                        const repository = config.repository.metric.createDefinition(
                            ...args
                        );
                        return enhanceDefinitionRepository(repository);
                    },
                    // createCollection(...args) {
                    //     const repository = config.repository.metric.createCollection(
                    //         ...args
                    //     );
                    //     return enhanceCollectionRepository(repository);
                    // },
                },
            },
            controller: {
                ...config.controller,
                settings: {
                    ...config.controller.settings,
                    createAsset(...args) {
                        const controller = config.controller.settings.createAsset(
                            ...args
                        );
                        return {
                            useProps() {
                                const props = controller.useProps();
                                const context = useContext(FeatureFlagContext);
                                if (!context) {
                                    throw new Error(`feature flag context not found`);
                                }
                                return {
                                    ...props,
                                    isVisible(item) {
                                        if (
                                            item.key ===
                                            PageIds.SETTINGS_ASSET_IMPORTS_LIST
                                        ) {
                                            return (
                                                context.flags.itbenchmarks?.enabled ??
                                                false
                                            );
                                        }
                                        return props.isVisible(item);
                                    },
                                };
                            },
                        };
                    },
                },
            },
        });
    };
}
