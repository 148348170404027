import React from 'react';
import { AccountReference, AuthenticationV2 } from '../../../../domain/platform';
import { ScheduleListController } from './scheduleListInterface';
import { ScheduleListViewProps } from './scheduleListProps';

export function createScheduleListContainer(config: {
    hook: {
        useOrganization(): AccountReference;
        useAuth(): AuthenticationV2;
    };
    controller: ScheduleListController;
    View: React.FC<{ children?: React.ReactNode | undefined } & ScheduleListViewProps>;
}): React.FC<
    { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
> {
    const {
        View,
        controller: { useProps: useView },
        hook: { useOrganization, useAuth },
    } = config;
    return (_props) => {
        const organization = useOrganization();
        const auth = useAuth();
        const viewProps = useView({ account: organization, auth }, {});
        return <View {...viewProps} />;
    };
}
