import { createTabLayoutContainer } from './tabLayoutContainer';
import { TabLayoutController, TabLayoutProvider } from './tabLayoutInterface';

export function createTabLayout(controller: TabLayoutController): TabLayoutProvider {
    return {
        create(config) {
            return {
                controller,
                Container: createTabLayoutContainer(config, controller),
            };
        },
    };
}
