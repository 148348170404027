import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { WorkspaceServiceConfig } from './workspaceConfig';
import { WorkspaceService } from './workspaceInterface';
import { LoadedWorkspaceDto, UpdateWorkspaceDto } from '../../../api';

export function createWorkspaceService(init: WorkspaceServiceConfig): WorkspaceService {
    const {
        api,
        hook: { useQuery, useMutation },
    } = init;
    const PREFIX = ['v2', 'workspaces'];
    init.cacheRegistrar.register('workspace', PREFIX);
    return {
        useList(context, query) {
            const response = useQuery({
                queryKey: PREFIX,
                async queryFn() {
                    const response = await api.workspaces.list(context, {});
                    return response.data;
                },
                suspense: true,
                staleTime: Infinity,
            });
            return response.data ?? [];
        },
        useEnhancedList(context, query) {
            const response = useQuery({
                queryKey: [
                    ...PREFIX,
                    'enhanced',
                    ...(query.expand || ['no']),
                    query.page_size,
                    query.page,
                    query.active
                ],
                async queryFn() {
                    const response = await api.workspaces.enhancedList(context, query);
                    return response;
                },
                suspense: true,
                staleTime: Infinity,
            });
            return (
                response.data ?? {
                    data: [],
                    pagination: {
                        count: 0,
                        page: 1,
                        page_size: 10,
                        num_pages: 1,
                    },
                }
            );
        },
        useUpdate(context) {
            const client = useQueryClient();

            const mutationUpdate = useMutation<
                LoadedWorkspaceDto,
                null,
                UpdateWorkspaceDto
            >({
                async mutationFn(payload) {
                    const response = await api.workspaces.update(context, payload);
                    // console.log('created', response);
                    return response;
                },
                async onSuccess(response, arg) {
                    const ctx = { client };
                    await init.cacheRegistrar.refresh(ctx, 'workspace');
                    if (arg.access) {
                        await init.cacheRegistrar.refresh(ctx, 'collaberator');
                        await init.cacheRegistrar.refresh(ctx, 'users');
                    }
                },
            });
            return (payload) => mutationUpdate.mutateAsync(payload);
        },
    };
}
