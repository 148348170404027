import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { CollaboratorListDto } from './collaboratorModel';
import { CollaboratorResource } from './collaboratorInterface';
import { AxiosCacheInstance } from 'axios-cache-interceptor';

export function createCollaboratorResource(client: AxiosInstance): CollaboratorResource {
    return {
        async list(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = {};
            if (query.workspaces) {
                params['workspace'] = query.workspaces;
            }

            const response = await // @ts-expect-error
            (client as AxiosCacheInstance).get<CollaboratorListDto>(
                '/api/v1/collaborators',
                {
                    cache: false,
                    params,
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );
            return {
                data: response.data.data,
            };
        },
    };
}
