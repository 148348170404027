import React from 'react';
import { ApplicationErrorController } from './applicationErrorInterface';
import { ApplicationErrorContainerProps } from './applicationErrorProps';
import { BaseApplicationShellContainerConfig } from '../../base';

export function createApplicationErrorContainer(
    config: BaseApplicationShellContainerConfig,
    controller: ApplicationErrorController
): React.FC<{ children?: React.ReactNode | undefined } & ApplicationErrorContainerProps> {
    return ({ as: View, children, ...containerProps }) => {
        const props = controller.useProps(containerProps);
        return <View {...props} />;
    };
}
