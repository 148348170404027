import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { assert } from '@varos/util-typescript';
import { OverlayContainerProps } from './overlayProps';
import {
    OverlayContextValue,
    OverlayController,
    OverlayStateProvider,
} from './overlayInterface';
import { OverlayConfig } from './overlayConfig';

export function createOverlayContainer(
    config: OverlayConfig,
    Context: React.Context<OverlayContextValue | null>,
    states: OverlayStateProvider,
    controller: OverlayController
): React.FC<OverlayContainerProps> {
    return ({ as: View, ...containerProps }) => {
        const [searchParams, setSearchParams] = useSearchParams();
        const context = React.useContext(Context);
        const state = states.useState({ searchParams: [searchParams, setSearchParams] });
        assert(context, 'not inside overlay context');
        const props = controller.useProps({
            overlayRef: context.overlayRef,
            drawer: {
                isOpen: state.value.overlay,
                onClose() {
                    console.log('closing overlay');
                    state.onChange({ ...state.value, overlay: false });
                },
            },
        });
        console.log('overlay portal props', props, state.value);
        return <View {...props} />;
    };
}
