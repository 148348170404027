import { Toaster } from './toastInterface';
import { ToastConfig } from './toastConfig';
import { createToastController } from './toastController';
import { createToastContainer } from './toastContainer';
import { buildToast } from './toastFactory';
import { createToastStore } from './toastStore';

export function createToast(config: ToastConfig = {}): Toaster {
    const store = createToastStore();
    const controller = createToastController();
    const Container = createToastContainer(store, controller);
    return {
        trigger(props) {
            const toast = buildToast(props);
            store.addToast(toast);
            setTimeout(() => {
                store.removeToast(toast.id);
            }, toast.duration);
        },
        Container,
    };
}
