import { chain } from 'lodash';
import React, { useEffect, useMemo } from 'react';
import {
    CONNECTION_STATUS_ORDERING,
    METRIC_ORDERING,
    PLUGIN_ORDERING,
} from '../../../view';
import { OnboardingMetricConfig } from './onboardingMetricConfig';
import { OnboardingMetricController } from './onboardingMetricInterface';
import { OnboardingMetricViewProps } from './onboardingMetricProps';

export function createOnboardingMetricController(
    config: OnboardingMetricConfig
): OnboardingMetricController {
    const {
        controller,
        hooks: { useLocalStorage },
    } = config;
    return {
        useProps(context, data, form, props): OnboardingMetricViewProps {
            const viewProps = controller.metric.useProps(context, data, form, props);
            const formProps = viewProps.getFormProps();

            const [, setOnboardingState] = useLocalStorage(
                context.account.id.toString()
            );

            useEffect(() => {
                setOnboardingState({
                    step: 'metrics',
                    account: context.account.id,
                    asset: context.workspace.id as number,
                });
            }, []);

            // const handleSubmitClick: React.MouseEventHandler = async (event) => {
            //     const response = await formProps.onSubmit(event);
            //     props.onContinue?.();
            // };

            const items = viewProps.getFilteredItems();
            const sections = useMemo(
                () =>
                    chain(items)
                        .filter(
                            (item) =>
                                item.connection?.status == 'ready' ||
                                item.connection?.status == 'syncing' ||
                                item.connection?.status == 'disconnected'
                        )
                        .groupBy((item) => item.plugin.id)
                        .map((items, pluginId) => {
                            const [first, ...rest] = items;
                            return {
                                id: first.plugin.id,
                                label: first.plugin.name,
                                items: chain(items)
                                    .orderBy(
                                        (item) =>
                                            METRIC_ORDERING.findIndex(
                                                (kind) => item.metric.kind === kind
                                            ),
                                        'asc'
                                    )
                                    .value(),
                            };
                        })
                        .orderBy(
                            (item) => {
                                const [sample] = item.items;
                                return [
                                    PLUGIN_ORDERING.findIndex(
                                        (plugin) => plugin === sample.plugin.id
                                    ),
                                    CONNECTION_STATUS_ORDERING.findIndex(
                                        (status) => status === sample.connection?.status
                                    ),
                                ];
                            },
                            ['asc', 'asc']
                        )
                        .value(),
                [items]
            );

            return {
                getFormProps() {
                    return {
                        ...viewProps.getFormProps(),
                        onSubmit: form.handleSubmit(async (values) => {
                            const response = await formProps.onSubmit(values);
                            console.log('response', response);
                            props.onContinue?.();
                        }),
                        submitLabel: 'Select at least one metric to continue',
                    };
                },
                getSectionProps() {
                    return {
                        sections,
                    };
                },
                getViewProps() {
                    return viewProps;
                },
            };
        },
    };
}
