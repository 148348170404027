import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { assert } from '@varos/util-typescript';
import { useDisclosure, useToken } from '@chakra-ui/react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
    AiOutlineBulb,
    AiOutlineCloudSync,
    AiOutlineCreditCard,
    AiOutlineDashboard,
    AiOutlineFileSearch,
    AiOutlineHome,
    AiOutlineLink,
    AiOutlineLogout,
    AiOutlineSetting,
    AiOutlineUserAdd,
} from 'react-icons/ai';
import { HiOutlineDocumentChartBar } from 'react-icons/hi2';
import { Icons } from '../../../../../config';
import { PageIds } from '../../../../config';
import { useUserApi } from '../../../../app';
import { ApplicationShellUrlParamSchema } from '../../applicationShellSchema';
import { BaseApplicationShellContainerConfig } from '../../base';
import { ApplicationShellContainerProps } from './applicationShellProps';
import { ApplicationErrorContainerProps } from '../error';
import { ShellLayoutController, ShellLayoutLoader } from './applicationShellInterface';

export function createApplicationShellContainer(
    config: BaseApplicationShellContainerConfig & {
        Container: {
            Error: React.FC<ApplicationErrorContainerProps>;
        };
    },
    loader: ShellLayoutLoader,
    controller: ShellLayoutController
): React.FC<ApplicationShellContainerProps> {
    const {
        UI: {
            Application: { createErrorBoundary },
        },
        Container: { Error: ErrorContainer },
        Theme: {
            Desktop: { Shell: DesktopView, Error: DesktopErrorView },
            Mobile: { Shell: MobileView, Error: MobileErrorView },
        },
        context: { useContext },
        kernel: {
            infra: {
                overlay: { Provider: OverlayProvider },
                optionParser: { useOptions },
            },
        },
    } = config;

    const ErrorBoundary = createErrorBoundary({
        errorClass: Error,
    });

    return ({ children, ...containerProps }) => {
        const user = useUserApi();
        // const defaultWorkspaceUrl = user.getDefaultWorkspaceUrl();
        const currentWorkspace = user.getCurrentWorkspace();
        const [search, setSearch] = useState('');

        const location = useLocation();
        const navigate = useNavigate();
        const overlayRef = useRef<HTMLDivElement>(null);
        const backgroundColor = useToken('colors', 'whiteAlpha.100');
        const [searchParams, setSearchParams] = useSearchParams();

        const context = useContext();

        // TODO invitation code migrated from old context, should be cleaned up
        const mutation = {
            invite: config.repository.invite.useCreate({
                auth: context.auth,
                account: { id: context.principal.account },
            }),
        };

        const options = useOptions(ApplicationShellUrlParamSchema);
        const data = loader.useLoad(context, containerProps);

        const disclosure = {
            workspace: useDisclosure({ onClose: setSearch.bind(null, '') }),
            invite: useDisclosure(),
        };

        const props = controller.useProps(context, data, {
            location,
            navigate,
            home: {
                link: {
                    to: '/',
                },
            },
            status: null,
            shortcuts: {
                groups: [
                    {
                        label: 'Account',
                        items: [
                            {
                                id: PageIds.SETTINGS_COMPANY_NEW,
                                label: 'Company',
                                Icon: Icons.Peers.Company,
                                path: `/u/settings/companies/new`,
                            },
                            {
                                id: PageIds.INTEGRATION_OVERVIEW,
                                label: 'Integration',
                                Icon: Icons.Accounts.Integration,
                                path: `/u/integrations`,
                            },
                        ],
                    },
                    {
                        label: 'Peers',
                        items: currentWorkspace
                            ? [
                                  {
                                      id: PageIds.PEER_COMPETITIVE_SET_NEW,
                                      label: 'Competitive set',
                                      Icon: Icons.Peers.CompetitiveSets,
                                      path: `/u/assets/${currentWorkspace.id}/peers/competitive_sets/new`,
                                  },
                              ]
                            : [],
                    },
                ].filter((item) => item.items.length > 0),
            },
            workspace: {
                visible: !!options.workspaceId,
                focusRef: useRef(null),
                search: {
                    value: search,
                    onChange(value) {
                        setSearch(value);
                    },
                },
                select: {
                    value: currentWorkspace ? [currentWorkspace.id.toString()] : [],
                    onChange(value) {
                        const [first = null] = value;
                        assert(first, 'expected selected value');
                        const path = location.pathname.replace(
                            /\/assets\/[A-Za-z-_0-9]+\//,
                            `/assets/${value}/`
                        );
                        navigate(path);
                        disclosure.workspace.onClose();
                    },
                },
                disclosure: disclosure.workspace,
            },
            account: {
                invite: {
                    isOpen: disclosure.invite.isOpen,
                    onClose: disclosure.invite.onClose,
                    onOpen: disclosure.invite.onOpen,
                    async onSubmit(values) {
                        return mutation.invite.mutateAsync({
                            kind: 'member',
                            firstName: values.firstName,
                            lastName: values.lastName,
                            email: values.email,
                            role: values.role === 'admin' ? 'admin' : 'standard',
                            accessAssetIds: values.access_asset_ids,
                            title: values.title,
                        });
                    },
                },
                disclosure: useDisclosure(),
                actions: [
                    {
                        id: PageIds.SETTINGS_ACCOUNT_BILLING_SUBSCRIPTION,
                        label: 'Billing',
                        Icon: AiOutlineCreditCard,
                        path: '/u/settings',
                    },
                    {
                        id: PageIds.SETTINGS_ACCOUNT_NOTIFICATIONS,
                        label: 'Settings',
                        Icon: AiOutlineSetting,
                        path: '/u/settings/notifications',
                    },
                ],
                action: {
                    logout: {
                        id: 'logout',
                        name: 'Logout',
                        kind: 'custom',
                        Icon: AiOutlineLogout,
                        onPerform() {
                            return config.kernel.infra.authenticator.logout(context);
                        },
                    },
                },
            },
            navigation: {
                navigate,
                location,
                disclosure: useDisclosure({
                    isOpen: options.overlay === 'workspace',
                    onOpen() {
                        searchParams.set('overlay', 'workspace');
                        setSearchParams(searchParams);
                    },
                    onClose() {
                        searchParams.delete('overlay');
                        setSearchParams(searchParams);
                    },
                }),
                items: [
                    {
                        id: PageIds.HOME,
                        label: 'Home',
                        Icon: AiOutlineHome,
                        path: `/u/assets/${currentWorkspace?.id}/home`,
                    },
                    {
                        id: PageIds.TOP_CREATIVES,
                        label: 'Top Creatives',
                        Icon: AiOutlineBulb,
                        path: '/u/private-groups',
                        tag: { children: 'New' },
                    },
                    {
                        id: PageIds.DASHBOARD_OVERVIEW,
                        label: 'Dashboards',
                        Icon: AiOutlineDashboard,
                        path: `/u/assets/${currentWorkspace?.id}/dashboards`,
                    },
                    {
                        id: PageIds.OPPORTUNITIES_OVERVIEW,
                        label: 'Opportunities',
                        Icon: AiOutlineFileSearch,
                        path: `/u/assets/${currentWorkspace?.id}/opportunities`,
                    },
                    {
                        id: PageIds.REPORTS,
                        label: 'Reports',
                        Icon: HiOutlineDocumentChartBar,
                        path: `/u/assets/${currentWorkspace?.id}/reporting`,
                    },
                    {
                        id: PageIds.PEER_OVERVIEW,
                        label: 'Peers',
                        Icon: Icons.Peers.CompetitiveSets,
                        path: `/u/assets/${currentWorkspace?.id}/peers`,
                        tag: { children: 'New' },
                    },
                    {
                        id: PageIds.INTEGRATION_OVERVIEW,
                        label: 'Integrations',
                        Icon: AiOutlineCloudSync,
                        path: '/u/integrations',
                    },
                ],
            },
        });

        if (props.browser.mode === 'mobile') {
            return (
                <OverlayProvider overlayRef={overlayRef}>
                    <Helmet>
                        {/* set correct theme background for mobiel devices */}
                        <meta name="theme-color" content={backgroundColor} />
                    </Helmet>
                    <ErrorBoundary
                        fallback={(info) => (
                            <ErrorContainer as={MobileErrorView} info={info} />
                        )}
                    >
                        <MobileView {...props}>{children}</MobileView>
                    </ErrorBoundary>
                </OverlayProvider>
            );
        }
        return (
            <ErrorBoundary
                fallback={(info) => <ErrorContainer as={DesktopErrorView} info={info} />}
            >
                <DesktopView {...props}>{children}</DesktopView>
            </ErrorBoundary>
        );
    };
}
