import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { AccountScope } from '../../app';

import {
    OnboardingAccountRouteConfig,
    OnboardingAssetRouteConfig,
    OnboardingUserRouteConfig,
} from './onboardingRouteConfig';
import {
    createOnboardingAssetRouteController,
    createOnboardingOrganizationRouteController,
} from './onboardingRouteController';
import { OnboardingRouteControllerProps } from './onboardingRouteProps';
import {
    createOnboardingAssetRouteView,
    createOnboardingAccountRouteView,
} from './onboardingRouteView';
import {
    createOnboardingOrganizationStrategyContainer,
    createStrategyAssetController,
    createStrategyOrganizationController,
    createOnboardingAssetStrategyContainer,
    createOnboardingUserStrategyContainer,
} from './strategy';

export function createOnboardingUserRoute(
    config: OnboardingUserRouteConfig
): React.FC<{ children?: React.ReactNode | undefined } & OnboardingRouteControllerProps> {
    const { hook, items, Layout } = config;

    const controller = {
        route: createOnboardingOrganizationRouteController(config, items),
        // strategy: createStrategyUserController(config),
    };

    // const loader = createOnboardingRouteLoader(config);

    const instances = items.map((item) => {
        const instance = item.create({
            Layout: Layout.Item,
        });
        return {
            ...item,
            Component: createOnboardingUserStrategyContainer(
                // controller.strategy,
                instance.Component
            ),
        };
    });

    const View = createOnboardingAccountRouteView(config, instances);

    return (props) => {
        const [searchParams, setSearchParams] = useSearchParams();
        // const workspace = hook.useWorkspace();
        const auth = hook.useAuth();

        const context: Pick<AccountScope, 'auth'> = {
            auth,
        };

        // const viewData = loader.useData(context, props);
        const viewProps = controller.route.useProps(props);

        useEffect(() => {
            // searchParams.set()
            setSearchParams(searchParams);
        }, []);

        return <View {...viewProps} />;
    };
}

export function createOnboardingOrganizationRoute(
    config: OnboardingAccountRouteConfig
): React.FC<{ children?: React.ReactNode | undefined } & OnboardingRouteControllerProps> {
    const { hook, items, Layout } = config;

    const controller = {
        route: createOnboardingOrganizationRouteController(config, items),
        strategy: createStrategyOrganizationController(config),
    };

    const instances = items.map((item) => {
        const instance = item.create({
            Layout: Layout.Item,
        });
        return {
            ...item,
            Component: createOnboardingOrganizationStrategyContainer(
                controller.strategy,
                instance.Component
            ),
        };
    });

    const View = createOnboardingAccountRouteView(config, instances);

    return (props) => {
        const organization = hook.useAccount();
        const [searchParams, setSearchParams] = useSearchParams();
        // const workspace = hook.useWorkspace();
        const auth = hook.useAuth();

        const context: AccountScope = {
            account: organization,
            // workspace,
            auth,
        };

        const viewProps = controller.route.useProps(props);

        useEffect(() => {
            // searchParams.set()
            setSearchParams(searchParams);
        }, []);

        return <View {...viewProps} />;
    };
}

export function createOnboardingAssetRoute(
    config: OnboardingAssetRouteConfig
): React.FC<{ children?: React.ReactNode | undefined } & OnboardingRouteControllerProps> {
    const { hook, items, Layout } = config;

    const controller = {
        route: createOnboardingAssetRouteController(config, items),
        strategy: createStrategyAssetController(config),
    };

    const instances = items.map((item) => {
        const instance = item.create({
            Layout: Layout.Item,
        });
        return {
            ...item,
            Component: createOnboardingAssetStrategyContainer(
                controller.strategy,
                instance.Component
            ),
        };
    });

    const View = createOnboardingAssetRouteView(config, instances);

    return (props) => {
        const organization = hook.useAccount();
        const [searchParams, setSearchParams] = useSearchParams();
        // const workspace = hook.useWorkspace();
        const auth = hook.useAuth();

        const context: AccountScope = {
            account: organization,
            // workspace,
            auth,
        };

        const viewProps = controller.route.useProps(props);

        useEffect(() => {
            // searchParams.set()
            setSearchParams(searchParams);
        }, []);

        return <View {...viewProps} />;
    };
}
