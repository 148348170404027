import { Box, ButtonGroup, Text, Tooltip, Button, Icon } from '@chakra-ui/react';
import { observer } from 'mobx-react';
import React from 'react';
import { CardSpinner } from '../../../../domain';
import { OnboardingAssetRouteStrategy } from '../../../route';
import { OnboardingAssetPrepareConfig } from './onboardingPrepareConfig';
import { createOnboardingPrepareView } from './onboardingPrepareView';
import { AiOutlineArrowRight } from 'react-icons/ai';

// export const INSIGHT_WORKSPACE_ITEM_ID = 'insights';

export function createOnboardingPrepareItem(
    config: OnboardingAssetPrepareConfig
): OnboardingAssetRouteStrategy {
    const { provider, loader, hooks } = config;
    const Component = createOnboardingPrepareView();
    const controller = provider.createController(config);
    return {
        id: 'prepare',
        name: 'Preparing asset',
        slug: 'prepare',
        create(config) {
            const {
                Layout: { Container, Content },
            } = config;
            return {
                Component: observer((props) => {
                    const account = hooks.useAccount();
                    const workspace = hooks.useWorkspace();
                    const auth = hooks.useAuth();
                    const context = { account, workspace, auth };

                    const data = loader.prepare.useData(context, {
                        asset: props.asset,
                    });

                    const viewProps = controller.useProps(context, data, {
                        asset: props.asset,
                        onContinue: props.onContinue,
                    });

                    if (viewProps.status === 'loading') {
                        return <CardSpinner />;
                    }

                    const formProps = viewProps.getFormProps();

                    return (
                        <Container
                            title="Finalizing your account"
                            description={
                                <Text>
                                    We are loading your data and generating benchmarks
                                </Text>
                            }
                            rightContent={
                                <Tooltip
                                    placement="top"
                                    hasArrow={true}
                                    isDisabled={formProps.isSubmittable}
                                    label={<Box p={2}>{formProps.submitLabel}</Box>}
                                >
                                    <span>
                                        <Button
                                            onClick={formProps.onSubmit}
                                            colorScheme="green"
                                            rightIcon={<Icon as={AiOutlineArrowRight} />}
                                            isDisabled={!formProps.isSubmittable}
                                            isLoading={formProps.isLoading}
                                        >
                                            Start benchmarking
                                        </Button>
                                    </span>
                                </Tooltip>
                            }
                        >
                            <Content isLoading={false}>
                                <Component {...viewProps.getViewProps()} />
                            </Content>
                        </Container>
                    );
                }),
            };
        },
    };
}
