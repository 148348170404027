import { useQueryClient } from '@tanstack/react-query';
import { SubscriptionCreateRequest, Subscription, ReportSdk } from '@varos/report-sdk';
import { Kernel } from '../../../base';
import { ReportSubscriptionRepository } from './reportSubscriptionInterface';

export function createReportSubscriptionRepository(
    kernel: Kernel,
    api: ReportSdk
): ReportSubscriptionRepository {
    const {
        infra: { repository },
    } = kernel;
    const PREFIX = ['report', 'subscription'];

    return {
        useFind(context, props, options) {
            const query = repository.useQuery({
                ...options,
                queryKey: [...PREFIX, 'find', props],
                async queryFn() {
                    const response = await api.subscriptionList(props);
                    return response.data.data;
                },
            });
            return query;
        },
        useLookup(context, props, options) {
            const query = repository.useQuery({
                ...options,
                queryKey: [...PREFIX, 'lookup', props],
                async queryFn() {
                    const response = await api.subscriptionRetrieve(props);
                    return response.data;
                },
            });
            return query;
        },
        useCreate(context) {
            const client = useQueryClient();
            const mutation = repository.useMutation(context, {
                async mutationFn(props: SubscriptionCreateRequest) {
                    const response = await api.subscriptionCreate({
                        subscriptionCreateRequest: props,
                    });
                    return response.data;
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(PREFIX);
                },
            });
            return mutation;
        },
        useDelete(context) {
            const client = useQueryClient();
            const mutation = repository.useMutation(context, {
                async mutationFn(props: Subscription) {
                    const response = await api.subscriptionDelete({
                        subscriptionId: props.id,
                    });
                    return;
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(PREFIX);
                },
            });
            return mutation;
        },
    };
}
