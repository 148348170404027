import { AiOutlineWarning } from 'react-icons/ai';
import { BaseApplicationShellControllerConfig } from '../../base';
import { ApplicationWorkspaceController } from './applicationWorkspaceInterface';
import { ApplicationWorkspaceItem } from './applicationWorkspaceModel';
import { ApplicationWorkspaceViewProps } from './applicationWorkspaceProps';

export function createApplicationWorkspaceController(
    config: BaseApplicationShellControllerConfig
): ApplicationWorkspaceController {
    const {
        view,
        kernel: { controller },
    } = config;

    const selectController = view.createSelect<ApplicationWorkspaceItem>({
        id: 'workspace',
        placeholder: 'Select company',
        getProps(item) {
            return {
                value: item.asset.id.toString(),
                label: item.asset.name,
            };
        },
    });

    const searchController = view.createSearch<ApplicationWorkspaceItem>({
        id: 'workspace',
        placeholder: 'Search companies',
        empty: 'No companies found',
        debounceMs: null,
        getProps(item) {
            return {
                id: item.asset.id.toString(),
                term: item.asset.name.toLowerCase(),
            };
        },
    });

    return {
        useProps(context, item, props): ApplicationWorkspaceViewProps {
            const { account: organization } = item;
            const browser = controller.browser.useProps({});
            const search = searchController.useProps({
                ...props.search,
                items: item.workspaces,
            });
            const select = selectController.useProps({
                ...props.select,
                items: item.workspaces,
            });
            return {
                focusRef: props.focusRef,
                browser,
                search,
                select,
                items: item.workspaces.flatMap((item) => {
                    const selectProps = select.getItemProps(item);
                    const searchProps = search.getItemProps(item);
                    if (!searchProps.isVisible) {
                        return [];
                    }
                    return [
                        {
                            id: selectProps.id,
                            label: item.asset.name,
                            select: selectProps,
                            disabled: null,
                            status:
                                !item.asset.claim || item.asset.claim?.kind === 'active'
                                    ? null
                                    : {
                                          Icon: AiOutlineWarning,
                                          colorScheme: 'orange',
                                          tooltip: {
                                              label: 'Unverified company',
                                          },
                                      },
                        },
                    ];
                }),
                popover: {
                    isOpen: props.disclosure.isOpen,
                    onOpen: props.disclosure.onOpen,
                    onClose: props.disclosure.onClose,
                },
            };
        },
    };
}
