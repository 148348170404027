import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme } from '../../common';
import { DataSyncResource } from './datasyncInterface';
import { DataSyncDtoSchema, DataSyncStartResponseDtoSchema } from './datasyncSchema';

export function createDataSyncResource(client: AxiosInstance): DataSyncResource {
    return {
        async create(context, request) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.post(`/api/v1/data-ingestion/sync`, request, {
                params: {
                    ...params,
                },
                headers: {
                    ...headers,
                },
            });

            const parsed = DataSyncStartResponseDtoSchema.parse(response.data);
            return parsed;
        },
        async get(context, id) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get(`/api/v1/data-ingestion/sync/${id}`, {
                params: {
                    ...params,
                },
                headers: {
                    ...headers,
                },
            });

            const parsed = DataSyncDtoSchema.parse(response.data);
            return parsed;
        },
        async cancel(context, id) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.delete(`/api/v1/data-ingestion/sync/${id}`, {
                params: {
                    ...params,
                },
                headers: {
                    ...headers,
                },
            });
            return;
        },
    };
}
