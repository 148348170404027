import { ReportSdk } from '@varos/report-sdk';
import { Kernel } from '../../../base';
import { ReportVersionRepository } from './reportVersionInterface';

export function createReportVersionRepository(
    kernel: Kernel,
    api: ReportSdk
): ReportVersionRepository {
    const {
        infra: { repository },
    } = kernel;
    const PREFIX = ['report', 'version'];
    return {
        useLookup(context, props, options) {
            const mutation = repository.useQuery({
                ...options,
                queryKey: [...PREFIX, context.principal?.id, props],
                async queryFn() {
                    const response = await api.versionRetrieve({
                        versionId: props.version.id,
                    });
                    return response.data;
                },
            });
            return mutation;
        },
    };
}
