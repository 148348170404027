import { useQuery } from '@tanstack/react-query';
import { RecommendationAdapter } from './recommendationAdapter';
import { RecommendationRepository } from './recommendationInterface';

export function createRecommendationRepository(
    adapter: RecommendationAdapter
): RecommendationRepository {
    const PREFIX = ['personalization', 'recommendation'];
    return {
        useFind(context, props, options) {
            const query = useQuery({
                queryKey: [...PREFIX, props, 'find'],
                async queryFn() {
                    try {
                        const response = await adapter.find(context, props);
                        return response;
                    } catch (error) {
                        console.error('failed recommendations', error);
                        return [];
                    }
                },
                staleTime: Infinity,
                ...options,
            });
            return query;
        },
    };
}
