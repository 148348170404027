import * as React from 'react';
import {
    Navigate,
    useLocation,
    useNavigate,
    Route,
    Outlet,
    useParams,
    useSearchParams,
} from 'react-router-dom';
import { LightMode } from '@chakra-ui/react';
import { VarosRoutes } from '../../infra';
import { observer } from 'mobx-react';
import { ChoosePlanRedirectPage } from './OnBoarding/choosePlan';
import { useStore } from '../../stores/setupContext';
import LoginSection from './auth/Login/Login';
import RegisterSection from './OnBoarding/Registration';
import MainOnBoarding from './OnBoarding/Main';
import OAuthReturn from './Integrations/OAuthReturn';
import ChangePasswordRequest from './auth/ChangePassword/RequestChangePassword';
import ChangePasswordResponse from './auth/ChangePassword/ResponseChangePassword';
import PasswordLessLogin from './auth/PasswordLessLogin/RequestPasswordLessLogin';

import { Box, Center, Container, Flex, Heading, VStack } from '@chakra-ui/react';
import { Image, Spinner, Text } from '@chakra-ui/react';
import { FeatureFlagContext } from '../../v2/middleware/feature-flag/featureFlagConstant';
// import Logo from '../../icons/logo.png';
import Logo from '../../svg/varos-logo-small-white.svg';
import { HomeContextProvider } from './main/context';
import { getPageAnalyticBasedOnUrl } from '../../utils/segmentAnalytics';
import { PlanPage } from '../../pages';
import { configureApplicationEntrypoint, useFeatureApi } from '../../v2';
import { SocialCallback } from './OnBoarding/SocialCallback';
import ConnectCardPage from './Integrations/ConnectCardPage';
import { FeatureFlags } from '../../v2/middleware';
import { AUTO_OPEN_URL_KEY } from '../../v2/strategy';

const ApplicationEntrypoint = ApplicationEntrypointStatic;

export interface DashboardManagerProps {
    // authToken: AuthStore['authToken'];
    // isLoading: boolean;
}

// height: '100%',
// backgroundColor: 'rgba(0,0,0,0.5)',
// display: 'flex',
// justifyContent: 'center',
// alignItems: 'center',

function Maintenance(): React.ReactElement {
    return (
        <Container
            h="100vh"
            flexDir="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <VStack spacing={4}>
                <Image src={Logo} width={300} />
                <Heading textAlign="center" fontSize="xx-large" fontWeight="bold">
                    We're currently updating Varos' dashboard to version 2.0. Everything
                    will be back up and running this week!
                </Heading>
                <Text>
                    Email <a href="mailto:yarden@varos.io">yarden@varos.io</a> if you need
                    anything in the meantime.
                </Text>
            </VStack>
        </Container>
    );
}

export function NoBrandConfigured(): React.ReactElement {
    return (
        <Container
            h="100vh"
            flexDir="column"
            display="flex"
            justifyContent="center"
            alignItems="center"
        >
            <VStack spacing={4}>
                <Image src={Logo} width={300} />
                <Heading textAlign="center" fontSize="xx-large" fontWeight="bold">
                    We're setting up your account. the account will be available shortly
                </Heading>
                <Text>
                    Email <a href="mailto:yarden@varos.io">yarden@varos.io</a> if you need
                    anything in the meantime.
                </Text>
            </VStack>
        </Container>
    );
}

function NoLoginMain() {
    return <Outlet />;
}

const SignoutSection: React.FC<
    { children?: React.ReactNode | undefined } & { children?: React.ReactNode }
> = () => {
    const { auth } = useStore();
    React.useEffect(() => {
        console.log('signing out');
        auth.logout();
    });
    return <></>;
};

const DashboardManager: React.FC<
    { children?: React.ReactNode } & DashboardManagerProps
> = (
    {
        // authToken,
        // isLoading,
    }
) => {
    return (
        <Box>
            <React.Suspense
                fallback={
                    <Flex h="full" justify="center" alignItems="center">
                        <Container centerContent maxW="xl">
                            <VStack
                                w="full"
                                h="full"
                                p={10}
                                spacing={8}
                                alignItems="flex-center"
                            >
                                <Center>
                                    <Spinner />
                                </Center>
                            </VStack>
                        </Container>
                    </Flex>
                }
            >
                <VarosRoutes>
                    <Route path="*">
                        <Route
                            element={
                                <LightMode>
                                    <Outlet></Outlet>
                                </LightMode>
                            }
                        >
                            <Route path="login">
                                <Route index element={<LoginSection />} />
                                <Route
                                    path="passwordless"
                                    element={<PasswordLessLogin />}
                                />
                            </Route>
                            <Route path="logout" element={<SignoutSection />} />
                            <Route path="register" element={<RegisterSection />} />
                            <Route
                                path="forgot-password/request"
                                element={<ChangePasswordRequest />}
                            />
                            <Route
                                path="forgot-password/response"
                                element={<ChangePasswordResponse />}
                            />
                            <Route path="oauth/cb" element={<OAuthReturn />} />
                            <Route path="integrations/cb" element={<OAuthReturn />} />
                            <Route path="on-boarding/*" element={<MainOnBoarding />} />
                            <Route path="redirect-social" element={<SocialCallback />} />
                            <Route path="settings/plan" element={<PlanPage />} />
                            <Route path="redirect" element={<ChoosePlanRedirectPage />} />
                        </Route>
                        <Route index element={<MainRouter />} />
                    </Route>

                    {ApplicationEntrypoint.root}

                    <Route
                        path="u/integrations/:integrationId/connect-card"
                        element={<ConnectCardPage />}
                    />
                    <Route path="u/integrations/definitions">
                        <Route
                            path=":integrationDefinitionId/connect-card"
                            element={<ConnectCardPage />}
                        />
                    </Route>

                    {/* <Route path="/u/:subject/:subjectId/*" element={<Main />} /> */}
                    {/* <Route index element={<MainRouter />} /> */}
                    {/* <Route >
                        <Maintenance />
                    </Route> */}
                </VarosRoutes>
            </React.Suspense>
        </Box>
    );
};

// const Observed = observer(DashboardManager);

const MainRouter = observer(function (): React.ReactElement {
    const { auth } = useStore();
    const { requiredState, isStarted } = auth;

    if (!isStarted) {
        return <Spinner />;
    }

    switch (requiredState?.state) {
        case 'login':
            return <Navigate to="/login" replace />;
        default:
            if (requiredState?.state === 'complete') {
                return <Navigate to="/u" replace />;
            } else {
                return <Navigate to="/u/onboarding" replace />;
            }
    }
});

import { useLocalStorage as useLocalStorageBase } from 'react-use';
import { FEATURE_FLAG_STORAGE_KEY } from '../../v2/config';
import { toJS } from 'mobx';
import { ApplicationEntrypointStatic } from './ApplicationDepsStatic';
import { INTEGRATED_QUERY_PARAM_KEY } from '../../hooks';

const {
    repository: {
        platform: { clientPreference },
    },
    hook: {
        onboarding: { useLocalStorage },
    },
} = ApplicationEntrypoint;

const WithStoreConnection = () => {
    const { auth } = useStore();
    const navigate = useNavigate();
    const router = useLocation();
    const [searchParams] = useSearchParams();

    const didIntegrate = Boolean(searchParams.get(INTEGRATED_QUERY_PARAM_KEY));

    // console.log(
    //     'auth.currentUser?.organizations?.[0]?.organization?.id',
    //     auth.currentUser?.organizations?.[0]?.organization?.id
    // );

    const accountId = auth.loggedInAccount?.id ?? null;

    const shouldLoadPreferences = !!accountId;
    const preference = clientPreference.useLookup(
        {
            auth: { user: null, scheme: { kind: 'legacy', store: auth } },
            // careful unsafe but we guard with enabled flag
            account: { id: accountId! },
        },
        {},
        { enabled: shouldLoadPreferences }
    );

    const [onboardingState, setOnboardingState, clearOnBoardingState] =
        useLocalStorage(accountId);
    const ignoreOnBoardingState = React.useMemo(() => {
        if (onboardingState?.account) {
            if (!accountId || onboardingState.account !== onboardingState.account) {
                return true;
            }
        } else {
            // if there's no account and step is not profile. ignore that state
            return onboardingState?.step !== 'profile';
        }
    }, [accountId, onboardingState?.account]);

    React.useEffect(() => {
        if (ignoreOnBoardingState) {
            clearOnBoardingState();
        }
    }, [ignoreOnBoardingState]);

    // Hack, this should come from the middleware provider, but
    // this part of the codebase is not integrated with the new entrypoint
    // infrastructure
    const [featureFlags] = useLocalStorageBase<FeatureFlags>(FEATURE_FLAG_STORAGE_KEY);
    const skipOnboardingRedirect = featureFlags?.bypassWorkspaceOnboarding?.enabled;

    const { requiredState, isStarted } = auth;

    // const prevReqState = usePrevious(requiredState);
    const managePageAnalytic = React.useCallback(() => {
        if (!router || !auth.currentUser) {
            return;
        }
        const analytic = getPageAnalyticBasedOnUrl(router.pathname);
        if (analytic) {
            const userData = {
                email: auth.currentUser.email,
                first_name: auth.currentUser.first_name,
                last_name: auth.currentUser.last_name,
                USER_ID: auth.currentUser.id,
                groupId: auth.loggedInAccount?.id,
            };

            // race condiiton here with analytics not yet being loaded
            if (process.env.VAROS_ENV === 'production') {
                window.analytics?.page(undefined, analytic, userData);
            } else {
                console.log('event page!', analytic, userData);
            }
        }
    }, [router.pathname, auth.currentUser, window.analytics]);

    React.useEffect(() => {
        if (didIntegrate && !location.pathname.includes('/onboarding')) {
            console.log('auto navigating to recently created integration..');
            navigate(`/u/settings/integrations?${AUTO_OPEN_URL_KEY}=true`);
        }
    }, [didIntegrate]);

    React.useEffect(() => {
        managePageAnalytic();
    }, [router.pathname]);

    React.useEffect(() => {
        if (preference.status === 'loading' && shouldLoadPreferences) {
            // wait with redirects until client preferences are loaded
            // console.log('waiting for preferences to load', preference);
            return;
        }
        if (!isStarted) {
            console.log('auth not started');
            return;
        }

        if (
            (router.pathname === '/' || router.pathname === '/u') &&
            preference.data?.layout === 'light'
        ) {
            console.info('navigating to light mode');
            navigate('/r', { replace: true });
            return;
        }

        if (router.pathname.startsWith('/settings')) {
            return;
        }
        if (router.pathname.startsWith('/legal')) {
            return;
        }
        if (router.pathname.startsWith('/tools')) {
            return;
        }
        if (router.pathname === '/r' || router.pathname.startsWith('/r/')) {
            return;
        }
        if (router.pathname === '/i' || router.pathname.startsWith('/i/')) {
            return;
        }
        if (
            router.pathname === '/invitations' ||
            router.pathname.startsWith('/invitations/')
        ) {
            return;
        }
        if (
            router.pathname.startsWith('/oauth/cb') ||
            router.pathname.startsWith('/integrations/cb')
        ) {
            return;
        }
        if (router.pathname.startsWith('/redirect')) {
            return;
        }
        // if (router.pathname.startsWith('/v2')) {
        //     return;
        // }
        if (router.pathname.startsWith('/dashboards')) {
            return;
        }
        if (router.pathname.startsWith('/workspaces')) {
            return;
        }

        switch (requiredState?.state) {
            case 'login':
                if (
                    !router.pathname.startsWith('/register') &&
                    !router.pathname.startsWith('/login') &&
                    !router.pathname.startsWith('/forgot-password')
                ) {
                    console.log('redirecting to login');
                    navigate('/login', { replace: true });
                }
                break;
            default:
                if (requiredState?.state === 'complete') {
                    if (!router.pathname.startsWith('/u')) {
                        if (auth.forwardTo) {
                            navigate(`/u/${auth.forwardTo}`, { replace: true });
                        } else {
                            navigate('/u', { replace: true });
                        }
                    }
                } else {
                    if (!router.pathname.includes('/onboarding')) {
                        if (
                            requiredState?.allow_dashboard_preview &&
                            router.pathname.startsWith('/u')
                        ) {
                            console.info('dashboard preview set');
                            return;
                        }

                        if (auth.loading) {
                            console.info('waiting for auth to load');
                            // wait until organization is loaded
                            return;
                        }

                        if (skipOnboardingRedirect && router.pathname.startsWith('/u')) {
                            console.log(
                                'skip onboarding feature flag enabled, disabling automatic redirect...'
                            );
                            return;
                        }

                        if (
                            router.pathname.startsWith('/u/settings') ||
                            router.pathname.includes('connect-card')
                        ) {
                            // HACK we need to be able to show the subscriptin management page and
                            // connect card during onboarding if a paywall is clicked
                            console.log(
                                'allowed settings and connect card, disabling automatic redirect...'
                            );
                            return;
                        }

                        if (ignoreOnBoardingState) {
                            console.log(
                                'account mismatch, restaring onboarding flow',
                                accountId,
                                onboardingState?.account
                            );
                            navigate('/u/onboarding?step=profile', { replace: true });
                            return;
                        }

                        console.log('redirecting', accountId, onboardingState?.step);

                        if (onboardingState?.step === 'profile') {
                            console.log('navigating to profile');
                            navigate('/u/onboarding?step=profile', { replace: true });
                        } else if (onboardingState?.step === 'integration') {
                            console.log('navigating to integrations');
                            navigate('/u/onboarding?step=integration', { replace: true });
                        } else if (
                            !ignoreOnBoardingState &&
                            onboardingState?.step === 'mapping' &&
                            onboardingState.asset
                        ) {
                            console.log('navigating to mapping');
                            navigate(
                                `/u/assets/${onboardingState.asset}/onboarding?step=mapping`,
                                { replace: true }
                            );
                        } else if (
                            onboardingState?.step === 'peer_groups' &&
                            onboardingState.asset
                        ) {
                            console.log('navigating to peer groups');
                            navigate(
                                `/u/assets/${onboardingState.asset}/onboarding?step=peer_groups`,
                                { replace: true }
                            );
                        } else if (
                            onboardingState?.step === 'metrics' &&
                            onboardingState.asset
                        ) {
                            navigate(
                                `/u/assets/${onboardingState.asset}/onboarding?step=metrics`,
                                { replace: true }
                            );
                        } else if (
                            onboardingState?.step === 'prepare' &&
                            onboardingState.asset
                        ) {
                            navigate(
                                `/u/assets/${onboardingState.asset}/onboarding?step=prepare`,
                                { replace: true }
                            );
                        } else {
                            console.log('navigating to start of onboarding');
                            navigate('/u/onboarding', { replace: true });
                        }
                    }
                }
                break;
        }
    }, [
        requiredState,
        isStarted,
        accountId,
        onboardingState,
        auth.loading,
        preference.data,
    ]);

    return <DashboardManager />;
};

export const ApplicationRouter = observer(WithStoreConnection);
