import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { ReportV2Api, ReportV2Dto } from '../../../api';
import { MockReportMiddlewareConfig } from './mockReportConfig';
import { ExternalReportContext, ExternalReportDispatch } from './mockReportInterface';

export function createMockReportEnhancer(
    init: MockReportMiddlewareConfig
): ApplicationEntryEnhancer {
    // const overrides: ReportV2Dto[] = [...Object.values(ReportDefinitions)];

    return (create) => (config) => {
        function formatParameterKey(key: string) {
            // NOTE see the below link for formatting rules
            // https://learn.hex.tech/docs/share-insights/apps/publish-and-share-apps#manually-specify-input-parameter-values-as-url-parameters
            return `_${key}`;
        }
        function formatHexKeyAndValue(
            key: string,
            value: string | boolean | number | null
        ): [string, string] {
            // NOTE see the below link for formatting rules
            // https://learn.hex.tech/docs/share-insights/apps/publish-and-share-apps#manually-specify-input-parameter-values-as-url-parameters
            let formatted = value;
            if (typeof value === 'string') {
                formatted = `"${value}"`;
            } else if (value === null) {
                formatted = 'null';
            } else {
                formatted = value.toString();
            }
            return [`_${key}`, formatted];
        }

        function buildUrl(dispatch: ExternalReportDispatch) {
            const url = new URL(dispatch.url);

            // Only append params if they exist and are not null
            if (dispatch.params) {
                const searchParams = new URLSearchParams(
                    Object.entries(dispatch.params).map(([key, value]) => {
                        if (dispatch.url.includes('app.hex.tech')) {
                            return formatHexKeyAndValue(key, value);
                        }
                        return [key, String(value)];
                    })
                );
                url.search = searchParams.toString();
            }

            return url.toString();
        }

        function enhanceReportApi(api: ReportV2Api): ReportV2Api {
            return {
                ...api,
                report: {
                    ...api.report,
                    async list(context, query, options) {
                        const token =
                            context.auth.scheme.kind === 'legacy'
                                ? context.auth.scheme.store.authToken
                                : context.auth.scheme.token;

                        const urlContext: ExternalReportContext = {
                            ...query,
                            token,
                        };
                        await new Promise((resolve) => setTimeout(resolve, 500));
                        const resolved = init.reports
                            .filter((candidate) =>
                                query.status
                                    ? query.status.includes(candidate.entity.status)
                                    : true
                            )
                            .map((item): ReportV2Dto => {
                                if (item.kind === 'external') {
                                    const dispatch = item.getTargetUrl(urlContext);
                                    return {
                                        kind: item.kind,
                                        ...item.entity,
                                        url: buildUrl(dispatch),
                                    };
                                }
                                return { kind: item.kind, ...item.entity };
                            });
                        return {
                            data: resolved,
                        };
                    },
                    async get(context, query) {
                        const token =
                            context.auth.scheme.kind === 'legacy'
                                ? context.auth.scheme.store.authToken
                                : context.auth.scheme.token;
                        const urlContext: ExternalReportContext = { ...query, token };
                        const resolved = init.reports.map((item): ReportV2Dto => {
                            if (item.kind === 'external') {
                                const dispatch = item.getTargetUrl(urlContext);

                                return {
                                    kind: item.kind,
                                    ...item.entity,
                                    url: buildUrl(dispatch),
                                };
                            }
                            return { kind: item.kind, ...item.entity };
                        });
                        const found = resolved.find(
                            (candidate) => candidate.id === query.id
                        );
                        if (found) {
                            await new Promise((resolve) => setTimeout(resolve, 600));
                            return found;
                        }
                        throw new Error(`report ${query.id} not found`);
                        // return api.report.get(context, id);
                    },
                },
            };
        }

        return create({
            ...config,
            api: {
                ...config.api,
                createReportApiV2(...args) {
                    const api = config.api.createReportApiV2(...args);
                    return enhanceReportApi(api);
                },
            },
        });
    };
}
