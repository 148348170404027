import { createContext, useContext } from 'react';
import { AccountEntity, AccountReference, AuthenticationV2 } from '../../../domain';
import { AccountDependencies } from './accountInterface';

export interface UserScope {
    auth: AuthenticationV2;
}

export interface AccountScope extends UserScope {
    account: AccountReference;
}

export interface AccountScopeValue {
    account: Pick<AccountEntity, 'id'>;
    adapter: AccountDependencies;
}

export const AccountScopeContext = createContext<AccountScopeValue | null>(
    null
);

export const useAccountScope = () => {
    const context = useContext(AccountScopeContext);
    if (!context) {
        throw new Error(`account context not available`);
    }
    return context;
};
