import { z } from 'zod';
import { LEGAL_LAYOUT_RETURN_PATH_KEY } from './legalLayoutConstant';
import { LegalLayoutControllerProps } from './legalLayoutProps';

export const LegalLayoutUrlParamSchema = z
    .object({
        [LEGAL_LAYOUT_RETURN_PATH_KEY]: z.string().optional(),
    })
    .transform(
        (item): LegalLayoutControllerProps => ({
            back: item.return_path ? { to: item.return_path, replace: true } : null,
        })
    );
