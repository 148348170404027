import { SubmissionItemAggregate } from '../../../../view/studies';
import { assert } from '../../../../util/assert';
import { StudiesLoaderBaseConfig } from '../../base';
import { SurveyDetailLoader } from './surveyDetailInterface';

export function createSurveyDetailLoader(
    config: StudiesLoaderBaseConfig
): SurveyDetailLoader {
    const { repository } = config;
    const MAX_SUBMISSION_SHOW = 3;
    return {
        useLoad(context, props) {
            const surveyQuery = repository.survey.useLookup(context, props.survey, {
                suspense: true,
            });
            const submissionQuery = repository.submission.useFind(
                context,
                {
                    dataset: props.survey,
                    asset: context.workspace ? {
                        id: context.workspace.id as number,
                    }: null,
                },
                {
                    suspense: true,
                }
            );
            assert(surveyQuery.status === 'success', 'expected suspense');
            assert(submissionQuery.status === 'success', 'expected suspense');

            return {
                survey: surveyQuery.data,
                submission: {
                    items: submissionQuery.data
                        .map(
                            (submission): SubmissionItemAggregate => ({
                                submission,
                                report: null,
                                survey: surveyQuery.data,
                                data: null,
                                respondents: null,
                                user: null,
                            })
                        )
                        .slice(0, MAX_SUBMISSION_SHOW),
                },
            };
        },
    };
}
