import pluralize from 'pluralize';
import { AccountEntity } from '../../../../domain';
import { getAssetDomain } from '../../../../domain/assets';
import { AssetListItemAggregate } from './assetItemModel';
import { AssetListItemProps } from './assetItemProps';

export function buildAssetItemProps(
    organization: AccountEntity,
    item: AssetListItemAggregate,
    index: number
): AssetListItemProps {
    return {
        id: item.asset.id,
        title: item.asset.name,
        secondary: getAssetDomain(item.asset) ?? 'N/A',
        isLocked: false,
        isDefault: organization.state.defaultAsset
            ? organization.state.defaultAsset.id === item.asset.id
            : index === 0,
        lockedReason: null,
        getLinkProps() {
            return { to: `/u/assets/${item.asset.id}/home` };
        },
        getButtonProps() {
            return {};
        },
        getButtonTooltipProps() {
            return {
                isDisabled: true,
            };
        },
        getDataSourceProps() {
            if (item.mappings.length === 0) {
                return {
                    label: 'No data sources',
                    to: `/u/assets/${item.asset.id}/settings/data-sources`,
                };
            }
            const plural = pluralize('data source', item.mappings.length);
            return {
                to: `/u/assets/${item.asset.id}/settings/data-sources`,
                label: `${item.mappings.length} ${plural}`,
            };
        },
    };
}
