import React from 'react';
import { AcceptOrganizationViewProps } from './acceptOrganizationProps';
import { RegistrationViewConfig } from '../../../../../base';
import { Alert, AlertDescription, AlertIcon, AlertTitle, VStack } from '@chakra-ui/react';
import { StepLayoutViewProps } from '../common';

export function createRegistrationAcceptOrganizationView(
    config: RegistrationViewConfig,
    Layout: React.FC<StepLayoutViewProps>
): React.FC<AcceptOrganizationViewProps> {
    const {
        UI: {
            Form: { FieldList, FieldItem, Input, InputGroup, InputLeftAddon },
        },
    } = config;
    return (props) => (
        <Layout {...props.layout}>
            <VStack align="start" w="full" spacing={6}>
                {props.form.error && (
                    <Alert status="error">
                        <AlertIcon />
                        <AlertDescription>{props.form.error.label}</AlertDescription>
                    </Alert>
                )}
                <FieldList w="full" spacing={4}>
                    <FieldItem
                        {...props.form.getFieldItemProps('name')}
                        label="Company name"
                    >
                        <InputGroup>
                            <Input
                                {...props.form.form.register('name')}
                                placeholder="Enter company name"
                            />
                        </InputGroup>
                    </FieldItem>
                    <FieldItem
                        {...props.form.getFieldItemProps('website')}
                        label="Website URL"
                        description="A valid top-level domain name that can be verified by our servers"
                    >
                        <InputGroup>
                            <InputLeftAddon>https://</InputLeftAddon>
                            <Input
                                {...props.form.form.register('website')}
                                placeholder="Enter website URL"
                            />
                        </InputGroup>
                    </FieldItem>
                </FieldList>
            </VStack>
        </Layout>
    );
}
