import { useNavigate, useSearchParams } from 'react-router-dom';
import {
    OnboardingAccountRouteConfig,
    OnboardingAssetRouteConfig,
} from '../onboardingRouteConfig';
import { OnboardingOrganizationRouteSearchParams } from '../onboardingRouteSchema';
import { getOnboardingRouting } from '../../../domain/onboarding';
import {
    OnboardingStrategyAssetController,
    OnboardingStrategyOrganizationController,
} from './strategyInterface';

export function createStrategyOrganizationController(
    config: OnboardingAccountRouteConfig
): OnboardingStrategyOrganizationController {
    const { service, hook } = config;
    return {
        useProps(props) {
            const navigate = useNavigate();
            const [searchParams, setSearchParams] = useSearchParams();

            const mapped = [...searchParams.entries()].reduce(
                (acc, [key, value]) => ({ ...acc, [key]: value }),
                {} as Record<string, string>
            );

            // this is only safe because al the query parameters are optional
            const parsed = OnboardingOrganizationRouteSearchParams.parse(mapped);

            const onboardingOrgState = service.organization.useLookup({
                auth: hook.useAuth(),
                account: hook.useAccount(),
            });

            const routing = getOnboardingRouting(onboardingOrgState.data, {
                slug: parsed.step!,
            });

            // console.log('onboardingOrgState', onboardingOrgState.data);
            // console.log('routing', routing);

            if (routing.next) {
                searchParams.set('step', routing.next.slug);
            }

            const nextQuery = searchParams.toString();

            let successPath = parsed.success_path
                ? parsed.success_path
                : routing.next
                  ? `/u/onboarding?${nextQuery}`
                  : parsed.source_path ?? '/u';

            const handleBack = () => {
                if (parsed.cancel_path) {
                    navigate(parsed.cancel_path, { replace: true });
                }
            };

            return {
                onContinue(params) {
                    // if (routing.next === null && params?.asset) {
                    //     // HACK currently we navigate straight to the asset onboarding after last organization step
                    //     // to avoid the theme flickering when relying on automatic redirect from
                    //     // onboarding middleware
                    //     return navigate(`/u/assets/${params.asset}/onboarding`);
                    // }
                    // if (successPath) {
                    return navigate(successPath, { replace: true });
                    // }
                },
                onBack: handleBack,
            };
        },
    };
}

export function createStrategyAssetController(
    config: OnboardingAssetRouteConfig
): OnboardingStrategyAssetController {
    const { service, hook } = config;
    return {
        useProps(props) {
            const navigate = useNavigate();
            const [searchParams, setSearchParams] = useSearchParams();

            const mapped = [...searchParams.entries()].reduce(
                (acc, [key, value]) => ({ ...acc, [key]: value }),
                {} as Record<string, string>
            );

            // this is only safe because al the query parameters are optional
            const parsed = OnboardingOrganizationRouteSearchParams.parse(mapped);

            const context = {
                auth: hook.useAuth(),
                account: hook.useAccount(),
                workspace: hook.useWorkspace(),
            };
            const onboardingAssetState = service.asset.useLookup(context);

            const routing = getOnboardingRouting(onboardingAssetState.data, {
                slug: parsed.step ?? onboardingAssetState.data.steps[0].id,
            });

            const handleFinish = () => {
                if (routing.next?.slug) {
                    searchParams.set('step', routing.next?.slug);
                }

                const nextQuery = searchParams.toString();

                let successPath = parsed.success_path
                    ? parsed.success_path
                    : routing.next
                      ? `/u/assets/${context.workspace.id}/onboarding?${nextQuery}`
                      : parsed.source_path ?? `/u/assets/${context.workspace.id}/home`;

                if (successPath) {
                    console.log('navigating success', successPath, routing);
                    navigate(successPath, { replace: true });
                }
            };

            const handleBack = () => {
                if (parsed.cancel_path) {
                    navigate(parsed.cancel_path, { replace: true });
                }
            };

            return {
                asset: props.asset,
                onContinue(context) {
                    handleFinish();
                },
                onBack: handleBack,
            };
        },
    };
}
