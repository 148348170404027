import { WorkspaceReference, WorkspaceWithStateReference } from '../../../domain/platform';
import { AccountScope } from '../account';

export interface WorkspaceContextV2 extends AccountScope {
    workspace: WorkspaceReference;
}

export interface WorkspaceStateContextV2 extends AccountScope {
    workspace: WorkspaceWithStateReference;
}

export type AnyContext = WorkspaceContextV2 | AccountScope;

export function isWorkspaceContext(context: WorkspaceContextV2 | AccountScope): context is WorkspaceContextV2{
    return !!(context as WorkspaceContextV2).workspace;
}