import { useMemo, useState } from 'react';
import { chain } from 'lodash';
import { ReportScheduleDto, ReportScheduleReference } from '../../../../api';
import { Workspace } from '../../../../domain';
import { ScheduleListViewProps } from '../../..';
import { useAccountContextV2 } from '../../../../context';
import { NotificationSubscriptionControllerConfig } from './scheduleListConfig';
import { ScheduleListController } from './scheduleListInterface';

const SCHEDULES: ReportScheduleDto['schedule'][] = ['weekly', 'monthly', 'quarterly'];

export function createScheduleListController(
    init: NotificationSubscriptionControllerConfig
): ScheduleListController {
    const {
        service: {
            schedule: {
                useList: useSchedules,
                useUpdate: useUpdateSchedule,
                useToggle: useToggleSchedule,
            },
        },
    } = init;
    return {
        useProps(context, props): ScheduleListViewProps {
            const {
                data: {
                    // workspaces: { data: workspaces = [] },
                    workspaces,
                },
            } = useAccountContextV2();
            // const workspaces = useWorkspaces(context);

            const filtered = useMemo(
                () => workspaces.filter((item) => item.visible),
                [workspaces]
            );

            const schedules = useSchedules(context, filtered);

            const [editScheduleId, setScheduleEditId] =
                useState<ReportScheduleReference | null>(null);

            const editSchedule = useMemo(
                () =>
                    editScheduleId
                        ? schedules.find(
                              (item) =>
                                  item.asset == editScheduleId.asset &&
                                  item.key == editScheduleId.key
                          )
                        : null,
                [schedules, editScheduleId]
            );

            const updateSchedule = useUpdateSchedule(context);
            const toggleSchedule = useToggleSchedule(context);

            const workspaceById = useMemo(
                () =>
                    workspaces.reduce(
                        (acc, workspace) => ({ ...acc, [workspace.id]: workspace }),
                        {} as Record<string, Workspace>
                    ),
                [workspaces]
            );

            const schedulesMapped = useMemo(
                () =>
                    chain(schedules)
                        .flatMap((schedule) => {
                            const workspace = workspaceById[schedule.asset];
                            if (!workspace) {
                                console.error(`workspace '${schedule.asset}' not found`);
                            }
                            return workspaceById[schedule.asset]
                                ? [
                                      {
                                          ...schedule,
                                          workspace,
                                          recipients: chain(schedule.recipients).value(),
                                      },
                                  ]
                                : [];
                        })
                        .orderBy(
                            (value) => [
                                value.workspace.name,
                                SCHEDULES.findIndex(
                                    (candidate) => candidate === value.schedule
                                ),
                            ],
                            ['asc', 'asc']
                        )
                        .value(),
                [schedules, workspaceById]
            );

            return {
                getChildProps(schedule, index) {
                    return {
                        toggleProps: {
                            defaultChecked: schedule.enabled,
                            onChange(event) {
                                return toggleSchedule(schedule, {
                                    enabled: event.target.checked,
                                });
                            },
                        },
                        onEdit() {
                            setScheduleEditId(schedule);
                        },
                    };
                },
                modalProps: editSchedule
                    ? {
                          key: `${editSchedule.key}_${editSchedule.asset}`,
                          schedule: editSchedule,
                          isOpen: true,
                          onClose: setScheduleEditId.bind(null, null),
                          onSubmit(values) {
                              return updateSchedule(editSchedule, values);
                          },
                      }
                    : null,
                schedules: schedulesMapped,
            };
        },
    };
}
