import {
    Box,
    Button,
    Checkbox,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    SimpleGrid,
    StackDivider,
    Tag,
    Text,
    VStack,
    Link as ChakraLink,
    Wrap,
    useToken,
    Image,
    TextProps,
} from '@chakra-ui/react';
import React from 'react';
import { Icons } from '../../../../../../../config';
import { StudyItemViewProps } from '../../../../../../view/studies';
import { StudiesExploreRouteConfig } from '../../studiesReportListConfig';
import { StudiesExploreViewProps } from '../../studiesReportListProps';

export function createStudiesExploreMobileView(
    config: Pick<StudiesExploreRouteConfig, 'Layout' | 'UI'>
): React.FC<{ children?: React.ReactNode | undefined } & StudiesExploreViewProps> {
    const {
        UI: {
            Application: { Link: Link, Anchor, Tooltip },
        },
    } = config;

    const VAROS_PURPLE: TextProps['color'] = 'purple.400';
    const ACCESS_COLOR = VAROS_PURPLE;

    const ReportItem: React.FC<
        { children?: React.ReactNode | undefined } & StudyItemViewProps
    > = (props) => {
        return (
            <VStack
                align="start"
                w="full"
                h="full"
                spacing={3}
                cursor="pointer"
                _hover={{ borderColor: 'whiteAlpha.400' }}
            >
                <Link
                    style={{ width: '100%', height: '100%' }}
                    {...(props.links.responses
                        ? props.links.responses
                        : props.links.detail)}
                >
                    <VStack
                        w="full"
                        h="full"
                        align="start"
                        p={4}
                        position="relative"
                        justify="space-between"
                        spacing={3}
                    >
                        <HStack w="full" h="full" spacing={3} justify="space-between">
                            <HStack w="full" spacing={3}>
                                <Box p={3} bg="whiteAlpha.50" borderRadius="xl">
                                    <Icon w={5} h={5} as={Icons.Reports.Report}></Icon>
                                </Box>
                                <VStack align="start" w="full" spacing={0}>
                                    <HStack w="full" justify="space-between">
                                        <Text fontWeight="semibold" noOfLines={1}>
                                            {props.label}
                                        </Text>
                                        {/* {props.status.access.kind !==
                                        'missing_dependencies' && (
                                        <Tooltip
                                            placement="top"
                                            hasArrow={true}
                                            p={4}
                                            bg="#1D1C21"
                                            isDisabled={!props.status.access.description}
                                            label={props.status.access.description}
                                        >
                                            <Box
                                                as="span"
                                                cursor="default"
                                                flexShrink={0}
                                            >
                                                <Tag
                                                    colorScheme={
                                                        props.status.access.color
                                                    }
                                                >
                                                    {props.status.access.label}
                                                </Tag>
                                            </Box>
                                        </Tooltip>
                                    )} */}
                                    </HStack>
                                    <Text noOfLines={1} color="whiteAlpha.700">
                                        {props.tagline ?? props.description}
                                    </Text>
                                </VStack>
                            </HStack>
                            <HStack>
                                <Button
                                    flexShrink={0}
                                    size="sm"
                                    color="blue.400"
                                    bg="whiteAlpha.50"
                                    border="none"
                                    borderRadius="full"
                                    textTransform="uppercase"
                                    fontSize="sm"
                                    letterSpacing="wide"
                                    fontWeight="semibold"
                                    h={7}
                                >
                                    Open
                                </Button>
                            </HStack>
                        </HStack>
                    </VStack>
                </Link>
            </VStack>
        );
    };

    return (props) => {
        return (
            <VStack align="start" w="full" spacing={6}>
                {props.study.fulfilled.items.length > 0 && (
                    <VStack align="start" w="full" spacing={3}>
                        <VStack
                            align="start"
                            w="full"
                            spacing={0}
                            bg="whiteAlpha.50"
                            borderRadius="lg"
                            divider={
                                <StackDivider
                                    borderWidth={1}
                                    borderColor="whiteAlpha.100"
                                />
                            }
                        >
                            <HStack
                                w="full"
                                justify="space-between"
                                px={4}
                                py={3}
                                color="whiteAlpha.600"
                                fontSize="xs"
                                letterSpacing="wider"
                                textTransform="uppercase"
                                fontWeight="bold"
                            >
                                <Text>My reports</Text>
                            </HStack>
                            {props.study.fulfilled.items.map((item) => {
                                const itemProps =
                                    props.study.fulfilled.getItemProps(item);
                                return <ReportItem key={item.report.id} {...itemProps} />;
                            })}
                        </VStack>
                    </VStack>
                )}
                {props.study.unfulfilled.items.length > 0 && (
                    <VStack align="start" w="full" spacing={6}>
                        <VStack
                            align="start"
                            w="full"
                            spacing={0}
                            bg="whiteAlpha.50"
                            borderRadius="lg"
                            divider={
                                <StackDivider
                                    borderWidth={1}
                                    borderColor="whiteAlpha.100"
                                />
                            }
                        >
                            <HStack
                                w="full"
                                justify="space-between"
                                px={4}
                                py={3}
                                color="whiteAlpha.600"
                                fontSize="xs"
                                letterSpacing="wider"
                                textTransform="uppercase"
                                fontWeight="bold"
                            >
                                <Text>All reports</Text>
                            </HStack>
                            {props.study.unfulfilled.items.map((item) => {
                                const itemProps =
                                    props.study.unfulfilled.getItemProps(item);
                                return <ReportItem key={item.report.id} {...itemProps} />;
                            })}
                        </VStack>
                    </VStack>
                )}
            </VStack>
        );
    };
}
