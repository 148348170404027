import { useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { AssistantContainerBaseConfig } from '../../../base';
import { AssistantLayoutStateProvider } from './assistantLayoutInterface';

export function createAssistantLayoutStateProvider(
    config: AssistantContainerBaseConfig
): AssistantLayoutStateProvider {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
    } = config;
    const schema = z.object({
        show_detail: z.boolean({ coerce: true }).default(false),
    });
    return {
        useState() {
            const [searchParams, setSearchParams] = useSearchParams();
            const options = useOptions(schema);
            return {
                detail: {
                    value: options.show_detail,
                    onChange(value) {
                        if (value) {
                            searchParams.set('show_detail', 'true');
                        } else {
                            searchParams.delete('show_detail');
                        }
                        setSearchParams(searchParams, { replace: true });
                    },
                },
            };
        },
    };
}
