import { ApplicationAccountController, ShellLayoutEnhancer } from '../../../../../shell';
import { ApplicationEntryEnhancer } from '../../../../../entrypoint';
import { AnyUserManagmentEvent } from './userManagementTrackingEvent';

export function createSettingsUserManagementTrackingStrategy(): ApplicationEntryEnhancer {
    return (create) => (init) => {
        const useTracker = init.infra.createUseTracker();

        function createShellEnhancer(): ShellLayoutEnhancer {
            return (create) => (config) => {
                function enhanceController(
                    instance: ApplicationAccountController
                ): ApplicationAccountController {
                    return {
                        ...instance,
                        useProps(context, item, props) {
                            const tracker = useTracker<AnyUserManagmentEvent>();
                            return instance.useProps(context, item, {
                                ...props,
                                invite: {
                                    ...props.invite,
                                    async onSubmit(values) {
                                        const result =
                                            await props.invite.onSubmit(values);
                                        tracker.track('permissions_teammate_invited', {
                                            permissions_brand_scope:
                                                values.role == 'admin' ? 'org' : 'asset',
                                        });
                                        return result;
                                    },
                                },
                            });
                        },
                    };
                }
                return create({
                    ...config,
                    provider: {
                        ...config.provider,
                        createAccountController(...args) {
                            const instance = config.provider.createAccountController(
                                ...args
                            );
                            return enhanceController(instance);
                        },
                    },
                });
            };
        }

        const instance = create({
            ...init,
            enhancer: {
                ...init.enhancer,
                shell: [...init.enhancer.shell, createShellEnhancer()],
            },
            route: {
                ...init.route,
                // createAccountRoute(routeConfig) {
                //     return init.route.createAccountRoute({
                //         ...routeConfig,
                //         controller: {
                //             ...routeConfig.controller,
                //             useUserNavigation() {
                //                 const userNavProps =
                //                     routeConfig.controller.useUserNavigation();
                //                 const tracker = useTracker<AnyUserManagmentEvent>();
                //                 if(userNavProps.inviteUser) {

                //                         return {
                //                             ...userNavProps,
                //                             inviteUser: {
                //                                 ...userNavProps.inviteUser,
                //                                 props: {
                //                                     ...userNavProps.inviteUser.props,
                //                                     onSubmit(dto) {
                //                                         return userNavProps.inviteUser ? userNavProps.inviteUser.props
                //                                             .onSubmit(dto)
                //                                             .then((retVal) => {
                //                                                 tracker.track(
                //                                                     'permissions_teammate_invited',
                //                                                     {
                //                                                         permissions_brand_scope:
                //                                                             dto.role == 'admin'
                //                                                                 ? 'org'
                //                                                                 : 'asset',
                //                                                     }
                //                                                 );
                //                                                 return retVal;
                //                                             }): Promise.reject('error');
                //                                     },
                //                                 },
                //                             },
                //                         }
                //                 } else {
                //                     return userNavProps;
                //                 }
                //             },
                //         },

                //     });
                // },
            },
        });
        return instance;
    };
}
