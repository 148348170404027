import { useMemo } from 'react';
import { HomeRouteController, HomeRouteProps } from '../../route';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { ReportInvitationAdapter, ReportInvitationEntity, isWorkspaceContext } from '../../app';
import { ReportInvitationMiddlewareConfig } from './reportInvitationMiddlewareConfig';
import { PageItemAggregateV2 } from '../../view/page';
import { groupBySafe } from '../../util';
import { assert } from '../../util/assert';

export function createReportInvitationMiddleware(
    init: ReportInvitationMiddlewareConfig
): ApplicationEntryEnhancer {
    // const URL_KEY = 'preset';

    return (create) => (config) => {
        const invitationsByAsset = groupBySafe(init.invitations, (item) =>
            item.asset?.id?.toString() || 'account'
        );

        function enhanceInvitationAdapter(
            adapter: ReportInvitationAdapter
        ): ReportInvitationAdapter {
            return {
                ...adapter,
                async find(context, query) {
                    const response = await adapter.find(context, query);
                    const invitations = invitationsByAsset[isWorkspaceContext(context) ? context.workspace.id: 'account'] ?? [];
                    return [
                        ...response,
                        ...invitations
                            .filter((item) =>
                                query.report
                                    ? query.report.includes(item.report.id)
                                    : true
                            )
                            .map(
                                (item): ReportInvitationEntity => ({
                                    id: `inv_${item.report.id}_${item.asset?.id || context.account.id}`,
                                    object: 'invitation',
                                    title: item.title,
                                    asset: item.asset,
                                    report: item.report,
                                })
                            ),
                    ];
                },
            };
        }

        function enhanceHomeController(
            controller: HomeRouteController
        ): HomeRouteController {
            return {
                ...controller,
                useProps(context, item, ...args): HomeRouteProps {
                    const reportQuery = instance.repository.reports.report.useCollection(
                        context,
                        {},
                        { suspense: true }
                    );

                    assert(reportQuery.status === 'success', 'expected suspense');

                    const merged = useMemo((): PageItemAggregateV2 => {
                        // TODO support multiple recommended reports
                        const [sampleInvitation, ...rest] = init.invitations.filter(
                            (candidate) =>
                                candidate.asset?.id === context.workspace.id &&
                                !!reportQuery.data.byId[candidate.report.id]
                        );
                        if (!sampleInvitation) {
                            return item.page;
                        }
                        return {
                            ...item.page,
                            alert: {
                                ...item.page.alert,
                                items: [
                                    ...item.page.alert.items,
                                    {
                                        id: 'home_top_companies',
                                        status: 'info',
                                        kind: 'link',
                                        title:
                                            sampleInvitation.title ??
                                            'You have been invited to access a report',
                                        to: `/u/assets/${context.workspace.id}/reporting/reports/${sampleInvitation.report.id}`,
                                        actionText: 'View report',
                                        isDismissable: true,
                                    },
                                ],
                            },
                        };
                    }, [item.page.alert.items, reportQuery.data]);

                    const viewProps = controller.useProps(
                        context,
                        {
                            ...item,
                            page: merged,
                        },
                        ...args
                    );
                    return viewProps;
                },
            };
        }

        const instance = create({
            ...config,
            adapter: {
                ...config.adapter,
                reports: {
                    ...config.adapter.reports,
                    createInvitation(...args) {
                        const adapter = config.adapter.reports.createInvitation(...args);
                        return enhanceInvitationAdapter(adapter);
                    },
                },
            },
            route: {
                ...config.route,
                createHomeRoute(routeConfig) {
                    return config.route.createHomeRoute({
                        ...routeConfig,
                        deps: {
                            ...routeConfig.deps,
                            controller: {
                                ...routeConfig.deps.controller,
                            },
                        },
                        providers: {
                            ...routeConfig.providers,
                            root: {
                                ...routeConfig.providers.root,
                                createController(...args) {
                                    const controller =
                                        routeConfig.providers.root.createController(
                                            ...args
                                        );
                                    return enhanceHomeController(controller);
                                },
                            },
                        },
                    });
                },
            },
        });
        return instance;
    };
}
