import { capitalize, chain } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { assert } from '../../../util/assert';
import {
    AnyMappableEntity,
    AssetStatus,
    getDefinitionStatus,
    Integration,
    IntegrationHelpers,
} from '../../../domain/assets';
import { AssetPrepareConfig } from './assetPrepareConfig';
import { AssetPrepareController } from './assetPrepareInterface';
import { AssetPrepareViewProps } from './assetPrepareProps';
import { getCollectionStatus } from '../mapping/list/assetMappingHelper';
import { formatMinuteDurationRange } from './assetPrepareHelper';
import moment from 'moment';

export function createAssetPrepareController(
    config: AssetPrepareConfig
): AssetPrepareController {
    const STATUS_CHECK_INTERVAL_MS = 15000;
    return {
        useProps(context, data, props): AssetPrepareViewProps {
            const status = useMemo<AssetStatus | null>(() => {
                if (data.connection.find.data === undefined) {
                    return null;
                }
                const [first] = data.connection.find.data.filter(
                    (item) => item.status === 'ready'
                );
                if (data.profile.lookup.data?.account?.finishedOnboarding) {
                    return 'ready';
                }
                if (first) {
                    return 'ready';
                }
                return 'syncing';
            }, [data.profile.lookup.data, data.connection.find.data, data.isLoading]);

            const fastestIntegration = useMemo(
                () =>
                    data.aggregate.data?.integrations.find(
                        (candidate) =>
                            candidate.definitionId ===
                            data.integration.definition.fastest?.id
                    ),
                [
                    data.aggregate.data?.integrations,
                    data.integration.definition.fastest?.id,
                ]
            );

            const [trigger, setTrigger] = useState(false);
            useEffect(() => {
                const intervalId = setInterval(() => {
                    setTrigger((prev) => !prev);
                }, STATUS_CHECK_INTERVAL_MS);

                // Cleanup interval on component unmount
                return () => clearInterval(intervalId);
            }, []);

            const isDelayed = useMemo(() => {
                if (!fastestIntegration || !data.integration.definition.fastest) {
                    return null;
                }
                const now = new Date();
                return IntegrationHelpers.isOrWasDelayed(now, {
                    integration: fastestIntegration,
                    definition: data.integration.definition.fastest,
                });
            }, [fastestIntegration, data.integration.definition.fastest, trigger]);

            if (data.isLoading) {
                return {
                    status: 'loading',
                };
            }
            
            // console.log('status', status);
            return {
                status: 'success',
                getFormProps() {
                    return {
                        async onSubmit() {
                            data.profile.update.mutateAsync({
                                accountId: context.account.id,
                                finishedOnBoarding: true
                            });
                            return;
                        },
                        error: data.profile.update.error,
                        isSubmittable: status === 'ready' && !data.isLoading,
                        isSubmitting: data.profile.update.isLoading,
                    };
                },
                getStatusProps() {
                    let label = `We are preparing your account`;

                    if (data.integration.definition.fastest) {
                        const {
                            refreshRate: {
                                interval,
                                range: { from, to },
                            },
                        } = data.integration.definition.fastest;
                        const durationText = formatMinuteDurationRange(
                            from ?? 0,
                            to ?? Infinity
                        );
                        label = `${label} (usually takes ${durationText})`;
                    }

                    if (status === 'ready') {
                        return {
                            status,
                            label: 'Your account is ready',
                            description: null,
                            isLoading: false,
                        };
                    }

                    if (isDelayed) {
                        return {
                            status: 'syncing',
                            label: `It's taking a bit longer than usual, we will reach out to you when it's ready`,
                            description: null,
                            isLoading: false,
                        };
                    }

                    return {
                        status: 'syncing',
                        label: label,
                        description: null,
                        isLoading: false,
                    };
                },
            };
        },
    };
}
