import React from 'react';
import { StudiesContextProvider } from '../../base';
import {
    StudiesExploreController,
    StudiesExploreLoader,
} from './studiesReportListInterface';
import {
    StudiesExploreContainerProps,
    StudiesExploreViewProps,
} from './studiesReportListProps';

export function createStudiesExploreRoute(
    contexts: StudiesContextProvider,
    controller: StudiesExploreController,
    loader: StudiesExploreLoader,
    View: {
        Desktop: React.FC<StudiesExploreViewProps>;
        Mobile: React.FC<StudiesExploreViewProps>;
    }
): React.FC<StudiesExploreContainerProps> {
    return ({ children, ...containerProps }) => {
        const context = contexts.useContext();
        const data = loader.useLoad(context);
        const props = controller.useProps(context, data, {});
        if (children) {
            return children;
        }
        if (props.browser.mode === 'mobile') {
            return <View.Mobile {...props} />;
        }
        return <View.Desktop {...props} />;
    };
}
