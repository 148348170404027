import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { AccountProfileFormValues } from '../../../view';
import { OnboardingProfileConfig } from './onboardingProfileConfig';
import { OnboardingProfileController } from './onboardingProfileInterface';
import { OnboardingProfileViewProps } from './onboardingProfileProps';

export function createOnboardingProfileController(
    config: OnboardingProfileConfig
): OnboardingProfileController {
    const { router, controller } = config;
    return {
        useProps(deps, props): OnboardingProfileViewProps {
            const { data, form } = deps;
            const navigate = useNavigate();

            useEffect(() => {
                if (data.profile.lookup.data) {
                    const knownFirstProfile = data.profile.lookup.data.personalProfile?.work_experiences?.at(0);

                    form.reset({
                        firstname: data.profile.lookup.data.firstname ?? '',
                        lastname: data.profile.lookup.data.lastname ?? '',
                        companyWebsite: data.profile.lookup.data.account?.kind === 'organization' && data.profile.lookup.data.account?.domain  || knownFirstProfile?.company_domain || '',
                        companyName: data.profile.lookup.data.account?.kind === 'organization' && data.profile.lookup.data.account?.organizationName || knownFirstProfile?.company_name || '',
                        isAgency:
                        data.profile.lookup.data.account?.kind === 'organization' && data.profile.lookup.data.account.organizationType === 'ad_agency',
                    });
                }
            }, [data.profile.lookup.data]);

            const viewProps = controller.profile.useProps(data, form, {});

            const formProps = viewProps.getFormProps();

            const handleSubmitClick = async (values: AccountProfileFormValues) => {
                const response = await formProps.onSubmit(values);
                const path = router.getSuccessPath();
                console.info(`redirecting to asset onboarding -> ${path}`);
                navigate(path);
            };

            return {
                getFormProps() {
                    return {
                        onSubmit: handleSubmitClick,
                        submitLabel: `Fill out the form to continue`,
                        isSubmittable: form.formState.isDirty || form.formState.isValid,
                        isLoading: form.formState.isSubmitting,
                    };
                },
                getViewProps() {
                    return viewProps;
                },
            };
        },
    };
}
