import { snakeCaseKeys } from '../../../../../../util';
import { SettingAccountItemProviderConfig } from '../../../../../../route';
import { SubscriptionEditAlertListProps } from '../../../../../../view/billing';
import { PageIds, ModalIds } from '../../../../../../config';
import { SettingsBillingConfig } from '../../settingsBillingConfig';
import { SettingSubscriptionEditController } from './subscriptionEditInterface';
import { PLAN_URL_KEY } from './subscriptionEditConstant';
import { buildPaymenthMethodOptions } from '../../payment-method';

export function createSettingSubscriptionEditController(
    init: Pick<SettingsBillingConfig, 'controller' | 'providers'>,
    config: SettingAccountItemProviderConfig
): SettingSubscriptionEditController {
    const {
        controller: { subscription },
        providers: { createModal },
    } = init;
    const {
        api: { controller: pageController },
    } = config;

    const controller = {
        modal: createModal(),
    };

    return {
        useProps(context, deps, item, props) {
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.SETTINGS_ACCOUNT_BILLING_SUBSCRIPTION,
                    breadcrumbs: [
                        {
                            label: 'Subscription',
                        },
                    ],
                },
            });

            const pageStatusProps = pageProps.getStatusProps();

            const editProps = subscription.edit.useProps(context, deps.edit, {
                item,
                status: pageStatusProps?.isDisabled
                    ? {
                          description: pageStatusProps.description,
                          error: pageStatusProps.error,
                          isDisabled: pageStatusProps.isDisabled,
                      }
                    : undefined,
            });
            const formProps = editProps.getFormStateProps();
            const pageAlertProps = pageProps.getAlertProps();

            let viewAlertProps = editProps.getAlertListProps();

            viewAlertProps = {
                ...viewAlertProps,
                items: [
                    ...viewAlertProps.items,
                    ...(item.methods.length === 0
                        ? [
                              {
                                  status: 'info',
                                  label: `Payment method is not configured`,
                                  action: {
                                      kind: 'link',
                                      label: 'Add payment method',
                                      id: 'subscription_banner_add_payment_method_link',
                                      to: '/u/settings/payment/edit',
                                  },
                              } as const,
                          ]
                        : []),
                ],
            };

            const searchParams = new URLSearchParams(
                Object.entries(
                    snakeCaseKeys(
                        buildPaymenthMethodOptions({
                            returnPath: props.location.pathname,
                        })
                    )
                )
            );

            const submitButtonProps = editProps.getSubmitButtonProps();

            const paymentMethodModalProps = controller.modal.useProps(deps.modal, {
                id: ModalIds.SUBSCRIPTION_PAYMENT_METHOD_REQUIRED,
                trigger: null,
            });

            return {
                page: pageProps,
                modal: paymentMethodModalProps,
                button: {
                    submit: item.methods.length > 0 ? submitButtonProps : null,
                    paymentmethod:
                        item.methods.length === 0
                            ? paymentMethodModalProps.triggerProps
                            : null,
                    manage: {
                        onClick() {
                            props.onPortal();
                        },
                    },
                },
                link: {
                    addPaymentMethod: {
                        id: 'subscription_add_payment_method_link',
                        to: `../payment/edit?${searchParams.toString()}`,
                    },
                },
                getEditProps() {
                    return editProps;
                },
                getAlertProps(): SubscriptionEditAlertListProps {
                    if (pageAlertProps.items.length > 0) {
                        return pageAlertProps;
                    }
                    return viewAlertProps;
                },
                getFormProps() {
                    return {
                        onSubmit: formProps.handleSubmit(async (values) => {
                            try {
                                const response = await formProps.onSubmit(values);
                                deps.toast({
                                    kind: 'success',
                                    description: 'Subscription updated',
                                });
                                const [searchParams, setSearchParams] = deps.searchParams;
                                // clear the default plan in the url parameter after subscribing to a new plan
                                searchParams.delete(PLAN_URL_KEY);
                                setSearchParams(searchParams);
                                return response;
                            } catch (error) {
                                console.error(error);
                                deps.toast({
                                    kind: 'error',
                                    description: 'An error occured',
                                });
                                throw error;
                            }
                        }),
                    };
                },
            };
        },
    };
}
