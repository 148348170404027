import { MdBookmark, MdBookmarkBorder } from 'react-icons/md';
import { ReportInsightController } from './reportInsightInterface';
import { ReportInsightViewProps } from './reportInsightProps';
import { ReportControllerBaseConfig } from '../../base';

export function createReportInsightController(
    config: ReportControllerBaseConfig
): ReportInsightController {
    const { kernel } = config;

    const actionController = kernel.provider.createActionController({
        id: 'toggle',
    });

    return {
        useProps(context, item, props): ReportInsightViewProps {
            const sourceLabel = [`Varos - Facebook Ads integration`].join(', ');
            const toggle = actionController.useProps(props.action.bookmark);
            return {
                title: item.insight.title,
                description: item.insight.description,
                caption: item.insight.caption,
                type: item.insight.style.type,
                source: {
                    label: sourceLabel,
                },
                actions: [toggle],
                insight: item.insight,
            };
        },
    };
}
