import React from 'react';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { useAccountContextV2 } from '../../context';
import { useUserApi } from '../..';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router';
import { PageIds } from '../../config';
import {
    ApplicationNavigationController,
    ShellLayoutController,
    ShellLayoutEnhancer,
} from '../../shell';

export function createAssetClaimsEnhancer(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        // function enhanceController(
        //     controller: AccountRouteController
        // ): AccountRouteController {
        //     return {
        //         ...controller,
        //         useProps(...args) {
        //             const viewProps = controller.useProps(...args);
        //             const {
        //                 data: { account: organization },
        //             } = useAccountContextV2();
        //             const userApi = useUserApi();
        //             const currentLocation = useLocation();
        //             const navigate = useNavigate();
        //             const currentWorkspace = userApi.getCurrentWorkspace();
        //             React.useEffect(() => {
        //                 if (
        //                     currentWorkspace?.claim.kind === 'inactive' &&
        //                     !currentLocation.pathname.endsWith(
        //                         '/settings/general-settings'
        //                     )
        //                 ) {
        //                     return navigate(
        //                         `/u/assets/${currentWorkspace.id}/settings/general-settings`,
        //                         {
        //                             replace: true,
        //                         }
        //                     );
        //                 }
        //             }, [currentWorkspace]);

        //             if (!currentWorkspace) {
        //                 return viewProps;
        //             }
        //             if (currentWorkspace.claim.kind === 'inactive') {
        //                 return {
        //                     ...viewProps,
        //                     workspaceUrl: `/u/assets/${currentWorkspace.id}/settings/general-settings`,
        //                     dashboardUrl: null,
        //                     homeUrl: `/u/assets/${currentWorkspace.id}/settings/general-settings`,
        //                     creativeCoOpUrl: null,
        //                     researchUrl: null,
        //                     insightsUrl: null,
        //                     reportsUrl: null,
        //                     peersUrl: null,
        //                     expertCallUrl: null,
        //                 };
        //             }
        //             return viewProps;
        //         },
        //     };
        // }

        function enhanceController(
            instance: ShellLayoutController
        ): ShellLayoutController {
            return {
                ...instance,
                useProps(context, item, props) {
                    const {
                        data: { account: organization },
                    } = useAccountContextV2();
                    const userApi = useUserApi();
                    const currentLocation = useLocation();
                    const navigate = useNavigate();
                    const currentWorkspace = userApi.getCurrentWorkspace();

                    const isClaimInactive = currentWorkspace?.claim.kind === 'inactive';

                    React.useEffect(() => {
                        if (
                            isClaimInactive &&
                            !currentLocation.pathname.endsWith(
                                '/settings/general-settings'
                            )
                        ) {
                            console.info('asset not claimed, redirecting to settings...');
                            return navigate(
                                `/u/assets/${currentWorkspace.id}/settings/general-settings`,
                                {
                                    replace: true,
                                }
                            );
                        }
                    }, [isClaimInactive, currentWorkspace]);

                    return instance.useProps(
                        context,
                        item,
                        isClaimInactive
                            ? {
                                  ...props,
                                  home: {
                                      link: {
                                          to: `/u/assets/${currentWorkspace.id}/settings/general-settings`,
                                      },
                                  },
                                  navigation: {
                                      ...props.navigation,
                                      items: props.navigation.items.flatMap((item) => {
                                          if (!currentWorkspace) {
                                              return [item];
                                          }
                                          if (item.id === 'home') {
                                              return [
                                                  {
                                                      ...item,
                                                      path: `/u/assets/${currentWorkspace.id}/settings/general-settings`,
                                                  },
                                              ];
                                          }
                                          return [];
                                      }),
                                  },
                              }
                            : props
                    );
                },
            };
        }

        function createShellEnhancer(): ShellLayoutEnhancer {
            return (create) => (config) => {
                return create({
                    ...config,
                    provider: {
                        ...config.provider,
                        createRootController(...args) {
                            const instance = config.provider.createRootController(
                                ...args
                            );
                            return enhanceController(instance);
                        },
                    },
                });
            };
        }

        return create({
            ...config,
            enhancer: {
                ...config.enhancer,
                shell: [...config.enhancer.shell, createShellEnhancer()],
            },
            controller: {
                ...config.controller,
                settings: {
                    ...config.controller.settings,
                    createAsset(...args) {
                        const controller = config.controller.settings.createAsset(
                            ...args
                        );
                        return {
                            useProps() {
                                const props = controller.useProps();

                                const userApi = useUserApi();
                                const currentWorkspace = userApi.getCurrentWorkspace();
                                if (!currentWorkspace) {
                                    return props;
                                }
                                const isActiveClaim =
                                    currentWorkspace.claim.kind === 'active';
                                return {
                                    ...props,
                                    isVisible(item) {
                                        if (!isActiveClaim) {
                                            return item.key === 'general';
                                        }
                                        return props.isVisible(item);
                                    },
                                };
                            },
                        };
                    },
                },
            },
            route: {
                ...config.route,
                // createAccountWorkspaceRoute(routeConfig) {
                //     return config.route.createAccountWorkspaceRoute({
                //         ...routeConfig,
                //         providers: {
                //             ...routeConfig.providers,
                //             createController(...args) {
                //                 const controller = routeConfig.providers.createController(
                //                     ...args
                //                 );
                //                 return enhanceController(controller);
                //             },
                //         },
                //     });
                // },
            },
        });
    };
}
