import { ReportSdk } from '@varos/report-sdk';
import { Kernel } from '../../../base';
import { ReportContentRepository } from './reportContentInterface';

export function createReportContentRepository(
    kernel: Kernel,
    api: ReportSdk
): ReportContentRepository {
    const {
        infra: { repository },
    } = kernel;
    const PREFIX = ['report', 'content'];
    return {
        useLookup(context, props, options) {
            const mutation = repository.useQuery({
                ...options,
                queryKey: [...PREFIX, context.principal?.id, props],
                async queryFn() {
                    const response = await api.contentRetrieve({
                        contentId: props.content.id,
                    });
                    return response.data;
                },
            });
            return mutation;
        },
    };
}
