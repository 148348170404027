import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { combineCollectionStatus, isLoadedCollection } from '../../../base';
import { OrganizationMappingServiceConfig } from './mappingConfig';
import { buildConnectionBulk } from './mappingFactory';
import { OrganizationMappingService } from './mappingInterface';
import { OrganizationMappingAggregate } from './mappingModel';

export function createOrganizationMappingService(
    config: OrganizationMappingServiceConfig
): OrganizationMappingService {
    const { repository } = config;
    return {
        useLookup(context, options) {
            const [pollIntegrations, setPollIntegrations] = useState(false);

            const definitionQuery = repository.definition.useFind(
                context,
                {},
                {
                    ...options,
                    // staleTime: Infinity,
                    // ...options,
                }
            );

            const integrationQuery = repository.integration.useFind(
                context,
                {},
                {
                    suspense: options?.suspense,
                    enabled: options?.enabled,
                    refetchInterval() {
                        if (pollIntegrations) {
                            console.info('enabling integration polling...');
                            return 15000;
                        }
                        return false;
                    },
                    // ...options,
                    // staleTime: Infinity,
                    // ...options,
                }
            );

            const assetQuery = repository.asset.useFind(
                context,
                {},
                {
                    ...options,
                    // staleTime: Infinity,
                    // ...options,
                }
            );

            const analyzing = useMemo(
                () =>
                    integrationQuery.data?.items.filter(
                        (item) => item.status === 'analyzing'
                    ),
                [integrationQuery.data?.items]
            );

            useEffect(() => {
                if (analyzing?.length !== 0) {
                    setPollIntegrations(true);
                } else {
                    setPollIntegrations(false);
                }
            }, [analyzing?.length]);

            // console.log('analyzing', analyzing);

            const entityQuery = repository.entity.useFind(
                context,
                {
                    integrations:
                        integrationQuery.data?.items.map((item) => ({ id: item.id })) ??
                        [],
                },
                {
                    staleTime: Infinity,
                    suspense: options?.suspense,
                    // enabled: isLoadedCollection(integrationQuery),
                    retry: false,
                    keepPreviousData: true,
                    refetchInterval(data, query) {
                        // const definitionsIds = new Set(
                        //     integrationQuery.data?.items.map((item) => item.definitionId)
                        // );
                        // const syncedIntegrationIds = new Set(
                        //     data?.items.map((entity) => entity.integrationId)
                        // );
                        if (analyzing?.length === 0) {
                            return false;
                        }
                        // if (syncedIntegrationIds.size >= definitionsIds.size) {
                        //     return false;
                        // }
                        console.log(
                            'not all integrations are synced, schedulling refresh...',
                            integrationQuery.data?.items.map((item) => item.id)
                            // syncedIntegrationIds.size,
                            // definitionsIds.size
                        );
                        return 15000;
                    },
                }
            );

            const mappingQuery = repository.connection.useFind(
                context,
                {
                    integrations:
                        integrationQuery.data?.items.map((item) => ({ id: item.id })) ??
                        [],
                },
                {
                    suspense: options?.suspense,
                    retry: false,
                    staleTime: 60000,
                    // enabled: isLoadedCollection(integrationQuery),
                    // staleTime: Infinity,
                    // ...options,
                }
            );

            // const collectionStatus = combineCollectionStatus(
            //     // assetQuery,
            //     entityQuery,
            //     integrationQuery,
            //     mappingQuery,
            //     definitionQuery
            // );

            const collectionStatus = [
                entityQuery,
                integrationQuery,
                mappingQuery,
                definitionQuery,
            ].every((item) => item.status === 'success')
                ? 'loaded'
                : 'loading';

            // console.log('asda', [
            //     entityQuery.status,
            //     integrationQuery.status,
            //     mappingQuery.status,
            //     definitionQuery.status,
            // ]);

            // console.log(
            //     'asdasd',
            //     assetQuery,
            //     entityQuery,
            //     integrationQuery,
            //     mappingQuery,
            //     definitionQuery
            // );

            if (collectionStatus == 'loading' || assetQuery.status == 'loading') {
                return {
                    status: 'loading',
                    data: null,
                };
            }

            return {
                status: 'loaded',
                data: {
                    root: {
                        id: context.account.id,
                    },
                    assets: assetQuery.data ?? [],
                    entities: entityQuery.data?.items ?? [],
                    integrations: integrationQuery.data?.items ?? [],
                    mappings: mappingQuery.data?.items ?? [],
                    definitions: definitionQuery.data?.items ?? [],
                },
            };
        },
        useSave(context, item) {
            const mutation = {
                connection: repository.connection.useBulk(context),
            };

            return useMutation({
                async mutationFn(update: OrganizationMappingAggregate) {
                    const payload = {
                        connection: buildConnectionBulk(item, update),
                    };
                    const response = await mutation.connection.mutateAsync(
                        payload.connection
                    );
                    return;
                },
            });
        },
    };
}
