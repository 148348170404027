import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AccountScope } from '../../platform';
import { PeerSetAdapter } from './peersetAdapter';
import { PeerSetRepository } from './peersetInterface';
import {
    PeerSetLookupQuery,
    PeerSetFindQuery,
    PeerSetCreateProps,
    PeerSetUpdateProps,
} from './peersetQuery';
import { PeerSetEntity } from './peersetModel';

export function createPeerSetRepository(adapter: PeerSetAdapter): PeerSetRepository {
    const PREFIX = ['peersets'];
    const PREFIX_FIND = ['peersets', 'find'];

    function getFindPrefix(context: AccountScope, query: PeerSetFindQuery) {
        return [...PREFIX_FIND, context.account.id, query];
    }
    function getLookupPrefix(context: AccountScope, query: PeerSetLookupQuery) {
        return [...PREFIX, context.account.id, query.id];
    }
    function getWritePrefix(context: AccountScope, command: PeerSetCreateProps) {
        return [...PREFIX, context.account.id, command];
    }

    return {
        useFind(context, query, options) {
            const queryClient = useQueryClient();
            const applied: PeerSetFindQuery = {
                page_size: query.page_size ?? 20,
                ...query,
            };
            const data = useQuery({
                queryKey: [...getFindPrefix(context, applied)],
                async queryFn() {
                    const response = await adapter.find(context, applied);
                    return response;
                },
                refetchOnWindowFocus: false,
                ...options,
                onSuccess(data) {
                    for (const group of data.items) {
                        const queryKey = getLookupPrefix(context, group);
                        queryClient.setQueryData(queryKey, group);
                    }
                },
            });
            return data;
        },
        useLookup(context, query, options) {
            const data = useQuery({
                queryKey: [...getLookupPrefix(context, query)],
                async queryFn() {
                    const response = await adapter.lookup(context, query);
                    return response;
                },
                refetchOnWindowFocus: false,
                ...options,
            });
            return data;
        },
        useCreate(context) {
            const client = useQueryClient();
            const mutation = useMutation({
                mutationFn(payload: PeerSetCreateProps) {
                    return adapter.create(context, payload);
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(PREFIX_FIND);
                },
            });
            return mutation;
        },
        useUpdate(context) {
            const client = useQueryClient();
            const mutation = useMutation({
                mutationFn([item, payload]: [PeerSetEntity, PeerSetUpdateProps]) {
                    return adapter.update(context, item, payload);
                },
                async onSuccess(data, variables, _context) {
                    await client.refetchQueries(PREFIX_FIND);
                    client.setQueryData(
                        getLookupPrefix(context, {
                            id: data.id,
                        }),
                        data
                    );
                },
            });
            return mutation;
        },
        useRemove(context) {
            const client = useQueryClient();
            const mutationState = useMutation({
                mutationFn(entity: PeerSetEntity) {
                    return adapter.remove(context, entity);
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(PREFIX_FIND);
                },
            });
            return mutationState;
        },
    };
}
