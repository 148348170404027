import { Kernel } from '../../../base';
import { MeAdapter } from './meRepositoryAdapter';
import { MeRepository } from './meRepositoryInterface';

export function createMeRepository(kernel: Kernel, adapter: MeAdapter): MeRepository {
    const {
        infra: { repository },
    } = kernel;
    return {
        useLookup(context, options) {
            const query = repository.useQuery({
                queryKey: ['me'],
                async queryFn() {
                    const response = await adapter.lookup(context);
                    return response;
                },
                ...options,
            });
            return query;
        },
    };
}
