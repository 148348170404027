import {
    ApplicationRepository,
    isAuthenticatedContext,
    KernelEnhancer,
} from '../../base';
import { ErrorKernelMiddlewareConfig } from './errorMiddlewareConfig';

export function createErrorKernelEnhancer(
    init: ErrorKernelMiddlewareConfig
): KernelEnhancer {
    const { client } = init;
    return (create) => (config) => {
        function enhanceRepository(
            instance: ApplicationRepository
        ): ApplicationRepository {
            return {
                ...instance,
                useMutation(context, options) {
                    return instance.useMutation(context, {
                        ...options,
                        onError(...args) {
                            const [error, variables] = args;
                            client.withScope((scope) => {
                                console.error('tracking sentry error', error);
                                if (isAuthenticatedContext(context)) {
                                    scope.setUser({
                                        id: context.principal.id,
                                    });
                                    if (context.organization) {
                                        scope.setTag(
                                            'organization',
                                            context.organization
                                        );
                                    }
                                }
                                if (typeof variables === 'object' && variables !== null) {
                                    scope.setContext('input', variables as any);
                                }
                                client.captureException(error, scope);
                            });
                            return options.onError?.(...args);
                        },
                    });
                },
            };
        }
        return create({
            ...config,
            infra: {
                ...config.infra,
                repository: enhanceRepository(config.infra.repository),
            },
        });
    };
}
