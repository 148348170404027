import thumbnail from '../../../../images/report_thumbnail_agency.png';
import screenshot_spend_over_revenue from '../../../../images/report_detail_agency_compensation_spend_over_revenue.png';
import screenshot_spend_over_budget from '../../../../images/report_detail_agency_compensation_spend_over_budget.png';
import screenshot_compensation_model from '../../../../images/report_detail_agency_compensation_model.png';
import { AnyReportConfiguration } from '../../../middleware';

export const HexExampleReport: AnyReportConfiguration = {
    kind: 'external',
    entity: {
        id: 'hex_example',
        name: 'Hex Example',
        status: 'draft',
        tagline: `Eu minim consequat enim in`,
        description: `Nulla labore dolore duis enim ex labore. Reprehenderit do non ea est elit elit id esse aliqua occaecat non incididunt ipsum irure. Cupidatat Lorem non ea quis adipisicing ipsum id dolor laborum fugiat exercitation. Dolor laboris minim consequat aliquip adipisicing non cupidatat eiusmod anim do officia consequat. Ea consequat labore consectetur culpa qui fugiat elit ex. Duis ut Lorem minim ullamco eu magna. Sit occaecat aliquip deserunt dolor.`,
        category: 'marketing',
        tags: ['compensation'],
        dependencies: [],
        thumbnail: {
            description: null,
            url: thumbnail,
        },
        images: [
            {
                description: null,
                url: screenshot_spend_over_revenue,
            },
            {
                description: null,
                url: screenshot_spend_over_budget,
            },
            {
                description: null,
                url: screenshot_compensation_model,
            },
        ],
    },
    getTargetUrl(context) {
        return {
            url: `https://app.hex.tech/2a598c0b-ad4c-479d-a508-b19f5909d4dc/app/924f316e-8798-4ffe-97f9-0c60e2db0cc6/latest`,
            params: { asset: context.asset, token: context.token },
        };
    },
};
