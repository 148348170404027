import { QueryFunction } from '@tanstack/react-query';
import { ApplicationContext } from '../../../base';
import { SubscriptionDto, getSubscription } from '../../../api';

const MAX = 15;

export function createGetSubscriptionHandler(
    context: Pick<ApplicationContext, 'api' | 'account'>,
    {
        maxAttempts = MAX,
        ...options
    }: Partial<{ maxAttempts: number; pollUntilActiveMs: number }> = {}
): QueryFunction<SubscriptionDto> {
    return async ({ signal }) => {
        let count = 0;
        let isRunning = true;
        signal?.addEventListener('abort', () => {
            isRunning = false;
        });
        while (isRunning && context.account) {
            const response = await getSubscription(context.api);
            if (!options.pollUntilActiveMs) {
                return response;
            }
            if (response.active) {
                return response;
            }
            if (count >= maxAttempts) {
                throw new Error(`max number of polling requests`);
            }
            count += 1;
            await sleep(options.pollUntilActiveMs);
        }
        // canceled query, we consider free
        throw new Error(`canceled query`);
    };
}

function sleep(amountMs: number) {
    return new Promise((resolve) => {
        setTimeout(resolve, amountMs);
    });
}
