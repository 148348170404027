import { WorkspaceContextV2 } from '../../../../app';
import { GroupInviteAggregate } from './groupInviteItemModel';
import { GroupInviteItemViewProps } from './groupInviteItemProps';

export function buildGroupInviteItemViewProps(
    context: WorkspaceContextV2,
    item: GroupInviteAggregate,
    index: number
): GroupInviteItemViewProps {
    return {
        id: item.group.id,
        label: item.group.name,
        sender: {
            label: item.sender.details.kind === 'organization' ? item.sender.details.name : item.sender.details.identities.at(0)?.email!,
        },
        created: {
            relative: {
                label: '3 days ago',
            },
        },
        links: {},
        action: {
            accept: {
                onClick() {},
            },
            decline: {
                onClick() {},
            },
        },
    };
}
