import React from 'react';
import { ReportContainerBaseConfig } from '../../../../base';
import { ReportInstanceDetailStatusController } from './reportStatusInterface';
import {
    ReportInstanceDetailStatusContainerProps,
    ReportInstanceDetailStatusViewProps,
} from './reportStatusProps';
import { useLocation, useNavigate } from 'react-router';

export function createReportInstanceDetailStatusContainer(
    config: ReportContainerBaseConfig,
    controller: ReportInstanceDetailStatusController,
    View: React.FC<ReportInstanceDetailStatusViewProps>
): React.FC<ReportInstanceDetailStatusContainerProps> {
    const {
        context: { useContext },
    } = config;

    return ({ ...containerProps }) => {
        const location = useLocation();
        const navigate = useNavigate();
        const context = useContext();
        const props = controller.useProps(context, containerProps.item, {
            navigate,
            location,
        });
        return <View {...props} />;
    };
}
