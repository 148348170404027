import React from 'react';
import { Heading, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { AiOutlineArrowRight } from 'react-icons/ai';
import { RegistrationViewConfig } from '../../../../../../base';
import { StepLayoutViewProps } from '../../../fragment/common/step/stepLayoutProps';

export function createStepLayoutDesktopView(
    config: Pick<RegistrationViewConfig, 'UI'>
): React.FC<{ children?: React.ReactNode | undefined } & StepLayoutViewProps> {
    const {
        UI: {
            Application: { Button },
        },
    } = config;
    return (props) => {
        return (
            <form {...props.form.getFormElementProps()}>
                <VStack align="start" borderRadius="lg" p={12} spacing={12}>
                    <VStack align="start" spacing={3}>
                        <Heading fontSize="4xl" color="black">
                            {props.title}
                        </Heading>
                        {props.description && (
                            <Text fontSize="xl" color="blackAlpha.700">
                                {props.description}
                            </Text>
                        )}
                    </VStack>
                    {props.children}
                    <HStack
                        pt={12}
                        borderTopWidth={2}
                        borderStyle="solid"
                        borderColor="gray.200"
                        w="full"
                        justify="end"
                        spacing={3}
                    >
                        {props.back && (
                            <Button color="gray.900" {...props.back}>
                                Back
                            </Button>
                        )}
                        <Button
                            {...props.form.getSubmitButtonProps()}
                            colorScheme="green"
                            rightIcon={<Icon as={AiOutlineArrowRight} />}
                        />
                    </HStack>
                </VStack>
            </form>
        );
    };
}
