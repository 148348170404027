import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AccountScope } from '../../platform';
import { AccountInvitationAdapter } from './invitationAdapter';
import { AccountInvitationRepository } from './invitationInterface';
import {
    AccountInvitationBulkProps,
    AnyAccountInvitationCreateProps,
} from './invitationProps';
import { AccountInvitationQuery, VerifyInvitationQuery } from './invitationQuery';
import {
    InvitationAcceptPayload,
    InvitationAcceptResponse,
} from '../../../domain/accounts';

export function createAccountInvitationRepository(
    adapter: AccountInvitationAdapter
): AccountInvitationRepository {
    const PREFIX = ['account', 'invitation'];
    function getPrefix(context: AccountScope, query: AccountInvitationQuery) {
        return [...PREFIX, context.account, query];
    }
    function getReadPrefix(query: VerifyInvitationQuery) {
        return ['account', 'invitation', query.invitationId, query.token];
    }
    return {
        useSend(context) {
            const client = useQueryClient();
            const result = useMutation<
                void,
                Error,
                string
            >({
                async mutationFn(invitationId: string) {
                    await adapter.send(context, invitationId);
                },
                async onSuccess(data) {
                    await client.refetchQueries(PREFIX);
                },
            });
            return result;
        },
        useFind(context, query, options) {
            const result = useQuery({
                staleTime: Infinity,
                queryKey: [...getPrefix(context, query)],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response.items;
                },
                ...options,
            });
            return result;
        },
        useCreate(context) {
            const client = useQueryClient();
            const result = useMutation({
                async mutationFn(props: AnyAccountInvitationCreateProps) {
                    const [response] = await adapter.bulk(context, {
                        items: [
                            {
                                kind: 'create',
                                data: props,
                            },
                        ],
                    });
                    return response;
                },
                async onSuccess(data) {
                    await client.refetchQueries(PREFIX);
                },
            });
            return result;
        },
        useBulk(context) {
            const client = useQueryClient();
            const result = useMutation({
                async mutationFn(props: AccountInvitationBulkProps) {
                    const response = await adapter.bulk(context, props);
                    return response;
                },
                async onSuccess(data) {
                    await client.refetchQueries(PREFIX);
                },
            });
            return result;
        },
        useVerify(query, options) {
            const data = useQuery({
                ...options,
                queryKey: [...getReadPrefix(query)],
                async queryFn() {
                    const response = await adapter.findOne(query);
                    return response;
                },
                refetchOnWindowFocus: true,
            });

            return data;
        },
        useAccept() {
            const client = useQueryClient();
            const mutationState = useMutation<
                InvitationAcceptResponse,
                Error,
                InvitationAcceptPayload
            >({
                mutationFn(payload) {
                    return adapter.accept(payload);
                },
                async onSuccess(data, variables, context) {
                    await client.refetchQueries(['account', 'invitation']);
                },
            });
            return mutationState;
        },
    };
}
