import { InvitationAcceptPayload } from '../../../../../../../domain/accounts';
import { isOrganizationInvitation } from '../../../../../../../domain/accounts';
import { RegistrationInvitationContext } from '../../context';
import { AcceptProfileFormValues } from '../profile';
import { RegistrationAcceptCreateContainerProps } from '../../registrationAcceptCreateProps';
import { AcceptOrganizationFormValues } from './acceptOrganizationModel';

export function buildServicePayload(
    context: RegistrationInvitationContext,
    props: RegistrationAcceptCreateContainerProps & {
        profile: AcceptProfileFormValues;
        organization: AcceptOrganizationFormValues;
    }
): InvitationAcceptPayload {
    let organization = undefined;
    if (isOrganizationInvitation(context.data.invitation)) {
        if (!context.data.invitation.invitee) {
            // NOTE if there’s an invitee  in the invitation, the organization field in the accept request must
            // be null. (this is a case where one of the users that has common invitation already created an org and the
            // inivitation will be accepted to that org)
            organization = {
                name: props.organization.name,
                url: props.organization.website,
            };
        }
    }
    return {
        token: props.token,
        invitation_id: props.invitation.id,
        email: props.profile.email,
        first_name: props.profile.firstname,
        last_name: props.profile.lastname,
        organization,
        member_profile: null,
    };
}
