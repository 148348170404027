import { useMemo } from 'react';
import { useAccountContextV2 } from '../../../context';
import {
    AnyOnboardingAccountStep,
    AccountOnboardingState,
} from '../../../domain/onboarding';
import { OnboardingAccountStateServiceConfig } from './accountConfig';
import { OnboardingAccountStateService } from './accountInterface';

export function createOnboardingAccountStateService(
    config: OnboardingAccountStateServiceConfig
): OnboardingAccountStateService {
    return {
        useLookup(context) {
            const {
                data: { workspaces },
            } = useAccountContextV2();

            const onboardingstate = useMemo((): AccountOnboardingState => {
                let steps: AnyOnboardingAccountStep[] = [];
                if (workspaces.length === 0) {
                    steps = [
                        ...steps,
                        {
                            id: 'integration',
                            slug: 'integration',
                            status: 'force',
                            config: null,
                        },
                        {
                            id: 'asset',
                            slug: 'asset',
                            status: 'force',
                            config: null,
                        },
                    ];
                }
                return { scope: 'account', steps };
            }, [workspaces]);

            return {
                status: 'loaded',
                data: onboardingstate,
            };
        },
    };
}
