import { AnyVisualization, BarCountVisualization } from '../../../domain/visualization';
import { ReportV2Dto, ReportV2Visualization } from '../../../api';
import { AnyResolvedType, Property } from '../../../domain/attributes';
import { assertNever } from '../../../util';
import {
    AnyVisualizationMetric,
    VisualizationDefinitionEntity,
} from '../../../app/visualization-v3';
import { ReportV2Adapter, ReportV2Entity } from '../../../app';
import { ReportV2ImplConfig } from './reportConfig';
import { castViewPropertyToDomain } from '../../api';

export function createReportV2Impl(config: ReportV2ImplConfig): ReportV2Adapter {
    const {
        api: { report: api },
    } = config;

    function toVisualization(
        item: ReportV2Visualization,
        index: number
    ): VisualizationDefinitionEntity {
        const letters = ['a', 'b', 'c', 'd', 'e'];
        return {
            id: index.toString(),
            name: item.name,
            description: item.description ?? null,
            kind: item.kind,
            metrics: item.metrics.map((item, index): AnyVisualizationMetric => {
                if (item.kind === 'aggregation') {
                    return {
                        kind: 'aggregation',
                        type: item.type,
                        key: item.key ?? letters[index] ?? 'unknown',
                        plugin: item.namespace,
                        table: item.table ?? null,
                        column: item.column ?? null,
                        visible: item.visible ?? true,
                    };
                }
                if (item.kind === 'formula') {
                    return {
                        kind: 'formula',
                        key: item.key ?? letters[index] ?? 'unknown',
                        displayName: item.display_name ?? null,
                        formula: item.formula,
                        format: item.format ?? null,
                        visible: item.visible ?? true,
                    };
                }
                // assertNever(item.kind);
                throw Error('not impl');
            }),
            query: {
                granularity: item.query.granularity ?? null,
                // @ts-expect-error
                breakdown: item.query.breakdown ?? null,
                // @ts-expect-error
                filter: item.query.filter ?? null,
                // @ts-expect-error
                period: item.query.period ?? null,
            },
        };
    }

    function toEntity(item: ReportV2Dto): ReportV2Entity {
        if (item.kind === 'dataset') {
            return {
                kind: item.kind,
                id: item.id,
                object: 'report',
                status: item.status,
                name: item.name,
                category: item.category,
                description: item.description,
                tagline: item.tagline,
                tags: item.tags,
                thumbnail: item.thumbnail,
                images: item.images,
                dataset: {
                    id: item.dataset,
                },
                traits: item.traits.map(
                    castViewPropertyToDomain
                ) as Property<AnyResolvedType>[],
                dependencies: item.dependencies,
                visualizations: item.visualizations.map(toVisualization),
            };
        }
        return {
            kind: item.kind,
            id: item.id,
            object: 'report',
            name: item.name,
            status: item.status,
            category: item.category,
            description: item.description,
            tagline: item.tagline,
            tags: item.tags,
            thumbnail: item.thumbnail,
            images: item.images,
            dependencies: item.dependencies,
            url: item.url,
        };
    }

    return {
        async find(context, query) {
            const response = await api.report.list(context, {
                limit: query.limit,
                asset: query.asset,
                status: query.status,
            });
            return response.data.map(toEntity);
        },
        async findOne(context, lookup) {
            const response = await api.report.get(context, lookup);
            return toEntity(response);
        },
    };
}
