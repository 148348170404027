import React, { Suspense } from 'react';
import { useLocation, useNavigate } from 'react-router';
import {
    Button,
    Center,
    Drawer,
    DrawerBody,
    DrawerCloseButton,
    DrawerContent,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    HStack,
    Icon,
    IconButton,
    Text,
} from '@chakra-ui/react';
import { UrlOptionParser } from '../../../../base';
import { StudiesBaseContainerConfig, StudiesContextProvider } from '../../base';
import {
    StudiesDetailController,
    StudiesDetailLoader,
} from './studiesReportDetailInterface';
import { StudiesDetailViewProps } from './studiesReportDetailProps';
import { StudiesDetailPathParamsSchema } from './studiesReportDetailSchema';
import { CardSpinner } from '../../../../../domain';
import { BsArrowLeftShort, BsChevronLeft } from 'react-icons/bs';

export function createStudiesDetailRoute(
    config: StudiesBaseContainerConfig,
    options: UrlOptionParser,
    contexts: StudiesContextProvider,
    controller: StudiesDetailController,
    loader: StudiesDetailLoader,
    View: {
        Desktop: React.FC<StudiesDetailViewProps>;
        Mobile: React.FC<StudiesDetailViewProps>;
    }
): React.FC<{ children?: React.ReactNode }> {
    const {
        kernel: {
            infra: {
                overlay: { Portal: OverlayPortal },
            },
            controller: { browser: browserController },
        },
    } = config;

    const Inner: React.FC<{ children?: React.ReactNode }> = (containerProps) => {
        const location = useLocation();
        const navigate = useNavigate();
        const params = options.useOptions(StudiesDetailPathParamsSchema);
        const context = contexts.useContext();
        const data = loader.useLoad(context, {
            report: { id: params.reportId },
        });
        const props = controller.useProps(context, data, { navigate, location });
        if (props.browser.mode === 'mobile') {
            return <View.Mobile {...props} />;
        }
        return <View.Desktop {...props} />;
    };

    return (containerProps) => {
        const browser = browserController.useProps({});
        const navigate = useNavigate();
        if (browser.mode === 'mobile') {
            // TODO we need to abstract away this mobile navigation
            return (
                <Drawer
                    isOpen={true}
                    onClose={() =>
                        navigate(
                            // @ts-expect-error
                            -1,
                            { replace: true }
                        )
                    }
                    placement="right"
                >
                    <DrawerOverlay />
                    <DrawerContent w="100dvw" maxW="full" bg="#141414">
                        <HStack
                            position="sticky"
                            px={4}
                            py={3}
                            bg="#141414"
                            justify="start"
                            spacing={2}
                        >
                            <DrawerCloseButton
                                as={IconButton}
                                icon={
                                    <Icon
                                        color="whiteAlpha.800"
                                        fontSize="md"
                                        as={BsChevronLeft}
                                    />
                                }
                                bg="none"
                                variant="link"
                                position="inherit"
                                _focus={{ outline: 'none', shadow: 'none' }}
                                _hover={{ bg: 'whiteAlpha.50' }}
                            />
                        </HStack>
                        <DrawerBody p={0}>
                            <Suspense
                                fallback={
                                    <Center h="full" w="full">
                                        <CardSpinner />
                                    </Center>
                                }
                            >
                                <Inner {...containerProps} />
                            </Suspense>
                        </DrawerBody>
                    </DrawerContent>
                </Drawer>
            );
        }
        return <Inner {...containerProps} />;
    };
}
