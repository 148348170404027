import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { chain } from 'lodash';
import {
    PluginConnection,
    MetricCalculation,
    MetricConfiguration,
    MetricDefinition,
} from '../../../../domain/metrics';
import { PluginEntity } from '../../../../domain';
import { useWorkspaceContextV2 } from '../../../../context';
import { PeerGroup } from '../../../../domain/peers';
import { MetricStaticDeps } from '../../metricsConfig';
import { BenchmarkListLoader, BenchmarkListQueries } from './benchmarkListInterface';
import { BenchmarkListAggregate } from './benchmarkListModel';

export function createBenchmarkListLoader(init: MetricStaticDeps): BenchmarkListLoader {
    const { repository } = init;
    return {
        useLoad(adapter, context, collection, query, options): BenchmarkListQueries {
            const {
                calculations: { find: listCalculations },
            } = adapter;

            // const {
            //     peergroups,
            //     plugins,
            //     connections,
            //     metric: { definitions },
            // } = useWorkspaceContextV2();

            // const plugins = service.plugin.useResolvedList(
            //     context,
            //     {
            //     },
            //     {
            //         suspense: true,
            //         keepPreviousData: true

            //     }
            // );

            // const peergroups = repository.peergroup.useFind(
            //     context,
            //     {
            //         workspace: context.workspace,
            //         plugins: plugins.data?.map(x=> x.id) || [],
            //     },
            //     { enabled: plugins.status === 'success', suspense: true }
            // );

            // const connections = repository.connection.useFind(
            //     context,
            //     {
            //         workspaces: [context.workspace],
            //     },
            //     { suspense: true }
            // );
            
            const definitions = repository.metric.definition.useFind(
                context,
                {
                    plugins: context.plugins.data || [],
                    assets: [context.workspace]
                },
                { enabled: context.plugins.status === 'success', suspense: true }
            );

            // return {
            //     status: context.plugins.status === 'success' ?context.plugins.status: 'loading',
            //     plugins: context.plugins,
            //     item: {
            //         status: context.plugins.status,
            //         isLoading: context.plugins.status === 'loading',
            //         data: []
            //     }
            // }

            const groupsByPlugin = useMemo(
                () =>
                    context.peergroups.data?.reduce(
                        (acc, item) => ({ ...acc, [item.plugin.id]: item }),
                        {} as Record<string, PeerGroup | undefined>
                    ) ?? {},
                [context.peergroups.data]
            );

            const pluginsById = useMemo(
                () =>
                    context.plugins.data?.reduce(
                        (acc, item) => ({ ...acc, [item.id]: item }),
                        {} as Record<string, PluginEntity | undefined>
                    ),
                [context.plugins.data]
            );

            const definitionById = useMemo(
                () =>
                    definitions.data?.reduce(
                        (acc, definition) => ({ ...acc, [definition.id]: definition }),
                        {} as Record<string, MetricDefinition | undefined>
                    ),
                [definitions.data]
            );

            const includedDefinitions = useMemo(
                () =>
                    collection.metrics.flatMap((item) => {
                        const definition = definitionById?.[item.definition];
                        if (!definition) {
                            console.info(
                                `metric '${item.definition}' not found in response`,
                                definitionById
                            );
                        }
                        return definition ? [definition] : [];
                    }) ?? [],
                [collection.metrics, definitionById]
            );

            // console.log('DEBUG collection', collection);

            // console.log('DEBUG includedDefinitions', includedDefinitions);

            const connectedPlugins = useMemo(
                () =>
                    new Set(
                        context.connections.data?.flatMap((item) =>
                            item.status === 'ready' ? [item.plugin.id] : []
                        )
                    ),
                [context.connections.data]
            );

            const connectionsByMetric = useMemo(
                () =>
                    context.connections.data?.reduce(
                        (acc, item) => ({ ...acc, [item.plugin.id]: item }),
                        {} as Record<string, PluginConnection | undefined>
                    ),
                [context.connections.data]
            );

            const connectedMetrics = useMemo(
                () =>
                    includedDefinitions.filter((item) =>
                        connectedPlugins.has(item.plugin)
                    ),
                [includedDefinitions, connectedPlugins]
            );

            const responseConfigurations = repository.metric.configuration.useFind(
                context,
                {
                    workspace: { id: context.workspace.id },
                    metrics: connectedMetrics ?? [],
                },
                {
                    suspense: true,
                    enabled:
                        definitions.status === 'success' &&
                        context.plugins.status === 'success' &&
                        context.connections.status === 'success',
                }
            );

            const configurationByDefinitionId =
                responseConfigurations.data?.reduce(
                    (acc, configuration) => ({
                        ...acc,
                        [configuration.definition.id]: { ...configuration },
                    }),
                    {} as Record<
                        string,
                        | Pick<
                              MetricConfiguration,
                              'dashboard' | 'filters' | 'ranking' | 'definition'
                          >
                        | undefined
                    >
                ) ?? {};

            const calculations = useQuery({
                staleTime: Infinity,
                refetchOnWindowFocus: false,
                enabled:
                    definitions.status === 'success' &&
                    context.peergroups.status === 'success' &&
                    responseConfigurations.status === 'success',
                queryKey: [
                    'benchmark',
                    'list',
                    'calculations',
                    context.account,
                    context.workspace,
                    configurationByDefinitionId,
                    groupsByPlugin,
                    query.date,
                    connectedMetrics,
                ],
                suspense: true,
                async queryFn() {
                    try {
                        const response = await listCalculations(
                            context,
                            {
                                metrics: connectedMetrics.flatMap((definition) => {
                                    const configuration =
                                        configurationByDefinitionId[definition.id];
                                    const peergroup =
                                        groupsByPlugin[definition.plugin] ?? null;
                                    if (!configuration) {
                                        console.info(
                                            `metric '${definition.id}' has not configured`,
                                            configurationByDefinitionId
                                        );
                                        return [];
                                    }
                                    if (!peergroup) {
                                        console.info(
                                            `metric '${definition.id}' has no peer groups configured`,
                                            groupsByPlugin
                                        );
                                        return [];
                                    }
                                    return [
                                        {
                                            definition,
                                            configuration: {
                                                ...configuration,
                                                competitiveSet: {
                                                    conditions: peergroup.conditions,
                                                },
                                                dimensions: [],
                                            },
                                        },
                                    ];
                                }),
                                date: query.date,
                            },
                            options
                        );
                        return response;
                    } catch (error) {
                        console.error(error);
                        return [];
                    }
                },
            });

            const calculationByMetric = useMemo(
                () =>
                    calculations.data?.reduce(
                        (acc, calculation) => ({
                            ...acc,
                            [calculation.definition.id]: calculation,
                        }),
                        {} as Record<string, MetricCalculation | undefined>
                    ) ?? {},
                [calculations.data]
            );

            const aggregate = useMemo(
                () =>
                    chain(includedDefinitions)
                        .flatMap((item): BenchmarkListAggregate[] => {
                            const connection = connectionsByMetric?.[item.plugin];
                            // console.log('DEBUG conn', item.id, connection);
                            const plugin = pluginsById?.[item.plugin];
                            const calculation = calculationByMetric[item.id] ?? null;
                            const peergroup = groupsByPlugin[item.plugin] ?? null;

                            const configuration =
                                configurationByDefinitionId[item.id] ?? null;

                            if (!connection) {
                                console.info(
                                    `no connection found for plugin '${item.plugin}'`,
                                    connectionsByMetric
                                );
                                return [];
                            }
                            if (!peergroup) {
                                console.info(
                                    `no peergroup found for plugin '${item.plugin}'`,
                                    groupsByPlugin
                                );
                                return [];
                            }
                            if (connection.status !== 'ready') {
                                // console.info(
                                //     ` connection not ready, discarding`,
                                //     item.id,
                                //     connection
                                // );
                                return [];
                            }
                            if (!plugin) {
                                console.info(
                                    `plugin '${item.plugin}' not found`,
                                    pluginsById
                                );
                                return [];
                            }
                            if (!configuration) {
                                console.info(
                                    `configuration for metric '${item.id}' not found`,
                                    configurationByDefinitionId
                                );
                                return [];
                            }

                            if (!calculation) {
                                // console.info(
                                //     `calculation for metric '${item.id}' not found`,
                                //     calculationByMetric
                                // );
                                return [];
                            }

                            let rankedMetric: MetricDefinition | null = null;

                            if (
                                configuration.ranking.kind === 'fixed' &&
                                configuration.ranking.comparison !== item.key
                            ) {
                                // const definition = definitionById[ranking.comparison];
                                // if (!definition) }{
                                //     console.info('')
                                // }
                                const rankingMetricId = `${plugin.id}.${configuration.ranking.comparison}`;
                                rankedMetric = definitionById?.[rankingMetricId] ?? null;
                                if (!rankedMetric) {
                                    console.info(
                                        `ranking metric ${rankingMetricId} not found`,
                                        definitionById
                                    );
                                }
                            }

                            return [
                                {
                                    metric: item,
                                    connection,
                                    calculation,
                                    plugin,
                                    ranking: rankedMetric,
                                    configuration: {
                                        ...configuration,
                                        competitiveSet: {
                                            conditions: peergroup.conditions,
                                        },
                                        dimensions: [],
                                    },
                                },
                            ];
                        })
                        // .orderBy(
                        //     (item) =>
                        //         item.plugin.id === PluginSlugs.EXECUTIVE_SUMMARY
                        //             ? -1
                        //             : item.plugin.id,
                        //     'desc'
                        // )
                        .value(),
                [
                    includedDefinitions,
                    connectionsByMetric,
                    pluginsById,
                    configurationByDefinitionId,
                    definitionById,
                    calculationByMetric,
                    groupsByPlugin,
                ]
            );

            // console.log('DEBUG calculationByMetric', calculationByMetric);
            // console.log('DEBUG aggregate', aggregate);

            const status = [
                context.connections,
                context.plugins,
                definitions,
                context.peergroups,
                calculations,
            ].every((query) => query.status === 'success')
                ? 'success'
                : 'loading';

            return {
                status,
                plugins: context.plugins,
                item: {
                    isLoading: status === 'loading',
                    status,
                    data: aggregate,
                },
            };
        },
    };
}
