import { z } from 'zod';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { ChargeCompleteContainerProps } from './chargeCompleteContainerProps';
import { SESSION_ID, NEXT } from './chargeCompleteConstant';
import { ChargeCompleteControllerProps, ChargeCompleteDeps } from '../../../view/billing';
import { BillingChargeCompleteConfig } from './chargeCompleteConfig';
import { createChargeCompletedLoader } from './chargeCompleteLoader';
import { createChargeCompleteView } from './chargeCompleteView';
import { Icon } from '@chakra-ui/react';
import { AiOutlineWarning } from 'react-icons/ai';

export function createChargeCompleteRoute(
    init: BillingChargeCompleteConfig
): React.FC<{ children?: React.ReactNode | undefined } & ChargeCompleteContainerProps> {
    const {
        service: {
            subscription: { useRefresh },
        },
        hooks: { useAccount: useOrganization, useAuth },
        UI,
    } = init;

    const loader = createChargeCompletedLoader(init);

    const ParamSchema = z.object({
        [SESSION_ID]: z.string(),
        [NEXT]: z.string(),
    });
    const View = createChargeCompleteView(init);
    const ErrorBoundary = UI.createErrorBoundary({ errorClass: Error });

    const RetValView: React.FC<
        { children?: React.ReactNode | undefined } & ChargeCompleteContainerProps
    > = ({ children }) => {
        const viewContext = {
            account: useOrganization(),
            auth: useAuth(),
        };

        const [searchParams, _] = useSearchParams();

        const viewOptions = useMemo<{ [SESSION_ID]?: string; [NEXT]?: string }>(() => {
            const raw = Object.fromEntries(searchParams.entries());
            const parsed = ParamSchema.safeParse(raw);

            return parsed.success ? parsed.data : {};
        }, [searchParams]);

        if (!viewOptions[SESSION_ID]) {
            return (
                <UI.EmptyState
                    status="danger"
                    icon={<Icon as={AiOutlineWarning} />}
                    title="No Payment Found"
                />
            );
        }
        const loadedData = loader.useLoad(viewContext, viewOptions[SESSION_ID]);
        const viewDeps: ChargeCompleteDeps = {
            location: useLocation(),
            mutation: {
                refreshSubscription: useRefresh(viewContext),
            },
        };
        const controllerProps = React.useMemo<ChargeCompleteControllerProps>(
            () => ({
                ...loadedData,
                nextRoute: viewOptions[NEXT],
            }),
            [loadedData]
        );

        const viewProps = init.controller.charge.complete.useProps(
            viewContext,
            viewDeps,
            controllerProps
        );
        return <View {...viewProps}>{children}</View>;
    };
    return (props) => {
        return (
            <ErrorBoundary
                key={location.pathname}
                fallback={(info) => (
                    <UI.EmptyState
                        status="danger"
                        icon={<Icon as={AiOutlineWarning} />}
                        title="An unexpected error occured"
                    />
                )}
            >
                <RetValView {...props} />
            </ErrorBoundary>
        );
    };
}
