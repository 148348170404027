import { useMemo } from 'react';
import { PluginServiceConfigV2 } from './pluginConfig';
import { PluginServiceV2 } from './pluginInterface';
import { chain } from 'lodash';
import { isReferenceType, resolveProperties } from '../../domain/attributes';


export function createPluginServiceV2(
    init: PluginServiceConfigV2
): PluginServiceV2 {
    const {
        repository,
    } = init;
    return {
        // @ts-expect-error
        useResolvedList(context, query, options) {
            const responsePlugins = repository.plugin.useFind(context, query, options);
            const types = useMemo(
                () =>
                    chain(responsePlugins.data)
                        .flatMap((plugin) => plugin.traits.map((trait) => trait.type))
                        .filter(isReferenceType)
                        .uniqBy((item) => item.id)
                        .value(),
                [responsePlugins.data]
            );

            const responseMembers = repository.member.useFind(
                context,
                {
                    workspace: context.workspace,
                    types,
                },
                { staleTime: Infinity, enabled: options.enabled }
            );
            
            const resolvedPlugins = useMemo(
                () =>
                    responsePlugins.data?.map((plugin) => {
                        return {
                            ...plugin,
                            traits: resolveProperties(
                                responseMembers.data ?? [],
                                plugin.traits
                            ),
                        };
                    }) || [],
                [responsePlugins.data, responseMembers.data]
            );
            
            return {
                ...responsePlugins,
                data: resolvedPlugins,
            };  
        },
        useCollection(context, query, options) {
            const retVal = repository.plugin.useCollection(context, query, options);
            return retVal;
        },
    };
}
