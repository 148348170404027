import { TooltipProps } from '@chakra-ui/react';
import { capitalize } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { PageIds } from '../../../../../config';
import { SelectBlock } from '../../../../../app/assistant';
import { ServiceUnavailableError } from '../../../../../base';
import { AssistantControllerBaseConfig } from '../../../base';
import { MessageItemViewProps } from './message';
import { SuggestionItemProps } from './suggestion';

import { ThreadDetailControllerProps, ThreadDetailViewProps } from './threadDetailProps';
import { ThreadDetailController } from './threadDetailInterface';

export function createThreadDetailController(
    config: AssistantControllerBaseConfig
): ThreadDetailController {
    const {
        kernel: {
            provider: { createPageController },
        },
        dependencies: { createChatController },
    } = config;

    const pageControler = createPageController({
        id: PageIds.ASSISTANT_THREAD_DETAIL,
    });
    const chatController = createChatController();

    function getTooltipProps(
        props: ThreadDetailControllerProps
    ): Omit<TooltipProps, 'children'> {
        if (props.isSubmitting) {
            return {
                isDisabled: false,
                label: `Waiting for assistant reply`,
            };
        }
        if (props.chat.state.text.value.trim() === '') {
            return {
                isDisabled: false,
                label: `Type a message to interact with the assistant`,
            };
        }
        return {
            isDisabled: true,
        };
    }

    return {
        useProps(context, item, props): ThreadDetailViewProps {
            const chat = chatController.useProps(item.chat, {
                ...props.chat,
                onSubmit(message) {
                    props.onSubmit({
                        message,
                        source: 'composer',
                    });
                },
            });
            const page = pageControler.useProps(
                {
                    entity: {
                        id: item.thread.id,
                        object: 'thread',
                    },
                },
                {}
            );

            const handleSuggestionClick = useCallback(
                async (value: string) => {
                    props.onSubmit({
                        message: { text: value },
                        source: 'suggestion',
                    });
                },
                [props]
            );

            const suggestions = useMemo(
                () =>
                    item.mostRecentMessage?.content
                        .find(
                            (block): block is SelectBlock =>
                                block.kind === 'input' && block.type === 'select'
                        )
                        ?.options.map(
                            (option): SuggestionItemProps => ({
                                id: option.value,
                                label: option.label,
                                button: {
                                    onClick: handleSuggestionClick.bind(
                                        null,
                                        option.label
                                    ),
                                },
                            })
                        ) ?? [],
                [item.mostRecentMessage, handleSuggestionClick]
            );

            let error: {
                message: string;
            } | null = null;

            if (props.error && props.error instanceof ServiceUnavailableError) {
                error = {
                    message: 'Server is currently unavailable, please try again later',
                };
            } else if (props.error) {
                error = {
                    message: 'Something went wrong, please contact support',
                };
            }

            return {
                isLoading: props.status === 'processing',
                chat,
                suggestion: {
                    items: suggestions,
                },
                detail: {
                    workflow: {
                        label: item.workflow.displayName,
                        description: item.workflow.description ?? 'no description',
                    },
                },
                message: {
                    items: item.entries.map((item): MessageItemViewProps => {
                        const { message, result: event } = item;
                        return {
                            id: message.id,
                            author: {
                                kind: message.role,
                                label: capitalize(message.role),
                            },
                            content: event
                                ? // when the response was an event we assume that in was in response to
                                  // the most recent action
                                  message.content.filter(
                                      (block) => block.kind !== 'action'
                                  )
                                : message.content,
                        };
                    }),
                },
                composer: {
                    tooltip: getTooltipProps(props),
                    error: error,
                },
            };
        },
    };
}
