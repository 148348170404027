import React from 'react';
import { AssistantContainerBaseConfig } from '../../../base';
import { AssistantUrlParamSchema } from '../../../assistantSchema';
import {
    AssistantLayoutController,
    AssistantLayoutLoader,
    AssistantLayoutStateProvider,
} from './assistantLayoutInterface';
import {
    AssistantLayoutContainerProps,
    AssistantLayoutViewProps,
} from './assistantLayoutProps';
import { useLocation, useNavigate } from 'react-router';

export function createAssistantLayoutContainer(
    config: AssistantContainerBaseConfig,
    states: AssistantLayoutStateProvider,
    loader: AssistantLayoutLoader,
    controller: AssistantLayoutController,
    View: {
        Desktop: React.FC<
            { children?: React.ReactNode | undefined } & AssistantLayoutViewProps
        >;
        Mobile: React.FC<
            { children?: React.ReactNode | undefined } & AssistantLayoutViewProps
        >;
    }
): React.FC<{ children?: React.ReactNode | undefined } & AssistantLayoutContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        context: {
            root: { useContext },
        },
    } = config;
    const { Desktop: DesktopView, Mobile: MobileView } = View;
    return ({ children, ...containerProps }) => {
        const options = useOptions(AssistantUrlParamSchema);
        const state = states.useState();
        const context = useContext();
        const data = loader.useLoad(context, containerProps);
        const props = controller.useProps(context, data, {
            ...options,
            detail: state.detail,
            navigation: {
                navigate: useNavigate(),
                location: useLocation(),
            },
        });
        if (props.browser.mode === 'mobile') {
            return (
                <MobileView {...containerProps} {...props}>
                    {children}
                </MobileView>
            );
        }
        return (
            <DesktopView {...containerProps} {...props}>
                {children}
            </DesktopView>
        );
    };
}
