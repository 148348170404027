import React from 'react';
import { isActiveAssetClaim, isAssetOwner } from '../../../../domain/assets';
import { AssetClaimConfig } from './assetClaimConfig';
import { AssetClaimController } from './assetClaimInterface';
import { AssetClaimFormProps, AssetClaimViewProps } from './assetClaimProps';
import { claimDetail, claimStatus, claimToColor, isClaimInProcess } from './assetClaimHelper';
import { InputProps } from '@chakra-ui/react';



export function createAssetClaimController(config: AssetClaimConfig): AssetClaimController {
    return {
        useProps(context, deps, props): AssetClaimViewProps {
            const { form } = deps;

            const formProps: AssetClaimFormProps = {
                isSubmittable: form.formState.isDirty,
                error: deps.update.error,
                handleSubmit: deps.form.handleSubmit,
                async onSubmit(values) {
                    const response = await deps.update.mutateAsync([
                        props.item.asset.id,
                        {
                            addEvidence: {
                                note: values.note,
                                evidence: values.evidence
                            }
                        },
                    ]);
                    return response;
                },
            };
            // const [evidenceFile, setEvidenceFile] = React.useState<File | null>(null);
            // const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            //     if (e.target.files) {
            //       setEvidenceFile(e.target.files[0]);
            //     }
            //   };
            React.useEffect(() => {
                form.register('evidence', {
                    required: true
                });
            }, []);
            const refreshState = config.repository.asset.useClaim(context);
            
            return {
                getRefreshProps() {
                    return {
                        getRefreshButtonProps() {
                            return {
                                onClick: () => refreshState.mutate({
                                    assetId: props.item.asset.id
                                }),
                                isLoading: refreshState.isLoading,
                                type: 'button',
                                isDisabled: false,
                            }
                        },
                        result: refreshState.data ?? null,
                        error: refreshState.error,
                    }
                },
                getStateProps() {
                    return {
                        claim: props.item.asset.claim,
                        expiresAt: isActiveAssetClaim(props.item.asset.claim) ? props.item.asset.claim.expires_at: null,
                        colorScheme: claimToColor(props.item.asset.claim),
                        label: claimStatus(props.item.asset.claim),
                        isLoading: isClaimInProcess(props.item.asset.claim),
                        detail: claimDetail(props.item.asset.claim)
                    }
                },
                getFormProps() {
                    return formProps;
                },
                getControlProps(key) {
                    const error = form.formState.errors[key] ?? null;
                    return {
                        isInvalid: !!error,
                        error,
                    };
                },
                getInputProps(key) {
                    if(key === 'evidence') {
                        return {
                            onChange: function(event) {
                                if(event.target.files) {
                                    form.setValue('evidence', event.target.files[0])
                                } else {
                                    form.resetField('evidence');
                                }
                            }

                        }
                    }
                    return { ...form.register(key) };
                },
                getSubmitButtonProps() {
                    return {
                        type: 'submit',
                        isDisabled: !formProps.isSubmittable,
                        isLoading: deps.update.isLoading,
                    };
                },
                getFormElementProps() {
                    return {
                        onSubmit: form.handleSubmit(formProps.onSubmit),
                    };
                },
                getFormControlProps(key) {
                    const error = form.formState.errors[key] ?? null;
                    return {
                        isInvalid: !!error,
                    };
                },
                getErrorMessageProps(key) {
                    const error = form.formState.errors[key] ?? null;
                    return {
                        children: error?.message,
                    };
                },
                getAlertProps() {
                    if (!formProps.error) {
                        return null;
                    }
                    return {
                        status: 'error',
                        label: formProps.error.message,
                    };
                },
            };
        },
    };
}
