import { ActionItemControllerV2 } from '../../view/common';
import { ApiError, KernelEnhancer } from '../../base';

export function createKernelToastEnhancer(): KernelEnhancer {
    return (create) => (config) => {
        function enhanceActionController(
            instance: ActionItemControllerV2
        ): ActionItemControllerV2 {
            return {
                ...instance,
                useProps(props) {
                    if (props.kind !== 'custom') {
                        return instance.useProps(props);
                    }
                    return instance.useProps({
                        ...props,
                        async onPerform(...args) {
                            try {
                                const response = await props.onPerform(...args);
                                return response;
                            } catch (error) {
                                if (error instanceof ApiError) {
                                    kernel.infra.toasterV2.trigger({
                                        title:
                                            error.message ??
                                            'An unexpected error occured',
                                        status: 'error',
                                    });
                                } else {
                                    kernel.infra.toasterV2.trigger({
                                        title: 'An unexpected error occured',
                                        status: 'error',
                                    });
                                }
                                // console.error('toast error', error);
                                throw error;
                            }
                        },
                    });
                },
            };
        }
        const kernel = create({
            ...config,
            provider: {
                ...config.provider,
                createActionController(...args) {
                    const instance = config.provider.createActionController(...args);
                    return enhanceActionController(instance);
                },
            },
        });

        return kernel;
    };
}
