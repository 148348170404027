import { ViewEntity } from '../../../domain';
import { ViewAdapter } from '../../../app/query';
import { castTypeToDomain } from '../../api';
import { ViewImplConfig } from './viewConfig';

export function createViewImpl(config: ViewImplConfig): ViewAdapter {
    const { api } = config;
    return {
        async find(context, query) {
            const response = await api.dashboard.views.list(context, {
                ...query,
                assets: query.assets?.map(x => x.id.toString()),
            });
            return response.data.map((item): ViewEntity => {
                return {
                    ...item,
                    columns: item.columns.map((column) => ({
                        ...column,
                        type: castTypeToDomain(column.type),
                        constraints: [],
                        isDisabled: false,
                        disabledReason: null,
                    })),
                };
            });
        },
    };
}
