import { useMemo } from 'react';
import { SelectV2Config } from './selectV2Config';
import { SelectV2Controller } from './selectV2Interface';
import { SelectV2ItemProps, SelectV2ViewProps } from './selectV2Props';

export function createSelectV2Controller<TItem>(
    config: SelectV2Config<TItem>
): SelectV2Controller<TItem> {
    return {
        useProps(props) {
            const mapped = useMemo(() => props.items.map(config.getProps), [props.items]);

            const selected = useMemo(
                () => mapped.find((candidate) => props.value.includes(candidate.value)),
                [mapped, props.value]
            );

            const getItemProps: SelectV2ViewProps<TItem>['getItemProps'] = (item) => {
                const mapped = config.getProps(item);
                const isSelected = props.value.includes(mapped.value);
                return {
                    id: mapped.value,
                    isSelected,
                    label: mapped.label,
                    option: {
                        value: mapped.value,
                    },
                    container: {
                        onClick: props.onChange.bind(null, [mapped.value]),
                        'aria-selected': isSelected,
                    },
                };
            };

            return {
                input: {
                    select: {
                        value: props.value,
                        onChange(event) {
                            props.onChange([event.target.value]);
                        },
                    },
                },
                trigger: {
                    label: selected?.label ?? config.placeholder,
                },
                getItemProps,
            };
        },
    };
}
