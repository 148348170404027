import { assert } from '../../../util/assert';
import { ConversationThreadAdapter } from '../../../app/assistant';
import { ThreadImplConfig } from './threadImplConfig';
import { toThreadEntity } from './threadImplSerdes';

export function createThreadImpl(config: ThreadImplConfig): ConversationThreadAdapter {
    const {
        api: { assistant: api },
    } = config;

    return {
        async find(context, query) {
            throw new Error('not impl');
        },
        async lookup(context, query) {
            // assert(context.auth.scheme.kind === 'legacy', 'expected legacy auth');
            // await context.auth.scheme.store.waitAuthenticated();
            // assert(context.auth.scheme.store.authToken, 'missing token');
            const response = await api.threadRetrieve(
                {
                    threadId: query.id,
                }
                // {
                //     headers: {
                //         Authorization: `bearer ${context.auth.scheme.store.authToken}`,
                //     },
                // }
            );
            return toThreadEntity(response.data);
        },
        async create(context, props) {
            // assert(context.auth.scheme.kind === 'legacy', 'expected legacy auth');
            // await context.auth.scheme.store.waitAuthenticated();
            // assert(context.auth.scheme.store.authToken, 'missing token');
            const response = await api.threadCreate(
                {
                    threadCreatePayload: props,
                }
                // {
                //     headers: {
                //         Authorization: `bearer ${context.auth.scheme.store.authToken}`,
                //     },
                // }
            );
            return toThreadEntity(response.data);
        },
    };
}
