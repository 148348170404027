import { useQuery, useQueryClient } from '@tanstack/react-query';
import { normalizeCollection } from '../../../base';
import { ReportV2Adapter } from './reportAdapter';
import { ReportV2Repository } from './reportInterface';
import { ReportV2Lookup, ReportV2Query } from './reportQuery';
import { isWorkspaceContext } from '../../platform';

export function createReportV2Repository(adapter: ReportV2Adapter): ReportV2Repository {
    const PREFIX = ['report-v2'];

    function getLookupKey(query: ReportV2Lookup) {
        return [...PREFIX, 'lookup', query];
    }

    return {
        useCollection(context, query, options) {
            const client = useQueryClient();
            
            const applied: ReportV2Query = {
                ...query,
                limit: query.limit ?? 100,
                asset: context.workspace ? (context.workspace.id as number): null,
                status: query.status ?? ['published'],
            };
            const result = useQuery({
                ...options,
                queryKey: [...PREFIX, 'find', context.account.id, applied],
                async queryFn() {
                    const response = await adapter.find(context, applied);
                    return normalizeCollection(response);
                },
                onSuccess(data) {
                    for (const id of data.ids) {
                        const item = data.byId[id];
                        if (item) {
                            const queryKey = getLookupKey({
                                asset: applied.asset,
                                id,
                            });
                            client.setQueryData(queryKey, item);
                        }
                    }
                },
            });
            return result;
        },
        useLookup(context, lookup, options) {
            const applied: ReportV2Lookup = {
                asset: isWorkspaceContext(context) ? (context.workspace.id as number): null,
                id: lookup.id,
            };
            return useQuery({
                ...options,
                queryKey: getLookupKey(applied),
                async queryFn() {
                    const response = await adapter.findOne(context, applied);
                    return response;
                },
            });
        },
    };
}
