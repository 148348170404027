import { z } from 'zod';
import { OverlayStateProvider } from './overlayInterface';
import { useMemo } from 'react';
import { OverlayConfig } from './overlayConfig';

export function createOverlayStateProvider(config: OverlayConfig): OverlayStateProvider {
    const UrlParamsSchema = z.object({
        [config.stateKey]: z.boolean({ coerce: true }).optional(),
    });
    return {
        useState(props) {
            const [searchParams, setSearchParams] = props.searchParams;
            const options = useMemo(
                () => UrlParamsSchema.parse(Object.fromEntries(searchParams.entries())),
                [searchParams]
            );
            console.log('options', options);
            return {
                value: {
                    overlay: options[config.stateKey] ?? false,
                },
                onChange(value) {
                    if (value.overlay) {
                        searchParams.set(config.stateKey, 'true');
                    } else {
                        searchParams.delete(config.stateKey);
                    }
                    setSearchParams(searchParams, { replace: true });
                },
            };
        },
    };
}
