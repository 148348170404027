import { PeerContextConfig } from './peerBaseConfig';
import { PeerContextProvider } from './peerBaseInterface';

export function createPeerContextProvider(
    config: PeerContextConfig
): PeerContextProvider {
    const { hook } = config;
    return {
        useContext() {
            return {
                account: hook.useAccount(),
                workspace: hook.useWorkspace(),
                auth: hook.useAuth(),
            };
        },
    };
}
