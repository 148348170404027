import { BaseApplicationShellLoaderConfig } from '../../../base';
import { ApplicationShellContextLoader } from './applicationContextInterface';

export function createApplicationContextLoader(
    config: BaseApplicationShellLoaderConfig
): ApplicationShellContextLoader {
    return {
        useLoad(props) {
            return {
                asset: null,
            };
        },
    };
}
