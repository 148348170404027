import { AccountAdapterV2 } from '../../../app/platform';
import { AccountDto } from '../../../api/platform/account';
import { AccountImplConfig } from './accountConfig';
import { AccountEntity } from '../../../domain';

export function createOrganizationImpl(config: AccountImplConfig): AccountAdapterV2 {
    const { api } = config;
    function toEntity(response: AccountDto): AccountEntity {
        return {
            ...response,
            state: {
                finishedOnBoarding: response.state.finished_on_boarding ?? false,
                defaultAsset: response.state.default_asset_id
                    ? { id: response.state.default_asset_id }
                    : null,

                pendingActions: response.state.pending_actions
                    ? response.state.pending_actions.map((x) => ({
                          scope: x.scope,
                          isRequired: x.is_required,
                          spec: x.spec,
                      }))
                    : null,
            },
        };
    }
    return {
        async lookup(context, query) {
            const response = await api.platform.account.get(context, query.id);
            return toEntity(response);
        },
        async update(context, props) {
            const response = await api.platform.account.update(context, props.accountId, {
                finished_on_boarding: props.finishedOnBoarding,
                default_asset: props.defaultAsset,
            });
            return toEntity(response);
        },
    };
}
