import { ApplicationErrorController } from './applicationErrorInterface';

export function createApplicationErrorController(): ApplicationErrorController {
    return {
        useProps(props) {
            return {
                label: props.info.error.message,
                link: {
                    back: { to: '/u' },
                },
            };
        },
    };
}
