import { useStore } from '../../../../stores/setupContext';
import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router';
import { AccountDto, AccountUpdateDto } from '../../../api/platform';
import { AssetMappingModuleConfig } from './mappingConfig';
import { AssetMappingController } from './mappingInterface';

export function createMappingController(
    init: AssetMappingModuleConfig['deps']
): AssetMappingController {
    const {
        api: {
            platform: {
                account: { get: getOrganization, update: updateOrganization },
                assets: { list: listAssets },
            },
        },
        hook: { useQuery, useMutation },
    } = init;
    return {
        useProps(context, auth) {
            const client = useQueryClient();
            const navigate = useNavigate();

            const keys = {
                organization: ['organization', context.account.id],
                assets: ['assets', 'mapping', context.account.id],
            };

            const organizationQuery = useQuery({
                queryKey: keys.organization,
                async queryFn() {
                    const response = await getOrganization(
                        context,
                        context.account.id
                    );
                    return response;
                },
                suspense: true,
            });

            const assetQuery = useQuery({
                queryKey: keys.assets,
                refetchOnWindowFocus: true,
                refetchInterval: 60000,
                staleTime: 15000,
                retry: false,
                async queryFn() {
                    const response = await listAssets(context, {});
                    return response.data;
                },
                suspense: true,
            });

            const defaultAssetIndex =
                assetQuery.data?.findIndex(
                    (item) => item.id === organizationQuery.data?.state?.default_asset_id
                ) ?? 0;

            const [selectedIndex, setSelectedIndex] = useState<number>(
                Math.max(0, defaultAssetIndex)
            );

            const mutationUpdate = useMutation({
                async mutationFn(payload: AccountUpdateDto) {
                    const response = await updateOrganization(
                        context,
                        context.account.id,
                        payload
                    );
                    return response;
                },
            });

            const selectedAsset = assetQuery.data?.[selectedIndex];

            return {
                selected: selectedAsset?.id.toString() ?? null,
                items: assetQuery.data ?? [],
                getChildProps(item, index) {
                    return {
                        selected: index === selectedIndex,
                        onSelect() {
                            setSelectedIndex(index);
                        },
                    };
                },
                async onSubmit() {
                    await mutationUpdate.mutateAsync({
                        default_asset: selectedAsset?.id,
                    });

                    client.setQueryData<AccountDto>(keys.organization, {
                        ...organizationQuery.data!,
                        state: {
                            ...(organizationQuery.data?.state || {finished_on_boarding: false, pending_actions: null}),
                            default_asset_id: selectedAsset?.id ?? null,

                        }
                    });

                    // NOTE this is a hack. we need to refresh the onboarding state that is still
                    // part of the old legacy infrastructure, otherwise the user
                    // will keep getting redirected back to this view
                    await auth.getMyAccount();

                    navigate(`/u/assets/${selectedAsset?.id}`);

                    return;
                },
            };
        },
    };
}
