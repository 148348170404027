import { assert } from '@varos/util-typescript';
import { ReportLoaderBaseConfig } from '../../../../base';
import { ReportInstanceDetailContentLoader } from './reportContentInterface';
import { ReportInstanceDetailContentAggregate } from './reportContentModel';
import { useMemo } from 'react';

export function createReportInstanceDetailContentLoader(
    config: ReportLoaderBaseConfig
): ReportInstanceDetailContentLoader {
    const { repository } = config;
    return {
        useLoad(context, props): ReportInstanceDetailContentAggregate {
            const contentQuery = repository.content.useLookup(
                context,
                {
                    content: props.content,
                },
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );
            const bookmarkQuery = repository.bookmark.useFind(
                context,
                {
                    version: props.item.version.id,
                },
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );
            const subscriptionQuery = repository.subscription.useFind(
                context,
                {
                    version: props.item.version.id,
                },
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );
            assert(contentQuery.status === 'success', 'expected suspense');
            assert(bookmarkQuery.status === 'success', 'expected suspense');
            assert(subscriptionQuery.status === 'success', 'expected suspense');
            return useMemo(
                () => ({
                    report: props.item.report,
                    version: props.item.version,
                    content: contentQuery.data,
                    bookmarks: bookmarkQuery.data,
                    subscription: subscriptionQuery.data[0] ?? null,
                }),
                [
                    props.item.report,
                    props.item.version,
                    contentQuery.data,
                    bookmarkQuery.data,
                    subscriptionQuery.data,
                ]
            );
        },
    };
}
