import React from 'react';
import { ReportContainerBaseConfig } from '../../../../base';
import {
    ReportInstanceDetailContentController,
    ReportInstanceDetailContentLoader,
} from './reportContentInterface';
import {
    ReportInstanceDetailContentContainerProps,
    ReportInstanceDetailContentViewProps,
} from './reportContentProps';

export function createReportInstanceDetailContentContainer(
    config: ReportContainerBaseConfig,
    loader: ReportInstanceDetailContentLoader,
    controller: ReportInstanceDetailContentController,
    View: React.FC<ReportInstanceDetailContentViewProps>
): React.FC<ReportInstanceDetailContentContainerProps> {
    const {
        repository,
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        context: { useContext },
    } = config;

    return ({ ...containerProps }) => {
        const context = useContext();
        const mutation = {
            subscription: {
                create: repository.subscription.useCreate(context),
                delete: repository.subscription.useDelete(context),
            },
        };
        const data = loader.useLoad(context, containerProps);
        const props = controller.useProps(context, data, {
            period: null,
            subscribe: data.subscription
                ? {
                      id: 'unsubscribe',
                      kind: 'delete',
                      name: 'Unsubscribe',
                      onPerform: mutation.subscription.delete.mutateAsync.bind(
                          null,
                          data.subscription
                      ),
                  }
                : {
                      id: 'subscribe',
                      kind: 'custom',
                      name: 'Subscribe',
                      colorScheme: 'green',
                      onPerform: mutation.subscription.create.mutateAsync.bind(null, {
                          report: data.report.id,
                      }),
                  },
        });
        return <View {...props} />;
    };
}
