import { Box, HStack, List, ListItem, Switch, Text, VStack } from '@chakra-ui/react';
import React from 'react';
import { FeatureFlagViewProps } from '../featureFlagProps';

export const FeatureFlagForm: React.FC<
    { children?: React.ReactNode | undefined } & FeatureFlagViewProps
> = (props) => {
    return (
        <List spacing={4}>
            {/* <ListItem py={2}>
                <HStack justifyContent="space-between">
                    <Box>Home</Box>
                    <Box>
                        <Switch
                            defaultChecked={props.value.home?.enabled}
                            onChange={(event) =>
                                props.onChange({
                                    ...props.value,
                                    home: { enabled: event.target.checked },
                                })
                            }
                        />
                    </Box>
                </HStack>
            </ListItem> */}
            {/* <ListItem py={2}>
                <HStack justifyContent="space-between">
                    <Box>Insights</Box>
                    <Box>
                        <Switch
                            defaultChecked={props.value.insights?.enabled}
                            onChange={(event) =>
                                props.onChange({
                                    ...props.value,
                                    insights: { enabled: event.target.checked },
                                })
                            }
                        />
                    </Box>
                </HStack>
            </ListItem> */}
            <ListItem py={2}>
                <HStack justifyContent="space-between">
                    <Box>Dynamic dashboards</Box>
                    <Box>
                        <Switch
                            defaultChecked={props.value.dynamicDashboards?.enabled}
                            onChange={(event) =>
                                props.onChange({
                                    ...props.value,
                                    dynamicDashboards: { enabled: event.target.checked },
                                })
                            }
                        />
                    </Box>
                </HStack>
            </ListItem>
            <ListItem py={2}>
                <HStack justifyContent="space-between">
                    <Box>AI Surveys</Box>
                    <Box>
                        <Switch
                            defaultChecked={props.value.chat?.enabled}
                            onChange={(event) =>
                                props.onChange({
                                    ...props.value,
                                    chat: { enabled: event.target.checked },
                                })
                            }
                        />
                    </Box>
                </HStack>
            </ListItem>
            <ListItem py={2}>
                <HStack justifyContent="space-between">
                    <Box>Permissions</Box>
                    <Box>
                        <Switch
                            defaultChecked={props.value.permissions?.enabled}
                            onChange={(event) =>
                                props.onChange({
                                    ...props.value,
                                    permissions: { enabled: event.target.checked },
                                })
                            }
                        />
                    </Box>
                </HStack>
            </ListItem>
            <ListItem py={2}>
                <HStack justifyContent="space-between">
                    <Box>Example Mode</Box>
                    <Box>
                        <Switch
                            defaultChecked={props.value.exampleMode?.enabled}
                            onChange={(event) =>
                                props.onChange({
                                    ...props.value,
                                    exampleMode: { enabled: event.target.checked },
                                })
                            }
                        />
                    </Box>
                </HStack>
            </ListItem>
            <ListItem py={2}>
                <HStack justifyContent="space-between">
                    <Box>IT Benchmarks</Box>
                    <Box>
                        <Switch
                            defaultChecked={props.value.itbenchmarks?.enabled}
                            onChange={(event) =>
                                props.onChange({
                                    ...props.value,
                                    itbenchmarks: { enabled: event.target.checked },
                                })
                            }
                        />
                    </Box>
                </HStack>
            </ListItem>
            <ListItem py={2}>
                <HStack justifyContent="space-between">
                    <Box>Experimental reports</Box>
                    <Box>
                        <Switch
                            defaultChecked={props.value.draftReports?.enabled}
                            onChange={(event) =>
                                props.onChange({
                                    ...props.value,
                                    draftReports: { enabled: event.target.checked },
                                })
                            }
                        />
                    </Box>
                </HStack>
            </ListItem>
            {/* <ListItem py={2}>
                <HStack justifyContent="space-between">
                    <Box>Tactical calls</Box>
                    <Box>
                        <Switch
                            defaultChecked={props.value.expertCalls?.enabled}
                            onChange={(event) =>
                                props.onChange({
                                    ...props.value,
                                    expertCalls: { enabled: event.target.checked },
                                })
                            }
                        />
                    </Box>
                </HStack>
            </ListItem> */}
            {/* <ListItem py={2}>
                <HStack justifyContent="space-between">
                    <Box>Fixed cohorts</Box>
                    <Box>
                        <Switch
                            defaultChecked={props.value.fixedCohorts?.enabled}
                            onChange={(event) =>
                                props.onChange({
                                    ...props.value,
                                    fixedCohorts: { enabled: event.target.checked },
                                })
                            }
                        />
                    </Box>
                </HStack>
            </ListItem> */}
            {/* <ListItem py={2}>
                <HStack justifyContent="space-between">
                    <Box>Experimental Dashboard Filters</Box>
                    <Box>
                        <Switch
                            defaultChecked={
                                props.value.experimentalDashboardFilters?.enabled
                            }
                            onChange={(event) =>
                                props.onChange({
                                    ...props.value,
                                    experimentalDashboardFilters: {
                                        enabled: event.target.checked,
                                    },
                                })
                            }
                        />
                    </Box>
                </HStack>
            </ListItem> */}
            <ListItem py={2}>
                <HStack justifyContent="space-between" spacing={2}>
                    <VStack alignItems="flex-start" spacing={0.5}>
                        <Box>
                            <Text>Bypass workspace onboarding</Text>
                        </Box>
                        <Box>
                            <Text color="whiteAlpha.500">
                                Disable the automatic default asset onboarding redirect
                            </Text>
                        </Box>
                    </VStack>
                    <Box>
                        <Switch
                            defaultChecked={
                                props.value.bypassWorkspaceOnboarding?.enabled
                            }
                            onChange={(event) =>
                                props.onChange({
                                    ...props.value,
                                    bypassWorkspaceOnboarding: {
                                        enabled: event.target.checked,
                                    },
                                })
                            }
                        />
                    </Box>
                </HStack>
            </ListItem>
        </List>
    );
};
