import { z } from 'zod';


export const FileDtoSchema = z.object({
    id: z.string(),
    account_id: z.string(),
    url: z.string(),
    name: z.string().nullable(),
    extension: z.string()
});
