import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListResponse } from '../../common';
import { IntegrationResource } from './integrationInterface';
import { IntegrationDto } from './integrationModel';
import { IntegrationListResponseSchema } from './integrationSchema';

export function createIntegrationResource(client: AxiosInstance): IntegrationResource {
    return {
        async list(context, query) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get('/api/v1/integrations', {
                params: {
                    page_size: query.page_size,
                    ...params,
                },
                headers: {
                    ...headers,
                },
            });

            const parsed = IntegrationListResponseSchema.parse(response.data);

            const transformed: ListResponse<IntegrationDto> = {
                data: parsed.items,
            };
            return transformed;
        },
    };
}
