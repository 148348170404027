import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListCursorResponse } from '../../common';
import { GroupInviteDto } from './inviteModel';
import { GroupInviteResource } from './inviteInterface';
import { GroupInviteListResponseSchema, GroupInviteSchema } from './inviteSchema';

export function createGroupInviteResource(client: AxiosInstance): GroupInviteResource {
    return {
        async list(context, query): Promise<ListCursorResponse<GroupInviteDto>> {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get('/api/v1/traits/invites', {
                params: {
                    // expand: ['definition'],
                    ...params,
                    ...query,
                },
                headers: {
                    ...headers,
                },
            });
            try {
                const parsed = GroupInviteListResponseSchema.parse(response.data);
                return parsed;
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async create(context, group) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }
            const response = await client.post(
                '/api/v1/traits/groups',
                {
                    name: group.name,
                    membership: {
                        kind: 'internal',
                    },
                },
                {
                    params,
                    headers: {
                        ...headers,
                    },
                }
            );

            const parsed = GroupInviteSchema.parse(response.data);
            return parsed;
        },
        async remove(context, group) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }
            const response = await client.delete(
                `/api/v1/traits/groups/${group.groupId}`,
                {
                    params,
                    headers: {
                        ...headers,
                    },
                }
            );
        },
    };
}
