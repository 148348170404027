// import { resolvePath } from 'react-router';
import { Kernel } from '../../../base';
import { TabLayoutController } from './tabLayoutInterface';

export function createTabLayoutController(kernel: Kernel): TabLayoutController {
    const { controller } = kernel;
    return {
        useProps(props) {
            const browser = controller.browser.useProps({});
            return {
                browser,
                items: props.items.map((item) => {
                    // const path = resolvePath(item.path, `/u/assets/${context.workspace.id}`);
                    return {
                        ...item,
                        isActive: props.location.pathname.includes(item.path),
                        link: {
                            to: item.path,
                        },
                    };
                }),
            };
        },
    };
}
