import { ReportControllerBaseConfig } from '../../../base';
import { ReportInstanceDetailController } from './reportDetailInterface';
import { ReportInstanceDetailViewProps } from './reportDetailProps';

export function createReportInstanceDetailController(
    config: ReportControllerBaseConfig
): ReportInstanceDetailController {
    return {
        useProps(context, item, props): ReportInstanceDetailViewProps {
            return {
                content: item.content?.version.content
                    ? {
                          item: item.content,
                          content: { id: item.content?.version.content },
                      }
                    : null,
                status: {
                    item: item.status,
                },
            };
        },
    };
}
