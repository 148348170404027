import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { AssetEvidenceFormProvider } from './assetClaimInterface';
import { EvidenceFormValues } from './assetClaimModel';

export function createAssetEvidenceFormProvider(): AssetEvidenceFormProvider {
    return {
        useForm(context, props) {
            const retVal =  useForm<EvidenceFormValues>({
                defaultValues: {},
                mode: 'onSubmit',
            });
            return retVal;
        },
    };
}
