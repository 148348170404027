import { useQuery } from '@tanstack/react-query';
import { assert } from '../../../../util/assert';
import { ReportNewConfig } from './reportNewConfig';
import { ReportNewLoader } from './reportNewInterface';
import { ReportNewAggregate } from './reportNewModel';

export function createReportNewLoader(config: ReportNewConfig): ReportNewLoader {
    const { repository } = config;
    return {
        useLoad(context, props) {
            const assets = {
                find: repository.asset.useFind(context, {}, { staleTime: Infinity }),
                create: repository.asset.useCreate(context),
            };
            const preference = repository.preference.useLookup(context, {}, {});

            const status = [assets.find, preference].every(
                (item) => item.status === 'success'
            )
                ? 'success'
                : 'loading';

            return {
                status,
                asset: {
                    create: assets.create,
                },
                report: { create: repository.report.useCreate(context) },

                item: useQuery({
                    enabled: status === 'success',
                    queryKey: [
                        'report',
                        'new',
                        'aggregate',
                        status,
                        context.account.id,
                    ],
                    queryFn(): ReportNewAggregate {
                        const found = assets.find.data?.find(
                            (item) => item.id === props.asset.id
                        );
                        assert(found, `asset ${props.asset.id} not found`);
                        assert(preference.data, `preference data not found`);
                        return { asset: found, preference: preference.data };
                    },
                }),
            };
        },
    };
}
