import { z } from 'zod';

export const AssetTypeSchema = z.union([
    z.literal('mobile_app'),
    z.literal('ecommerce_website'),
    z.literal('saas'),
    z.literal('website'),
    z.literal('marketplace'),
]);


export const AssetDtoSchema = z.object({
    id: z.number(),
    name: z.string(),
    asset_type: AssetTypeSchema,
    url: z.string(),
    external_identifier: z.string(),
    icon_url: z.nullable(z.string()),
    is_visible: z.boolean(),
    created_at: z.date({ coerce: true }),
    updated_at: z.date({ coerce: true }),
    claim: z.nullable(z.object({
        id: z.string(),
        evidences: z.array(z.object({
            material: z.object({
                kind: z.literal('file'),
                file_id: z.string(),
                note: z.string().nullable(),
                created_at: z.date({coerce: true})
            }),
            status: z.enum(['allowed', 'denied', 'pending']),
            updated_at: z.date({coerce: true}).nullable(),
            expiry: z.date({coerce: true}).nullable(),
        })),
        claim: z.discriminatedUnion('kind', [
            z.object({
                kind: z.literal('active'),
                is_owner: z.boolean(),
                reason: z.object({kind: z.string()}),
                expires_at: z.date({coerce: true}).nullable()
            }),
            z.object({
                kind: z.literal('inactive'),
                status: z.enum(['pending', 'blocked', 'expired'])
            }),
        ])
    }))
});

export const AssetDtoListSchema = z.object({
    data: z.array(AssetDtoSchema),
});


export const RunAssetClaimResultSchema = z.discriminatedUnion('status', [
    z.object({
        status: z.literal('active'),
        is_owner: z.boolean(),
        reason: z.object({kind: z.string()}),
        expires_at: z.date({coerce: true})
    }),
    z.object({
        status: z.literal('failure'),
        instructions: z.array(z.string()).nullable(),
    }),
    z.object({
        status: z.literal('pending'),
        ran_side_effect: z.boolean(),
        instructions: z.array(z.string()).nullable(),
    }),
]);