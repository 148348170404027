import { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { AxiosCacheInstance } from 'axios-cache-interceptor';
import { convertAxiosToError, isAxiosErr } from '../../../../utils/axiosUtils';
import { LegacyAuthenticationScheme } from '../../common';
import { UserResource } from './userInterface';
import { UserListDto, UserDto, LoadedUserDto } from './userModel';
import { UserListSchema, UserSchema } from './userSchema';


export function createUserResource(client: AxiosInstance): UserResource {
    return {
        async list(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = {};
            if (query.expand) {
                params['expand'] = query.expand;
            }
            if (query.page) {
                params['page'] = query.page;
            }
            if (query.pageSize) {
                params['page_size'] = query.pageSize;
            }
            if (query.after_token) {
                params['after_token'] = query.after_token;
            }
            if (query.search) {
                params['search'] = query.search;
            }
            // @ts-expect-error
            const response = await (client as AxiosCacheInstance).get('/api/v1/account/users', {
                cache: false,
                params,
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            try {
                const parsed = UserListSchema.parse(response.data);
                return {
                    pagination: parsed.pagination,
                    data: parsed.data.map(u => ({
                        id: u.id,
                        firstName: u.details.first_name,
                        lastName: u.details.last_name,
                        // a user without any email is currently not supported
                        email: u.details.identities[0].email,
                        // we assume at least one membership
                        role: u.memberships[0]?.role || 'standard',
                        title: u.memberships[0].profile?.title ?? null,
                        assets: u.assets,
                    }))
                };
            }catch(x) {
                console.log(x);
                throw x;
            }
        },
        // async create(context, dto) {
        //     const scheme = context.auth.scheme as LegacyAuthenticationScheme;
        //     await scheme.store.waitAuthenticated();
        //     try {
        //         const response = await client.post(
        //             '/api/v1/account/users',
        //             { ...dto, asset_access_role: 'manager' },
        //             {
        //                 headers: {
        //                     Authorization: `bearer ${scheme.store.authToken}`,
        //                 },
        //             }
        //         );
        //         UserSchema.parse(response.data);
        //         return response.data;

        //     } catch (e) {
        //         if (isAxiosErr(e)) {
        //             throw convertAxiosToError(e);
        //         } else {
        //             console.log(e);
        //             throw e;
        //         }
        //     }
        // },
        async remove(context, userId) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            try {
                const response = await client.delete<unknown, AxiosResponse<void>>(
                    `/api/v1/account/users/${userId}`,
                    {
                        headers: {
                            Authorization: `bearer ${scheme.store.authToken}`,
                        },
                    }
                );
            } catch (e) {
                if (isAxiosErr(e)) {
                    throw convertAxiosToError(e);
                } else {
                    throw e;
                }
            }
        },
    };
}
