import { useResolvedPath, useHref } from 'react-router';
import { NavigationConfig } from './navigationConfig';
import { NavigationController } from './navigationInterface';
import { NavigationViewProps } from './navigationProps';

export function createNavigationController(
    config: NavigationConfig
): NavigationController {
    return {
        useProps(props): NavigationViewProps {
            const hasHistory = props.location.key !== 'default';
            let back: NavigationViewProps['back'];

            if (hasHistory) {
                back = {
                    trigger() {
                        props.navigate(config.rootPath, { replace: true });
                    },
                    button: {
                        onClick() {
                            props.navigate(
                                // @ts-expect-error this is actually a valid API but typings are not up-to-date
                                -1,
                                { replace: true }
                            );
                        },
                    },
                };
            } else {
                back = {
                    trigger() {
                        props.navigate(config.rootPath, { replace: true });
                    },
                    button: {
                        onClick() {
                            props.navigate(config.rootPath, { replace: true });
                        },
                    },
                };
            }
            return {
                back: back,
            };
        },
    };
}
