import {
    ApplicationLayoutConfig,
    ApplicationLayoutInit,
} from './applicationLayoutConfig';
import {
    ApplicationLayout,
    ApplicationLayoutEnhancer,
} from './applicationLayoutInterface';
import { createTabLayout, createTabLayoutController } from './tab';

export function createApplicationLayout(
    config: ApplicationLayoutInit,
    enhancer?: ApplicationLayoutEnhancer
): ApplicationLayout {
    return create(
        {
            ...config,
            provider: {
                createTabController: createTabLayoutController,
            },
        },
        enhancer
    );
}

function create(
    config: ApplicationLayoutConfig,
    enhancer?: ApplicationLayoutEnhancer
): ApplicationLayout {
    if (enhancer) {
        return enhancer(create)(config);
    }
    return {
        tab: createTabLayout(config.provider.createTabController(config.kernel)),
    };
}
