import { PageIds } from '../../../../../../../config';
import { RegistrationControllerConfig } from '../../../../../base';
import { RegistrationAcceptWelcomeController } from './acceptWelcomeInterface';

export function createRegistrationAcceptWelcomeController(
    config: RegistrationControllerConfig
): RegistrationAcceptWelcomeController {
    const {
        provider: { createPageController, createVideoController },
    } = config;

    const { useProps: usePageProps } = createPageController({
        id: PageIds.REGISTRATION_ACCEPT_WELCOME,
        title: 'Welcome to Varos!',
        description: `Get up and running in a few minutes`,
        provider: config.provider,
    });

    const { useProps: useVideoProps } = createVideoController();

    return {
        useProps(context, props) {
            const page = usePageProps({}, props.page);
            const video = useVideoProps(props.video);
            return {
                page,
                video,
                button: {
                    onClick() {
                        props.stepper.onChange(props.stepper.selected + 1);
                    },
                },
                login: {
                    link: {
                        id: 'onboarding_invitation_login',
                        to: '/login',
                    },
                },
            };
        },
    };
}
