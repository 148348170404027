import React, { useMemo } from 'react';
import { AxiosInstance } from 'axios';
import { useAuthenticatedContext } from '../../../../container';
import { useAuthenticatedApi } from '../../../../infra';
import { AccountScopeContext, AccountScopeValue } from './accountContext';
import { AccountDependencies } from './accountInterface';

export interface AccountConfig {
    getDeps(config: {
        api: AxiosInstance;
    }): AccountDependencies;
}

export interface AccountSetProviderProps {}

export function createAccountProvider(
    config: AccountConfig
): React.FC<{ children?: React.ReactNode | undefined } & AccountSetProviderProps> {
    return (props) => {
        const appcontext = useAuthenticatedContext();
        const api = useAuthenticatedApi();
        const adapter = useMemo<AccountDependencies>(
            () =>
                config.getDeps({
                    api
                }),
            [api, appcontext.account]
        );

        if (!appcontext.account?.id) {
            throw new Error(`no account context found in context`);
        }
        const context: AccountScopeValue = {
            adapter,
            account: {
                id: appcontext.account.id,
            },
        };
        return (
            <AccountScopeContext.Provider value={context}>
                {props.children}
            </AccountScopeContext.Provider>
        );
    };
}
