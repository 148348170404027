import React from 'react';
import { AccountScope } from '../../../../app';
import { GroupsConfig } from '../common/groupsConfig';
import { GroupsLoader } from '../loader';
import { GroupsRedirectProps } from './groupsRedirectProps';
import {
    GroupRedirectController,
    GroupRedirectControllerProps,
} from './groupRedirectInterface';

export function createGroupRedirectContainer(
    hooks: GroupsConfig['deps']['hook'],
    loader: GroupsLoader,
    controller: GroupRedirectController,
    View: React.FC<{ children?: React.ReactNode | undefined } & GroupsRedirectProps>
) {
    return (props: GroupRedirectControllerProps) => {
        const organization = hooks.useAccount();
        const auth = hooks.useAuth();
        const orgContext: AccountScope = {
            account: organization,
            auth,
        };
        const data = loader.useLoad(orgContext);
        const viewProps = controller.useProps(props, data);

        return <View {...viewProps} />;
    };
}
