import { QueryCache, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ApplicationContext, Kernel } from '../../../base';
import { UserScope } from '../../platform';
import { ConversationThreadAdapter } from './conversationThreadAdapter';
import { ThreadRepository } from './conversationThreadInterface';
import { ConversationThreadCreateProps } from './conversationThreadProps';
import { ConversationThreadLookupQuery } from './conversationThreadQuery';

export function createThreadRepository(
    kernel: Kernel,
    adapter: ConversationThreadAdapter
): ThreadRepository {
    const PREFIX = ['convesations', 'thread'];

    function getLookupKey(
        context: ApplicationContext,
        props: ConversationThreadLookupQuery
    ) {
        return [...PREFIX, context.principal?.id, props.id];
    }

    return {
        useFind(context, query, options) {
            const client = useQueryClient();
            const result = kernel.infra.repository.useQuery({
                staleTime: Infinity,
                ...options,
                queryKey: [...PREFIX, context.principal?.id, query],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
                onSuccess(data) {
                    for (const item of data) {
                        const queryKey = getLookupKey(context, item);
                        client.setQueryData(queryKey, item);
                    }
                },
            });
            return result;
        },
        useLookup(context, query, options) {
            const result = kernel.infra.repository.useQuery({
                staleTime: Infinity,
                ...options,
                queryKey: getLookupKey(context, query),
                async queryFn() {
                    const response = await adapter.lookup(context, query);
                    return response;
                },
            });
            return result;
        },
        useCreate(context) {
            const client = useQueryClient();
            const result = useMutation({
                async mutationFn(props: ConversationThreadCreateProps) {
                    const response = await adapter.create(context, props);
                    return response;
                },
                async onSuccess(data, variables, _context) {
                    const queryKey = getLookupKey(context, data);
                    client.setQueryData(queryKey, data);
                },
            });
            return result;
        },
    };
}
