import { AxiosInstance } from 'axios';
import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useAuthenticatedContext } from '../../../../container';
import { AccountReference, AuthenticationV2, Workspace } from '../../../domain';
import { useWorkspaceApi } from '../workspace';
import { DashboardConfigurationAdapter } from '../configuration';
import { ChartAdapter } from '../chart';
import { DashboardContext, DashboardContextValue } from './dashboardContext';
import { DashboardAdapter } from './dashboardAdapter';
import { PreferencesAdapter } from '../preferences';
import { Navigate } from 'react-router-dom';
import { DashboardRepository } from './dashboardInteface';
import { PluginServiceV2 } from '../../../service';
import { PeerGroupRepository } from '../../peers';

export interface DashboardProviderConfig {
    param: string;
    hook: {
        useAuth(): AuthenticationV2;
        useAccount(): AccountReference;
    }
    repository: {
        dashboard: DashboardRepository;
        peergroup: PeerGroupRepository;
    };
    service: {
        plugin: PluginServiceV2
    };
    getDeps(config: { workspace: Workspace; api: AxiosInstance }): {
        dashboard: DashboardAdapter;
        configuration: DashboardConfigurationAdapter;
        chart: ChartAdapter;
        preferences: PreferencesAdapter;
    };
}
const BW_DASHBOARD_SLUGS: Record<string, string> = {
    'googleads-v2': 'google-a-d-s-v2',
};

export interface DashboardProviderProps {}

export function createDashboardProvider(
    config: DashboardProviderConfig
): React.FC<{ children?: React.ReactNode | undefined } & DashboardProviderProps> {
    return (props) => {
        const { api } = useAuthenticatedContext();
        
        const workspace = useWorkspaceApi().getWorkspace();
        const account = config.hook.useAccount();
        const auth = config.hook.useAuth();
        const workspaceState = React.useMemo(() => ({
            ...workspace,
            claimActive: workspace.claim.kind === 'active'
        }), [workspace]);
        const adapter = useMemo(
            () => config.getDeps({ api, workspace }),
            [api, workspace]
        );
        
        const { [config.param]: dashboardId, ...rest } = useParams();
        if (!dashboardId) {
            const candidates = Object.keys(rest);
            throw new Error(
                `url parameter '${config.param}' not found. found ${candidates.join(
                    ', '
                )}`
            );
        }
        const appContext = {
            auth,
            account,
            workspace: workspaceState
        };
        const plugins = config.service.plugin.useResolvedList(appContext, {}, {
                suspense: appContext.workspace.claimActive, 
                enabled: appContext.workspace.claimActive
        });
        const dashboards = config.repository.dashboard.useFind(
            appContext,
            {
                workspace
            },
            {
                suspense: appContext.workspace.claimActive,
                enabled: appContext.workspace.claimActive,

            }
        )
        const dashboard = dashboards.data?.find((item) => item.id === dashboardId);

        const collectedPeergroups = config.repository.peergroup.useFind(appContext, {
            workspace: appContext.workspace,
            plugins: plugins.data?.map(x => x.id) ?? []
        }, {enabled: plugins.status === 'success' && appContext.workspace.claimActive});
        
        const peergroup = useMemo(
            () =>
                collectedPeergroups.data?.find(
                    (item) => item.plugin.id === dashboard?.pluginId
                ) ?? null,
                [collectedPeergroups.data, dashboard?.pluginId]
        );
            
        const plugin = useMemo(
            () => plugins.data?.find((candidate) => candidate.id === dashboard?.pluginId),
            [plugins, dashboard?.pluginId]
        );
            
        if(!appContext.workspace.claimActive) {
            return <Navigate to='../../settings/general-settings' />;
        }
        if (!dashboard) {
            if (BW_DASHBOARD_SLUGS[dashboardId]) {
                return <Navigate to={`../${BW_DASHBOARD_SLUGS[dashboardId]}`} />;
            }
            throw new Error(`dashboard '${dashboardId}' not found`);
        }
        const context: DashboardContextValue = {
            workspace,
            dashboard,
            peergroup,
            refetchPeerGroup() {
                collectedPeergroups.refetch();
            },
            plugin: plugin ?? null,
            plugins,
            adapter: {
                chart: adapter.chart,
                preferences: adapter.preferences,
                dashboard: adapter.dashboard,
                configuration: adapter.configuration,
            },
        };

        return (
            <DashboardContext.Provider value={context}>
                {props.children}
            </DashboardContext.Provider>
        );
    };
}
