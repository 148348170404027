import { createChakraStepperController } from '../../../../../vendor/chakra-stepper';
import { RegistrationControllerConfig } from '../../../base';
import { RegistrationAcceptCreateController } from './registrationAcceptCreateInterface';
import { RegistrationAcceptCreateViewProps } from './registrationAcceptCreateProps';

export function createRegistrationAcceptCreateController(
    config: RegistrationControllerConfig
): RegistrationAcceptCreateController {
    const {
        kernel: {
            controller: { browser: browserController },
        },
    } = config;
    const { useProps: useStepperProps } = createChakraStepperController();
    return {
        useProps(item, props): RegistrationAcceptCreateViewProps {
            const stepper = useStepperProps(props.stepper);
            const browser = browserController.useProps({});
            return {
                browser,
                stepper,
                isOrganization:
                    item.invitation.kind === 'external' &&
                    item.invitation.account.kind === 'organization',
                button: {
                    home: {
                        onClick() {
                            stepper.onChange(0);
                        },
                    },
                },
                link: {
                    login: {
                        id: 'onboarding_invitation_login',
                        to: '/login',
                    },
                },
                anchor: {
                    home: {
                        id: 'onboarding_invitation_home',
                        href: 'https://varos.com',
                        target: '_self',
                    },
                },
            };
        },
    };
}
