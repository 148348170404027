import { OverlayController } from './overlayInterface';

export function createOverlayController(): OverlayController {
    return {
        useProps(props) {
            return {
                overlayRef: props.overlayRef,
                drawer: props.drawer,
            };
        },
    };
}
