import { chain } from 'lodash';
import {
    AccountPreferenceSectionSaveDto,
    isTraitSection,
    TraitFilterSection,
} from '../../../../api';
import { MultiFilterRule, MetadataRule } from '../../../../models/Metadata';
import { DashboardsByPlugin, PluginsByDashboard } from '../../../../config';
import { PeerGroup } from '../../../domain/peers';
import { castConditionToDomain } from '../../api';
import { PeerGroupAdapter } from '../../../app/peers';
import { AnyCondition } from '../../../domain/attributes';
import { PeerGroupImplConfig } from './groupConfig';
import { castConditionToApi } from '../../api';
import { PreferencTraitFiltersSectionMatchDto, PreferenceUpdateDto } from '../../../api';

export function createPeerGroupImpl(config: PeerGroupImplConfig): PeerGroupAdapter {
    const {
        api: { preferences },
    } = config;
    return {
        async find(context, query) {
            // const response = await preferences.list(context, {
            //     assetId: Number(context.workspace.id),
            //     pageSize: 100,
            //     sections: ['trait_filters'],
            // });

            const response2 = await preferences.match(context, {
                assetId: Number(query.workspace.id),
                plugins: query.plugins,
                dashboards: [],
                pageSize: 100,
                sections: ['trait_filters'],
            });

            console.log('DEBUG response2', response2.data);

            const sectionByPlugin = response2.data.reduce(
                (acc, match) => {
                    const [[, asset], [, org], [, plugin]] = [
                        match.context.find(([kind]) => kind === 'asset') ?? [null, null],
                        match.context.find(([kind]) => kind === 'account') ?? [
                            null,
                            null,
                        ],
                        match.context.find(([kind]) => kind === 'plugin') ?? [null, null],
                    ];
                    const [section] = match.sections.flatMap((section) =>
                        isTraitSection(section) ? [section] : []
                    );
                    if (
                        plugin &&
                        asset === query.workspace.id.toString() &&
                        org === context.account.id.toString() &&
                        section
                    ) {
                        return { ...acc, [plugin]: section };
                    }
                    return { ...acc };
                },
                {} as Record<string, PreferencTraitFiltersSectionMatchDto | undefined>
            );

            console.log('DEBUG sectionByPlugin', sectionByPlugin);

            return query.plugins.map((pluginId) => {
                const section = sectionByPlugin[pluginId];
                const isCustom = !!section?.value?.is_customized;
                return {
                    workspace: {
                        id: query.workspace.id,
                    },
                    plugin: {
                        id: pluginId,
                    },
                    default: !isCustom,
                    conditions: section
                        ? (section.value.rules.sub_rules.map((rule): AnyCondition => {
                              const mapped = castConditionToDomain({
                                  key: rule.metadata_definition_key,
                                  operator: rule.operator,
                                  value: rule.value,
                              });
                              return mapped;
                          }) ?? [])
                        : [],
                };
            });

            // console.log(
            //     'DEBUG response',
            //     context.workspace.id,
            //     query.plugins,
            //     response.data
            // );

            // console.log('DEBUG query.plugins', query.plugins);
            // console.log('DEBUG response.data', response.data);

            // const mapped = chain(query.plugins)
            //     .flatMap((plugin): PeerGroup[] => {
            //         const pluginMatch = response.data.find((candidate) => {
            //             return (
            //                 candidate.scopes.some(
            //                     (scope) =>
            //                         scope.subject_type === 'plugin' &&
            //                         scope.subject_id === plugin
            //                 ) &&
            //                 candidate.scopes.some(
            //                     (scope) =>
            //                         scope.subject_type === 'org' &&
            //                         scope.subject_id ===
            //                             context.organization.id.toString()
            //                 ) &&
            //                 candidate.scopes.some(
            //                     (scope) =>
            //                         scope.subject_type === 'asset' &&
            //                         scope.subject_id === context.workspace.id.toString()
            //                 )
            //             );
            //         });
            //         const assetAndOrgMatch = response.data.find((candidate) => {
            //             return (
            //                 !candidate.scopes.some(
            //                     (scope) => scope.subject_type === 'plugin'
            //                 ) &&
            //                 candidate.scopes.some(
            //                     (scope) =>
            //                         scope.subject_type === 'org' &&
            //                         scope.subject_id ===
            //                             context.organization.id.toString()
            //                 ) &&
            //                 candidate.scopes.some(
            //                     (scope) =>
            //                         scope.subject_type === 'asset' &&
            //                         scope.subject_id === context.workspace.id.toString()
            //                 )
            //             );
            //         });

            //         const appliedMatch = pluginMatch ?? assetAndOrgMatch;

            //         if (!appliedMatch || appliedMatch.section_type !== 'trait_filters') {
            //             console.warn(
            //                 `no match found for plugin '${plugin}' trait filters`,
            //                 context,
            //                 response.data
            //             );
            //             return [
            //                 {
            //                     plugin: {
            //                         id: plugin,
            //                     },
            //                     default: true,
            //                     conditions: [],
            //                 },
            //             ];
            //         }
            //         const isCustom = !!appliedMatch?.section.is_customized;
            //         return [
            //             {
            //                 plugin: {
            //                     id: plugin,
            //                 },
            //                 default: !isCustom,
            //                 conditions:
            //                     appliedMatch?.section.rules.sub_rules.map(
            //                         (rule): AnyCondition => {
            //                             const mapped = castConditionToDomain({
            //                                 key: rule.metadata_definition_key,
            //                                 operator: rule.operator,
            //                                 value: rule.value,
            //                             });
            //                             return mapped;
            //                         }
            //                     ) ?? [],
            //             },
            //         ];
            //     })
            //     .uniqBy((item) => item.plugin.id)
            //     .value();

            // return mapped;
        },
        async putAll(context, entities) {
            // console.log('saving', entities);

            const mapped = entities.map((group): AccountPreferenceSectionSaveDto => {
                const dashboard = DashboardsByPlugin[group.plugin.id];
                return {
                    // dashboard: dashboard,
                    // assetId: Number(context.workspace.id) as number,
                    scopes: [
                        {
                            subject_type: 'asset',
                            subject_id: group.workspace.id.toString(),
                        },
                        {
                            subject_type: 'plugin',
                            subject_id: group.plugin.id,
                        },
                    ],
                    config: {
                        section_type: 'trait_filters',
                        value: {
                            is_customized: true,
                            rules: {
                                operator: 'and',
                                sub_rules: group.conditions.map((condition) => {
                                    const casted = castConditionToApi(condition);
                                    return {
                                        metadata_definition_key: casted.key,
                                        operator: casted.operator,
                                        value: casted.value as any,
                                    };
                                }),
                            },
                        },
                    },
                };
            });

            // console.log('saved mapped', mapped);

            // await Promise.all(
            //     mapped.map((request) => preferences.update(context, request))
            // );

            const response = await preferences.update(context, {
                // dashboard: undefined,
                // assetId: Number(context.workspace.id) as number,
                sections: mapped,
            });

            // await new Promise((resolve) => setTimeout(resolve, 2000));
        },
    };
}
