import React, { useMemo } from 'react';
import { AccountScope } from '../../../app';
import {
    GroupsLoader,
    GroupsActivatedController,
    GroupRedirectController,
    GroupsIntroController,
    GroupsConfig,
    GroupsActivatedViewProps,
    GroupsIntroProps,
    GroupsRedirectProps,
    createGroupRedirectContainer,
} from '../../../view/groups/group';

export function createGroupsAnalyzeRoute(config: {
    hook: GroupsConfig['deps']['hook'];
    loader: GroupsLoader;
    controller: {
        activated: GroupsActivatedController;
        introduction: GroupsIntroController;
        redirect: GroupRedirectController;
    };
    view: {
        Activated: React.FC<
            { children?: React.ReactNode | undefined } & GroupsActivatedViewProps
        >;
        Introduction: React.FC<
            { children?: React.ReactNode | undefined } & GroupsIntroProps
        >;
        Redirect: React.FC<
            { children?: React.ReactNode | undefined } & GroupsRedirectProps
        >;
    };
}) {
    const { hook, loader, controller, view } = config;

    return {
        Activated: () => {
            const organization = config.hook.useAccount();
            const auth = config.hook.useAuth();
            const orgContext: AccountScope = {
                account: organization,
                auth,
            };
            const data = loader.useLoad(orgContext);
            const viewProps = controller.activated.useProps(orgContext, data);

            return <view.Activated {...viewProps} />;
        },
        Introduction: () => {
            const organization = config.hook.useAccount();
            const auth = config.hook.useAuth();
            const orgContext: AccountScope = {
                account: organization,
                auth,
            };
            const data = loader.useLoad(orgContext);
            const viewProps = controller.introduction.useProps(orgContext, data);

            return <view.Introduction {...viewProps} />;
        },
        Redirect: createGroupRedirectContainer(
            hook,
            loader,
            controller.redirect,
            view.Redirect
        ),
    };
}
