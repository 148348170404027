import { useStore } from '../../../../stores/setupContext';
import { observer } from 'mobx-react';
import React from 'react';
import { OnboardingAccountRouteStrategy } from '../../../route';
import { AssetMappingContainerConfig } from './onboardingMappingConfig';
import { Box, ButtonGroup, Text } from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import { ArrowForwardIcon } from '@chakra-ui/icons';

// export const INSIGHT_WORKSPACE_ITEM_ID = 'insights';

export function createOnboardingMappingItem(
    config: AssetMappingContainerConfig
): OnboardingAccountRouteStrategy {
    const { controller, Component, hooks } = config;
    return {
        id: 'default_asset',
        name: 'Select asset',
        slug: 'default_asset',
        create(config) {
            const {
                Layout: { Container, Content, Button },
            } = config;
            return {
                Component: observer((props) => {
                    const navigate = useNavigate();
                    const { auth: authstore } = useStore();
                    const organization = hooks.useOrganization();
                    const auth = hooks.useAuth();
                    const viewProps = controller.useProps(
                        { account: organization, auth },
                        authstore
                    );
                    const handleSubmit = async () => {
                        await viewProps.onSubmit();
                        // props.onContinue?.();
                        navigate(`/u/assets/${viewProps.selected}`);
                    };
                    return (
                        <Container
                            title="Select your default asset"
                            description={
                                <Text>
                                    <Box as="span">
                                        Our free plans grant access to one brand's data.
                                        To unlock multiple brands, upgrade to our{' '}
                                    </Box>
                                    <Box
                                        as="span"
                                        display="inline-block"
                                        color="blue.500"
                                        textDecoration="underline"
                                    >
                                        <Link to="/settings/plan?type=agency">
                                            agency plan
                                        </Link>
                                    </Box>
                                </Text>
                            }
                            rightContent={
                                <ButtonGroup>
                                    {props.onContinue && (
                                        <Button variant="continue" onClick={handleSubmit}>
                                            Continue
                                        </Button>
                                    )}
                                </ButtonGroup>
                            }
                        >
                            <Content isLoading={false}>
                                <Component {...viewProps} />
                            </Content>
                        </Container>
                    );
                }),
            };
        },
    };
}
