import React from 'react';
import { isActiveAssetClaim, isAssetOwner } from '../../../../domain/assets';
import { AssetEditConfig } from './assetEditConfig';
import { AssetEditController } from './assetEditInterface';
import { AssetEditFormProps, AssetEditViewProps } from './assetEditProps';



export function createAssetEditController(config: AssetEditConfig): AssetEditController {
    return {
        useProps(context, deps, props): AssetEditViewProps {
            const { form } = deps;
            const formValues = form.watch();

            const formProps: AssetEditFormProps = {
                isSubmittable: form.formState.isDirty,
                error: deps.update.error,
                handleSubmit: form.handleSubmit,
                async onSubmit(values) {
                    const response = await deps.update.mutateAsync([
                        props.item.asset.id,
                        {
                            ...values,
                        },
                    ]);
                    return response;
                },
            };
            const isEditDetailsAllowed =  React.useMemo(() => isAssetOwner(props.item.asset.claim), [props.item.asset.claim])

            return {
                isEditDetailsAllowed,
                getFormProps() {
                    return formProps;
                },
                getControlProps(key) {
                    const error = form.formState.errors[key] ?? null;
                    return {
                        isInvalid: !!error,
                        error,
                    };
                },
                getInputProps(key) {
                    if (key === 'kind') {
                        const option = this.getSelectOptionsProps(key).find(
                            (candidate) => candidate.value === formValues.kind
                        );
                        // temp hack. kind input should be a dropdown
                        return {
                            ...form.register(key),
                            value: option?.label ?? formValues.kind,
                        };
                    }
                    return { ...form.register(key) };
                },
                getSelectOptionsProps(key) {
                    if (key === 'kind') {
                        return [
                            {
                                value: 'website',
                                label: 'Website',
                            },
                        ];
                    }
                    return [];
                },
                getSubmitButtonProps() {
                    return {
                        type: 'submit',
                        isDisabled: !isEditDetailsAllowed || !formProps.isSubmittable,
                        isLoading: deps.update.isLoading,
                    };
                },
                getFormElementProps() {
                    return {
                        onSubmit: form.handleSubmit(formProps.onSubmit),
                    };
                },
                getFormControlProps(key) {
                    const error = form.formState.errors[key] ?? null;
                    return {
                        isInvalid: !!error,
                    };
                },
                getErrorMessageProps(key) {
                    const error = form.formState.errors[key] ?? null;
                    return {
                        children: error?.message,
                    };
                },
                getAlertProps() {
                    if (!formProps.error) {
                        return null;
                    }
                    return {
                        status: 'error',
                        label: formProps.error.message,
                    };
                },
            };
        },
    };
}
