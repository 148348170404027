import { assert } from '@varos/util-typescript';
import { ReportLoaderBaseConfig } from '../../../base';
import { ReportNewLoader } from './reportNewInterface';

export function createReportNewLoader(config: ReportLoaderBaseConfig): ReportNewLoader {
    const { repository } = config;
    return {
        useLoad(context, props) {
            const reportQuery = repository.instance.useLookup(
                context,
                {
                    report: { id: props.template.slug },
                },
                {
                    staleTime: Infinity,
                    suspense: true,
                }
            );
            assert(reportQuery.status === 'success', 'expected suspense');
            return {
                template: reportQuery.data,
            };
        },
    };
}
