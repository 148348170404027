import React from 'react';
import { TabLayoutContainerProps } from './tabLayoutProps';
import { TabLayoutController } from './tabLayoutInterface';
import { TabLayoutConfig } from './tabLayoutConfig';
import { useLocation } from 'react-router';

export function createTabLayoutContainer(
    config: TabLayoutConfig,
    controller: TabLayoutController
): React.FC<TabLayoutContainerProps> {
    const {
        Theme: { Desktop: DesktopView, Mobile: MobileView },
    } = config;
    return ({ children, ...containerProps }) => {
        const location = useLocation();
        const props = controller.useProps({ location, items: config.items });
        if (props.browser.mode === 'mobile') {
            return <MobileView {...props}>{children}</MobileView>;
        }
        return <DesktopView {...props}>{children}</DesktopView>;
    };
}
