import { AxiosInstance } from 'axios';
import { LegacyAuthenticationScheme, ListCursorResponse } from '../../common';
import { GroupDto } from './groupModel';
import { GroupResource } from './groupInterface';
import { GroupListResponseSchema, GroupSchema } from './groupSchema';

export function createGroupResource(client: AxiosInstance): GroupResource {
    return {
        async list(context, query): Promise<ListCursorResponse<GroupDto>> {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get('/api/v1/traits/groups', {
                params: {
                    // expand: ['definition'],
                    ...params,
                    ...query,
                },
                headers: {
                    ...headers,
                },
            });
            try {
                const parsed = GroupListResponseSchema.parse(response.data);
                return parsed;
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async lookup(context, groupId): Promise<GroupDto> {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }

            const response = await client.get(`/api/v1/traits/groups/${groupId}`, {
                params: {
                    ...params,
                },
                headers: {
                    ...headers,
                },
            });
            try {
                const parsed = GroupSchema.parse(response.data);
                return parsed;
            } catch (e) {
                console.log(e);
                throw e;
            }
        },
        async create(context, group) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }
            const response = await client.post(
                '/api/v1/traits/groups',
                {
                    name: group.name,
                    membership: {
                        kind: 'internal',
                    },
                },
                {
                    params,
                    headers: {
                        ...headers,
                    },
                }
            );

            const parsed = GroupSchema.parse(response.data);
            return parsed;
        },
        async createV2(context, props) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }
            const response = await client.post('/api/v1/traits/groups', props, {
                params,
                headers: {
                    ...headers,
                },
            });

            const parsed = GroupSchema.parse(response.data);
            return parsed;
        },
        async update(context, id, payload) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }
            const response = await client.patch(`/api/v1/traits/groups/${id}`, payload, {
                params,
                headers: {
                    ...headers,
                },
            });

            const parsed = GroupSchema.parse(response.data);
            return parsed;
        },
        async remove(context, group) {
            let headers: Record<string, string> = {};
            let params: Record<string, string> = {};

            if (context.auth.scheme.kind === 'apiKey') {
                params = { ...params, 'api-key': context.auth.scheme.token };
                if (context.auth.scheme.assume?.kind === 'account') {
                    headers = {
                        ...headers,
                        'x-varos-account': context.auth.scheme.assume.id.toString(),
                    };
                }
            } else {
                const scheme = context.auth.scheme as LegacyAuthenticationScheme;
                await scheme.store.waitAuthenticated();
                headers = {
                    ...headers,
                    Authorization: `bearer ${scheme.store.authToken}`,
                };
            }
            const response = await client.delete(
                `/api/v1/traits/groups/${group.groupId}`,
                {
                    params,
                    headers: {
                        ...headers,
                    },
                }
            );
        },
    };
}
