import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { AccountScope, WorkspaceContextV2 } from '../../platform';
import { MemberAdapter } from './memberAdapter';
import { MembersByTypesQuery } from './memberQuery';

export function createMemberRepository(adapter: MemberAdapter) {
    return {
        useFind(
            context: AccountScope,
            query: MembersByTypesQuery,
            options: Pick<UseQueryOptions, 'suspense' | 'staleTime' | 'enabled'> = {}
        ) {
            return useQuery({
                ...options,
                queryKey: ['members', query],
                async queryFn() {
                    return adapter.findByTypes(context, query);
                },
            });
        },
    };
}
