import { Center } from '@chakra-ui/react';
import React from 'react';
import { CardSpinner } from '../../../domain';
import { WorkspaceStateContextV2 } from '../../app';
import { BootstrappedContextProvider } from '../../base';
import { useSystemContextV2 } from '../system';
import { WorkspaceContextConfig } from './workspaceConfig';
import { WorkspaceContext } from './workspaceContext';
import { WorkspaceContextData } from './workspaceModel';

export function createWorkspaceProvider(
    config: WorkspaceContextConfig
): Pick<BootstrappedContextProvider<WorkspaceContextData>, 'Provider'> {
    const {
        deps: { hook },
        provider: { createLoader },
    } = config;
    const loader = createLoader(config.deps);
    return {
        Provider(props) {
            const system = useSystemContextV2();
            const organization = hook.useAccount();
            const workspace = hook.useWorkspace();
            const auth = hook.useAuth();
            const context: WorkspaceStateContextV2 = { account: organization, workspace, auth };
            const value = loader.useData(context);
            if (!value.isSuccess || !system.isSuccess) {
                return <CardSpinner />;
            }
            return (
                <WorkspaceContext.Provider value={value}>
                    {props.children}
                </WorkspaceContext.Provider>
            );
        },
    };
}
