import { useMemo } from 'react';
import { chain } from 'lodash';
import pluralize from 'pluralize';
import { assert } from '../../../../util/assert';
import { AssetListItemProps, buildAssetItemProps } from '../item';
import { AssetListConfig } from './assetListConfig';
import { AssetListController } from './assetListInterface';
import {
    AssetListCollectionProps,
    AssetListSearchProps,
    AssetListViewProps,
} from './assetListProps';

export function createAssetListController(config: AssetListConfig): AssetListController {
    return {
        useProps(context, deps, props): AssetListViewProps {
            const itemPropsById = useMemo(
                () =>
                    props.item.assets.reduce(
                        (acc, item, index) => {
                            const itemProps = buildAssetItemProps(
                                props.item.organization,
                                item,
                                index
                            );
                            return {
                                ...acc,
                                [item.asset.id]: itemProps,
                            };
                        },
                        {} as Record<string, AssetListItemProps | undefined>
                    ),
                [props.item.organization, props.item.assets]
            );

            const ordered = useMemo(() => {
                return chain(props.item.assets)
                    .filter((item) => item.asset.visible)

                    .orderBy(
                        (item) => {
                            const itemProps = itemPropsById[item.asset.id];
                            const isDefault = itemProps?.isDefault;
                            const hasMappings = item.mappings.length > 0;
                            return [
                                isDefault ? 1 : -1,
                                hasMappings ? 1 : -1,
                                Number(item.asset.updatedAt),
                            ];
                        },
                        ['desc', 'desc', 'desc']
                    )
                    .value();
            }, [
                props.item.assets,
                itemPropsById,
                props.item.organization.state.defaultAsset?.id,
            ]);

            const searched = useMemo(
                () =>
                    chain(ordered)
                        .filter(
                            (item) =>
                                (item.asset.name
                                    .toLowerCase()
                                    .includes(deps.state.value.search) ||
                                    item.asset.url?.includes(deps.state.value.search)) ??
                                true
                        )
                        .value(),
                [ordered, deps.state.value.search]
            );

            const searchProps = useMemo<AssetListSearchProps>(
                () => ({
                    value: deps.state.value.search,
                    onChange(event) {
                        deps.state.onChange({
                            ...deps.state.value,
                            search: event.target.value,
                        });
                    },
                }),
                [deps.state.value.search, deps.state.onChange]
            );

            const collectionProps = useMemo<AssetListCollectionProps>(
                () =>
                    searched.length === 0
                        ? {
                              status: 'empty',
                              label: 'No companies found',
                          }
                        : {
                              status: 'loaded',
                              label: `${searched.length} found`,
                          },
                [searched]
            );

            return {
                items: searched,
                getItemProps(item) {
                    const itemProps = itemPropsById[item.asset.id];
                    assert(itemProps, `no item props found for asset ${item.asset.id}`);
                    return itemProps;
                },
                getSearchProps() {
                    return searchProps;
                },
                getCollectionProps() {
                    return collectionProps;
                },
            };
        },
    };
}
