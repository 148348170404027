import { useQueries, useQuery } from '@tanstack/react-query';
import { AnyLookup } from '../../base';
import { Workspace } from '../../domain';
import { AssetClaim, Asset } from '../../domain/assets'
import { OrganizationContextConfig } from './organizationConfig';
// import { SystemContextDeps } from './organizationConfig';
import { OrganizationContextLoader } from './organizationInterface';
import {
    AccountContextAggregate,
    AccountContextData,
} from './organizationModel';

export const ORG_WORKSPACE_QUERY_KEY = ['org', 'workspaces'];

export function createOrganizationContextLoader(
    config: OrganizationContextConfig
): OrganizationContextLoader {
    const {
        deps: { repository },
    } = config;
    return {
        useLoad(context): AnyLookup<AccountContextAggregate> {
            const assets = repository.asset.useFind(context, {
                visible: true,
                expand: ['claim']
            });

            const featureset = repository.feature.useLookup(context, {
                staleTime: Infinity,
            });

            const account = repository.account.useLookup(
                context,
                {
                    id: context.account.id,
                },
                {
                    staleTime: Infinity,
                }
            );

            const roles = repository.role.useFind(
                context,
                {
                    account: context.account,
                },
                {}
            );

            // console.log('DEBUG assets', assets.data);
            if (
                assets.status !== 'success' ||
                featureset.status !== 'success' ||
                roles.status !== 'success' ||
                account.status !== 'success'
            ) {
                return {
                    status: 'loading',
                    data: null,
                };
            }

            return {
                status: 'loaded',
                data: {
                    scope: context,
                    data: {
                        workspaces: assets.data as (Asset & { claim: AssetClaim})[],
                        features: featureset.data,
                        roles: roles.data,
                        account: account.data,
                    },
                },
            };

            // return {
            //     isFetched: assets.isFetched,
            //     isLoading: assets.isLoading,
            //     data: {
            //         roles,
            //         features: { ...featureset, data: featureset.data ?? {} },
            //         workspaces: {
            //             ...assets,
            //             data:
            //                 assets.data?.map(
            //                     (item): Workspace => ({
            //                         ...item,
            //                         url: item.url,
            //                         visible: item.visible,
            //                     })
            //                 ) ?? [],
            //         },
            //     },
            // };
        },
    };
}
