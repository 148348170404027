import React from 'react';
import { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router';
import { useAccountContextV2 } from '../../context';
import { PageIds } from '../../config';
// import { createAccountRoute } from '../../route';
import { ApplicationEntryEnhancer } from '../../entrypoint';
import { createFeatureFlagProvider } from './component';
import { FeatureFlagEnhancerConfig } from './featureFlagConfig';
import { FeatureFlagContext } from './featureFlagConstant';
import { compose } from 'lodash/fp';
import { createFeatureFlagImportsMiddleware } from './imports';
import { createFeatureFlagDraftReportsMiddleware } from './draft-reports';
import { createFeatureFlagChatMiddleware } from './chat';
import { PluginConnection } from '../../../v2/domain/metrics';
import { UseQueryResult } from '@tanstack/react-query';
import { Dashboard } from '../../domain/dashboard';

export function createFeatureFlagEnhancer(
    init: FeatureFlagEnhancerConfig
): ApplicationEntryEnhancer {
    return compose(
        _createFeatureFlagEnhancer(init),
        createFeatureFlagImportsMiddleware(),
        createFeatureFlagDraftReportsMiddleware(),
        createFeatureFlagChatMiddleware()
    );
}

function _createFeatureFlagEnhancer(
    init: FeatureFlagEnhancerConfig
): ApplicationEntryEnhancer {
    // const preview = new Set<number>(init.previewOrgIds);
    const Provider = createFeatureFlagProvider(init);

    return (create) => (config) => {
        // function enhanceOrganizationRoute(
        //     create: typeof createAccountRoute
        // ): typeof createAccountRoute {
        //     return (routeConfig) =>
        //         create({
        //             ...routeConfig,
        //             providers: {
        //                 ...routeConfig.providers,
        //                 createController(config) {
        //                     const controller =
        //                         routeConfig.providers.createController(config);
        //                     return {
        //                         ...controller,
        //                         useProps(...args) {
        //                             const props = controller.useProps(...args);
        //                             const context = useContext(FeatureFlagContext);
        //                             if (!context) {
        //                                 throw new Error(`feature flag context not found`);
        //                             }
        //                             const sysContext = useAccountContextV2();

        //                             let creativeCoOpEnabled =
        //                                 sysContext.data.features?.creative_co_op
        //                                     ?.enabled ?? true;
        //                             let researchProductEnabled =
        //                                 sysContext.data.features?.research_product
        //                                     ?.enabled ?? true;

        //                             return {
        //                                 ...props,
        //                                 // creativeCoOpUrl: creativeCoOpEnabled
        //                                 //     ? props.creativeCoOpUrl
        //                                 //     : null,
        //                                 researchUrl: researchProductEnabled
        //                                     ? props.researchUrl
        //                                     : null,
        //                                 isVisible(item) {
        //                                     if (init.id.homePage === item.id) {
        //                                         // return !!context.flags.home?.enabled;
        //                                         return true;
        //                                     }
        //                                     if (init.id.legacyReportPage === item.id) {
        //                                         // return !context.flags.home?.enabled;
        //                                         return false;
        //                                     }
        //                                     return props.isVisible(item);
        //                                 },
        //                             };
        //                         },
        //                     };
        //                 },
        //             },
        //         });
        // }
        return create({
            ...config,
            repository: {
                ...config.repository,
                platform: {
                    ...config.repository.platform,
                    createConnectionV2(conConfig) {
                        const underlying =
                            config.repository.platform.createConnectionV2(conConfig);
                        return {
                            ...underlying,
                            useFind(context, query, options) {
                                const flagcontext = useContext(FeatureFlagContext);
                                if (!flagcontext) {
                                    throw new Error(`feature flag context not found`);
                                }
                                const retVal = underlying.useFind(
                                    context,
                                    query,
                                    options
                                );
                                const altered: UseQueryResult<PluginConnection[]> =
                                    React.useMemo(() => {
                                        if (
                                            flagcontext.flags.exampleMode?.enabled &&
                                            retVal.data
                                        ) {
                                            return {
                                                ...retVal,
                                                data: retVal.data.map((x) => ({
                                                    ...x,
                                                    status: 'ready',
                                                })),
                                            };
                                        }
                                        return retVal;
                                    }, [
                                        flagcontext.flags.exampleMode?.enabled,
                                        retVal.data,
                                    ]);
                                return altered;
                            },
                        };
                    },
                    createDashboard(dashAdapter) {
                        const underlying =
                            config.repository.platform.createDashboard(dashAdapter);
                        return {
                            ...underlying,
                            useFind(context, query, options) {
                                const flagcontext = useContext(FeatureFlagContext);

                                if (!flagcontext) {
                                    throw new Error(`feature flag context not found`);
                                }
                                const retVal = underlying.useFind(
                                    context,
                                    query,
                                    options
                                );

                                const altered = React.useMemo(() => {
                                    if (
                                        flagcontext.flags.exampleMode?.enabled &&
                                        retVal.data
                                    ) {
                                        return {
                                            ...retVal,
                                            data: retVal.data.map<Dashboard>((x) => ({
                                                ...x,
                                                highlighted: true,
                                                access: 'active',
                                                connection: {
                                                    kind: 'connected',
                                                },
                                            })),
                                        };
                                    }
                                    return retVal;
                                }, [flagcontext.flags.exampleMode?.enabled, retVal.data]);
                                return altered;
                            },
                        };
                    },
                },
            },

            context: {
                ...config.context,
                createWorkspace(workspaceConfig) {
                    return config.context.createWorkspace({
                        ...workspaceConfig,
                        provider: {
                            createLoader(deps) {
                                const loader =
                                    workspaceConfig.provider.createLoader(deps);
                                return {
                                    ...loader,
                                    useData(context) {
                                        const result = loader.useData(context);
                                        const flagcontext =
                                            useContext(FeatureFlagContext);

                                        if (!flagcontext) {
                                            throw new Error(
                                                `feature flag context not found`
                                            );
                                        }

                                        if (flagcontext.flags.exampleMode?.enabled) {
                                            return {
                                                ...result,
                                                data: {
                                                    ...result.data,
                                                    exampleMode: true,
                                                },
                                            };
                                        }
                                        return result;
                                    },
                                };
                            },
                        },
                    });
                },
                // createOrganization(orgConfig) {
                //     return config.context.createOrganization({
                //         ...orgConfig,
                //         provider: {
                //             createLoader(deps) {
                //                 const loader = orgConfig.provider.createLoader({
                //                     ...deps,
                //                 });
                //                 return {
                //                     ...loader,
                //                     useLoad(
                //                         context
                //                     ): AnyLookup<OrganizationContextAggregate> {
                //                         const result = loader.useLoad(context);
                //                         const flagcontext =
                //                             useContext(FeatureFlagContext);

                //                         if (!flagcontext) {
                //                             throw new Error(
                //                                 `feature flag context not found`
                //                             );
                //                         }

                //                         if (result.status !== 'loaded') {
                //                             return result;
                //                         }

                //                         // let insightsEnabled =
                //                         //     result.data.data?.features.insights
                //                         //         ?.enabled ?? false;

                //                         // let dynamicDashboardsEnabled =
                //                         //     result.data.data?.features.dynamic_dashboards
                //                         //         ?.enabled ?? false;

                //                         // if (
                //                         //     flagcontext.flags.insights?.enabled === true
                //                         // ) {
                //                         //     console.info(
                //                         //         'forcing insight feature enabled because of feature flag...'
                //                         //     );
                //                         //     // allow overriding feature status with flag
                //                         //     insightsEnabled = true;
                //                         // }

                //                         // if (
                //                         //     flagcontext.flags.dynamicDashboards
                //                         //         ?.enabled === true
                //                         // ) {
                //                         //     // console.info(
                //                         //     //     'forcing dynamic dashboards feature enabled because of feature flag...'
                //                         //     // );
                //                         //     // allow overriding feature status with flag
                //                         //     dynamicDashboardsEnabled = true;
                //                         // }

                //                         // if (!result.data.data.features) {
                //                         //     return result;
                //                         // }

                //                         return {
                //                             ...result,
                //                             data: {
                //                                 ...result.data,
                //                                 data: {
                //                                     ...result.data.data,
                //                                     features: {
                //                                         ...result.data.data.features,
                //                                         // data: {
                //                                         // ...result.data.data.features
                //                                         //     .data,
                //                                         // insights: result.data.data
                //                                         //     .features.data.insights
                //                                         //     ? {
                //                                         //           ...result.data
                //                                         //               .features.data
                //                                         //               .insights,
                //                                         //           enabled:
                //                                         //               insightsEnabled,
                //                                         //       }
                //                                         //     : undefined,
                //                                         // dynamic_dashboards: result
                //                                         //     .data.data.features.data
                //                                         //     .dynamic_dashboards
                //                                         //     ? {
                //                                         //           ...result.data
                //                                         //               .features.data
                //                                         //               .dynamic_dashboards,
                //                                         //           enabled:
                //                                         //               dynamicDashboardsEnabled,
                //                                         //       }
                //                                         //     : undefined,
                //                                         // },
                //                                     },
                //                                 },
                //                             },
                //                         };
                //                     },
                //                 };
                //             },
                //         },
                //     });
                // },
            },
            redirect: {
                ...config.redirect,
                // createRoot() {
                //     // const Redirect = config.redirect.createRoot();
                //     return (props) => {
                //         // const system = useSystemContextV2();
                //         const location = useLocation();
                //         // if (system.isLoading) {
                //         //     return <></>;
                //         // }
                //         // if (system.data.features.data?.home?.enabled) {
                //         console.info('redirecting to home...');
                //         return (
                //             <Navigate to="home" replace={true} state={location.state} />
                //         );
                //         // }
                //         // console.info('home redirect not enabled, using default...');
                //         // return <Redirect {...props} />;
                //     };
                // },
            },
            route: {
                ...config.route,
                // createAccountRoute: enhanceOrganizationRoute(
                //     config.route.createAccountWorkspaceRoute
                // ),
                // createAccountWorkspaceRoute: enhanceOrganizationRoute(
                //     config.route.createAccountWorkspaceRoute
                // ),
                createWorkspaceRoute(routeConfig) {
                    const Route = config.route.createWorkspaceRoute({
                        ...routeConfig,
                        deps: {
                            ...routeConfig.deps,
                            hook: {
                                ...routeConfig.deps.hook,
                                useRedirects(items) {
                                    const context = useContext(FeatureFlagContext);
                                    if (!context) {
                                        throw new Error(`feature flag context not found`);
                                    }
                                    if (
                                        !context.flags.bypassWorkspaceOnboarding?.enabled
                                    ) {
                                        return routeConfig.deps.hook.useRedirects(items);
                                    }
                                    console.info(
                                        'disabling workspace onboarding redirect...'
                                    );
                                    // no not redirect to default asset onboarding screen
                                    return routeConfig.deps.hook.useRedirects(
                                        items.filter(
                                            (item) =>
                                                item.id !== init.id.defaultAssetRedirect
                                        )
                                    );
                                },
                            },
                        },
                    });
                    return (props) => {
                        props;
                        return <Route {...props} />;
                    };
                },
                createDashboardRoute(routeConfig) {
                    return config.route.createDashboardRoute({
                        ...routeConfig,

                        Layout: {
                            ...routeConfig.Layout,
                        },
                        controller: {
                            ...routeConfig.controller,
                            useFilterList: () => {
                                const props = routeConfig.controller.useFilterList();
                                const context = useContext(FeatureFlagContext);
                                if (!context) {
                                    throw new Error(`feature flag context not found`);
                                }
                                if (
                                    !context.flags.experimentalDashboardFilters?.enabled
                                ) {
                                    // console.log('FILTERING EXPERIMENTAL')
                                    const retVal = {
                                        ...props,
                                        filters: props.filters.filter(
                                            (x) =>
                                                !init.id.experimentalFilters.has(
                                                    x.property.key
                                                )
                                        ),
                                    };
                                    return retVal;
                                }
                                return props;
                            },
                        },
                    });
                },
            },
            controller: {
                ...config.controller,
                settings: {
                    ...config.controller.settings,
                    createOrganization() {
                        const controller =
                            config.controller.settings.createOrganization();
                        return {
                            useProps() {
                                const props = controller.useProps();
                                const context = useContext(FeatureFlagContext);
                                if (!context) {
                                    throw new Error(`feature flag context not found`);
                                }
                                return {
                                    ...props,
                                    isVisible(item) {
                                        return props.isVisible(item);
                                    },
                                };
                            },
                        };
                    },
                },
            },
            module: {
                ...config.module,
                // createSettingsModule(moduleConfig) {
                //     return config.module.createSettingsModule({
                //         ...moduleConfig,
                //         providers: {
                //             ...moduleConfig.providers,
                //             createController(controllerConfig) {
                //                 const controller =
                //                     moduleConfig.providers.createController(
                //                         controllerConfig
                //                     );
                //                 return {
                //                     ...controller,
                //                     useProps() {
                //                         const context = useContext(FeatureFlagContext);
                //                         if (!context) {
                //                             throw new Error(
                //                                 `feature flag context not found`
                //                             );
                //                         }
                //                         const props = controller.useProps();
                //                         return {
                //                             ...props,
                //                             isVisible(item) {
                //                                 if (item.key !== init.id.permissions) {
                //                                     return props.isVisible(item);
                //                                 }
                //                                 return (
                //                                     context.flags.permissions?.enabled ??
                //                                     props.isVisible(item)
                //                                 );
                //                             },
                //                         };
                //                     },
                //                 };
                //             },
                //         },
                //     });
                // },
            },
            provider: {
                ...config.provider,
                custom: [...config.provider.custom, Provider],
            },
        });
    };
}
