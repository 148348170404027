import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { PluginDto } from '../../../../api';
import { useWorkspaceApi } from '../../../../app/dashboard';
import { WorkspaceNavigationController } from './navigationInterface';
import { createWorkspaceItems } from './navigationFactory';
import { WorkspaceNavigationControllerConfig } from './navigationConfig';

// temp hack to workaround not-highlighted dashboards
// not being able to be returned from API
const HIDDEN_SLUGS = ['creative-breakdowns'];

export function createWorkspaceNavigationController(
    config: WorkspaceNavigationControllerConfig
): WorkspaceNavigationController {
    const {
        hook: { useAuth, useAccount, useAuthV2 },
        repository: { dashboard },
        service: {
            plugin: { useList: usePlugins },
        },
    } = config;
    return {
        useProps() {
            const location = useLocation();
            const auth = useAuth();
            const authV2 = useAuthV2();
            const account = useAccount();
            const api = useWorkspaceApi();
            const ctx = { account, auth: authV2 };
            const workspace = api.getWorkspace();
            const isClaimActive = workspace.claim.kind == 'active';
            const dashboards = config.repository.dashboard.useFind(
                ctx,
                { workspace: api.getWorkspace() },
                { suspense: isClaimActive, enabled: isClaimActive }
            );
            // const collection = dashboard.useFind(ctx, { workspace: api.getWorkspace() }, {enabled: isClaimActive});
            const plugins = usePlugins({ auth });

            const pluginsById = useMemo(
                () =>
                    plugins.reduce(
                        (acc, plugin) => ({ ...acc, [plugin.id]: plugin }),
                        {} as Record<string, PluginDto>
                    ),
                [plugins]
            );

            const items = useMemo(
                () =>
                    createWorkspaceItems(
                        pluginsById,
                        (dashboards.data ?? []).filter(
                            (item) => !HIDDEN_SLUGS.includes(item.id)
                        )
                    ),
                [dashboards.data, pluginsById]
            );

            return {
                items,
                isInstalled(item) {
                    return item.kind === 'dashboard'
                        ? item.dashboard.highlighted
                        : Boolean(
                              item.dashboards.some((dashboard) => dashboard.highlighted)
                          );
                },
                isActive(item) {
                    return item.kind === 'dashboard'
                        ? location.pathname.includes(item.dashboard.id)
                        : Boolean(
                              item.dashboards.some((dashboard) =>
                                  location.pathname.includes(dashboard.id)
                              )
                          );
                },
                getPath(dashboard) {
                    const base = api.getDashboardUrl(dashboard);
                    if (dashboard.access === 'inactive') {
                        return `${base}/setup`;
                    }
                    return base;
                },
            };
        },
    };
}
