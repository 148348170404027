import { AxiosInstance, AxiosResponse } from 'axios';
import { listParticipantsV2 } from '../../../../api/peers';
import { LegacyAuthenticationScheme, ListOffsetResponse } from '../../common';
import { WorkspaceDto, LoadedWorkspaceDto } from './workspaceModel';
import { WorkspaceResource } from './workspaceInterface';
import { AxiosCacheInstance } from 'axios-cache-interceptor';
import { isErrorInfo } from '../../../../models/Errors';

export function createWorkspaceResource(client: AxiosInstance): WorkspaceResource {
    return {
        async list(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();
            try {
                const response = await listParticipantsV2(
                    client,
                    undefined,
                    1000,
                    undefined,
                    { token: scheme.store.authToken ?? undefined }
                );
                return {
                    data: response.data.items.map<WorkspaceDto>((item) => ({
                        id: item.asset.id,
                        name: item.asset.title,
                        url: null,
                        asset_type: null,
                    })),
                };
            } catch (e) {
                if (isErrorInfo(e) && e.errType == 'noPerm') {
                    return { data: [] };
                }
                throw e;
            }
        },
        async enhancedList(context, query) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const params: Record<string, unknown> = {};
            let baseExpand = ['claim']
            if (query.expand) {
                baseExpand = [...baseExpand, ...query.expand];
                }
            params['expand'] = baseExpand;
            if (query.page) {
                params['page'] = query.page;
            }
            if (query.page_size) {
                params['page_size'] = query.page_size;
            }
            if(query.active) {
                params['claim_active'] = query.active
            }
            // @ts-expect-error
            const response = await (client as AxiosCacheInstance).get<
                ListOffsetResponse<LoadedWorkspaceDto>
            >('/api/v2/workspaces', {
                cache: false,
                params,
                headers: {
                    Authorization: `bearer ${scheme.store.authToken}`,
                },
            });
            return response.data;
        },
        async update(context, dto) {
            const scheme = context.auth.scheme as LegacyAuthenticationScheme;
            await scheme.store.waitAuthenticated();

            const response = await client.patch<
                unknown,
                AxiosResponse<LoadedWorkspaceDto>
            >(
                `/api/v2/workspaces/${dto.id}`,
                {
                    access: {
                        type: 'automatic',
                        ...dto.access,
                    },
                },
                {
                    headers: {
                        Authorization: `bearer ${scheme.store.authToken}`,
                    },
                }
            );
            return response.data;
        },
    };
}
