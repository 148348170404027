import { capitalize } from '../../../../../utils/strings';
import {
    getReportAccessStatus,
    getReportDependencyStatus,
    AnyContext,
    isWorkspaceContext,
} from '../../../../app';
import { buildDataSourceListProps } from '../data-source';
import { buildDependencyListProps } from '../dependency';
import { buildDependencyItemProps } from '../dependency/item';
import { buildAccessStatusProps, buildDependencyStatusProps } from '../status';
import { ReportItemAggregate } from './studyItemModel';
import { StudyItemViewProps } from './studyItemProps';

export function buildStudyItemViewProps(
    context: AnyContext,
    item: ReportItemAggregate
): StudyItemViewProps {
    const accessStatus = getReportAccessStatus(item);
    const dependencyStatus = getReportDependencyStatus(item);
    const linkPrefix = isWorkspaceContext(context)
        ? `/u/assets/${context.workspace.id}`
        : '/u/account';
    return {
        iconUrl: '',
        label: item.report.name,
        description: item.report.description,
        tagline: item.report.tagline,
        category: {
            label: capitalize(item.report.category),
        },
        status: {
            access: buildAccessStatusProps(accessStatus),
            dependency: buildDependencyStatusProps(dependencyStatus),
        },
        thumbnail: {
            image: {
                src: item.report.thumbnail.url,
                alt: item.report.thumbnail.description ?? 'thumbnail',
            },
        },
        dependencies: buildDependencyListProps(item),
        dataSource: buildDataSourceListProps(item),
        highlight: null,
        tags: [
            ...item.report.tags.map((tag) => ({
                label: capitalize(tag),
            })),
        ],
        links: {
            join: item.dataset
                ? {
                      to: `${linkPrefix}/reporting/surveys/submissions/new?survey=${item.dataset.id}`,
                  }
                : null,
            detail: {
                to: `${linkPrefix}/reporting/reports/${item.report.id}`,
            },
            submission: item.submission
                ? {
                      detail: {
                          to: `${linkPrefix}/reporting/surveys/submissions/${item.submission.id}`,
                      },
                  }
                : null,
            responses: {
                to: `${linkPrefix}/reporting/reports/${item.report.id}`,
            },
        },
    };
}
