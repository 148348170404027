import React from 'react';
import {
    UserIntegrationDefinition,
    isConnected,
    DashboardIntegration,
    BasicDashboardDepReason,
    dashboardStatusFromDependencies,
    DashboardStatus,
} from '../../../../domain';
import { DashboardContext } from '../../../../hooks/dashboards/dashboardContext';
import { useDashboardDeps } from '.';
import {
    useAssetIntegrationDefinitionPreferences,
    useConnectedBy,
    useIntegrationContext,
    useLeanDashboard,
} from '../../../../hooks';
import { DashboardListItem, DashboardListItem as StatusDto } from '../../../../api';
import { DASHBOARD_DEPENDENCY_TEXT_BY_SLUG } from '../../../../config/dashboard';
import { DashboardIntegrationProps } from '../props';
import { orderBy } from 'lodash';
import {
    getReasonToShow,
    isReasonToShow,
    isIntegrationTestExecDetails,
    isIntegrationRequirement,
    isViewTestExecDetails,
    isIntegrated,
    isPipelineRequirement,
    isPipelineTestExecDetails,
    isPipelineInSync,
} from './helpers';
import { createForIntegration, createForView, makeIntegrationReasonByIntegration, makePipelineReasonByIntegration } from './messages';
import { useHome } from '../../../../sections/dashboard/main/context';
import { IntegrationPreference } from '../../../../models';
import { showIntegrationRequirementText } from './depsViewHelper';
import { IntegrationTestExecutionDetails, PipelineTestExecutionDetails } from 'src/api/v2/dashboard/deps';

export interface DepsViewProps {
    mainCta: string;
    integrationRequirementsErrorText?: string;
    // ctaDescriptions?: string[];
    integrations: DashboardIntegrationProps;
    // activate: UseMutationResult<void, ErrorInfo, DashboardContext, unknown>;
    primaryReason?: BasicDashboardDepReason;
    isLocked: boolean;
    isLoading: boolean;
    dashboard: DashboardListItem | null;
    status: DashboardStatus;
}

type IntegTestExec = IntegrationTestExecutionDetails | PipelineTestExecutionDetails;

export function useDashboardDepsViewProps(
    dashContext: DashboardContext,
    {
        suspense,
        requireMarkNotUsed,
        ...options
    }: {
        requireMarkNotUsed?: boolean;
        suspense?: boolean;
    } = {}
): DepsViewProps {
    const home = useHome();
    const dashbDeps = useDashboardDeps(dashContext, { suspense });

    const lastTest = React.useMemo(() => {
        if (dashbDeps.data) {
            return dashbDeps.data.tests[dashbDeps.data.tests.length - 1];
        }
    }, [dashbDeps.data]);

    const mainCta = React.useMemo(() => {
        if (lastTest) {
            if (lastTest.test.description) {
                return lastTest.test.description;
            }
        }
        const integCount = dashbDeps.data?.tests.reduce<number>((agg, curr) => agg + curr.test.mandatory_requirements.filter(isIntegrationRequirement).length, 0);
        if(integCount) {
            // NOTE: we've changed the UX a bit and so for now we just hardcode the title and include the cta instructions in the error text and tooltips
            return `Either connect the integrations below or indicate that you don't use them`;
        } return `Requirement not fullfiled: ${lastTest?.test.test_name}` || 'Requirements for dashboard are not fulfilled';
    }, [lastTest]);

    const integrationContext = useIntegrationContext(dashContext);

    const { dashboard, isLoading: dashboardLoading } = useLeanDashboard(dashContext);

    const preferenceResponse = useAssetIntegrationDefinitionPreferences(dashContext, { suspense: true });

    const preferencesByDefinitionId = React.useMemo(
        () =>
            preferenceResponse.data?.reduce(
                (acc, preference) => ({ ...acc, [preference.definition_id]: preference }),
                {} as Record<string, IntegrationPreference | undefined>
            ),
        [preferenceResponse]
    );

    const dashboardOverrides = DASHBOARD_DEPENDENCY_TEXT_BY_SLUG[dashContext.slug] || null;

    const integTestExecDetails = React.useMemo<IntegrationTestExecutionDetails[]>(
        () =>
            (dashbDeps.data?.tests
                .flatMap((x) => x.execution_details)
                .filter((curr) => isIntegrationTestExecDetails(curr)) || []) as IntegrationTestExecutionDetails[],
        [dashbDeps.data]
    );
    const pipelineTestExecDetails = React.useMemo<PipelineTestExecutionDetails[]>(
        () =>
            (dashbDeps.data?.tests
                .flatMap((x) => x.execution_details)
                .filter((curr) => isPipelineTestExecDetails(curr)) || []) as PipelineTestExecutionDetails[],
        [dashbDeps.data]
    );

    const disconnectedIntegrationIds = React.useMemo(
        () => {
            const integrationIds = integTestExecDetails
                    .flatMap((x) => Object.values(x.state.by_integration))
                    .reduce<Set<number>>((agg, curr) => {
                        for (const integ of curr) {
                            if (integ.status != 'integrated') {
                                if (integ.integration_id) {
                                    agg.add(integ.integration_id);
                                } else {
                                    const integDefinition = integrationContext.integrationDefinitions.data?.find(
                                        (x) => x.integration_type == integ.integration
                                    );
                                    if (
                                        integDefinition &&
                                        integDefinition.integrations &&
                                        integDefinition.integrations.length > 0
                                    ) {
                                        agg.add(integDefinition.integrations[0].id);
                                    }
                                }
                            }
                        }
                        return agg;
                    }, new Set<number>());
            for(const pipeline of pipelineTestExecDetails) {
                for(const ds of pipeline.state.datasets) {
                    if(ds.state == 'has_issues') {
                        integrationIds.add(ds.integration_id);
                    }
                }
            }
            return Array.from(integrationIds);
        },
        [integTestExecDetails, pipelineTestExecDetails, integrationContext.integrationDefinitions.data]
    );

    
    const connectedByMapping = useConnectedBy(dashContext, disconnectedIntegrationIds);

    const { integrations, anyRequiredInteg, isMultiIntegrationDashboard } = React.useMemo(() => {
        // this memo applies all the integrations that should be integrated
        const integBySlug = (integrationContext.integrationDefinitions.data || []).reduce<
            Record<string, UserIntegrationDefinition>
        >((agg, curr) => {
            agg[curr.integration_type] = curr;
            return agg;
        }, {});
        const mandatoryIntegs = new Set<string>();
        lastTest?.test.mandatory_requirements?.reduce<Set<string>>((agg, curr) => {
            if (isIntegrationRequirement(curr)) {
                curr.integrations.forEach((x) => agg.add(x));
            }
            else if (isPipelineRequirement(curr)) {
                curr.integration_types.forEach(x => agg.add(x));
            }
            return agg;
        }, mandatoryIntegs);
        const isMultiIntegrationDashboard = mandatoryIntegs.size > 1;

        const integsToShow = orderBy(
            (Object.values(
                integTestExecDetails.reduce<Record<string, DashboardIntegration>>((agg, curr) => {
                    for (const integSlug of curr.requirement.integrations) {
                        const integDef = integBySlug[integSlug];
                        if(!integDef) {
                            continue;
                        }
                        const integAssetStates = curr.state.by_integration[integSlug];
                        const isFulfilled = integAssetStates.every(isIntegrated);
                        const dashInteg: DashboardIntegration = {
                            integration: integDef,
                            // statuses: integAssetStates,
                            isFulfilled,
                            allowIgnore:
                                isMultiIntegrationDashboard &&
                                !isFulfilled &&
                                // An mandatory integration can never be ignored
                                !mandatoryIntegs.has(integDef.integration_type),
                            isIgnored: preferencesByDefinitionId?.[integDef.id]?.is_ignored ?? false,
                        };
                        const selectedStatus = integAssetStates.find(isReasonToShow);
                        if (selectedStatus?.status) {
                            const integId = selectedStatus.integration_id
                                ? selectedStatus.integration_id
                                : integDef.integrations.length > 0
                                ? integDef.integrations[0].id
                                : undefined;
                            const generatedReason = makeIntegrationReasonByIntegration(
                                selectedStatus.status,
                                selectedStatus,
                                integDef,
                                home?.value?.title || 'Brand',
                                (integId && connectedByMapping[integId]?.data) || undefined
                            );
                            dashInteg.errorReason = generatedReason;
                        }
                        agg[integSlug] = dashInteg;
                    }
                    return agg;
                }, {}) || {})
            ).concat(Object.values(pipelineTestExecDetails.reduce<Record<string, DashboardIntegration>>((agg, curr) => {
                const isFulfilled = curr.state.datasets.every(isPipelineInSync);
                let anyPipelineData = false;
                for(const state of curr.state.datasets) {
                    if(state.state === 'has_issues' || state.is_outdated) {
                        const integDef = integrationContext.integrationDefinitions.data?.find(x => x.integrations.some(xx => xx.id == state.integration_id));
                        if(!integDef) {
                            continue;
                        }
                        const integId = state.integration_id;
                        const generatedReason = makePipelineReasonByIntegration(
                            state,
                            integDef,
                            home?.value?.title || 'Brand',
                            (integId && connectedByMapping[integId]?.data) || undefined
                        );
                        const dashInteg: DashboardIntegration = {
                            integration: integDef,
                            // statuses: integAssetStates,
                            isFulfilled,
                            allowIgnore:
                                isMultiIntegrationDashboard &&
                                !isFulfilled &&
                                // An mandatory integration can never be ignored
                                !mandatoryIntegs.has(integDef.integration_type),
                            isIgnored: preferencesByDefinitionId?.[integDef.id]?.is_ignored ?? false,
                            errorReason: generatedReason
                        };
                        agg[integDef.integration_type] = dashInteg;
                        anyPipelineData = true;
                    }
                }
                if(!isFulfilled && !anyPipelineData) {
                    for(const integType of curr.requirement.integration_types) {
                        const integDef =  integBySlug[integType];
                        if(integDef) {
                            const dashInteg: DashboardIntegration = {
                                integration: integDef,
                                isFulfilled,
                                allowIgnore:
                                    isMultiIntegrationDashboard &&
                                    !isFulfilled &&
                                    // An mandatory integration can never be ignored
                                    !mandatoryIntegs.has(integType),
                                isIgnored: preferencesByDefinitionId?.[integDef.id]?.is_ignored ?? false,
                            };
                            agg[integType] = dashInteg;
                        }
                    }
                }
                return agg;
            }, {}))),
            (x) => isConnected(x.integration),
            'desc'
        );
        return {
            isMultiIntegrationDashboard,
            integrations: {
                context: integrationContext,
                integrations: integsToShow,
            },
            anyRequiredInteg: integsToShow.some((x) => x.allowIgnore && x.integration.is_available && !x.isIgnored),
        };
    }, [
        integrationContext.integrationDefinitions.data,
        preferencesByDefinitionId,
        integTestExecDetails,
        pipelineTestExecDetails,
        connectedByMapping,
    ]);
    
    const primaryReasons = React.useMemo(() => {
        const integDefs = integrations.context.integrationDefinitions.data || [];

        return Object.values(
            (dashbDeps.data?.tests || [])
                .filter((x) => !x.test_pass)
                .flatMap((x) => x.execution_details)
                .reduce<Record<string, BasicDashboardDepReason>>((agg, curr) => {
                    if (isIntegrationTestExecDetails(curr)) {
                        for (const integs of Object.values(curr.state.by_integration)) {
                            for (const integ of integs) {
                                if (!isIntegrated(integ)) {
                                    const selectedInteg = integDefs.find(
                                        (x) => x.integration_type == integ.integration
                                    );
                                    const integId = integ.integration_id
                                        ? integ.integration_id
                                        : selectedInteg && selectedInteg.integrations.length > 0
                                        ? selectedInteg.integrations[0].id
                                        : undefined;
                                    const reason = getReasonToShow(integ, integId);
                                    if (reason) {
                                        agg[reason.status] = createForIntegration(
                                            integ.status,
                                            integ,
                                            home?.value?.title || 'Brand',
                                            selectedInteg,
                                            (integId && connectedByMapping[integId]?.data) || undefined
                                        );
                                    }
                                }
                            }
                        }
                    } else if (isViewTestExecDetails(curr)) {
                        for (const views of Object.values(curr.state.by_view)) {
                            for (const view of views) {
                                if (!view.can_execute) {
                                    agg['view'] = createForView(home?.value?.title || 'Brand');
                                }
                            }
                        }
                    }
                    else if (isPipelineTestExecDetails(curr)) {
                        const isFulfilled = curr.state.datasets.every(isPipelineInSync);
                        let anyPipelineData = false;
                        for(const state of curr.state.datasets) {
                            if(state.state === 'has_issues' || state.state === 'in_flight' || state.is_outdated) {
                                const integDef = integrationContext.integrationDefinitions.data?.find(x => x.integrations.some(xx => xx.id == state.integration_id));
                                if(!integDef) {
                                    continue;
                                }
                                const integId = state.integration_id;
                                agg[integDef.integration_type] = makePipelineReasonByIntegration(
                                    state,
                                    integDef,
                                    home?.value?.title || 'Brand',
                                    (integId && connectedByMapping[integId]?.data) || undefined
                                );
                                anyPipelineData = true;
                            }
                        }
                        if(!isFulfilled && !anyPipelineData) {
                            for(const integType of curr.requirement.integration_types) {
                                const selectedInteg = integDefs.find(
                                    (x) => x.integration_type == integType
                                );
                                if(!selectedInteg) {
                                    continue;
                                }
                                agg[selectedInteg.integration_type] = {
                                    reasonType: 'no_integration',
                                    isLoading: false,
                                    text: `You don’t have an active ${selectedInteg.title}`,
                                    instructions: [`Connect ${selectedInteg.title} to access this dashboard`],
                                };
                            }
                        }
                    }
                    return agg;
                }, {})
        );
    }, [dashbDeps.data, integrations.context.integrationDefinitions.data, home?.value?.title, connectedByMapping]);

    const access = React.useMemo(
        () => dashboard?.access_list?.find((x) => x.asset_id == dashContext.assetId),
        [dashboard?.access_list, dashContext.assetId]
    );
    const status = dashboardStatusFromDependencies({
        // Note: temp hack that only allows optional requirements for multi-integration dashboards like mediamix
        anyRequiredInteg,
        canIgnore: isMultiIntegrationDashboard,
        hasAccess: access?.has_access ?? false,
        isActivated: access?.has_access ?? false,
        noAccessReasons: primaryReasons,
        integrations: integrations.integrations,
        requireDataSharing: access?.require_data_sharing ?? true,
    });
    const primaryReason = primaryReasons.length == 1 ? primaryReasons[0] : undefined;
    return {
        integrations,
        mainCta,
        integrationRequirementsErrorText: showIntegrationRequirementText(status)
            ? dashboardOverrides?.text || primaryReason?.text
            : undefined,
        primaryReason,
        isLoading: dashboardLoading || dashbDeps.isLoading || integrationContext.integrationDefinitions.isLoading,
        isLocked: status.kind !== 'accessable',
        dashboard,
        status,
    };
}
