import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { TraitFilterValues } from '../../../view/peer';
import {
    OnboardingAssetRouteStrategy,
    OnboardingOrganizationStrategyViewProps,
} from '../../../route';
import { WorkspaceContextV2 } from '../../../app';
import { createOnboardingTraitFilterView } from './filter';
import { OnboardingPeerGroupContainerConfig } from './onboardingPeerGroupConfig';
import {
    createPluginItemContainer,
    createOnboardingPluginItemController,
    createPluginItemLoader,
    createPluginItemView,
} from './plugin';
import { OnboardingPeerGroupState } from './onboardingPeerGroupInterface';
import { createPreviewView } from './preview';
import { OnboardingPeerGroupQueryParamsSchema } from './onboardingPeerGroupSchema';
import { Box, Heading, HStack, Icon, Text, VStack } from '@chakra-ui/react';
import { AiOutlineWarning } from 'react-icons/ai';

// export const INSIGHT_WORKSPACE_ITEM_ID = 'insights';

export function createOnboardingPeerGroupItem(
    init: OnboardingPeerGroupContainerConfig
): OnboardingAssetRouteStrategy {
    const {
        UI: { EmptyState },
        service,
        hooks,
        provider,
        repository,
        Context: { Workspace: WorkspaceProvider },
    } = init;
    return {
        id: 'peer_groups',
        name: 'Set peer group',
        slug: 'peer-groups',
        create(config) {
            const controller = provider.root.createController(init);
            const loader = provider.root.createLoader(
                init,
                {
                    peergroups: repository.peergroups,
                    competitive: repository.competitive,
                    connection: repository.connection,
                },
                {
                    integration: service.integration,
                    plugin: service.plugin,
                }
            );

            const View = provider.root.createView(config, {
                Plugin: createPluginItemContainer(
                    init,
                    createPluginItemLoader(init),
                    provider.plugin.createController(init, init.controller),
                    createPluginItemView(init, {
                        Filter: createOnboardingTraitFilterView(init),
                        Preview: createPreviewView(init),
                    })
                ),
            });

            const Component: React.FC<
                {
                    children?: React.ReactNode | undefined;
                } & OnboardingOrganizationStrategyViewProps
            > = observer((props) => {
                const [state, setState] = useState<{
                    plugin: string | null;
                    filters: Record<string, TraitFilterValues | undefined>;
                }>({
                    plugin: null,
                    filters: {},
                });

                const context: WorkspaceContextV2 = {
                    account: hooks.useAccount(),
                    auth: hooks.useAuth(),
                    workspace: hooks.useWorkspace(),
                };

                const stateApi: OnboardingPeerGroupState = {
                    plugin: {
                        value: state.plugin,
                        onChange(value) {
                            setState({ ...state, plugin: value });
                        },
                    },
                };

                const data = loader.useData(context, stateApi);

                const [searchParams] = useSearchParams();

                const mapped = [...searchParams.entries()].reduce(
                    (acc, [key, value]) => ({ ...acc, [key]: value }),
                    {} as Record<string, string>
                );

                const parsed = OnboardingPeerGroupQueryParamsSchema.parse(mapped);

                const viewProps = controller.useProps(context, stateApi, data, {
                    ...props,
                    initialValues: {
                        plugin: parsed.plugin ?? null,
                    },
                });

                // if (data.error) {
                //     return (
                //         <HStack w="full" justify="center" p={6}>
                //             <VStack
                //                 p={6}
                //                 bg="red.100"
                //                 minW="16rem"
                //                 // border="1px solid"
                //                 // borderColor="red.100"
                //                 borderRadius="lg"
                //             >
                //                 <VStack>
                //                     <Box>
                //                         <Icon
                //                             color="red.900"
                //                             fontSize="3xl"
                //                             as={AiOutlineWarning}
                //                         />
                //                     </Box>
                //                     <Heading color="red.900" fontSize="lg">
                //                         {data.title}
                //                     </Heading>
                //                     {data.description && (
                //                         <Text color="red.700" fontWeight="medium">
                //                             {data.description}
                //                         </Text>
                //                     )}
                //                 </VStack>
                //             </VStack>
                //         </HStack>
                //     );
                // }

                return <View {...viewProps} />;
            });

            return {
                Component(props) {
                    return (
                        <WorkspaceProvider>
                            <Component {...props} />
                        </WorkspaceProvider>
                    );
                },
            };
        },
    };
}
