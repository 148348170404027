import { AxiosInstance } from 'axios';
import { getAuthenticatedApi } from '../../../../../infra';
import { DashboardListItem, listDashboards } from '../../../../../api';
import { getDashboardConnection } from '../../dashboard';
import { getDashboardDeps } from '../../../../../api/v2/dashboard/deps';
import { ReportDependencyStatusAdapter } from '../../../../app/_reportold';
import { DashboardConnectionStatus } from '../../../../domain/dashboard';
import { ReportDependencyStatus } from '../../../../domain/report';
import { chain } from 'lodash';

export function createDependencyStatusImpl(config: {
    axios: AxiosInstance;
}): ReportDependencyStatusAdapter {
    let { axios } = config;
    return {
        async findByWorkspace(context, query) {
            const dashboardIds = new Set(
                query.dependencies.map((item) => item.dashboard.id)
            );

            // 100,
            //     'any',
            //     ['exclusive_access', 'is_highlighted']

            const responseDashboards = await listDashboards(
                axios,
                [query.workspace.id as number],
                undefined,
                100,
                'any',
                ['exclusive_access', 'is_highlighted'],
                context.auth.scheme.kind === 'apiKey'
                    ? {
                          accountId: context.account.id,
                          apiKey: context.auth.scheme.token,
                      }
                    : {
                          auth: context.auth.scheme,
                          callerName: 'report impl',
                      }
            );

            const dashboardsById = responseDashboards.items.reduce(
                (acc, item) => ({ ...acc, [item.slug]: item }),
                {} as Record<string, DashboardListItem | undefined>
            );

            // console.log('responseDashboards', responseDashboards);
            // console.log('query', query);

            const responseConnections = await Promise.all(
                query.dependencies.map(async (dependency) => {
                    // const response = await getDashboardDeps(
                    //     axios,
                    //     dependency.dashboard.id,
                    //     [query.workspace.id as number],
                    //     options
                    // );

                    const response = dashboardsById[dependency.dashboard.id];
                    if (!response) {
                        console.info(
                            `dependency dashboard ${dependency.dashboard.id} not found`
                        );
                        return [];
                    }

                    const connection = getDashboardConnection(
                        // name is not needed here, we just care about the status
                        { ...query.workspace, name: 'unknown' },
                        response.dependencies
                    );
                    return [{ dependency, connection }];
                })
            );

            const connectionByDashboard = chain(responseConnections)
                .flatten()
                .reduce(
                    (acc, { dependency, connection }) => ({
                        ...acc,
                        [dependency.dashboard.id]: connection,
                    }),
                    {} as Record<string, DashboardConnectionStatus | undefined>
                )
                .value();

            return responseDashboards.items
                .filter((item) => dashboardIds.has(item.slug))
                .flatMap<ReportDependencyStatus>((item) => {
                    const connection = connectionByDashboard[item.slug];
                    if (connection?.kind == 'error') {
                        return [];
                    }
                    // console.log('connection', item.name, connection, item.access_list);
                    if (
                        connection?.kind === 'pending' ||
                        connection?.kind === 'syncing'
                    ) {
                        return [];
                    }
                    return [
                        {
                            dashboard: {
                                id: item.slug,
                                name: item.name,
                                iconUrl: item.icon_url ?? null,
                            },
                            status:
                                connection?.kind === 'connected'
                                    ? 'ready'
                                    : 'disconnected',
                            // status: item.access_list?.[0].has_access
                            //     ? 'ready'
                            //     : 'disconnected',
                        },
                    ];
                });
        },
    };
}
