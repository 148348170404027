import React from 'react';
import { observer } from 'mobx-react';
import { IntegrationTitle } from './IntegrationTitle';
import { useStore } from '../../../stores/setupContext';
import { makeNewIntegrateHandler } from '../../../hooks/integrations/funcs';
import { Spinner, Image, Container, Flex, VStack, Wrap } from '@chakra-ui/react';
import ridge from '../../../onboarding-images/ridge.png';
import disco from '../../../onboarding-images/disco.png';
import bg from '../../../onboarding-images/bg-logo.png';
import darkroom from '../../../onboarding-images/darkroom.png';
import deal from '../../../onboarding-images/deel-logo.png';
import fai from '../../../onboarding-images/Fitness-ai.png';
import motion from '../../../onboarding-images/motion-logo.svg';
import {
    OnboardingIntegrationItemView,
    IntegrationCardListView,
    useIntegrationDefinitions,
} from '../../../views';
import { useAuthenticatedContext, useOnboarding } from '../../../hooks';

export const FooterOfCustomers: React.FC<
    { children?: React.ReactNode | undefined } & {}
> = ({}) => {
    return (
        <Wrap mt={10} justify="center" align="end" spacing={12}>
            <Image src={ridge} />
            <Image src={disco} />
            <Image pb={0.5} src={bg} />
            <Image src={darkroom} />
            <Image src={deal} />
            <Image src={fai} />
            <Image src={motion} height="32px" />
        </Wrap>
    );
};

const WithStoreConnection: React.FC<
    { children?: React.ReactNode | undefined } & {}
> = ({}) => {
    const rootStore = useStore();
    const {
        auth: { skipCurrentStage, requiredState, loggedInAccount: loggedInOrganization },
        integrations: { isLoadingId, isLoadingIntegrateLink },
    } = rootStore;
    const context = useAuthenticatedContext();
    const definitions = useIntegrationDefinitions(context, { suspense: true });
    const onboarding = useOnboarding({ definitions: definitions.data ?? [] });
    return loggedInOrganization ? (
        <Flex justifyContent="center" alignItems="center" h="100%" flexDir="column">
            <Container centerContent maxW="container.xl" id="container-id-id">
                <VStack
                    w="100%"
                    h="75vh"
                    pt={10}
                    px={10}
                    spacing={8}
                    borderX="1px"
                    borderTop="1px"
                    borderColor="frame"
                    borderRadius={10}
                    align="stretch"
                    sx={{ background: 'box' }}
                >
                    <IntegrationTitle
                        onContinue={skipCurrentStage}
                        isDisabled={!onboarding.canContinue}
                    />
                    <IntegrationCardListView
                        listProps={{ spacing: 5 }}
                        definitions={definitions.data ?? []}
                        renderItem={({ definition }) => (
                            <OnboardingIntegrationItemView
                                key={definition.id}
                                definition={definition}
                                onConnect={onboarding.onConnect}
                                onReconnect={onboarding.onReconnect}
                                isLoadingId={isLoadingId}
                                isLoadingConnection={isLoadingIntegrateLink}
                            />
                        )}
                    />
                </VStack>

                <FooterOfCustomers />
            </Container>
        </Flex>
    ) : (
        <Spinner />
    );
};

export default observer(WithStoreConnection);
