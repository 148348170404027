import { useQuery } from '@tanstack/react-query';
import { CountAdapter } from './countAdapter';

import { CountRepository } from './countInterface';

export function createCountRepository(adapter: CountAdapter): CountRepository {
    return {
        useLookup(context, query, options) {
            return useQuery({
                ...options,
                queryKey: ['count', context.account, query],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
            });
        },
    };
}
