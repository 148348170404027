import React, { Suspense } from 'react';
import { Select as ChakraSelect } from 'chakra-react-select';
import {
    Alert,
    AlertIcon,
    AlertTitle,
    Avatar,
    Box,
    Button,
    ButtonGroup,
    Center,
    DarkMode,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    HStack,
    Icon,
    IconButton,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    Menu,
    MenuButton,
    MenuGroup,
    MenuItem,
    MenuList,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverHeader,
    PopoverTrigger,
    Radio,
    RadioGroup,
    Select,
    Stack,
    StackDivider,
    Tag,
    Text,
    Tooltip,
    useToken,
    VStack,
} from '@chakra-ui/react';
import {
    AiOutlineCheck,
    AiOutlineLock,
    AiOutlinePlus,
    AiOutlineSearch,
    AiOutlineUserAdd,
} from 'react-icons/ai';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Controller, FieldError } from 'react-hook-form';
import { Graphics } from '../../../../../config/svg';
import { GRAY } from '../../../../../config';
import { CardSpinner } from '../../../../../../domain';
import { UserInviteFormViewProps } from '../../../../../view';
import {
    ApplicationShellViewProps,
    ApplicationWorkspaceViewProps,
} from '../../../../../shell/application';
import { ApplicationThemeViewConfig } from '../../../base';

export function createApplicationShellDesktopView(
    config: ApplicationThemeViewConfig
): React.FC<ApplicationShellViewProps> {
    const {
        UI: {
            Application: { Link },
        },
    } = config;

    const WorkspaceSelect: React.FC<ApplicationWorkspaceViewProps> = (props) => {
        return (
            <VStack align="start" w="full" spacing={3} py={3}>
                <HStack w="full" px={3}>
                    <InputGroup>
                        <Input
                            ref={props.focusRef}
                            {...props.search.input}
                            _focus={{
                                outline: 'none',
                                borderColor: 'whiteAlpha.500',
                                shadow: 'none',
                            }}
                        />
                        <InputLeftElement>
                            <Icon as={AiOutlineSearch} />
                        </InputLeftElement>
                    </InputGroup>
                </HStack>
                <VStack align="start" w="full" spacing={0}>
                    {props.search.empty ? (
                        <HStack
                            py={2}
                            px={3}
                            justify="center"
                            w="full"
                            color="whiteAlpha.600"
                        >
                            <Text>{props.search.empty.label}</Text>
                        </HStack>
                    ) : null}
                    {props.items.map((item) => (
                        <HStack
                            {...item.select.container}
                            key={item.id}
                            w="full"
                            cursor="pointer"
                            justify="space-between"
                            spacing={3}
                            py={2}
                            px={3}
                            _hover={{ bg: 'whiteAlpha.100' }}
                            _selected={{
                                bg: 'whiteAlpha.50',
                                _hover: { bg: 'whiteAlpha.100' },
                            }}
                        >
                            <HStack>
                                <Box
                                    visibility={
                                        item.select.isSelected ? 'inherit' : 'hidden'
                                    }
                                >
                                    <Icon as={AiOutlineCheck} />
                                </Box>
                                <Text>{item.label}</Text>
                            </HStack>
                            {item.disabled === null && item.status && (
                                <Tooltip
                                    {...item.status.tooltip}
                                    placement="top"
                                    shouldWrapChildren={true}
                                    hasArrow={true}
                                    bg="gray.50"
                                    color="gray.900"
                                >
                                    <Icon
                                        color={`${item.status.colorScheme}.400`}
                                        as={item.status?.Icon}
                                    />
                                </Tooltip>
                            )}
                            {item.disabled && (
                                <Tooltip
                                    {...item.disabled.tooltip}
                                    placement="top"
                                    shouldWrapChildren={true}
                                    hasArrow={true}
                                    bg="gray.50"
                                    color="gray.900"
                                >
                                    <Icon color="whiteAlpha.500" as={AiOutlineLock} />
                                </Tooltip>
                            )}
                        </HStack>
                    ))}
                </VStack>
            </VStack>
        );
    };

    // TODO copied from old code path, clean up
    const Invite: React.FC<UserInviteFormViewProps> = (props) => {
        const workspaceErrorMsg = (
            props.formState.errors.workspaces as unknown as FieldError
        )?.message; // @ts-ignore
        return (
            <Modal
                isOpen={props.isOpen}
                onClose={props.onClose}
                closeOnEsc={true}
                closeOnOverlayClick={true}
            >
                <ModalOverlay />
                <ModalContent
                    onKeyDown={(e) => {
                        e.stopPropagation();
                    }}
                >
                    <form onSubmit={props.onSubmit} noValidate>
                        <ModalHeader>Invite Teammate</ModalHeader>
                        <ModalCloseButton
                            _focus={{ outline: 'none' }}
                            _focusVisible={{ outline: 'none' }}
                        />
                        <ModalBody>
                            <VStack spacing={2}>
                                <FormControl isInvalid={!!props.formState.errors.email}>
                                    <FormLabel>Email address</FormLabel>
                                    <Input
                                        placeholder="Email"
                                        {...props.register('email', {
                                            required: 'Required',
                                        })}
                                        isRequired
                                        title="Email"
                                        type="text"
                                    />
                                    {props.formState.errors.email &&
                                        props.formState.errors.email.message && (
                                            <FormErrorMessage>
                                                {props.formState.errors.email.message}
                                            </FormErrorMessage>
                                        )}
                                </FormControl>
                                <FormControl
                                    isInvalid={!!props.formState.errors.firstName}
                                >
                                    <FormLabel>First Name</FormLabel>
                                    <Input
                                        placeholder="First Name"
                                        {...props.register('firstName', {})}
                                        isRequired
                                        title="First Name"
                                        type="text"
                                    />
                                    {props.formState.errors.firstName &&
                                        props.formState.errors.firstName.message && (
                                            <FormErrorMessage>
                                                {props.formState.errors.firstName.message}
                                            </FormErrorMessage>
                                        )}
                                </FormControl>
                                <FormControl
                                    isInvalid={!!props.formState.errors.lastName}
                                >
                                    <FormLabel>Last Name</FormLabel>
                                    <Input
                                        placeholder="Last Name"
                                        {...props.register('lastName', {})}
                                        isRequired
                                        title="Last Name"
                                        type="text"
                                    />
                                    {props.formState.errors.lastName &&
                                        props.formState.errors.lastName.message && (
                                            <FormErrorMessage>
                                                {props.formState.errors.lastName.message}
                                            </FormErrorMessage>
                                        )}
                                </FormControl>

                                <FormControl isInvalid={!!props.formState.errors.title}>
                                    <FormLabel>Title</FormLabel>
                                    <Controller
                                        control={props.control}
                                        name="title"
                                        render={({
                                            field: { onChange, onBlur, value, name, ref },
                                            fieldState: {
                                                invalid,
                                                isTouched,
                                                isDirty,
                                                error,
                                            },
                                            formState,
                                        }) => (
                                            <ChakraSelect
                                                ref={ref}
                                                value={value}
                                                onChange={onChange}
                                                isMulti={false}
                                                options={props.titles.map((t) => ({
                                                    value: t,
                                                    label: t,
                                                }))}
                                            />
                                        )}
                                    />
                                </FormControl>
                                <FormControl
                                    as="fieldset"
                                    isInvalid={!!props.formState.errors.workspaces}
                                >
                                    <FormLabel as="legend">Brand Access</FormLabel>
                                    {props.allowFullWorkspaceAccess && (
                                        <Controller
                                            control={props.control}
                                            name="role"
                                            rules={{
                                                required: 'Required',
                                            }}
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    name,
                                                    ref,
                                                },
                                                fieldState: {
                                                    invalid,
                                                    isTouched,
                                                    isDirty,
                                                    error,
                                                },
                                                formState,
                                            }) => (
                                                <RadioGroup
                                                    onChange={onChange}
                                                    value={value}
                                                >
                                                    <Stack>
                                                        <Radio value="admin">
                                                            Full Brands Access
                                                        </Radio>
                                                        <Radio value="standard">
                                                            Specific Brands Access
                                                        </Radio>
                                                    </Stack>
                                                </RadioGroup>
                                            )}
                                        />
                                    )}
                                    <InputGroup
                                        orientation="vertical"
                                        flexDirection="column"
                                        mt={2}
                                    >
                                        <Controller
                                            control={props.control}
                                            name="workspaces"
                                            rules={{
                                                validate: (selectedWorkspaces) => {
                                                    if (
                                                        props.allowFullWorkspaceAccess &&
                                                        props.getValues('role') == 'admin'
                                                    ) {
                                                        return;
                                                    }
                                                    if (
                                                        (props.workspacesOptions.length >
                                                            0 &&
                                                            !selectedWorkspaces) ||
                                                        selectedWorkspaces.length == 0
                                                    ) {
                                                        return 'Please grant access to least one brand';
                                                    }
                                                },
                                            }}
                                            render={({
                                                field: {
                                                    onChange,
                                                    onBlur,
                                                    value,
                                                    name,
                                                    ref,
                                                },
                                                fieldState: {
                                                    invalid,
                                                    isTouched,
                                                    isDirty,
                                                    error,
                                                },
                                                formState,
                                            }) => (
                                                <ChakraSelect
                                                    ref={ref}
                                                    isDisabled={
                                                        props.getValues('role') == 'admin'
                                                    }
                                                    value={value}
                                                    onChange={onChange}
                                                    isMulti={true}
                                                    options={props.workspacesOptions}
                                                />
                                            )}
                                        />
                                    </InputGroup>
                                    <FormErrorMessage>
                                        {workspaceErrorMsg}
                                    </FormErrorMessage>
                                    {props.error ? (
                                        <Text color="red.500">{props.error.message}</Text>
                                    ) : null}
                                </FormControl>
                            </VStack>
                        </ModalBody>
                        <ModalFooter>
                            <ButtonGroup>
                                <Button variant="ghost" size="sm" onClick={props.onClose}>
                                    Cancel
                                </Button>
                                <Button
                                    isLoading={props.formState.isSubmitting}
                                    // isDisabled={props.formState.isSubmitting}
                                    type="submit"
                                    colorScheme="blue"
                                    variant="solid"
                                    size="sm"
                                >
                                    Invite
                                </Button>
                            </ButtonGroup>
                        </ModalFooter>
                    </form>
                </ModalContent>
            </Modal>
        );
    };

    return (props) => {
        const scrollbarBg = useToken('colors', 'whiteAlpha.400');
        const scrollbarHoverBg = useToken('colors', 'whiteAlpha.500');
        return (
            <>
                <Grid
                    w="100vw"
                    h="100vh"
                    templateRows={
                        props.status === null
                            ? 'min-content 1fr'
                            : 'min-content min-content 1fr'
                    }
                    templateColumns="1fr"
                >
                    <GridItem>
                        <HStack
                            w="full"
                            h="full"
                            justify="space-between"
                            px={4}
                            py={2}
                            bg={GRAY[700]}
                        >
                            <HStack h="full" w="full" spacing={6}>
                                <Link {...props.home.link}>
                                    <Image
                                        w={20}
                                        src={Graphics.Brand.WhiteText}
                                        alt="brand"
                                    />
                                </Link>
                                {props.workspace && (
                                    <Popover
                                        {...props.workspace.popover}
                                        placement="bottom-start"
                                        initialFocusRef={props.workspace.focusRef}
                                    >
                                        <PopoverTrigger>
                                            <Button
                                                variant="outline"
                                                rightIcon={<Icon as={ChevronDownIcon} />}
                                                color="whiteAlpha.800"
                                                size="sm"
                                                bg="none"
                                                _hover={{ bg: 'whiteAlpha.50' }}
                                                _focus={{ outline: 'none' }}
                                                _focusVisible={{ outline: 'none' }}
                                            >
                                                {props.workspace.select.trigger.label}
                                            </Button>
                                        </PopoverTrigger>
                                        <PopoverContent
                                            _focus={{ outline: 'none' }}
                                            bg="#292929"
                                            maxW="80%"
                                            minW={350}
                                        >
                                            <PopoverHeader>
                                                <HStack w="full" justify="space-between">
                                                    <Text
                                                        fontWeight="medium"
                                                        fontSize="sm"
                                                    >
                                                        Companies
                                                    </Text>
                                                    <Button
                                                        size="sm"
                                                        colorScheme="blue"
                                                        leftIcon={
                                                            <Icon as={AiOutlinePlus} />
                                                        }
                                                    >
                                                        Add
                                                    </Button>
                                                </HStack>
                                            </PopoverHeader>
                                            <PopoverBody p={0}>
                                                <WorkspaceSelect {...props.workspace} />
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                )}
                            </HStack>
                            <HStack
                                color="whiteAlpha.800"
                                spacing={4}
                                justify="end"
                                divider={
                                    <StackDivider
                                        borderColor="whiteAlpha.100"
                                        borderWidth={1}
                                    />
                                }
                            >
                                <HStack spacing={4}>
                                    {props.navigation.view.navigation.items.map(
                                        (item) => (
                                            <Link key={item.id} {...item.link}>
                                                <HStack
                                                    aria-current={item.isActive}
                                                    w="full"
                                                    borderRadius="md"
                                                    py={1.5}
                                                    px={3}
                                                    fontWeight="semibold"
                                                    fontSize="sm"
                                                    cursor="pointer"
                                                    _hover={{ bg: 'whiteAlpha.50' }}
                                                    {...(item.isActive
                                                        ? { bg: 'whiteAlpha.100' }
                                                        : {})}
                                                >
                                                    <Text whiteSpace="nowrap">
                                                        {item.label}
                                                    </Text>
                                                    {item.tag && (
                                                        <Tag
                                                            {...item.tag}
                                                            size="sm"
                                                            fontWeight="bold"
                                                            color="blackAlpha.900"
                                                            bg="#c6e74f"
                                                            letterSpacing="wide"
                                                        />
                                                    )}
                                                </HStack>
                                            </Link>
                                        )
                                    )}
                                </HStack>
                                <HStack spacing={4}>
                                    {props.shortcuts && (
                                        <Menu>
                                            <MenuButton
                                                as={IconButton}
                                                aria-label="shortcuts"
                                                colorScheme="blue"
                                                variant="outline"
                                                size="sm"
                                                borderRadius="full"
                                                icon={<Icon as={AiOutlinePlus} />}
                                            />
                                            <MenuList bg={GRAY[600]} zIndex={10}>
                                                {props.shortcuts.groups.map((group) => (
                                                    <MenuGroup
                                                        key={group.id}
                                                        title={group.label}
                                                    >
                                                        {group.items.map((item) => (
                                                            <MenuItem
                                                                {...item.item}
                                                                icon={
                                                                    <Icon
                                                                        as={item.Icon}
                                                                    />
                                                                }
                                                                key={item.id}
                                                                color="blue.300"
                                                                fontWeight="medium"
                                                                _hover={{
                                                                    bg: 'whiteAlpha.100',
                                                                }}
                                                            >
                                                                {item.label}
                                                            </MenuItem>
                                                        ))}
                                                    </MenuGroup>
                                                ))}
                                            </MenuList>
                                        </Menu>
                                    )}
                                    <Popover
                                        {...props.account.menu}
                                        placement="bottom-start"
                                    >
                                        <PopoverTrigger>
                                            <Avatar cursor="pointer" size="sm" />
                                        </PopoverTrigger>
                                        <PopoverContent
                                            maxW="16rem"
                                            bg="#292929"
                                            _focus={{ outline: 'none' }}
                                            _focusVisible={{ outline: 'none' }}
                                        >
                                            <PopoverBody p={0}>
                                                <VStack
                                                    align="start"
                                                    w="full"
                                                    divider={
                                                        <StackDivider
                                                            borderColor="whiteAlpha.200"
                                                            // borderWidth={1}
                                                        />
                                                    }
                                                    spacing={0}
                                                >
                                                    <VStack
                                                        align="start"
                                                        w="full"
                                                        spacing={0}
                                                    >
                                                        <HStack
                                                            fontWeight="medium"
                                                            pt={3}
                                                            px={3}
                                                            spacing={3}
                                                        >
                                                            <Avatar size="sm" />
                                                            <VStack
                                                                align="start"
                                                                spacing={0}
                                                            >
                                                                <Text>
                                                                    {
                                                                        props.account.view
                                                                            .account.label
                                                                    }
                                                                </Text>
                                                                <Text
                                                                    fontSize="sm"
                                                                    color="whiteAlpha.600"
                                                                    userSelect="none"
                                                                >
                                                                    {
                                                                        props.account.view
                                                                            .account.role
                                                                    }
                                                                </Text>
                                                            </VStack>
                                                        </HStack>
                                                        <VStack
                                                            align="start"
                                                            w="full"
                                                            py={2}
                                                            spacing={0}
                                                        >
                                                            <Button
                                                                {...props.account.view
                                                                    .invite.trigger
                                                                    .button}
                                                                py={2}
                                                                px={3}
                                                                fontWeight="inherit"
                                                                borderRadius="none"
                                                                bg="none"
                                                                width="full"
                                                                justifyContent="start"
                                                                iconSpacing={3}
                                                                leftIcon={
                                                                    <Icon
                                                                        as={
                                                                            AiOutlineUserAdd
                                                                        }
                                                                    />
                                                                }
                                                            >
                                                                Invite teammates
                                                            </Button>
                                                            {props.account.view.actions.items.map(
                                                                (action) => (
                                                                    <Link
                                                                        key={action.id}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                        {...action.link}
                                                                    >
                                                                        <Button
                                                                            py={2}
                                                                            px={3}
                                                                            fontWeight="inherit"
                                                                            borderRadius="none"
                                                                            bg="none"
                                                                            width="full"
                                                                            justifyContent="start"
                                                                            iconSpacing={
                                                                                3
                                                                            }
                                                                            leftIcon={
                                                                                <Icon
                                                                                    as={
                                                                                        action.Icon
                                                                                    }
                                                                                />
                                                                            }
                                                                            _hover={{
                                                                                bg: 'whiteAlpha.100',
                                                                            }}
                                                                        >
                                                                            {action.label}
                                                                        </Button>
                                                                    </Link>
                                                                )
                                                            )}
                                                        </VStack>
                                                    </VStack>
                                                    <HStack w="full" py={2}>
                                                        <Button
                                                            {...props.account.view.logout
                                                                .button}
                                                            py={2}
                                                            px={3}
                                                            fontWeight="inherit"
                                                            borderRadius="none"
                                                            bg="none"
                                                            width="full"
                                                            justifyContent="start"
                                                            iconSpacing={3}
                                                            leftIcon={
                                                                props.account.view.logout
                                                                    .Icon ? (
                                                                    <Icon
                                                                        as={
                                                                            props.account
                                                                                .view
                                                                                .logout
                                                                                .Icon
                                                                        }
                                                                    />
                                                                ) : undefined
                                                            }
                                                            _hover={{
                                                                bg: 'whiteAlpha.100',
                                                            }}
                                                        >
                                                            Logout
                                                        </Button>
                                                    </HStack>
                                                </VStack>
                                            </PopoverBody>
                                        </PopoverContent>
                                    </Popover>
                                </HStack>
                            </HStack>
                        </HStack>
                    </GridItem>
                    {props.status && (
                        <GridItem>
                            <Alert {...props.status.alert}>
                                <AlertIcon />
                                <AlertTitle w="full">
                                    <HStack w="full" justify="space-between">
                                        <Text>{props.status.label}</Text>
                                        <Link {...props.status.action.link}>
                                            <Button variant="link">
                                                {props.status.action.label}
                                            </Button>
                                        </Link>
                                    </HStack>
                                </AlertTitle>
                            </Alert>
                        </GridItem>
                    )}
                    <GridItem
                        bg="black"
                        overflowY="scroll"
                        css={{
                            scrollbarGutter: 'stable both-edges',
                            '&::-webkit-scrollbar': {
                                '-webkit-appearance': 'none',
                                width: '0.5rem',
                            },
                            '&::-webkit-scrollbar-track': {},
                            '&::-webkit-scrollbar-corner': {
                                '-webkit-appearance': 'none',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                borderRadius: '1rem',
                                background: scrollbarBg,
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                // background: scrollbarBg,
                                background: scrollbarHoverBg,
                            },
                        }}
                    >
                        <Suspense
                            fallback={
                                <Center h="full" w="full">
                                    <CardSpinner />
                                </Center>
                            }
                        >
                            {props.children}
                        </Suspense>
                    </GridItem>
                </Grid>
                <Invite {...props.account.view.invite.view} />
            </>
        );
    };
}
