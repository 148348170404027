import axios, { AxiosHeaderValue, AxiosInstance } from 'axios';
import { Http } from '../../base';

export function createHttpImpl(config: { axios: AxiosInstance }): Http {
    const { axios: instance } = config;
    return {
        request(context, request) {
            const headers: Record<string, AxiosHeaderValue | undefined> = {
                ...request.headers,
            };
            if (context.scheme.kind === 'apikey') {
                if (context.scheme.assume?.kind === 'account') {
                    console.log('assuming principal', context.scheme.assume);
                    headers['x-varos-account'] = context.scheme.assume.id;
                }
                return instance.request({
                    ...request,
                    headers,
                    params: {
                        ...request.params,
                        'api-key': context.scheme.value,
                    },
                });
            } else {
                console.log('sending bearer request', context, request);
                return instance.request({
                    ...request,
                    headers: {
                        ...request.headers,
                        Authorization: `Bearer ${context.scheme.value}`,
                    },
                });
            }
        },
    };
}
