import { useMemo, useState } from 'react';
import { useWorkspaceContextV2 } from '../../../context';
import { MetricSelectController } from './selectInterface';

export function createMetricSelectController(): MetricSelectController {
    return {
        useProps(props) {
            const [search, setSearch] = useState('');
            // const {
            //     connections,
            //     metric: {
            //         definitions: { data: metrics = [] },
            //     },
            // } = useWorkspaceContextV2();
            const connections = props.connections;
            const { data: metrics = [] } = props.definitions;

            const connectedPlugins = useMemo(
                () =>
                    new Set(
                        connections.data?.flatMap((item) =>
                            item.status === 'ready' ? [item.plugin.id] : []
                        )
                    ),
                [connections.data]
            );

            const filtered = useMemo(
                () =>
                    metrics
                        .filter((item) => connectedPlugins.has(item.plugin))
                        .filter(
                            (item) =>
                                item.name.toLowerCase().includes(search) ||
                                item.plugin.includes(search.toLowerCase())
                        ),
                [search, metrics]
            );

            return {
                items: metrics,
                filtered,
                isSelected(item) {
                    return item.id === props.selected?.id;
                },
                onSelect(item) {
                    props.onSelect(item);
                },
                fields: {
                    search: {
                        value: search,
                        onChange(event) {
                            return setSearch(event.target.value);
                        },
                    },
                },
            };
        },
    };
}
