import { useQuery } from '@tanstack/react-query';
import { DatasetConnectionAdapter } from './connectionAdapter';
import { DatasetConnectionRepository } from './connectionInterface';

export function createDatasetConnectionRepository(
    adapter: DatasetConnectionAdapter
): DatasetConnectionRepository {
    return {
        useFind(context, query, options) {
            const result = useQuery({
                ...options,
                retry: false,
                queryKey: ['dataset', 'connection', context.account, query],
                async queryFn() {
                    const response = await adapter.find(context, query);
                    return response;
                },
            });
            return result;
        },
    };
}
