// export async function preloadImages(urls: string[]): Promise<void> {
//     const promises = urls.map((url) => {
//         return new Promise<void>((resolve, reject) => {
//             const img = new Image();
//             img.src = url;
//             img.onload = () => resolve();
//             img.onerror = (err) => reject(err);
//         });
//     });
//     await Promise.all(promises);
// }

export async function preloadImages(urls: string[]): Promise<void> {
    const promises = urls.map((url) => {
        return new Promise<void>((resolve, reject) => {
            const img = new Image();
            img.src = url;
            img.onload = () => {
                document.body.removeChild(img);
                resolve();
            };
            img.onerror = (err) => {
                document.body.removeChild(img);
                console.warn('failed to load image url', url, err);
                reject(err);
            };
            img.style.display = 'none';
            document.body.appendChild(img);
        });
    });
    await Promise.all(promises);
}
