import { AxiosResponse } from 'axios';
import {
    RegistrationRequest,
    User,
    MeUser,
    UserUpdateRequest,
    LoginResponse,
} from '../models/Account';
import { apiV1 } from './axios';

export const fetchMyAccount = async () => {
    return apiV1.get<unknown, AxiosResponse<MeUser>>('/users/me');
};

export const searchUser = async (q: string) => {
    return apiV1.get<unknown, AxiosResponse<Array<User>>>(`/users/search?q=${q}`);
};

export const registerUserWithOrg = async (user: RegistrationRequest) => {
    return apiV1.post<User>('/users/register', user);
};

export const updateUserWithOrg = async (user: UserUpdateRequest) => {
    return apiV1.patch<unknown, AxiosResponse<User>>('/users/me', user);
};

export const updateFinishedOnBoarding = (accountId: string) => {
    // old code, do nuthing, just fetch the account
    return fetchMyAccount();
};

export const loginToAccount = async (accountId: string) => {
    return apiV1.post<unknown, AxiosResponse<LoginResponse>>(`/login/account`, {
        account_id: accountId,
    });
};

export interface AccountProps {
    identification_props: Record<string, any>;
    event_props: Record<string, any>;
}

export const fetchAcountProps = async () => {
    return apiV1.get<unknown, AxiosResponse<AccountProps>>(`/account/properties`);
};
