import { zodResolver } from '@hookform/resolvers/zod';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ChakraStepperControllerProps } from '../../../../../vendor/chakra-stepper';
import { RegistrationContainerConfig } from '../../../base';
import { RegistrationAcceptContextProvider } from './context';
import {
    AcceptOrganizationFormValues,
    AcceptOrganizationFormValuesSchema,
    AcceptProfileFormValues,
    AcceptProfileFormValuesSchema,
} from './fragment';
import {
    buildInitialOrganizationFormValues,
    buildInitialProfileFormValues,
} from './registrationAcceptCreateFactory';
import {
    RegistrationAcceptCreateController,
    RegistrationAcceptCreateLoader,
    RegistrationAcceptTheme,
} from './registrationAcceptCreateInterface';
import {
    RegistrationAcceptCreateContainerProps,
    RegistrationAcceptCreateViewProps,
} from './registrationAcceptCreateProps';

export function createRegistrationAcceptCreateContainer(
    config: RegistrationContainerConfig & {
        accept: {
            context: RegistrationAcceptContextProvider;
        };
    },
    loader: RegistrationAcceptCreateLoader,
    controller: RegistrationAcceptCreateController,
    View: {
        Desktop: React.FC<RegistrationAcceptCreateViewProps>;
        Mobile: React.FC<RegistrationAcceptCreateViewProps>;
    }
): React.FC<RegistrationAcceptCreateContainerProps> {
    const {
        accept: {
            context: { Provider: ContextProvider },
        },
        context: { useContext },
    } = config;

    return (containerProps) => {
        const [searchParams, setSearchParams] = useSearchParams();
        const context = useContext();

        const forms = {
            profile: useForm<AcceptProfileFormValues>({
                resolver: zodResolver(AcceptProfileFormValuesSchema),
            }),
            organization: useForm<AcceptOrganizationFormValues>({
                resolver: zodResolver(AcceptOrganizationFormValuesSchema),
            }),
        };

        const stepper: ChakraStepperControllerProps = {
            selected: containerProps.step ?? 0,
            onChange(selected) {
                searchParams.set('step', selected.toString());
                setSearchParams(searchParams, { replace: true });
            },
        };

        const data = loader.useLoad(context, containerProps);

        useEffect(() => {
            const profile = buildInitialProfileFormValues(data);
            const organization = buildInitialOrganizationFormValues(data);

            const { success: profileValid } =
                AcceptProfileFormValuesSchema.safeParse(profile);
            const { success: organizationValid } = organization
                ? AcceptOrganizationFormValuesSchema.safeParse(organization)
                : { success: true };

            forms.profile.reset(profile);
            if (organization) {
                forms.organization.reset(organization);
            }

            if (stepper.selected === 0) {
                return;
            } else if (!profileValid) {
                console.info('navigating to incomplete profile step');
                stepper.onChange(1);
            } else if (!organizationValid) {
                console.info('navigating to incomplete organization step');
                stepper.onChange(2);
            }
        }, []);

        const props = controller.useProps(data, {
            navigate: useNavigate(),
            stepper,
        });

        return (
            <ContextProvider
                value={{
                    ...containerProps,
                    stepper,
                    form: forms,
                    data,
                    metadata: {
                        invitationId: data.invitation.id,
                        invitationStatus: data.invitation.status,
                        invitationSourceId: data.invitation.source?.id,
                        invitationSourceKind: data.invitation.source?.kind,
                    },
                }}
            >
                {props.browser.mode === 'desktop' && <View.Desktop {...props} />}
                {props.browser.mode === 'mobile' && <View.Mobile {...props} />}
            </ContextProvider>
        );
    };
}
