import React from 'react';
import { ReportInstanceDetailContentContainerProps } from './content';
import { ReportContainerBaseConfig } from '../../../base';
import { InstanceReportDetailParameterSchema } from './reportDetailSchema';
import { ReportInstanceDetailStatusContainerProps } from './status';
import {
    ReportInstanceDetailController,
    ReportInstanceDetailLoader,
} from './reportDetailInterface';
import { ReportInstanceDetailContainerProps } from './reportDetailProps';

export function createReportInstanceDetailContainer(
    config: ReportContainerBaseConfig,
    loader: ReportInstanceDetailLoader,
    controller: ReportInstanceDetailController,
    View: {
        Content: React.FC<ReportInstanceDetailContentContainerProps>;
        Status: React.FC<ReportInstanceDetailStatusContainerProps>;
    }
): React.FC<ReportInstanceDetailContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        context: { useContext },
    } = config;

    const { Content: ContentView, Status: StatusView } = View;

    return ({ ...containerProps }) => {
        const options = useOptions(InstanceReportDetailParameterSchema);
        const context = useContext();
        const data = loader.useLoad(context, options);
        const props = controller.useProps(context, data, {});
        if (props.content) {
            return <ContentView {...props.content} />;
        }
        return <StatusView {...props.status} />;
    };
}
