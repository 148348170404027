import React from 'react';
import { ReportContainerBaseConfig } from '../../../../base';

import {
    ReportLayoutController,
    ReportLayoutLoader,
    ReportLayoutStateProvider,
} from './reportLayoutInterface';
import { ReportLayoutContainerProps, ReportLayoutViewProps } from './reportLayoutProps';
import { ReportLayoutUrlParamSchema } from './reportLayoutSchema';

export function createReportLayoutContainer(
    config: ReportContainerBaseConfig,
    states: ReportLayoutStateProvider,
    loader: ReportLayoutLoader,
    controller: ReportLayoutController,
    View: React.FC<{ children: React.ReactElement } & ReportLayoutViewProps>
): React.FC<ReportLayoutContainerProps> {
    const {
        kernel: {
            infra: {
                optionParser: { useOptions },
            },
        },
        context: { useContext },
    } = config;
    return ({ children, ...containerProps }) => {
        const options = useOptions(ReportLayoutUrlParamSchema);
        const state = states.useState();
        const context = useContext();
        const data = loader.useLoad(context, containerProps);
        const props = controller.useProps(context, data, {
            ...options,
            detail: state.detail,
        });
        return (
            <View {...containerProps} {...props}>
                {children}
            </View>
        );
    };
}
