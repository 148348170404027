import { DashboardApi } from './dashboardInterface';
import { DashboardApiConfig } from './dashboardConfig';
import { createPluginResource } from './plugin';
import { createViewResource } from './view';
// import { createMetricCollectionResource } from '../metrics/collection';
// import { createMetricDefinitionResource } from '../metrics';
import { createDashboardResource } from './dashboard/dashboardResource';

export function createDashboardApi(config: DashboardApiConfig): DashboardApi {
    const { client } = config;
    return {
        plugins: createPluginResource(client),
        views: createViewResource(client),
        dashboards: createDashboardResource(client),
    };
}
