import {
    Alert,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    ButtonGroup,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    StackDivider,
    Text,
    VStack,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { AiOutlineCheck } from 'react-icons/ai';
import { PlanSelectItemProps } from 'src/v2/view/billing';
import { PlanModalConfig } from './planModalConfig';
import { PlanModalViewProps } from './planModalProps';

export function createPlanModalView(
    config: Pick<PlanModalConfig, 'UI'>
): React.FC<{ children?: React.ReactNode | undefined } & PlanModalViewProps> {
    const {
        UI: { Link },
    } = config;
    const PlanItem: React.FC<
        { children?: React.ReactNode | undefined } & PlanSelectItemProps
    > = (props) => {
        return (
            <VStack w="full" align="start" spacing={6} fontWeight="medium">
                <VStack align="start" w="full" spacing={2}>
                    <Heading fontSize="lg">{props.name}</Heading>
                    <Text fontSize="lg" color="whiteAlpha.700">
                        {props.price.label}
                    </Text>
                </VStack>
                {props.isCurrent && (
                    <Button
                        w="full"
                        borderRadius="full"
                        variant="outline"
                        _focus={{ outline: 'none' }}
                        // isDisabled={true}
                        cursor="default"
                        bg="whiteAlpha.300"
                        _hover={{
                            bg: 'whiteAlpha.300',
                        }}
                    >
                        Your current plan
                    </Button>
                )}
                {!props.isCurrent && (
                    <Button
                        w="full"
                        borderRadius="full"
                        colorScheme="green"
                        variant="outline"
                        onClick={props.onSelectClick}
                        _focus={{ outline: 'none' }}
                    >
                        Upgrade to {props.name}
                    </Button>
                )}
                <VStack w="full" align="start" spacing={3}>
                    {props.features.label && (
                        <Text fontSize="sm" fontWeight="semibold">
                            {props.features.label}
                        </Text>
                    )}
                    <VStack w="full" align="start" spacing={3}>
                        {props.features.items.map((item, index) => (
                            <HStack
                                key={index}
                                spacing={3}
                                w="full"
                                color="whiteAlpha.700"
                            >
                                <Icon as={AiOutlineCheck} />
                                <Text>{item.label}</Text>
                            </HStack>
                        ))}
                    </VStack>
                </VStack>
            </VStack>
        );
    };

    return (props) => {
        // HACK to fix close animation because when closing the addon modal
        // the "isAddOnRequired" prop gets set to false somewhere
        // const [isAddon, setIsAddon] = useState(false);
        // useEffect(() => {
        //     if (props.isAddOnRequired === true) {
        //         setIsAddon(true);
        //     } else {
        //         setIsAddon(false);
        //     }
        // }, [props.isAddOnRequired]);
        // console.log('props.isAddOnRequired', {
        //     isAddOnRequired: props.isAddOnRequired,
        //     isAddon,
        // });
        return (
            <Modal
                {...props.modalProps.containerProps}
                size={props.isAddOnRequired ? '2xl' : '6xl'}
                closeOnOverlayClick={true}
                closeOnEsc={true}
            >
                <ModalOverlay />
                <ModalContent top={props.isAddOnRequired ? '10vh' : undefined}>
                    <ModalCloseButton _focus={{ outline: 'none' }} />
                    <ModalBody p={0}>
                        <VStack
                            w="full"
                            align="start"
                            divider={
                                <StackDivider
                                    borderColor="whiteAlpha.200"
                                    borderWidth={1}
                                />
                            }
                            spacing={0}
                        >
                            {props.isAddOnRequired ? (
                                <VStack align="start" w="full" p={6} spacing={5}>
                                    <HStack w="full">
                                        <Heading fontSize="lg">
                                            Update your subscription
                                        </Heading>
                                    </HStack>
                                    <HStack w="full">
                                        <Alert status="warning" w="full">
                                            <AlertIcon />
                                            <AlertTitle w="full">
                                                <HStack
                                                    w="full"
                                                    justify="space-between"
                                                    spacing={12}
                                                >
                                                    <Link {...props.settingsLinkProps}>
                                                        <Text
                                                            cursor="pointer"
                                                            userSelect="none"
                                                            _hover={{
                                                                textDecor: 'underline',
                                                            }}
                                                            noOfLines={1}
                                                        >
                                                            Feature requires a
                                                            subscription add-on
                                                        </Text>
                                                    </Link>
                                                    <Link {...props.settingsLinkProps}>
                                                        <Text
                                                            whiteSpace="nowrap"
                                                            cursor="pointer"
                                                            userSelect="none"
                                                            _hover={{
                                                                textDecor: 'underline',
                                                            }}
                                                        >
                                                            View add-ons
                                                        </Text>
                                                    </Link>
                                                </HStack>
                                            </AlertTitle>
                                        </Alert>
                                    </HStack>
                                    {/* <Link {...props.settingsLinkProps}>
                                        <Button
                                            w="full"
                                            borderRadius="full"
                                            colorScheme="green"
                                            variant="outline"
                                            _focus={{ outline: 'none' }}
                                        >
                                            View add-ons
                                        </Button>
                                    </Link> */}
                                    <HStack
                                        w="full"
                                        justify="space-between"
                                        color="whiteAlpha.500"
                                        fontWeight="medium"
                                    >
                                        <HStack>
                                            <Text>Have questions?</Text>
                                            <Button
                                                cursor="pointer"
                                                variant="link"
                                                onClick={props.contactButtonProps.onClick}
                                                as="span"
                                                color="blue.300"
                                                textDecoration="underline"
                                                fontWeight="medium"
                                            >
                                                Contact us
                                            </Button>
                                        </HStack>
                                        <HStack>
                                            <Link {...props.settingsLinkProps}>
                                                <Text textDecoration="underline">
                                                    Manage subscription
                                                </Text>
                                            </Link>
                                        </HStack>
                                    </HStack>
                                </VStack>
                            ) : (
                                <>
                                    <HStack w="full" p={6}>
                                        <Heading fontSize="lg">Upgrade your plan</Heading>
                                    </HStack>
                                    <VStack w="full" spacing={3} p={6}>
                                        {/* <HStack w="full" justify="center">
                                            <ButtonGroup isAttached={true}>
                                                <Button
                                                    minW="12rem"
                                                    colorScheme="blue"
                                                    variant="outline"
                                                >
                                                    Brands
                                                </Button>
                                                <Button minW="12rem">Agencies</Button>
                                            </ButtonGroup>
                                        </HStack> */}
                                        <Text
                                            fontWeight="medium"
                                            fontSize="sm"
                                            color="whiteAlpha.700"
                                            fontStyle="italic"
                                        >
                                            Brands and agencies have different pricing
                                        </Text>
                                    </VStack>
                                    <Grid
                                        w="full"
                                        templateColumns={{ base: 'repeat(4, 1fr)' }}
                                        overflowX="scroll"
                                    >
                                        {props.selectProps.items
                                            // .slice(0, 3)
                                            .map((item, index) => (
                                                <GridItem
                                                    key={item.id}
                                                    borderLeftWidth={
                                                        // index % 3 !== 0 ? 2 : undefined
                                                        index % 4 !== 0 ? 2 : undefined
                                                    }
                                                    borderColor="whiteAlpha.200"
                                                    borderStyle="solid"
                                                    p={6}
                                                    pb={12}
                                                >
                                                    <PlanItem
                                                        {...props.selectProps.getItemProps(
                                                            item,
                                                            index
                                                        )}
                                                    />
                                                </GridItem>
                                            ))}
                                    </Grid>
                                    <HStack
                                        w="full"
                                        justify="space-between"
                                        p={6}
                                        color="whiteAlpha.500"
                                        fontWeight="medium"
                                    >
                                        <HStack>
                                            <Text>Have questions?</Text>
                                            <Button
                                                cursor="pointer"
                                                variant="link"
                                                onClick={props.contactButtonProps.onClick}
                                                as="span"
                                                color="blue.300"
                                                textDecoration="underline"
                                                fontWeight="medium"
                                            >
                                                Contact us
                                            </Button>
                                        </HStack>
                                        <HStack>
                                            <Link {...props.settingsLinkProps}>
                                                <Text textDecoration="underline">
                                                    Manage subscription
                                                </Text>
                                            </Link>
                                        </HStack>
                                    </HStack>
                                </>
                            )}
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        );
    };
}
