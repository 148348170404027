import React, { useMemo } from 'react';
import { LockIcon } from '@chakra-ui/icons';
import pluralize from 'pluralize';
import { ApplicationEntryEnhancer } from '../../../entrypoint';
import { useFeatureApi } from '../../../app';
import { useAccountContextV2 } from '../../../context';
import {
    OpportunityCollectionController,
    OpportunityCollectionViewProps,
} from '../../../view';
import { INSIGHT_WORKSPACE_ITEM_ID } from '../../workspace';
import { isLoadedCollection } from '../../../base';

export function createFeatureInsightsStrategy(): ApplicationEntryEnhancer {
    return (create) => (config) => {
        const {
            controller: {
                insights: {
                    opportunity: { createCollection },
                },
            },
            module: { createInsightModule },
            route: { createHomeRoute },
        } = config;

        // function enhanceInsightController(
        //     controller: InsightController
        // ): InsightController {
        //     return {
        //         useProps(...args) {
        //             const [context, formatter, props] = args;
        //             const api = useFeatureApi();
        //             const {
        //                 data: {
        //                     features: { data: features },
        //                 },
        //             } = useSystemContextV2();

        //             const viewProps = controller.useProps(context, formatter, {
        //                 ...props,
        //             });

        //             if (!features?.insights) {
        //                 return viewProps;
        //             }

        //             const { limit = null } = features?.insights.config ?? {};
        //             // const limit = 1;
        //             if (limit === null) {
        //                 return viewProps;
        //             }

        //             const overrides = useMemo<Partial<InsightViewProps>>(() => {
        //                 const truncatedCount = viewProps.items.length - limit;
        //                 let title = `Upgrade to unlock all insights`;
        //                 if (truncatedCount > 10) {
        //                     title = `Upgrade to unlock 10+ insights`;
        //                 } else if (truncatedCount > 5) {
        //                     title = `Upgrade to unlock 5+ insights`;
        //                 }
        //                 return {
        //                     items: viewProps.items.slice(0, limit),
        //                     status: {
        //                         title: title,
        //                         buttonProps: {
        //                             children: 'Upgrade',
        //                             onClick: api.restrictClick(features.insights),
        //                         },
        //                     },
        //                 };
        //             }, [viewProps.items, limit]);

        //             return { ...viewProps, ...overrides };
        //         },
        //     };
        // }

        function enhanceOpportunityController(
            controller: OpportunityCollectionController
        ): OpportunityCollectionController {
            return {
                useProps(...args) {
                    const [context, props] = args;
                    const api = useFeatureApi();
                    const {
                        data: {
                            // features: { data: features },
                            features: features,
                        },
                    } = useAccountContextV2();

                    const viewProps = controller.useProps(...args);

                    if (!features?.insights) {
                        return viewProps;
                    }

                    const { limit = null } = features?.insights.config ?? {};

                    // const limit = 1;
                    if (limit === null) {
                        return viewProps;
                    }

                    const overrides = useMemo<
                        Partial<OpportunityCollectionViewProps>
                    >(() => {
                        if (!viewProps.collection.data?.items) {
                            return {};
                        }
                        const truncatedCount =
                            viewProps.collection.data.items.length - limit;
                        let title = `Upgrade to unlock all opportunities`;
                        if (truncatedCount > 10) {
                            title = `Upgrade to unlock 10+ opportunities`;
                        } else if (truncatedCount > 5) {
                            title = `Upgrade to unlock 5+ opportunities`;
                        }
                        return {
                            collection: {
                                ...viewProps.collection,
                                data: {
                                    ...viewProps.collection.data,
                                    total: truncatedCount,
                                    items: viewProps.collection.data.items.slice(
                                        0,
                                        limit
                                    ),
                                },
                            },
                            getCollectionLabel() {
                                if (!isLoadedCollection(props.items)) {
                                    return null;
                                }
                                const pluralized = pluralize(
                                    'opportunity',
                                    props.items.data.items.length
                                );
                                let title = `${props.items.data.items.length} ${pluralized} found`;
                                if (truncatedCount > 10) {
                                    title = `10+ opportunities found`;
                                } else if (truncatedCount > 5) {
                                    title = `5+ opportunities found`;
                                }
                                return title;
                            },
                            getStatusProps() {
                                return {
                                    Icon: LockIcon,
                                    title,
                                    description: null,
                                    button: {
                                        children: 'Upgrade',
                                        onClick: features.insights
                                            ? api.restrictClick(features.insights)
                                            : undefined,
                                    },
                                };
                            },
                        };
                    }, [viewProps.collection.data?.items, limit]);

                    return { ...viewProps, ...overrides };
                },
            };
        }

        return create({
            ...config,
            controller: {
                ...config.controller,
                insights: {
                    ...config.controller.insights,
                    opportunity: {
                        ...config.controller.insights.opportunity,
                        createCollection(config) {
                            const controller = createCollection(config);
                            return enhanceOpportunityController(controller);
                        },
                    },
                },
            },
            route: {
                ...config.route,
                createHomeRoute(config) {
                    return createHomeRoute({
                        ...config,
                        config: {
                            ...config.config,
                            views: config.config.views.map((view) => {
                                // if (view.id === HOME_OPPORTUNITY_VIEW_ID) {
                                //     return view;
                                // }
                                return view;
                            }),
                        },
                        providers: {
                            ...config.providers,
                            root: {
                                ...config.providers.root,
                                createController(controllerConfig) {
                                    const controller =
                                        config.providers.root.createController(
                                            controllerConfig
                                        );
                                    return {
                                        ...controller,
                                        useProps(...args) {
                                            const [context, workspace, deps] = args;
                                            const props = controller.useProps(...args);

                                            return {
                                                ...props,
                                                isEnabled(item) {
                                                    // if (
                                                    //     (item.id === HOME_ALERTS_VIEW_ID ||
                                                    //         item.id ===
                                                    //             HOME_OPPORTUNITY_VIEW_ID) &&
                                                    //     !features?.insights.enabled
                                                    // ) {
                                                    //     console.info(
                                                    //         'insights feature not enabled, hiding section...'
                                                    //     );
                                                    //     return false;
                                                    // }
                                                    return props.isEnabled(item);
                                                },
                                            };
                                        },
                                    };
                                },
                            },
                        },
                    });
                },
            },
            container: {
                ...config.container,
                workspaces: {
                    ...config.container.workspaces,
                    createWorkspaceInsightContainer(containerConfig) {
                        const original =
                            config.container.workspaces.createWorkspaceInsightContainer(
                                containerConfig
                            );
                        if (original.id !== INSIGHT_WORKSPACE_ITEM_ID) {
                            return original;
                        }
                        return {
                            ...original,
                            useProps(inp) {
                                // const organization =
                                //     containerConfig.hooks.useOrganization();

                                const api = useFeatureApi();
                                const featureset = api.getFeatureset();
                                // const context = useContext(FeatureFlagContext);
                                // if (!context) {
                                //     throw new Error(`feature flag context not found`);
                                // }
                                const props = original.useProps(inp);

                                // const isPreviewOrg = preview.has(organization.id);

                                // useEffect(() => {
                                //     if (isPreviewOrg) {
                                //         console.info('enabling insights for preview org');
                                //     }
                                // }, [isPreviewOrg]);

                                const isEnabled = featureset.insights?.enabled;
                                // || isPreviewOrg;

                                // const isEnabled = true;

                                return {
                                    ...props,
                                    enabled: isEnabled,
                                };
                            },
                        };
                    },
                },
            },
            // TODO reenable this feature restriction after preview phase

            // container: {
            //     ...config.container,
            //     workspaces: {
            //         createWorkspaceInsightContainer(containerConfig) {
            //             const container =
            //                 config.container.workspaces.createWorkspaceInsightContainer(
            //                     containerConfig
            //                 );
            //             return {
            //                 ...container,
            //                 useProps(_) {
            //                     const api = useFeatureApi();
            //                     const featureset = api.getFeatureset();

            //                     const viewProps = container.useProps({});
            //                     let onClick = viewProps.onClick;

            //                     if (!featureset.insights.enabled) {
            //                         onClick = (event) => {
            //                             event.preventDefault();
            //                             event.stopPropagation();
            //                             api.restrict(featureset.insights);
            //                         };
            //                     }

            //                     return {
            //                         ...viewProps,
            //                         onClick,
            //                     };
            //                 },
            //             };
            //         },
            //     },
            // },
        });
    };
}
