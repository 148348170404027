import { assert } from '@varos/util-typescript';
import { BaseApplicationShellLoaderConfig } from '../../base';
import { ShellLayoutLoader } from './applicationShellInterface';
import { useMemo } from 'react';
import {
    ApplicationAccountAggregate,
    ApplicationWorkspaceListAggregate,
} from '../../view';

export function createApplicationShellLoader(
    config: BaseApplicationShellLoaderConfig
): ShellLayoutLoader {
    const { repository } = config;
    return {
        useLoad(context, props) {
            const meQuery = repository.me.useLookup(context, {
                suspense: true,
                staleTime: Infinity,
            });

            assert(meQuery.status === 'success', 'expected suspense');
            const accountQuery = repository.account.useLookup(
                context,
                {
                    id: meQuery.data.account.id,
                },
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );

            const assetQuery = repository.asset.useFind(
                {
                    auth: context.auth,
                    account: {
                        id: meQuery.data.account.id,
                    },
                },
                {
                    expand: ['claim'],
                    visible: true,
                },
                {
                    suspense: true,
                    staleTime: Infinity,
                }
            );

            assert(assetQuery.status === 'success', 'expected suspense');
            assert(accountQuery.status === 'success', 'expected suspense');

            const account = useMemo(
                (): ApplicationAccountAggregate => ({
                    me: meQuery.data,
                    account: accountQuery.data,
                }),
                [meQuery.data, accountQuery.data]
            );

            const workspace = useMemo(
                (): ApplicationWorkspaceListAggregate => ({
                    account: accountQuery.data,
                    workspaces: assetQuery.data.map((item) => ({ asset: item })),
                }),
                [assetQuery.data, accountQuery.data]
            );

            return {
                account,
                workspace,
            };
        },
    };
}
