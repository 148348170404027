import { HelpAdapter } from '../../base';
import { IntercomHelpImplConfig } from './intercomHelpConfig';

export function createIntercomHelpImpl(config: IntercomHelpImplConfig): HelpAdapter {
    function log(...messages: any[]) {
        const client = config.getClient();
        console.info('[Intercom]', ...messages);
        if (!client) {
            console.info('intercom not available in environment');
        }
    }
    return {
        open() {
            const client = config.getClient();
            log('opening widget');
            if (!client) {
                return;
            }
            client('show');
        },
        show() {
            const client = config.getClient();
            log('showing launcher');
            if (!client) {
                return;
            }
            client('update', {
                hide_default_launcher: false,
            });
        },
        hide() {
            const client = config.getClient();
            log('hiding launcher');
            if (!client) {
                return;
            }
            client('update', {
                hide_default_launcher: true,
            });
        },
    };
}
