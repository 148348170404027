import { PageIds } from '../../../../config';
import { StudiesControllerBaseConfig } from '../../base';
import { StudiesExploreRouteConfig } from './studiesReportListConfig';
import { StudiesExploreController } from './studiesReportListInterface';

export function createStudiesExploreController(
    config: Pick<StudiesExploreRouteConfig, 'controller'> &
        Pick<StudiesControllerBaseConfig, 'kernel'>
): StudiesExploreController {
    const {
        kernel: {
            controller: { browser: browserController },
        },
        controller: {
            page: pageController,
            list: listController,
            filter: filterController,
            help: helpController,
        },
    } = config;
    return {
        useProps(context, item, props) {
            const browser = browserController.useProps({});
            const pageProps = pageController.useProps(context, {
                item: {
                    id: PageIds.REPORTS_LIST,
                    breadcrumbs: [],
                },
            });
            const studyProps = {
                fulfilled: listController.useProps(context, {
                    item: item.study.fulfilled,
                }),
                unfulfilled: listController.useProps(context, {
                    item: item.study.unfulfilled,
                }),
            };
            const filterProps = filterController.useProps(context, {
                item: item.filter,
            });
            const helpProps = helpController.useProps({
                id: 'request_a_study',
            });
            return {
                browser,
                page: pageProps,
                study: studyProps,
                filter: filterProps,
                help: helpProps,
            };
        },
    };
}
