import { useBreakpointValue, useColorMode, useMediaQuery } from '@chakra-ui/react';
import { BrowserConfig } from './browserConfig';
import { BrowserController } from './browserInterface';

export function createBrowserController(config: BrowserConfig = {}): BrowserController {
    return {
        useProps(props) {
            const mode =
                useBreakpointValue({ base: 'mobile', md: 'desktop' } as const, {
                    ssr: false,
                }) ?? ('desktop' as const);

            const { colorMode } = useColorMode();
            return { mode, color: colorMode };
        },
    };
}
